import { useContext, useState } from "react";
import moment from "moment";
import Countdown from "react-countdown";
function FailedQuizPopup({ onClose, failedTime }) {
  let date = new Date();
  let differance = moment.duration(moment(failedTime).add(24, "hours").diff(moment(date)));
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <>Start</>;
    } else {
      // Render a countdown
      return (
        <>
          Retry in {hours}:{minutes}:{seconds}
        </>
      );
    }
  };
  return (
    <>
      <div className="modal commanModel testFailedPopup showBlock" id="testFailedPopup">
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="modalContant">
                    <div className="modelTopBox">
                      <div className="modelSubHeading">
                        <span>LOW SCORE</span>
                      </div>
                      <div className="modelHeading">
                        <h2>0 COINS RECEIVED</h2>
                      </div>
                      <div className="modelSpinningIcon">
                        <div className="spinnerIcon">
                          <img src="/images/svg-icons/red-circle-logo.svg" alt="circleLogo" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                    <div className="modelMiddleContant">
                      <h4 className="mb-0">
                        You did not pass the Reward Test{" "}
                        <img src="/images/svg-icons/the-reward-test.png" alt="the-reward-test" className="img-fluid" />
                      </h4>
                      <p className="mb-0">
                        You scored <strong>below 60%</strong> on the Reward Test. You can retake the test after a 24
                        hour gap to claim the rewards for this course.
                      </p>
                    </div>
                    <div className="modelBtn d-grid">
                      <a className="btn btn-block shareBtn">
                        <Countdown date={Date.now() + differance._milliseconds} renderer={renderer} />
                      </a>
                      <a type="button" className="btn btn-block closeBtn" onClick={onClose}>
                        Close
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
}

export default FailedQuizPopup;
