import util from '../util/util'
export default class AdminZoneService {
    getReferEarnDetails(dataId) {
        return util
            .sendApiRequest("/referearn/" + dataId, "GET", true)
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }
    saveReferEarnRecord(payload) {
        return util.sendApiRequest("/referearn", "POST", true, payload).then(
            (response) => {
                return response;
            },
            (error) => {
                throw error;
            }
        );
    }
    listEarnedCoins(data) {
        const activity = Object.keys(data).reduce((object, key) => {
            if (data[key] !== "") {
                object[key] = data[key];
            }
            return object;
        }, {});

        return util.sendApiRequest("/earnedcoins/list", "POST", true, activity).then(
            (response) => {
                return response;
            },
            (error) => {
                throw error;
            }
        );
    }
}