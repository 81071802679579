import { useContext, useEffect, useState } from "react";
import AccountService from "../../services/AccountService";
import { useFormik } from "formik";
import { GlobalContext } from "../../context/GlobalContext";
import * as Yup from "yup";
import moment from "moment";
import UpdateUserName from "../UpdateUserName/UpdateUserName";
import Loading from "../../shared/Loading";
const serv = new AccountService();
const ValidateSchema = Yup.object({
  full_name: Yup.string().required("Name is a required field"),
  user_name: Yup.string().required("User name is a required field"),
  mobile_no: Yup.string(),
  email: Yup.string().required("Email is a required field").email(),
  gender: Yup.string().required("Gender is a required field"),
  date_of_birth: Yup.string(),
  location: Yup.string(),
  website: Yup.string(),
  bio: Yup.string().required("Bio is a required field"),
  file: Yup.string(),
  show_date_of_birth: Yup.string(),
  show_location: Yup.string(),
});
function EditProfile({ onClose }) {
  const globalCtx = useContext(GlobalContext);
  const [user, setUser] = globalCtx.user;
  const [showUserNamePopup, setShowUserNamePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bioCounter, setBioCounter] = useState("");
  const [accountObj, setAccountObj] = new useState({
    full_name: "",
    user_name: "",
    mobile_no: "",
    email: "",
    gender: "",
    date_of_birth: "",
    location: "",
    website: "",
    bio: "",
    file: "",
    cover_image: "",
    show_date_of_birth: false,
    show_location: false,
  });
  useEffect(() => {
    getAccountDetails();
  }, [showUserNamePopup]);
  const getAccountDetails = async () => {
    let id = user._id;
    try {
      let resp = await serv.getUser(id);
      if (resp.data) {
        let new_obj = {
          full_name: resp.data?.full_name,
          user_name: resp.data?.user_name,
          mobile_no: resp.data?.mobile_no,
          email: resp.data?.email,
          gender: resp.data?.gender ? resp.data.gender : "Male",
          reward: resp.data?.reward,
          date_of_birth: resp.data?.date_of_birth ? moment(resp.data?.date_of_birth).format("YYYY-MM-DD") : "",
          location: resp.data?.location,
          website: resp.data.website ? resp.data.website : "",
          bio: resp.data?.bio,
          file: resp.data?.file,
          cover_image: resp.data?.cover_image,
          show_date_of_birth: resp.data?.show_date_of_birth ? true : false,
          show_location: resp.data?.show_location ? true : false,
        };
        setAccountObj(new_obj);
        setBioCounter(resp.data?.bio);
        // setUser({ ...user, ...new_obj });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    let id = user._id;
    const formData = new FormData();
    formData.append("full_name", values.full_name);
    formData.append("mobile_no", values.mobile_no);
    formData.append("email", values.email);
    formData.append("gender", values.gender);
    formData.append("date_of_birth", values.date_of_birth);
    formData.append("location", values.location);
    formData.append("bio", values.bio);
    formData.append("_id", id);
    formData.append("website", values.website);
    formData.append("file", values.file);
    formData.append("cover_image", values.cover_image);
    formData.append("show_date_of_birth", values.show_date_of_birth);
    formData.append("show_location", values.show_location);
    try {
      const resp = await serv.editUser(formData);
      setIsLoading(false);
      resetForm();
      getAccountDetails();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };
  const formik = useFormik({
    initialValues: accountObj,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  const handleUsernamePopup = () => {
    setShowUserNamePopup(!showUserNamePopup);
  };
  return (
    <>
      <div
        className="modal editModal showBlock"
        id="EditprofilePopup"
        onClick={(e) => {
          if (e.target == e.currentTarget) {
            onClose();
          }
        }}
      >
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title edit-profile-title">Edit Profile</h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      onClose();
                    }}
                  />
                </div>
                {/* Modal body */}
                <div className="modal-body p-0">
                  <div className="profilemodal_contet">
                    <div className="uploadFile position-relative">
                      <label
                        for="coverPicHide"
                        className="uploadCoverpic"
                        id="coverPicUploder"
                        style={{ width: "100%" }}
                      >
                        {typeof formik.values.cover_image == "string" ? (
                          formik.values.cover_image ? (
                            <div className="cover_instruct cover_instructCustom text-center">
                              <img src="images/svg-icons/addcamera.svg" className="img-fluid" />
                              <h4 className="mt-3 mb-2">Change Cover Picture</h4>
                              <p className="mb-0">880 x 200 px</p>
                            </div>
                          ) : (
                            <div className="cover_instruct cover_instructCustom text-center">
                              <img src="images/svg-icons/addcamera.svg" className="img-fluid" />
                              <h4 className="mt-3 mb-2">Upload Cover Picture</h4>
                              <p className="mb-0">880 x 200 px</p>
                            </div>
                          )
                        ) : (
                          <img
                            src={URL.createObjectURL(formik.values.cover_image)}
                            className="img-fluid"
                            style={{ height: "151px", width: "100%" }}
                          />
                        )}
                        <input
                          type="file"
                          className="form-control"
                          id="coverPicHide"
                          hidden
                          onChange={(event) => {
                            formik.setFieldValue("cover_image", event.currentTarget.files[0]);
                          }}
                        />
                      </label>
                      <div className="profile_div">
                        <label for="profilePicHide" className="profile_pic" id="profilePicUploder">
                          {typeof formik.values.file == "string" ? (
                            formik.values.file ? (
                              // <img src={accountObj.file} className="img-fluid" style={{ borderRadius: "100px", width: "100%" }} />
                              <div className="profile_instruct">
                                <img src="images/svg-icons/addcamera.svg" className="img-fluid" />
                                <p className="mt-1 mb-0">Change Profile Picture</p>
                              </div>
                            ) : (
                              <div className="profile_instruct">
                                <img src="images/svg-icons/addcamera.svg" className="img-fluid" />
                                <p className="mt-1 mb-0">Upload Profile Picture</p>
                              </div>
                            )
                          ) : (
                            <img
                              src={URL.createObjectURL(formik.values.file)}
                              className="img-fluid"
                              style={{ borderRadius: "100px", width: "100%", height: "100%" }}
                            />
                          )}
                          <input
                            type="file"
                            className="form-control"
                            id="profilePicHide"
                            hidden
                            onChange={(event) => {
                              formik.setFieldValue("file", event.currentTarget.files[0]);
                            }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="editprofile_fom">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="row editformgrid">
                          <div className="col-md-6 editformcol">
                            <div className="commonfield customBottomMargin">
                              <label htmlFor="fullname">Name</label>
                              <input
                                type="text"
                                className={
                                  "form-control" +
                                  (formik.touched.full_name && formik.errors.full_name ? " border-red" : "")
                                }
                                placeholder="Full Name"
                                name="full_name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.full_name}
                              />
                              {formik.touched.full_name && formik.errors.full_name ? (
                                <div className="formik-errors bg-error">{formik.errors.full_name}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-6 editformcol">
                            <div className="commonfield customBottomMargin">
                              <label htmlFor="username">Username</label>
                              <div
                                className={
                                  "form-group-custom formGroupCustom form-control " +
                                  (formik.touched.user_name && formik.errors.user_name ? " border-red" : "")
                                }
                              >
                                <img src="/images/svg-icons/at-sign.svg" />
                                <input
                                  type="text"
                                  // className={"" + (formik.touched.user_name &&
                                  //     formik.errors.user_name
                                  //     ? " border-red"
                                  //     : "")}
                                  placeholder="@userName"
                                  name="user_name"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.user_name}
                                  disabled
                                />
                                <a href="#" onClick={handleUsernamePopup}>
                                  Update
                                </a>
                              </div>
                              {formik.touched.user_name && formik.errors.user_name ? (
                                <div className="formik-errors bg-error">{formik.errors.user_name}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-6 editformcol">
                            <div className="commonfield customBottomMargin">
                              <label htmlFor="boiuser">Bio</label>
                              <lable className="bioCounter">
                                {bioCounter?.length > 0 ? bioCounter?.length : "0"}/250
                              </lable>
                              {/* <textarea className="form-control" rows={5} id="boiuser" defaultValue={"Tell the world your story using this bio space."} /> */}
                              <textarea
                                rows={5}
                                cols={23}
                                className={
                                  "form-control " + (formik.touched.bio && formik.errors.bio ? " border-red" : "")
                                }
                                placeholder="Tell the world your story using this bio space."
                                name="bio"
                                maxLength={250}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  setBioCounter(e.target.value);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.bio}
                              />
                              {formik.touched.bio && formik.errors.bio ? (
                                <div className="formik-errors bg-error">{formik.errors.bio}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-6 editformcol">
                            <div className="commonfield customBottomMargin">
                              <label htmlFor="location" className="d-flex justify-content-between">
                                Location
                                <span className="optionalLabel minusMargin60">(optional)</span>
                                <span className="d-flex align-items-center hideProfileCheck">
                                  <div className="accept_terms">
                                    <div className="form-group checkCustom">
                                      <input
                                        id="show_location"
                                        className="form-check-input"
                                        type="checkbox"
                                        name="show_location"
                                        checked={formik.values.show_location}
                                        onClick={() =>
                                          formik.setFieldValue("show_location", !formik.values.show_location)
                                        }
                                        // value={formik.values.accept}
                                      />
                                      <label htmlFor="show_location" className="m-0" />
                                    </div>
                                  </div>
                                  Hide on profile
                                </span>
                              </label>
                              {/* <input type="text" className="form-control" id="location" defaultValue="New Delhi, India" /> */}
                              <input
                                type="text"
                                className={
                                  "form-control " +
                                  (formik.touched.location && formik.errors.location ? " border-red" : "")
                                }
                                placeholder="New Delhi, India"
                                name="location"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.location}
                              />
                              {formik.touched.location && formik.errors.location ? (
                                <div className="formik-errors bg-error">{formik.errors.location}</div>
                              ) : null}
                            </div>
                            <div className="commonfield customBottomMargin m-0">
                              <label htmlFor="website">Website</label>
                              <span className="optionalLabel">(optional)</span>
                              {/* <input type="text" className="form-control weblink" id="website" defaultValue="0xshub.com" /> */}
                              <input
                                type="text"
                                className={
                                  "form-control " +
                                  (formik.touched.website && formik.errors.website ? " border-red" : "")
                                }
                                placeholder="www.abc.com"
                                name="website"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.website}
                              />
                              {formik.touched.website && formik.errors.website ? (
                                <div className="formik-errors bg-error">{formik.errors.website}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-6 editformcol mt-1">
                            <div className="commonfield customBottomMargin">
                              <label htmlFor="birthDate" className="d-flex justify-content-between me-8">
                                Birth Date
                                <span className="optionalLabel minusMargin60">(optional)</span>
                                <span className="d-flex align-items-center hideProfileCheck">
                                  <div className="accept_terms">
                                    <div className="form-group checkCustom">
                                      <input
                                        id="css"
                                        className="form-check-input"
                                        type="checkbox"
                                        name="show_date_of_birth"
                                        checked={formik.values.show_date_of_birth}
                                        onClick={() =>
                                          formik.setFieldValue("show_date_of_birth", !formik.values.show_date_of_birth)
                                        }
                                        // value={formik.values.accept}
                                      />
                                      <label htmlFor="css" className="m-0" />
                                    </div>
                                  </div>
                                  Hide on profile
                                </span>
                              </label>
                              {/* <input type="text" className="form-control" id="birthDate" defaultValue="25 May 2000" /> */}
                              <input
                                type="date"
                                className={
                                  "form-control " +
                                  (formik.touched.date_of_birth && formik.errors.date_of_birth ? " border-red" : "")
                                }
                                placeholder="25 May 2001"
                                name="date_of_birth"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.date_of_birth}
                              />
                              {formik.touched.date_of_birth && formik.errors.date_of_birth ? (
                                <div className="formik-errors bg-error">{formik.errors.date_of_birth}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-6 editformcol">
                            <div className="commonfield customBottomMargin">
                              <label htmlFor="mobileNo">Mobile No.</label>
                              <span className="optionalLabel">(optional)</span>
                              {/* <input type="text" className="form-control" id="mobileNo" defaultValue="+91 7362888888" /> */}
                              <input
                                type="text"
                                className={
                                  "form-control " +
                                  (formik.touched.mobile_no && formik.errors.mobile_no ? " border-red" : "")
                                }
                                placeholder="+910000000000"
                                name="mobile_no"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.mobile_no}
                              />
                              {formik.touched.mobile_no && formik.errors.mobile_no ? (
                                <div className="formik-errors bg-error">{formik.errors.mobile_no}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-6 editformcol">
                            <div className="commonfield commonfieldCustom">
                              <label htmlFor="gender">Gender</label>
                              {/* <input type="text" className="form-control" id="mobileNo" defaultValue="gender" /> */}
                              <div className="editprof_select img-select">
                                <select
                                  className={
                                    "form-control " +
                                    (formik.touched.gender && formik.errors.gender ? " border-red" : "")
                                  }
                                  name="gender"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.gender}
                                >
                                  <option value="Male" selected={true}>
                                    Male
                                  </option>
                                  <option value="Female">Female</option>
                                  <option value="Other">Other</option>
                                </select>
                                {formik.touched.gender && formik.errors.gender ? (
                                  <div className="formik-errors bg-error">{formik.errors.gender}</div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 editformcol">
                            <div className="modelSignBtn editprofile_save">
                              <button
                                type="submit"
                                className={
                                  "btn btn-primary btn-block" +
                                  (!(formik.isValid && formik.dirty) || isLoading ? " disabled" : "")
                                }
                              >
                                Save {isLoading && <Loading isInline={true} />}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showUserNamePopup ? (
        <UpdateUserName onClose={handleUsernamePopup} isUpdate={true} />
      ) : (
        <div className="modal-backdrop show"></div>
      )}
    </>
  );
}

export default EditProfile;
