import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import UserService from "../../services/UserService";
import { useFormik } from "formik";
import * as Yup from "yup";
import GlobalContext from "../../context/GlobalContext";
import Loading from "../../shared/Loading";
import { GoogleAuth } from "../../shared/TwoWayAuth";
const serv = new UserService();
const ValidateSchema = Yup.object({
  email: Yup.string().required("Email is a required field"),
  password: Yup.string().required("Password is a required field"),
});

function Login({ onClose, onShowSignup, onShowForgotPassword, setOtp, handleShowSocialLogin }) {
  const globalCtx = useContext(GlobalContext);
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const [user, setUser] = globalCtx.user;
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showLoginBtn, setShowLoginBtn] = useState(true)
  const [loginObj, setLoginObj] = new useState({
    email: "",
    password: "",
    remember: false,
  });
  const handleGoogleLogin = async (resp) => {
    try {
      localStorage.setItem("token", resp.token);
      localStorage.setItem("user", JSON.stringify(resp.data));
      setUser(resp.data);
      setIsAuthentiCated(true);
      onClose();
    } catch (error) {
      console.log(error);
    }
  };
  const onSubmit = async (values) => {
    setShowLoginBtn(false)
    setIsLoading(true);
    let obj = { ...values };
    try {
      const resp = await serv.login(obj);
      if (resp?.token) {
        let prevUser = JSON.parse(localStorage.getItem("prevUserList"));
        if (prevUser) {
          let prevUserFiltered = prevUser.filter((item) => {
            let email = item.split("#")[0];
            return email !== resp.data.email;
          });
          if (prevUserFiltered.length == prevUser.length) {
            await serv.sendLoginOtp(resp.data.email);
            localStorage.setItem("userTemp", JSON.stringify(resp.data));
            setOtp(true);
          } else {
            localStorage.setItem("token", resp.token);
            localStorage.setItem("user", JSON.stringify(resp.data));
            setUser(resp.data);
            setIsAuthentiCated(true);
          }
        } else {
          localStorage.setItem("userTemp", JSON.stringify(resp.data));
          await serv.sendLoginOtp(resp.data.email);
          setOtp(true);
        }
        setShowLoginBtn(true)
        setIsLoading(false);
        onClose();
      } else {
        setIsLoading(false);
        setShowLoginBtn(true)
        setErrorMsg(resp.error);
      }
    } catch (err) {
      setIsLoading(false);
      err = JSON.parse(err.message);
      setErrorMsg(err.err);
      setShowLoginBtn(true)
    }
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const formik = useFormik({
    initialValues: loginObj,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <>
      <div
        className="modal commanModel SignupPopup showBlock"
        id="Signin"
        onClick={() => {
          onClose();
        }}
      >
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center">
            <div className="modal-dialog">
              <div
                className="modal-content"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {/* <!-- Modal Header --> */}
                <div className="modal-header">
                  <h4 className="modal-title">Login</h4>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                      onClose();
                    }}
                  ></button>
                </div>
                {/* <!-- Modal body --> */}
                <div className="modal-body">
                  <div className="connectWalletOuter">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="modelFormInput">
                        <label for="signinemail">Username/email address</label>
                        <div
                          className={"input-group" + (formik.touched.email && formik.errors.email ? " border-red" : "")}
                        >
                          <span className="input-group-text pe-0">
                            <img src="/images/svg-icons/email-gray-icon.svg" />
                          </span>
                          <input
                            className="form-control"
                            placeholder="john@mavefi.com"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                        </div>
                        {formik.touched.email && formik.errors.email ? (
                          <div className="formik-errors bg-error">{formik.errors.email}</div>
                        ) : null}
                      </div>
                      <div className="modelFormInput">
                        <label for="signinpass">Password</label>
                        <div
                          className={
                            "input-group" + (formik.touched.password && formik.errors.password ? " border-red" : "")
                          }
                        >
                          <span className="input-group-text pe-0">
                            <img src="/images/svg-icons/password.svg" />
                          </span>
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="*************"
                            name="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                          />
                          <span className="input-group-text passwordGroupTxt ps-0">
                            {!showPassword ? (
                              <img
                                src="/images/svg-icons/hide.svg"
                                className="passwordHide"
                                onClick={handleShowPassword}
                              />
                            ) : (
                              <img
                                src="/images/svg-icons/show.svg"
                                className="passwordHide"
                                onClick={handleShowPassword}
                              />
                            )}
                          </span>
                        </div>
                        {formik.touched.password && formik.errors.password ? (
                          <div className="formik-errors bg-error">{formik.errors.password}</div>
                        ) : null}
                      </div>
                      <div className="modelFormInput d-flex align-items-center justify-content-between">
                        <div className="form-check">
                          <label className="form-check-label mb-0">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="remember"
                              checked={formik.values.remember}
                              onClick={() => formik.setFieldValue("remember", !formik.values.remember)}
                              value={formik.values.remember}
                            />{" "}
                            Remember me
                          </label>
                        </div>
                        <div className="forgotPassword">
                          <Link
                            to="#"
                            onClick={() => {
                              onShowForgotPassword();
                            }}
                          >
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                      {errorMsg && <div className="formik-errors bg-error text-center">{errorMsg}</div>}
                      <div className="modelSignBtn">
                        <div className="d-grid">
                          <button
                            type="submit"
                            className={
                              "btn btn-primary btn-block" +
                              (Object.keys(formik.errors).length !== 0 || !formik.touched || isLoading || !showLoginBtn
                                ? " disabled"
                                : "")
                            }
                          >
                            Login {isLoading && <Loading isInline={true} />}
                          </button>
                          <button
                            type=""
                            className={"btn btn-primary btn-block social-login-btn"}
                            onClick={() => handleShowSocialLogin("login")}
                          >
                            Or login with
                            <img src="/images/svg-icons/social-combo-blue.svg" className="img-fluid" />
                            {/* {isLoading && <Loading isInline={true} />} */}
                          </button>
                          {/* <GoogleAuth handleGoogleLogin={handleGoogleLogin} /> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {/* <!-- Modal footer --> */}
                <div className="modal-footer justify-content-center">
                  <div className="footerLink">
                    <p className="mb-0">
                      Don't have an account?{" "}
                      <Link
                        to="#"
                        onClick={() => {
                          onShowSignup();
                        }}
                      >
                        Sign up now
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
}

export default Login;
