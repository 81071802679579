import { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
function AccountCard({ account }) {
  return (
    <div className="col-sm-6 col-lg-6 col-xl-3 col-xxl-2 cardsBox pb-md-4 pb-3">
      <div className="mainUpBox">
        <div className={"mainUpdatesBox"}>
          <div className="updates_card glossaryCard front updates_cardCustom">
            <div className="profilePicSection">
              <div className="profileBannerPic profileBannerPicCustom">
                <a href="javascript:void(0);" id="uploadFileSec">
                  {account?.cover_image ? (
                    <img
                      src={account.cover_image}
                      alt="upload-icon"
                      width="100%"
                      className="img-fluid profileBannerPic "
                    />
                  ) : (
                    <div className="uploadCoverPicture text-center"></div>
                  )}
                </a>
              </div>
              <div className="profileImageSec profileImageSecCustom">
                <a href="javascript:void(0);" id="uploadProfileImgSec">
                  {account?.file ? (
                    <div className="uploadCoverPicture text-center">
                      <img
                        src={account.file}
                        alt="upload-icon"
                        className="img-fluid"
                        style={{ height: "auto", maxHeight: "50px" }}
                      />
                    </div>
                  ) : (
                    <div className="uploadCoverPicture text-center"></div>
                  )}
                </a>
              </div>
            </div>
            <div className="updates_box updates_box_custom">
              <div className="box_heading">
                <h4 className="m-0">{account?.full_name}</h4>
                <p className="m-0">@{account?.user_name}</p>
              </div>
            </div>
            <div className="box_content text-left d-flex justify-content-between align-items-center pe-2">
              <a className="nav-link">
                <img
                  src="/images/svg-icons/calenderSearchIcon.svg"
                  alt="calendar"
                  className="img-fulid img-unselect "
                  style={{ width: "12.15px", height: "13.5px", display: "inline-block" }}
                />{" "}
                <span className="joinedDate">Joined {moment(account?.createdAt).format("MMMM YYYY")}</span>
              </a>
              <Link to={`/profile/${account.user_name}`} className="gotoProfile">
                <span>View profile</span>
                {/* <img src="/images/svg-icons/right-arrow.svg" className="img-fluid" /> */}
                <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.75 0.338867C7.59008 0.338867 7.42998 0.401006 7.30811 0.523193L7.25439 0.576904C7.01002 0.821279 7.01002 1.21694 7.25439 1.46069L9.66895 3.87524H1.5C1.155 3.87524 0.875 4.15524 0.875 4.50024C0.875 4.84524 1.155 5.12524 1.5 5.12524H9.66895L7.25439 7.53979C7.01002 7.78417 7.01002 8.17983 7.25439 8.42358L7.30811 8.47729C7.55248 8.72167 7.94814 8.72167 8.19189 8.47729L11.7271 4.94214C11.9714 4.69776 11.9714 4.3021 11.7271 4.05835L8.19189 0.523193C8.06971 0.401006 7.90992 0.338867 7.75 0.338867Z"
                    fill="currentColor"
                    fill-opacity="0.75"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountCard;
