function TitleCard2({ image, title, content, content2, type , height98}) {
  return (
    <div className={"titleCard card titleCardCustom   " +(height98 ? " height98 " : " ")+ (type === "glossary" ? "glossaryTitleCard" : "titleAboutCard")}>
      <h4 className="mb-2 letterSpacing0">
        {image ? <img src={image} alt="book" className="img-fluid" /> : ""}
        {title}
      </h4>
      <p className="mb-0 letterSpacing0">
        {content}
        {content2 && (
          <>
            <br />
            {content2}
          </>
        )}
      </p>
    </div>
  );
}
export default TitleCard2;
