import moment from "moment";
import { useEffect, useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useParams, useNavigate } from "react-router-dom";
import NftService from "../../services/NftService";
import NewsSubscriptionService from "../../services/NewsSubscriptionService";
import GlobalContext from "../../context/GlobalContext";
import Loading from "../../shared/Loading";
import SocialShare from "../../shared/SocialShare";
import { Helmet } from "react-helmet";
import OwlCarousel from "react-owl-carousel";

const serv = new NftService();
const subsServ = new NewsSubscriptionService();

const arr = [1, 2, 3, 4, 5];
function NftProfileDetail() {
  const navigate = new useNavigate();
  const globalCtx = useContext(GlobalContext);
  const { updateBookmark, checkBookmark } = globalCtx;
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const { id } = useParams();
  const [showLoading, setShowLoading] = useState(true);
  const [nft, setNft] = useState(null);
  useEffect(() => {
    if (id) {
      getDetails();
    }
  }, [id]);

  async function getDetails() {
    const respData = await serv.getDetails(id);
    if (respData.data && respData.data.is_active) {
      await serv.updateViewCount({ _id: id });
      setNft(respData.data);
      setShowLoading(false);
    } else {
      navigate("/notfound");
    }
  }
  const handleNotification = async () => {
    let response = await serv.updateNotification({ channel_id: nft.channel_id._id });
    try {
      if (response) {
        console.log(response.data);
        getDetails();
      }
    } catch (error) {}
  };
  // const ValidateSchema = Yup.object({
  //     name: Yup.string().required("Name is required field"),
  //     email: Yup.string().email("Please enter correct email address").required("Email is required field"),
  // });

  // const onSubmit = async (values, { resetform }) => {
  //     let obj = {
  //         name: values.name,
  //         email: values.email,
  //     };
  //     try {
  //         const respData = await subsServ.addEmail(obj);
  //         if (respData.data) {
  //             setShowSuccessPopup(true);
  //             setTimeout(() => {
  //                 setShowSuccessPopup(false)
  //             }, 3000);
  //             values.name = "";
  //             values.email = "";
  //         }
  //     } catch (error) {
  //         console.log(error);
  //     }
  // };
  // const formik = useFormik({
  //     initialValues: value,
  //     validateOnBlur: true,
  //     onSubmit,
  //     validationSchema: ValidateSchema,
  //     enableReinitialize: true,
  // });
  if (showLoading) {
    return <Loading />;
  }
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <Helmet>
        <title>{nft.name ? nft.name : "Nft"} - MaveFi</title>
        <meta name="description" content={nft?.description ? nft?.description : ""} />
        <meta name="keywords" content={nft?.tags ? nft?.tags : ""} />
        <meta property="og:title" content={nft.name || "Mavefi - Nft"} />
        <meta property="og:description" content={nft.description || ""} />
        <meta property="og:image" content={nft.banner_img} />
      </Helmet>
      <div className="learnEarnSingle card">
        <div className="infoRow d-flex">
          <div className="infoCol infoCol1 nftInfoCol1">
            <div className="bannerImg">
              {nft.cover_img ? (
                <img
                  src={nft.cover_img}
                  alt="rectangle"
                  className="w-100 img-fluid"
                  style={{
                    height: "150px",
                    width: "100%",
                    borderRadius: "8px",
                    // objectFit: "cover",
                  }}
                />
              ) : (
                <div className="nfcEmptyBackground nfcInsiderBackground"></div>
              )}
              <div className="backArrow">
                <Link to="/nftprofile">
                  <img src="/images/svg-icons/backIcon.svg" alt="back-icon" className="img-fluid backIcon" />
                  <img
                    src="/images/svg-icons/backIcon-active.svg"
                    alt="back-icon"
                    className="img-fluid backIconActive"
                  />
                </Link>
              </div>
              <div className="nftBannerImgInner">
                <img src={nft.banner_img} alt="back-icon" className="img-fluid" />
              </div>
            </div>
            <div className="singleInfo singleSec">
              <div className="singleInfoInner nftSingleInfoInner">
                <div className="nftHeadingMain">
                  <h4>{nft.name}</h4>
                  <a>{nft.Symbol}</a>
                </div>
                <p className="lineBreak nftCollText">{nft.description}</p>
              </div>
            </div>
            <div className="raritiesHead">
              <h4>Top 5 by Rarities</h4>
              <div className="nftRaritiesList">
                {arr.map((item, idx) => {
                  return (
                    <a href="#">
                      <img src="/images/icons/nft_sample.png" className="img-fluid" />
                    </a>
                  );
                })}
                <a href="#" className="nftViewAll">
                  <h5>View all by Rarities</h5>
                  <p>Coming Soon</p>
                </a>
              </div>
            </div>
            <div className="raritiesHead">
              <h4>
                Recent Tweets{" "}
                <img src="/images/svg-icons/twitter-squared.svg" className="img-fluid" style={{ width: "16px" }} />
              </h4>
              <img src="/images/svg-icons/reset.svg" className="img-fluid resetBtn" />
              <div className="linedivide"></div>
              <div className="row">
                <div className="col tweetList">
                  <OwlCarousel
                    className="owl-carousel multiBlogSlider hompageSlider"
                    loop={true}
                    margin={10}
                    responsiveClass={true}
                    nav={true}
                    // navText={['<span class="arrow prev">‹</span>', '<span class="arrow next">›</span>']}
                    responsive={{
                      0: {
                        items: 1,
                      },
                      400: {
                        items: 2.6,
                      },
                      600: {
                        items: 2.6,
                      },
                      992: {
                        items: 3.6,
                      },
                      1200: {
                        items: 3.6,
                      },
                      1440: {
                        items: 3.6,
                      },
                    }}
                  >
                    {arr.map((item, idx) => {
                      return (
                        <a href="#" className="">
                          <p className="lineBreak">
                            Otherdeed claim window for Apes is coming to a close. Full details below.
                            https://twitter.com/OthersideMeta/status/1527333712840753160?s=20&t=34FSQfHuDWSot7a4xzQ0pg
                          </p>
                          <div className="tweetBtm">
                            <img
                              src="/images/svg-icons/twitter-squared.svg"
                              className="img-fluid"
                              style={{ width: "12px" }}
                            />
                            <p className="mb-0">5:59 AM · May 17, 2022</p>
                          </div>
                        </a>
                      );
                    })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
            <div className="raritiesHead">
              <h4>
                Real-time sales{" "}
                <img
                  src="/images/svg-icons/nft_boat.svg"
                  className="img-fluid"
                  style={{ width: "16px", marginRight: "7px" }}
                />
                <img src="/images/svg-icons/nft_dimond.svg" className="img-fluid" style={{ width: "16px" }} />
              </h4>
              <img src="/images/svg-icons/reset.svg" className="img-fluid resetBtn" />
              <div className="linedivide"></div>
              <div className="row">
                <div className="col tweetList realTimeTokenLs">
                  <OwlCarousel
                    className="owl-carousel multiBlogSlider hompageSlider"
                    loop={true}
                    margin={10}
                    responsiveClass={true}
                    nav={true}
                    // navText={['<span class="arrow prev">‹</span>', '<span class="arrow next">›</span>']}
                    responsive={{
                      0: {
                        items: 1,
                      },
                      400: {
                        items: 2.6,
                      },
                      600: {
                        items: 2.6,
                      },
                      992: {
                        items: 3.6,
                      },
                      1200: {
                        items: 3.9,
                      },
                      1440: {
                        items: 4,
                      },
                    }}
                  >
                    {arr.map((item, idx) => {
                      return (
                        <a href="#" className="">
                          <div className="tokenTop">
                            <img
                              src="/images/icons/nft_sample.png"
                              className="img-fluid"
                              style={{ width: "110px", height: "110px" }}
                            />
                            <div>
                              <h3>Sold for:</h3>
                              <p className="d-flex">
                                <img
                                  src="/images/svg-icons/nft_dimond.svg"
                                  className="img-fluid"
                                  style={{ width: "12px" }}
                                />{" "}
                                99.20
                              </p>
                              <h3>Token ID:</h3>
                              <p>fsad325</p>
                              <h3>Rank:</h3>
                              <p className="mb-0">3175</p>
                            </div>
                          </div>
                          <div className="tweetBtm align-items-end">
                            <p className="mb-0">
                              <span>(2 hours ago)</span>
                              <br />
                              5:59 AM · May 17, 2022
                            </p>
                            <img
                              src="/images/svg-icons/twitter-squared.svg"
                              className="img-fluid"
                              style={{ width: "12px" }}
                            />
                          </div>
                        </a>
                      );
                    })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
            <div className="raritiesHead">
              <h4>
                Real-time listings{" "}
                <img
                  src="/images/svg-icons/nft_boat.svg"
                  className="img-fluid"
                  style={{ width: "16px", marginRight: "7px" }}
                />
                <img src="/images/svg-icons/nft_dimond.svg" className="img-fluid" style={{ width: "16px" }} />
              </h4>
              <img src="/images/svg-icons/reset.svg" className="img-fluid resetBtn" />
              <div className="linedivide"></div>
              <div className="row">
                <div className="col tweetList realTimeTokenLs">
                  <OwlCarousel
                    className="owl-carousel multiBlogSlider hompageSlider"
                    loop={true}
                    margin={10}
                    responsiveClass={true}
                    nav={true}
                    // navText={['<span class="arrow prev">‹</span>', '<span class="arrow next">›</span>']}
                    responsive={{
                      0: {
                        items: 1,
                      },
                      400: {
                        items: 2.6,
                      },
                      600: {
                        items: 2.6,
                      },
                      992: {
                        items: 3.6,
                      },
                      1200: {
                        items: 3.9,
                      },
                      1440: {
                        items: 4,
                      },
                    }}
                  >
                    {arr.map((item, idx) => {
                      return (
                        <a href="#" className="">
                          <div className="tokenTop">
                            <img
                              src="/images/icons/nft_sample.png"
                              className="img-fluid"
                              style={{ width: "110px", height: "110px" }}
                            />
                            <div>
                              <h3>Sold for:</h3>
                              <p className="d-flex">
                                <img
                                  src="/images/svg-icons/nft_dimond.svg"
                                  className="img-fluid"
                                  style={{ width: "12px" }}
                                />{" "}
                                99.20
                              </p>
                              <h3>Token ID:</h3>
                              <p>fsad325</p>
                              <h3>Rank:</h3>
                              <p className="mb-0">3175</p>
                            </div>
                          </div>
                          <div className="tweetBtm align-items-end">
                            <p className="mb-0">
                              <span>(2 hours ago)</span>
                              <br />
                              5:59 AM · May 17, 2022
                            </p>
                            <img
                              src="/images/svg-icons/twitter-squared.svg"
                              className="img-fluid"
                              style={{ width: "12px" }}
                            />
                          </div>
                        </a>
                      );
                    })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
            <div className="raritiesHead">
              <h4>Courses </h4>
              <img src="/images/svg-icons/reset.svg" className="img-fluid resetBtn" />
              <div className="linedivide"></div>
              <div className="row">
                <div className="col tweetList realTimeTokenLs">
                  <OwlCarousel
                    className="owl-carousel multiBlogSlider hompageSlider"
                    loop={true}
                    margin={10}
                    responsiveClass={true}
                    nav={true}
                    // navText={['<span class="arrow prev">‹</span>', '<span class="arrow next">›</span>']}
                    responsive={{
                      0: {
                        items: 1,
                      },
                      400: {
                        items: 2.6,
                      },
                      600: {
                        items: 2.6,
                      },
                      992: {
                        items: 3.6,
                      },
                      1200: {
                        items: 3.9,
                      },
                      1440: {
                        items: 4,
                      },
                    }}
                  >
                    {arr.map((item, idx) => {
                      return (
                        <a href="#" className="">
                          <div className="tokenTop">
                            <img
                              src="/images/icons/nft_sample.png"
                              className="img-fluid"
                              style={{ width: "110px", height: "110px" }}
                            />
                            <div>
                              <h3>Sold for:</h3>
                              <p className="d-flex">
                                <img
                                  src="/images/svg-icons/nft_dimond.svg"
                                  className="img-fluid"
                                  style={{ width: "12px" }}
                                />{" "}
                                99.20
                              </p>
                              <h3>Token ID:</h3>
                              <p>fsad325</p>
                              <h3>Rank:</h3>
                              <p className="mb-0">3175</p>
                            </div>
                          </div>
                          <div className="tweetBtm align-items-end">
                            <p className="mb-0">
                              <span>(2 hours ago)</span>
                              <br />
                              5:59 AM · May 17, 2022
                            </p>
                            <img
                              src="/images/svg-icons/twitter-squared.svg"
                              className="img-fluid"
                              style={{ width: "12px" }}
                            />
                          </div>
                        </a>
                      );
                    })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
            <div className="raritiesHead">
              <h4>In the News </h4>
              <img src="/images/svg-icons/reset.svg" className="img-fluid resetBtn" />
              <div className="linedivide"></div>
              <div className="row">
                <div className="col tweetList realTimeTokenLs">
                  <OwlCarousel
                    className="owl-carousel multiBlogSlider hompageSlider"
                    loop={true}
                    margin={10}
                    responsiveClass={true}
                    nav={true}
                    // navText={['<span class="arrow prev">‹</span>', '<span class="arrow next">›</span>']}
                    responsive={{
                      0: {
                        items: 1,
                      },
                      400: {
                        items: 2.6,
                      },
                      600: {
                        items: 2.6,
                      },
                      992: {
                        items: 3.6,
                      },
                      1200: {
                        items: 3.9,
                      },
                      1440: {
                        items: 4,
                      },
                    }}
                  >
                    {arr.map((item, idx) => {
                      return (
                        <a href="#" className="">
                          <div className="tokenTop">
                            <img
                              src="/images/icons/nft_sample.png"
                              className="img-fluid"
                              style={{ width: "110px", height: "110px" }}
                            />
                            <div>
                              <h3>Sold for:</h3>
                              <p className="d-flex">
                                <img
                                  src="/images/svg-icons/nft_dimond.svg"
                                  className="img-fluid"
                                  style={{ width: "12px" }}
                                />{" "}
                                99.20
                              </p>
                              <h3>Token ID:</h3>
                              <p>fsad325</p>
                              <h3>Rank:</h3>
                              <p className="mb-0">3175</p>
                            </div>
                          </div>
                          <div className="tweetBtm align-items-end">
                            <p className="mb-0">
                              <span>(2 hours ago)</span>
                              <br />
                              5:59 AM · May 17, 2022
                            </p>
                            <img
                              src="/images/svg-icons/twitter-squared.svg"
                              className="img-fluid"
                              style={{ width: "12px" }}
                            />
                          </div>
                        </a>
                      );
                    })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
            <div className="raritiesHead ">
              <div className="grayBox"></div>
            </div>
          </div>
          <div className="infoCol infoCol2 nftInfoCol2">
            <div className="singleDisclaimer singleSec">
              <div className="singleDisclaimerInner">
                <div className="mainSingleDisclaimer mainSingleDisclaimerCustom">
                  <div className="singleDisclaimerContent">
                    <div className="singleDiscTxt">
                      <h2>General Information</h2>
                      <div className="nftGeneralInfo">
                        <div className="generalInfoHeading">
                          <p>Profile Type:</p>
                          <p>Chain:</p>
                          <p>Collection Category:</p>
                          <p>Collection Supply:</p>
                          <p>Resale Fee:</p>
                          <p>Contract Address:</p>
                          <p className="mb-0">Tags:</p>
                        </div>
                        <div className="generalInfoHeading generalInfoData">
                          <p>
                            <span className="btnTypeCollection">{nft.collection_type}</span>
                          </p>
                          <p>{nft.chain}:</p>
                          <p>{nft.collection_category}</p>
                          <p>{nft.supply}</p>
                          <p>{nft.resale_fee}</p>
                          <p>
                            {nft.contract_address.slice(0, 5) +
                              "..." +
                              nft.contract_address.slice(nft.contract_address.length - 5)}
                          </p>
                          <p className="mb-0">{nft.tags}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mainSingleDisclaimer mainSingleDisclaimerCustomSmall">
                  <div className="emptyBox"></div>
                </div>
                <div className="mainSingleDisclaimer mainSingleDisclaimerCustom">
                  <div className="singleDisclaimerContent">
                    <div className="singleDiscTxt">
                      <h2>Collection Stats</h2>
                      <div className="nftGeneralInfo">
                        <div className="generalInfoHeading">
                          <p>Floor Price:</p>
                          <p>Mint Price:</p>
                          <p>ROI:</p>
                          <p>Total Unique Owners:</p>
                          <p>Total Volume Traded:</p>
                          <p>Market Cap:</p>
                          <p>Trading Volume(24h):</p>
                          <p>Volume / Market Cap:</p>
                          <p>90 Day Avg. Price:</p>
                          <p className="mb-0">Trading Volume(90d):</p>
                        </div>
                        <div className="generalInfoHeading generalInfoData">
                          <p className="priceFix">
                            <span>122.9 ETH</span>
                            <span>($244,174)</span>
                          </p>
                          <p>0.20 ETH</p>
                          <p>61,350.00%</p>
                          <p>6,270</p>
                          <p>657,716.64 ETH</p>
                          <p className="priceFix">
                            <span>1,200,000 ETH</span>
                            <span>($2,869,026,174)</span>
                          </p>
                          <p className="priceFix">
                            <span>7,002.25 ETH</span>
                            <span>($14,004,087)</span>
                          </p>
                          <p>0.0048</p>
                          <p>115.52 ETH</p>
                          <p className="mb-0 priceFix">
                            <span>177,093.45 ETH</span>
                            <span>($14,004,087)</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mainSingleDisclaimer mainSingleDisclaimerCustom">
                  <div className="singleDisclaimerContent">
                    <div className="singleDiscTxt">
                      <h2>Traders Stats</h2>
                      <div className="nftGeneralInfo">
                        <div className="generalInfoHeading" style={{ width: "75%" }}>
                          <p>Never sold since mint:</p>
                          <p>Bought below current floor:</p>
                          <p>Listed for sale:</p>
                          <p className="mb-0">Selling below buy price:</p>
                        </div>
                        <div className="generalInfoHeading generalInfoData" style={{ width: "40%" }}>
                          <p>1,330 (13.3%)</p>
                          <p>8,579 (85.8%)</p>
                          <p>818 (8.18%)</p>
                          <p className="mb-0">18 (0.18%)</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NftProfileDetail;
