function AboutCardVertical({ title, richtext, image }) {
  return (
    <div className="titleCard card titleAboutCard col">
      <div className="d-flex flex-column justify-content-between h-100">
        <div className="aboutcard-top">
          <h4>{title}</h4>
          <div dangerouslySetInnerHTML={{ __html: richtext }}></div>
        </div>
        <div className="aboutcard-bottom">{image && <img src={image} className="img-fluid aboutImage" alt="about"></img>}</div>
      </div>
    </div>
  );
}

export default AboutCardVertical;
