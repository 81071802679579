import { useContext } from "react";
import GlobalContext from "../context/GlobalContext";
import VideoService from "../services/VideoService";
const serv = new VideoService();
function VideoAction({ videoDetail, setSearch, actionCallBack }) {
  const globalCtx = useContext(GlobalContext);
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const { updateBookmark, checkBookmark } = globalCtx;
  const blockVideo = async (type, id) => {
    try {
      let obj = {
        type: type,
        id: id,
      };
      await serv.blockVideo(obj).then((resp) => {
        actionCallBack();
      });
    } catch (error) {
      console.log(error);
    }
  };
  const unblockVideo = async (type, id) => {
    try {
      let obj = {
        type: type,
        id: id,
      };
      await serv.unblockVideo(obj).then((resp) => {
        console.log(resp);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const reportVideo = async (id) => {
    try {
      let obj = {
        _id: id,
      };
      await serv.reportVideo(obj).then((resp) => {
        console.log(resp);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="modeBtn dropdown">
      {/* <a href="javascript:void(0)" className="cardShareIcon" data-bs-toggle="dropdown"> */}
      <img
        src="/images/svg-icons/vertical-dots.svg"
        data-bs-toggle="dropdown"
        className="img-fluid"
        style={{ width: "10px", height: "15px" }}
      />
      {/* </a> */}
      {/* <ul className={"dropdown-menu " + (topGap && "marginTop5")}> */}
      <ul className="dropdown-menu videoDropDown marginTop5 videoActionHover" style={{ width: "240px" }}>
        <li>
          <a className="dropdown-item p-0" href={videoDetail.video_link} target="_blank">
            <img src="/images/svg-icons/video-play.svg" alt="share-now" className="img-fluid me-1 overwriteOwlImg" />{" "}
            Play video
          </a>
        </li>
        <li>
          <a
            className="dropdown-item p-0"
            href={`https://www.youtube.com/@${videoDetail?.channel_name?.replace(" ", "")}`}
            target="_blank"
          >
            <img src="/images/svg-icons/video-channel.svg" alt="share-now" className="img-fluid me-1 overwriteOwlImg" />{" "}
            View Youtube Channel
          </a>
        </li>
        {isAuthentiCated && (
          <li>
            <a
              className="dropdown-item p-0"
              href="javascript:void(0);"
              onClick={() => {
                updateBookmark(videoDetail._id, "video");
              }}
            >
              <img
                src="/images/svg-icons/video-bookmark.svg"
                alt="share-now"
                className="img-fluid me-2 overwriteOwlImg"
              />
              {checkBookmark(videoDetail._id) ? " Bookmarked" : " Bookmark"}
            </a>
          </li>
        )}

        <li>
          <a
            className="dropdown-item p-0"
            href="javascript:void(0);"
            onClick={(e) => {
              navigator.clipboard.writeText(`${window.location.origin}/videos/${videoDetail._id}`);
            }}
          >
            <img src="/images/svg-icons/video-share.svg" alt="share-now" className="img-fluid me-1 overwriteOwlImg" />{" "}
            Share
          </a>
        </li>
        {isAuthentiCated && (
          <>
            <hr style={{ margin: "8px 0px" }} />
            <li>
              <a
                className="dropdown-item p-0"
                href="javascript:void(0);"
                onClick={() => blockVideo("video", videoDetail._id)}
              >
                <img
                  src="/images/svg-icons/video-nt-interest.svg"
                  alt="share-now"
                  className="img-fluid me-1 overwriteOwlImg"
                />{" "}
                Not interested
              </a>
            </li>
            <li>
              <a
                className="dropdown-item p-0"
                href="javascript:void(0);"
                onClick={() => blockVideo("channel", videoDetail.channel_id)}
              >
                <img
                  src="/images/svg-icons/video-not-recomend.svg"
                  alt="share-now"
                  className="img-fluid me-1 overwriteOwlImg"
                />{" "}
                Don’t recommend channel
              </a>
            </li>
            <li>
              <a className="dropdown-item p-0" href="javascript:void(0);" onClick={() => reportVideo(videoDetail._id)}>
                <img
                  src="/images/svg-icons/video-report.svg"
                  alt="share-now"
                  className="img-fluid me-1 overwriteOwlImg"
                />{" "}
                Report
              </a>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}

export default VideoAction;
