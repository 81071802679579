import { useContext, useState } from "react";
import Countdown from "react-countdown";
import UserService from "../../services/UserService";
import { useFormik } from "formik";
import * as Yup from "yup";
import GlobalContext from "../../context/GlobalContext";
import OtpInput from "react18-input-otp";
import Loading from "../../shared/Loading";
const serv = new UserService();
const ValidateSchema = Yup.object({
  email: Yup.string().required("Email is a required field").email(),
});

function SignupOtp({ onClose, onEdit, onFinish }) {
  const globalCtx = useContext(GlobalContext);
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const [referToken, setReferToken] = globalCtx.referToken;
  const [user, setUser] = globalCtx.user;
  const [otp, setOtp] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [difference, setDifference] = useState(new Date().getTime() + 30000);
  const [signupObj, setSignupObj] = new useState({
    email: "",
  });

  let data = JSON.parse(localStorage.getItem("userDetails"));
  const handleResendOtp = async () => {
    try {
      await serv.sendOtp({ email: data.email, full_name: data.full_name });
      setDifference(new Date().getTime() + 30000);
    } catch (err) {
      setErrorMsg(err.message);
    }
  };
  const onSubmit = async (values) => {
    setIsLoading(true);
    let obj = { ...data };
    obj.otp = otp;
    if (referToken) {
      obj.referToken = referToken;
    }
    try {
      const resp = await serv.signup(obj);
      if (resp && resp.token) {
        localStorage.removeItem("userDetails");
        localStorage.setItem("token", resp.token);
        localStorage.setItem("user", JSON.stringify(resp.data));
        setIsAuthentiCated(true);
        setUser(resp.data);
        setReferToken("");
        setIsLoading(false);
        onFinish();
      } else {
        setIsLoading(false);
        setErrorMsg(resp.error);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.message);
    }
  };
  const formik = useFormik({
    initialValues: signupObj,
    validateOnBlur: true,
    onSubmit,
    // validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <div className="resend-otp-text" style={{ opacity: 1 }}>
          <p>
            Didn’t get a code?
            <span onClick={handleResendOtp}> Resend</span>
          </p>
        </div>
      );
    } else {
      return (
        <div className="resend-otp-text">
          <p>
            Didn’t get a code?
            <span> Resend</span> (in {seconds} seconds)
          </p>
        </div>
      );
    }
  };

  return (
    <>
      <div className="modal commanModel SignupPopup showBlock" id="Signup">
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Sign up</h4>
                  {/* <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                      onClose();
                    }}
                  ></button> */}
                </div>
                <div className="modal-body">
                  <div className="connectWalletOuter">
                    <form onSubmit={formik.handleSubmit}>
                      <h4 className="SignupPopupH4">Enter Your Verfication Code</h4>
                      <div className="modelFormInput">
                        <label for="signupemail" className="SignupPopupText">
                          A verfication email has been sent to
                          {" " + data.email} (not verfied).
                        </label>
                      </div>
                      <div className="modelFormInput">
                        <div className="modelFormInput modelFormOtpInput">
                          <div className="otp_input otp_input_box digitGroup">
                            <OtpInput
                              placeholder="_"
                              value={otp}
                              onChange={(value) => setOtp(value)}
                              numInputs={6}
                              separator={<span>&nbsp;&nbsp;</span>}
                              inputStyle={{
                                marginLeft: "8px",
                                marginRight: "10px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <Countdown date={difference} renderer={renderer} key={difference} />
                      {errorMsg && <div className="formik-errors bg-error text-center">{errorMsg}</div>}
                      <div className="modelSignBtn">
                        <div className="d-grid">
                          <button
                            type="submit"
                            className={
                              "btn btn-primary btn-block" +
                              (otp == "" || Object.keys(formik.errors).length !== 0 || !formik.touched || isLoading
                                ? " disabled"
                                : "")
                            }
                          >
                            Sign up {isLoading && <Loading isInline={true} />}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
}

export default SignupOtp;
