function AboutCard2({ title, richtext, image1, image2, image3, image4, image5 }) {
  return (
    <div className="titleCard card titleAboutCard col">
      <div className="d-flex flex-row gap-12">
        <div className="aboutcard-left p-0" style={{ width: "63%" }}>
          <h4>{title}</h4>
          <div dangerouslySetInnerHTML={{ __html: richtext }}></div>
        </div>
        <div className="aboutcard-right w-14_5">{image1 && <img src={image1} className="img-fluid aboutImage" alt="about"></img>}</div>
        <div className="aboutcard-right w-14_5">{image2 && <img src={image2} className="img-fluid aboutImage" alt="about"></img>}</div>
        <div className="aboutcard-right w-14_5">{image3 && <img src={image3} className="img-fluid aboutImage" alt="about"></img>}</div>
        <div className="aboutcard-right w-14_5">{image4 && <img src={image4} className="img-fluid aboutImage" alt="about"></img>}</div>
        <div className="aboutcard-right w-14_5">{image5 && <img src={image5} className="img-fluid aboutImage" alt="about"></img>}</div>
      </div>
    </div>
  );
}

export default AboutCard2;
