import { useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import util from "../../util/util"
function CropImage({ imageToCrop, onImageCropped, onClose }) {

    const [cropedImage, setCropedImage] = useState("")
    const cropperRef = useRef(null);
    const onCrop = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        setCropedImage(cropper.getCroppedCanvas().toDataURL())
    };
    const handleSubmit = async () => {
        let fileImage = await util.dataUrlToFile(cropedImage, "coverImage.png")
        onImageCropped(fileImage)
    }
    // const [cropConfig, setCropConfig] = useState(
    //     // default crop config
    //     {
    //         unit: "%",
    //         width: 30,
    //         aspect: 16 / 9
    //     }
    // );

    // const [imageRef, setImageRef] = useState();

    // async function cropImage(crop) {
    //     if (imageRef && crop.width && crop.height) {
    //         const croppedImage = await getCroppedImage(
    //             imageRef,
    //             crop,
    //             "croppedImage.jpeg" // destination filename
    //         );

    //         // calling the props function to expose
    //         // croppedImage to the parent component
    //         onImageCropped(croppedImage);
    //     }
    // }

    // function getCroppedImage(sourceImage, cropConfig, fileName) {
    //     // creating the cropped image from the source image
    //     const canvas = document.createElement("canvas");
    //     const scaleX = sourceImage.naturalWidth / sourceImage.width;
    //     const scaleY = sourceImage.naturalHeight / sourceImage.height;
    //     canvas.width = cropConfig.width;
    //     canvas.height = cropConfig.height;
    //     const ctx = canvas.getContext("2d");

    //     ctx.drawImage(
    //         sourceImage,
    //         cropConfig.x * scaleX,
    //         cropConfig.y * scaleY,
    //         cropConfig.width * scaleX,
    //         cropConfig.height * scaleY,
    //         0,
    //         0,
    //         cropConfig.width,
    //         cropConfig.height
    //     );

    //     return new Promise((resolve, reject) => {
    //         canvas.toBlob((blob) => {
    //             // returning an error
    //             if (!blob) {
    //                 reject(new Error("Canvas is empty"));
    //                 return;
    //             }

    //             blob.name = fileName;
    //             // creating a Object URL representing the Blob object given
    //             const croppedImageUrl = window.URL.createObjectURL(blob);

    //             resolve(croppedImageUrl);
    //         }, "image/jpeg");
    //     });
    // }
    return (
        <>
            <div className="modal commanModel SignupPopup showBlock"
                id="Signin"
                onClick={(e) => {
                    if (e.target == e.currentTarget) {
                        onClose();
                    }
                }}>
                <div className="vertical-alignment-helper">
                    <div className="vertical-align-center">
                        <div className="modal-dialog">
                            <div className="modal-content" style={{ display: "block", background: "none" }}>

                                {/* <ReactCrop
                                    src={imageToCrop || "/images/svg-icons/beta-background.png"}
                                    // src={"/images/svg-icons/beta-background.png"}
                                    crop={cropConfig}
                                    ruleOfThirds
                                    onImageLoaded={(imageRef) => setImageRef(imageRef)}
                                    onComplete={(cropConfig) => cropImage(cropConfig)}
                                    onChange={(cropConfig) => setCropConfig(cropConfig)}
                                    crossorigin="anonymous" // to avoid CORS-related problems
                                /> */}
                                <Cropper
                                    src={imageToCrop}
                                    style={{ width: "100%", maxHeight: "600px" }}
                                    // Cropper.js options
                                    initialAspectRatio={16 / 9}
                                    guides={false}
                                    crop={onCrop}
                                    ref={cropperRef}
                                />
                                <div className="d-grid">
                                    <button className="btn btn-primary btn-block" onClick={handleSubmit}>submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop show"></div>
        </>
    );
}

export default CropImage;
