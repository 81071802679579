import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CoursesFilter from "../pages/Courses/CourseFilter";
import Filters from "./Filters";
function PageSearchNew({
  searchText,
  allowedFilter,
  placeholder,
  searchCallBack,
  searchFilter,
  sortArray,
  filterObj,
  filterListObj,
}) {
  const [showCancel, setShowCancel] = useState(false);
  const [value, setValue] = useState("");
  const [sortBy, setSortBy] = useState({});
  const [filter, setFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [sortLabel, setSortLabel] = useState("Recently Added");
  useEffect(() => {
    setValue(searchText);
  }, [searchText]);

  function changeHandler(value) {
    console.log("searchCallBack called from course search changeHandler");
    if (value !== "") {
      setShowCancel(true);
    } else {
      setShowCancel(false);
    }
    setValue(value);
    searchCallBack(value, filter, sortBy);
  }

  function sortHandler(sort, label) {
    setSortBy(sort);
    setSortLabel(label);
    searchCallBack(value, filter, sort);
  }
  function handleFilters(filters) {
    setFilter(filters);
    searchCallBack(value, filters, sortBy);
  }
  return (
    <div className="filterSec d-flex align-items-center searchCustom">
      <div className="filterSearchInner d-flex">
        {allowedFilter && (
          <div className="filterIcons headerBtnSec">
            <div className="modeBtn">
              {/* <!-- Button to Open Filter Modal --> */}
              <Link
                onClick={() => setShowFilter(!showFilter)}
                to="#"
                // className="headerNotification"
                // data-bs-toggle="modal"
                // data-bs-target="#filterModel"
              >
                <img src="/images/svg-icons/filter-edit.svg" alt="notification" className="img-fluid" />
              </Link>
            </div>

            {/* {showFilter && (
              <CoursesFilter
                onClose={() => setShowFilter(!showFilter)}
                handleFilters={handleFilters}
                searchFilter={searchFilter}
              />
            )} */}
            {showFilter && (
              <Filters
                filterListObj={filterListObj}
                filterObj={filterObj}
                onClose={() => setShowFilter(!showFilter)}
                handleFilters={handleFilters}
                searchFilter={searchFilter}
              />
            )}
          </div>
        )}
        <div className="glossarySearch has-clear w-100">
          <div className="input-group">
            <span className="input-group-text">
              <img src="/images/svg-icons/search.svg" alt="search" className="img-fluid" />
            </span>
            <input
              value={value}
              type="text"
              className="form-control letterSpacing0"
              placeholder={placeholder}
              onChange={(e) => {
                changeHandler(e.target.value);
              }}
            />
            <span className={"input-group-text close form-control-clear" + (showCancel === false ? " hidden" : "")}>
              <img
                src="/images/svg-icons/close.svg"
                alt="search"
                className="img-fluid"
                onClick={() => {
                  changeHandler("");
                }}
              />
            </span>
          </div>
        </div>
      </div>
      <div className="mostbookmarked">
        <div className="dropdown">
          <Link to="#" className="btnBookmarkedToggle letterSpacing0" data-bs-toggle="dropdown">
            <img src="/images/svg-icons/most-bookmarked.svg" alt="search" className="img-fluid" />
            {sortLabel}
            <img src="/images/svg-icons/collapse-arrow.svg" alt="search" className="img-fluid collapse-arrow" />
          </Link>
          <ul className="dropdown-menu letterSpacing0">
            {sortArray?.map((item) => {
              return (
                <li>
                  <Link className="dropdown-item letterSpacing0" to="#" onClick={() => sortHandler(item.method, item.label)}>
                    {item.label}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PageSearchNew;
