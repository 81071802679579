import { useEffect, useState, useContext } from "react";
// import CourseService from "../../services/CourseService";
import GlobalContext from "../context/GlobalContext";

// const serv = new CourseService();

function Filters({ onClose, handleFilters, searchFilter, filterObj, filterListObj }) {
  const globalCtx = useContext(GlobalContext);
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const [enableFilterButton, setEnableFilterButton] = useState(false);
  //   const [filterTags, setFilterTags] = useState([]);
  //   const [tags, setTags] = useState([]);
  //   const [author, setAuthor] = useState([]);
  const [search, setSearch] = useState({});
  const [tagSearch, setTagSearch] = useState("");
  const [innerFilterObj, setInnerFilterObj] = useState(filterObj);
  const [innerFilterListObj, setInnerFilterListObj] = useState(filterListObj);
  //   const [filterAuthor, setFilterAuthor] = useState([]);

  //   const [filterRewards, setFilterRewards] = useState([]);
  //   const [filterLanguages, setFilterLanguages] = useState([]);

  //   const [filterStatus, setFilterStatus] = useState([]);

  //   useEffect(() => {
  //     filterList();
  //   }, []);

  //   let handleAutor = (event) => {
  //     if (event.target.checked) {
  //       setFilterAuthor([...filterAuthor, event.target.value]);
  //     } else {
  //       setFilterAuthor(filterAuthor.filter((id) => id !== event.target.value));
  //     }
  //     let newAuthor = author.map((item) => {
  //       if (item._id == event.target.value) {
  //         item.check = !item.check;
  //       }
  //       return item;
  //     });
  //     setAuthor(newAuthor);
  //     setEnableFilterButton(true);
  //   };
  //   let handleTag = (event) => {
  //     if (event.target.checked) {
  //       setFilterTags([...filterTags, event.target.value]);
  //     } else {
  //       setFilterTags(filterTags.filter((tag) => tag !== event.target.value));
  //     }
  //     let newTag = tags.map((item) => {
  //       if (item.value == event.target.value) {
  //         item.check = !item.check;
  //       }
  //       return item;
  //     });
  //     setTags(newTag);
  //     setEnableFilterButton(true);
  //   };
  //   let handleRewards = (event) => {
  //     if (event.target.checked) {
  //       setFilterRewards([...filterRewards, event.target.value]);
  //     } else {
  //       setFilterRewards(filterRewards.filter((reward) => reward !== event.target.value));
  //     }
  //     let newReward = rewards.map((item) => {
  //       if (item.value == event.target.value) {
  //         item.check = !item.check;
  //       }
  //       return item;
  //     });
  //     setRewards(newReward);
  //     setEnableFilterButton(true);
  //   };
  //   let handleLanguage = (event) => {
  //     if (event.target.checked) {
  //       setFilterLanguages([...filterLanguages, event.target.value]);
  //     } else {
  //       setFilterLanguages(filterLanguages.filter((reward) => reward !== event.target.value));
  //     }
  //     let newLanguage = languages.map((item) => {
  //       if (item.value == event.target.value) {
  //         item.check = !item.check;
  //       }
  //       return item;
  //     });
  //     setLanguages(newLanguage);
  //     setEnableFilterButton(true);
  //   };
  //   let handleStatus = (event) => {
  //     if (event.target.checked) {
  //       setFilterStatus([...filterStatus, event.target.value]);
  //     } else {
  //       setFilterStatus(filterStatus.filter((item) => item !== event.target.value));
  //     }
  //     let newStatus = status.map((item) => {
  //       if (item.value == event.target.value) {
  //         item.check = !item.check;
  //       }
  //       return item;
  //     });
  //     setStatus(newStatus);
  //     setEnableFilterButton(true);
  //   };
  let handleFilter = (event, type) => {
    let tempFilterObj = innerFilterObj;
    if (event.target.checked) {
      tempFilterObj[type] = [...tempFilterObj[type], event.target.value];
      //   setInnerFilterObj({...innerFilterObj,innerFilterObj[type]: [...innerFilterObj[type], event.target.value]});
    } else {
      tempFilterObj[type] = tempFilterObj[type].filter((item) => item !== event.target.value);
      //   setFilterStatus(filterStatus.filter((item) => item !== event.target.value));
    }
    setInnerFilterObj({ ...tempFilterObj });
    let tempFilterListObj = innerFilterListObj;
    tempFilterListObj[type] = innerFilterListObj[type].map((item) => {
      if (item._id) {
        if (item._id == event.target.value) {
          item.check = !item.check;
        }
      } else {
        if (item.value == event.target.value) {
          item.check = !item.check;
        }
      }
      return item;
    });
    // setStatus(newStatus);
    setInnerFilterListObj({ ...tempFilterListObj });
    setEnableFilterButton(true);
  };

  let handleSubmit = (triggerBy) => {
    if (triggerBy == "ApplyFilterButton" && enableFilterButton) {
      handleFilters(innerFilterObj);
    }
  };

  return (
    <>
      <div
        className="modal filterModel"
        id="filterModel"
        style={{ display: "block" }}
        onClick={(e) => {
          if (e.target == e.currentTarget) {
            handleSubmit("FilterModel");
            onClose();
          }
        }}
      >
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Filters</h4>
                  <button
                    onClick={(e) => {
                      handleSubmit("CloseButton");
                      onClose();
                    }}
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>

                <div className="modal-body">
                  <div className="accordion" id="accordionExample">
                    {Object.keys(innerFilterListObj).map((i) => {
                      return (
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button text-capitalize collapsed "
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={"#collapseOne" + i}
                              aria-expanded="true"
                              aria-controls={"collapseOne" + i}
                            >
                              {i}
                              {/* Course Status */}
                            </button>
                          </h2>
                          <div
                            id={"collapseOne" + i}
                            className="accordion-collapse collapse"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              {i == "author" || i == "tags" || i == "channel" || i == "publishers" ? (
                                <div className="filterSearch">
                                  <div className="headerSearchBar has-clear">
                                    <div className="input-group">
                                      <button className="btn" type="button" style={{ zIndex: 9 }}>
                                        <img src="/images/svg-icons/search.svg" alt="logo" className="img-fluid" />
                                      </button>
                                      <input
                                        type="text"
                                        className="form-control border-0"
                                        placeholder="Search"
                                        onChange={(e) => setSearch({ ...search, [i]: e.target.value })}
                                      />
                                      <span className="close form-control-clear hidden">
                                        <img src="/images/svg-icons/close.svg" alt="search" className="img-fluid" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {innerFilterListObj[i]
                                .filter((item) => {
                                  if (item.user_name) {
                                    return search[i]
                                      ? item.user_name.toLowerCase().includes(search[i]?.toLowerCase())
                                      : true;
                                  } else if (item.publisher_name) {
                                    return search[i]
                                      ? item.publisher_name.toLowerCase().includes(search[i]?.toLowerCase())
                                      : true;
                                  } else {
                                    return search[i]
                                      ? item.value.toLowerCase().includes(search[i]?.toLowerCase())
                                      : true;
                                  }
                                })
                                .map((item) => (
                                  <div className="accordionBody">
                                    <div className="accordionBodyTxt">
                                      <p className="mb-0">{item.value}</p>
                                      <p className="mb-0">{item.user_name}</p>
                                      <p className="mb-0">{item.publisher_name}</p>
                                      <p className="mb-0">{item.name}</p>
                                    </div>
                                    <div className="accordionBodyCheckBox">
                                      <div className="form-check">
                                        <input
                                          checked={item.check}
                                          className="form-check-input"
                                          type="checkbox"
                                          value={item._id || item.value}
                                          onChange={(e) => handleFilter(e, i)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {/* {isAuthentiCated ? (
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Course Status
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            {status.map((item) => (
                              <div className="accordionBody">
                                <div className="accordionBodyTxt">
                                  <p className="mb-0">{item.value}</p>
                                </div>
                                <div className="accordionBodyCheckBox">
                                  <div className="form-check">
                                    <input
                                      checked={item.check}
                                      className="form-check-input"
                                      type="checkbox"
                                      value={item.value}
                                      onChange={handleStatus}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Author
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="filterSearch">
                            <div className="headerSearchBar has-clear">
                              <div className="input-group">
                                <button className="btn" type="button" style={{ zIndex: 9 }}>
                                  <img src="/images/svg-icons/search.svg" alt="logo" className="img-fluid" />
                                </button>
                                <input
                                  type="text"
                                  className="form-control border-0"
                                  placeholder="Search"
                                  onChange={(e) => setAuthorSearch(e.target.value)}
                                />
                                <span className="close form-control-clear hidden">
                                  <img src="/images/svg-icons/close.svg" alt="search" className="img-fluid" />
                                </span>
                              </div>
                            </div>
                          </div>
                          {author
                            .filter((item) => item.user_name.toLowerCase().includes(authorSearch.toLowerCase()))
                            .map((item) => (
                              <div className="accordionBody">
                                <div className="accordionBodyTxt">
                                  <p className="mb-0">{item.user_name}</p>
                                </div>
                                <div className="accordionBodyCheckBox">
                                  <div className="form-check">
                                    <input
                                      checked={item.check}
                                      className="form-check-input"
                                      type="checkbox"
                                      value={item._id}
                                      onChange={handleAutor}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Tags
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="filterSearch">
                            <div className="headerSearchBar has-clear">
                              <div className="input-group">
                                <button className="btn" type="button" style={{ zIndex: 9 }}>
                                  <img src="/images/svg-icons/search.svg" alt="logo" className="img-fluid" />
                                </button>
                                <input
                                  type="text"
                                  className="form-control border-0"
                                  placeholder="Search"
                                  onChange={(e) => setTagSearch(e.target.value)}
                                />
                                <span className="close form-control-clear hidden">
                                  <img src="/images/svg-icons/close.svg" alt="search" className="img-fluid" />
                                </span>
                              </div>
                            </div>
                          </div>

                          {tags
                            .filter((item) => item.value.toLowerCase().includes(tagSearch.toLowerCase()))
                            .map((item) => (
                              <div className="accordionBody">
                                <div className="accordionBodyTxt">
                                  <p className="mb-0">{item.value}</p>
                                </div>
                                <div className="accordionBodyCheckBox">
                                  <div className="form-check">
                                    <input
                                      checked={item.check}
                                      className="form-check-input"
                                      type="checkbox"
                                      value={item.value}
                                      onChange={handleTag}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          Language
                        </button>
                      </h2>
                      <div
                        id="collapseSeven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSeven"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          {languages.map((item) => (
                            <div className="accordionBody">
                              <div className="accordionBodyTxt">
                                <p className="mb-0">{item.value}</p>
                              </div>
                              <div className="accordionBodyCheckBox">
                                <div className="form-check">
                                  <input
                                    checked={item.check}
                                    className="form-check-input"
                                    type="checkbox"
                                    value={item.value}
                                    onChange={handleLanguage}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          Rewards
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          {rewards.map((item) => (
                            <div className="accordionBody">
                              <div className="accordionBodyTxt">
                                <p className="mb-0">{item.value}</p>
                              </div>
                              <div className="accordionBodyCheckBox">
                                <div className="form-check">
                                  <input
                                    checked={item.check}
                                    className="form-check-input"
                                    type="checkbox"
                                    value={item.value}
                                    onChange={handleRewards}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* <!-- Modal footer --> */}
                <div className="modal-footer">
                  <button
                    type="button"
                    className={"btn btnFilter" + (enableFilterButton ? "" : " disabled opacity-half")}
                    // disabled="disabled"
                    onClick={(e) => {
                      handleSubmit("ApplyFilterButton");
                      onClose();
                    }}
                  >
                    APPLY FILTERS
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
}

export default Filters;
