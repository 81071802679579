import util from "../util/util";
import axios from "axios";
export default class CareerAptService {
  async addData(formData) {
    const token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "no-token";
    const config = {
      headers: {
        content: "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_BASEURL + "/careerapplicant",
        formData,
        config
      );
      if (response.err) {
        throw new Error(response.err);
      } else {
        return response;
      }
    } catch (err) {
      throw err;
    }
  }
}
