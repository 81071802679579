import { Link } from "react-router-dom";
import util from "../../util/util";

function BlogCard({ blog }) {
  return (
    <div className="col-sm-6 col-lg-6 col-xl-3 col-xxl-2 cardsBox">
      <div className="updates_card updateCard updateCardBlog updateCardBlogCustom">
        <Link to={"/blogs/" + blog._id}>
          <div className="updates_box upDatesBox upBlogBox">
            <div className="sliderBoxImg text-center mb-3">
              <img
                src={blog.blog_image}
                alt=""
                className="img-fluid"
                // style={{ height: "120px", width: "206px" }}
              />
            </div>
            <div className="d-flex boxHeadingGroup">
              <div className="box_heading ">
                <h4 className="m-0 blogHeading">{blog.blog_title}</h4>
              </div>
              <div className="box_content mb-3">
                <p className="m-0">
                  {blog.blog_title.length < 25
                    ? blog.content.replace(/<[^>]*>?/gm, "").substring(0, 112)
                    : blog.content.replace(/<[^>]*>?/gm, "").substring(0, 90)}
                </p>
              </div>
              <div
                className="box_heading box_post d-flex align-items-center justify-content-between"
                style={{ marginTop: "auto" }}
              >
                <div className="postingName">
                  <img
                    src="/images/svg-icons/doodle.svg"
                    alt="doodle"
                    className="img-fluid"
                  />
                  <p className="mb-0">{blog.author?.full_name}</p>
                </div>
                <div className="postDay">
                  <p className="mb-0">{util.timeSince(blog.createdAt)}</p>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default BlogCard;
