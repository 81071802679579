import { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import GlobalContext from "../context/GlobalContext";

function Sidebar() {
  const globalCtx = useContext(GlobalContext);
  const [searchDtl, setSearchDtl] = globalCtx.searchDtl;
  const [setting, setSetting] = globalCtx.setting;
  const [isActive, setIsActive] = useState(false);
  const [selectedNav, setSelectedNav] = useState(null);
  let location = useLocation();

  const clickHandler = (val) => {
    setSelectedNav(val);
  };
  useEffect(() => {
    handleActive();
  }, [location]);
  const handleActive = () => {
    if (location.pathname === "/") {
      setSelectedNav("/");
    } else if (location.pathname.includes("courses")) {
      setSelectedNav("/courses");
    } else if (location.pathname.includes("glossary")) {
      setSelectedNav("/glossary");
    } else if (location.pathname.includes("blogs")) {
      setSelectedNav("/blogs");
    } else if (location.pathname.includes("faq")) {
      setSelectedNav("/faq");
    } else if (location.pathname.includes("support")) {
      setSelectedNav("/support");
    } else if (location.pathname.includes("about")) {
      setSelectedNav("/about");
    } else if (location.pathname.includes("careers")) {
      setSelectedNav("/careers");
    } else if (location.pathname.includes("partners")) {
      setSelectedNav("/partners");
    } else if (location.pathname.includes("contributors")) {
      setSelectedNav("/contributors");
    } else if (location.pathname.includes("nftprofile")) {
      setSelectedNav("/nftprofile");
    } else if (location.pathname.includes("videos")) {
      setSelectedNav("/videos");
    } else {
      setSelectedNav(null);
    }
  };
  return (
    <div
      className="w-100 clearfix leftSection navbar navbar-expand-lg p-0"
      id="collapsibleNavbar"
      onClick={() => {
        setSearchDtl("");
      }}
    >
      <aside className="sidebarSection collapse navbar-collapse">
        <div className="topNav">
          <div className="logoColumn d-lg-flex d-none">
            <Link to="/" className="d-block">
              {setting?.brand?.header_logo && (
                <img src={setting?.brand?.header_logo} alt="logo" className="img-fluid img-unselect" />
              )}
            </Link>
          </div>
          <div className="menuColumn">
            <ul className="nav flex-column">
              <li className="nav-item">
                <Link className={"nav-link" + (selectedNav == "/" ? " active" : "")} to="/">
                  <div className="subMenuName d-flex align-items-center">
                    <img src="/images/svg-icons/home.svg" alt="logo" className="img-fluid" />
                    <span>Home</span>
                  </div>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={"nav-link" + (selectedNav == "/courses" ? " active" : "")} to="/courses">
                  <div className="subMenuName d-flex align-items-center">
                    <img src="/images/svg-icons/learn-earn.svg" alt="logo" className="img-fluid" />
                    <span>Learn</span>
                  </div>
                </Link>
              </li>
              {/* <li className="nav-item dropdown">
                <Link
                  className={"nav-link" + (selectedNav == "/nftprofile" ? " active" : "")}
                  to="/nftprofile"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="subMenuName d-flex align-items-center">
                    <img src="/images/svg-icons/profiles.svg" alt="logo" className="img-fluid" />
                    <span>Profiles</span>
                  </div>
                  <div className="subMenuIcon">
                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                  </div>
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item active" to="/nftprofile">
                      <span>Profile</span>
                    </Link>
                  </li>
                  <li>
                    <a className="dropdown-item" href="javascript:void(0);">
                      <span>NFT Collections</span>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="javascript:void(0);">
                      <span>Tokens</span>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="javascript:void(0);">
                      <span>DAOS</span>
                    </a>
                  </li>
                </ul>
              </li> */}
              <li className="nav-item">
                <Link className={"nav-link" + (selectedNav == "/glossary" ? " active" : "")} to="/glossary">
                  <div className="subMenuName d-flex align-items-center">
                    <img src="/images/svg-icons/glossary.svg" alt="logo" className="img-fluid" />
                    <span>Glossary</span>
                  </div>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={"nav-link  " + (selectedNav == "/videos" ? " active" : "")} to="/videos">
                  <div className="subMenuName d-flex align-items-center">
                    <img src="/images/svg-icons/video-camera.svg" alt="logo" className="img-fluid" />
                    <span>Videos</span>
                  </div>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={"nav-link disabled" + (selectedNav == "/news" ? " active" : "")} to="">
                  <div className="subMenuName d-flex align-items-center">
                    <span>📰 &nbsp;&nbsp; News</span>
                  </div>
                </Link>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link disabled" href="javascript:void(0);">
                  <div className="subMenuName d-flex align-items-center">
                    <img
                      src="/images/svg-icons/record.svg"
                      alt="logo"
                      className="img-fluid"
                    />
                    <span>Wall of Records</span>
                  </div>
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link disabled"
                  href="javascript:void(0)"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="subMenuName d-flex align-items-center">
                    <img
                      src="/images/svg-icons/rankings.svg"
                      alt="logo"
                      className="img-fluid"
                    />
                    <span>Rankings</span>
                  </div>
                  <div className="subMenuIcon">
                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                  </div>
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="javascript:void(0);">
                      <span>Crypto</span>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="javascript:void(0);">
                      <span>NFTs</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled" href="javascript:void(0);">
                  <div className="subMenuName d-flex align-items-center">
                    <img
                      src="/images/svg-icons/hammer-and-wrench.svg"
                      alt="logo"
                      className="img-fluid"
                    />
                    <span>Tools Suite</span>
                  </div>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled" href="javascript:void(0);">
                  <div className="subMenuName d-flex align-items-center">
                    <img
                      src="/images/svg-icons/pick.svg"
                      alt="logo"
                      className="img-fluid"
                    />
                    <span>SafeMint</span>
                  </div>
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link disabled"
                  href="javascript:void(0)"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="subMenuName d-flex align-items-center">
                    <img
                      src="/images/svg-icons/jobs-bounties.svg"
                      alt="logo"
                      className="img-fluid"
                    />
                    <span>Work in web3</span>
                  </div>
                  <div className="subMenuIcon">
                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                  </div>
                </a>

                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="javascript:void(0);">
                      <span>Jobs</span>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="javascript:void(0);">
                      <span>Bounties</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled" href="javascript:void(0);">
                  <div className="subMenuName d-flex align-items-center">
                    <img
                      src="/images/svg-icons/pick.svg"
                      alt="logo"
                      className="img-fluid"
                    />
                    <span>Podcasts</span>
                  </div>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled" href="javascript:void(0);">
                  <div className="subMenuName d-flex align-items-center">

                    <span>💳️ Gift Cards</span>
                  </div>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled" href="javascript:void(0);">
                  <div className="subMenuName d-flex align-items-center">

                    <span>🗓 Events Calendar</span>
                  </div>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled" href="javascript:void(0);">
                  <div className="subMenuName d-flex align-items-center">
                    <span>👺️ Known Scams</span>
                  </div>
                </a>
              </li>
              <li className="nav-item dropdown d-block d-lg-none">
                <a
                  className="nav-link"
                  href="javascript:void(0)"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="subMenuName d-flex align-items-center">
                    <img
                      src="/images/svg-icons/user-profiles.svg"
                      alt="logo"
                      className="img-fluid"
                    />
                    <span>Account</span>
                  </div>
                  <div className="subMenuIcon">
                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                  </div>
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="javascript:void(0);">
                      <span>Copy Address</span>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="javascript:void(0);">
                      <span>Account & Settings</span>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="javascript:void(0);">
                      <span>Help & support</span>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="javascript:void(0);">
                      <span>Change wallet</span>
                    </a>
                  </li>
                </ul>
                <div className="headerBtnSec slidebarBtn d-flex">
                  <div className="modeBtn">
                    <a
                      href="javascript:void(0)"
                      className="headerNotification"
                      data-bs-toggle="modal"
                      data-bs-target="#settingsPopup"
                    >
                      <img
                        src="/images/svg-icons/setting.svg"
                        alt="setting"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                  <div className="modeBtn disBtn">
                    <a href="javascript:void(0)" className="disconnectBtn">
                      Disconnect
                    </a>
                  </div>
                </div>
              </li> */}
            </ul>
          </div>
          {/* temp hidden */}
          {/* <div className="menuColumn">
            <ul className="nav flex-column">
              <li className="nav-item">
                <a className="nav-link disabled" href="javascript:void(0);">
                  <div className="subMenuName d-flex align-items-center">
                    <img
                      src="/images/svg-icons/portfolio.svg"
                      alt="logo"
                      className="img-fluid"
                    />
                    <span>Portfolio</span>
                  </div>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled" href="javascript:void(0);">
                  <div className="subMenuName d-flex align-items-center">
                    <img
                      src="/images/svg-icons/borrow.svg"
                      alt="logo"
                      className="img-fluid"
                    />
                    <span>Borrow/Lend</span>
                  </div>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled" href="javascript:void(0);">
                  <div className="subMenuName d-flex align-items-center">
                    <img
                      src="/images/svg-icons/tax-manager.svg"
                      alt="logo"
                      className="img-fluid"
                    />
                    <span>Tax Manager</span>
                  </div>
                </a>
              </li>
            </ul>
          </div> */}
          <div className="sideBarBottomContant">
            <div className="sidebarBottomNav">
              <ul className="nav">
                <li className="nav-item">
                  <a className="nav-link disabled" href="docs.mavefi.com" target="_blank">
                    Docs
                  </a>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link" + (selectedNav == "/blogs" ? " active" : "")} to="/blogs">
                    Blog
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link disabled" href="#">
                    Newsletter
                  </a>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link" + (selectedNav == "/faq" ? " active" : "")} to="/faq">
                    Faq's
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link " + (selectedNav == "/support" ? " active" : "")} to="/support">
                    Support
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link" + (selectedNav == "/about" ? " active" : "")} to="/about">
                    About
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    className={
                      "nav-link disabled" +
                      (selectedNav == "/careers" ? " active" : "")
                    }
                    to="/careers"
                  >
                    Careers
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link className={"nav-link" + (selectedNav == "/partners" ? " active" : "")} to="/partners">
                    Partners
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={"nav-link disabled" + (selectedNav == "/contributors" ? " active" : "")}
                    to="/contributors"
                  >
                    Contributors
                  </Link>
                </li>
              </ul>
            </div>
            {/* <div className="sideBarCopyRight">
              <p>
                Platform Ver. 2.0.2 <br /> © 2022 MaveFi Inc.
              </p>
            </div> */}
          </div>
        </div>
      </aside>
    </div>
  );
}

export default Sidebar;
