import { Link } from "react-router-dom";
import util from "../../util/util";
import { useState, useEffect } from "react";
import { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import SocialShare from "../../shared/SocialShare";

function NftProfileCard({ nft }) {
  const globalCtx = useContext(GlobalContext);
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const { updateBookmark, checkBookmark } = globalCtx;

  const [showBottomDetails, setShowBottomDetails] = useState(false);
  useEffect(() => {
    if (showBottomDetails) {
    }
  }, [showBottomDetails]);
  return (
    <div className={"col-sm-6 col-lg-6 col-xl-3 col-xxl-2  cardsBox cardsBoxNft "}>
      <div className="updates_card updateCard updateCardBlog updateCardBlogCustom">
        <div className="updates_box upDatesBox upBlogBox videoBannerCard">
          <Link to={"/nftprofile/" + nft._id}>
            <div className=" text-center nftImageHeader">
              {nft.cover_img && nft.cover_img !== "" ? (
                <img src={nft.cover_img} alt="" className="img-fluid" style={{ height: "74px", width: "100%" }} />
              ) : (
                <div className="nfcEmptyBackground"></div>
              )}
              <div className="internalImage">
                <img src={nft.banner_img} alt="" className="img-fluid internalImg" />
              </div>
            </div>
          </Link>
          <div className="d-flex boxHeadingGroup nftHeadingGroup">
            <a className="">{nft.collection_type}</a>
            <h4>{nft.name}</h4>
            <p>{nft.description.length > 125 ? nft.description.slice(0, 125) + "..." : nft.description}</p>
          </div>
          <div className="cardBtnIcon nftCardBtnCustom">
            <div className="headerBtnSec d-flex">
              {isAuthentiCated && (
                <div className="modeBtn">
                  <a
                    href="javascript:void(0)"
                    className=""
                    // onClick={() => {
                    //   updateBookmark(course._id, "course");
                    // }}
                  >
                    {/* {checkBookmark(course._id) ? ( */}
                    {false ? (
                      <img
                        src="/images/svg-icons/bookmark-active.svg"
                        alt="bookmark"
                        className="img-fluid bookmarkActive"
                      />
                    ) : (
                      <img
                        src="/images/svg-icons/bookmark-normal.svg"
                        alt="bookmark"
                        className="img-fluid bookmarkNormal"
                      />
                    )}
                    {/* <img
                      src="/images/svg-icons/bookmark-hover.svg"
                      alt="bookmark"
                      className="img-fluid bookmarkHover"
                    /> */}
                  </a>
                </div>
              )}
              <SocialShare
                twitter={{
                  title: "name",
                  // title: course.course_name,
                  url: window.location.origin + "/nftprofile/info/",
                  // url: window.location.origin + "/courses/info/" + course._id,
                }}
                facebook={{
                  title: "name",
                  // title: course.course_name,e,
                  url: window.location.origin + "/nftprofile/info/",
                  // url: window.location.origin + "/courses/info/" + course._id,
                }}
                website={{
                  title: "name",
                  // title: course.course_name,
                  url: window.location.origin + "/nftprofile/info/",
                  // url: window.location.origin + "/courses/info/" + course._id,
                }}
              />
            </div>
          </div>
        </div>
        {/* </Link> */}
      </div>
    </div>
  );
}

export default NftProfileCard;
