import { useEffect, useState } from "react";
import ContributorCard from "./ContributorCard";
import ContributorService from "../../services/ContributorService";
import ScrollMoreProvider from "../../shared/Scroll/ScrollMoreProvider";
import TitleCard2 from "../../shared/TitleCard2";
import PageSearch from "../../shared/PageSearch";
import Loading from "../../shared/Loading";
import { Helmet } from "react-helmet";
import PageHeader from "../../shared/PageHeader";

const serv = new ContributorService();

function Contributors() {
  const [records, setRecords] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [recordsCount, setRecordsCount] = useState(0);
  const [sortLabel, setSortLabel] = useState("Recently Added");
  const [sortBy, setSortBy] = useState({ createdAt: "desc" });
  const [search, setSearch] = useState({
    filter: { searchText: "", is_active: true },
    start: 0,
    length: 20,
    sortBy: { createdAt: "desc" },
  });
  let loading = false;

  useEffect(() => {
    getList();
  }, [search]);

  async function getList() {
    if (loading === false && (recordsCount == 0 || recordsCount > records.length)) {
      loading = true;
      let respData = await serv.listAll(search);
      setRecords(recordsCount == 0 ? respData.data : [...records, ...respData.data]);
      setRecordsCount(respData.total);
      setShowLoading(false);
    }
  }

  function reachedBottomCall() {
    let searchTemp = { ...search };
    searchTemp.start = search.start + search.length;
    setSearch(searchTemp);
  }

  function searchCallBack(value) {
    let searchTemp = { ...search };
    searchTemp.start = 0;
    searchTemp.filter.searchText = value;
    setRecordsCount(0);
    setSearch(searchTemp);
  }
  function sortHandler(sort, label) {
    if (label == "A to Z") {
      sort = { name: "asc" };
    } else if (label == "Z to A") {
      sort = { name: "desc" };
    }
    let searchTemp = { ...search };
    searchTemp.start = 0;
    searchTemp.sortBy = sort;
    setRecordsCount(0);
    setSearch(searchTemp);
    setSortBy(sort);
    setSortLabel(label);
  }
  if (showLoading) {
    return <Loading />;
  }

  return (
    <div className="ljSectionData w-100 clearfix glossarylist" id="ljSectionData">
      <Helmet>
        <title>Contributors | MaveFi</title>
      </Helmet>
      <PageHeader
        type="blog"
        searchCallBack={searchCallBack}
        search={{ ...search, sortLabel, sortHandler }}
        // handleReferPopUp={handleReferPopUp}
        titleCard={{
          image: "/images/svg-icons/trophy.svg",
          title: "Contributors",
          content:
            "We are looking for contributors who want to share their expertise, knowledge and insights with people eager to embrace the possibilities of the decentralised economy. If that’s you, contact us today!",
          type: "glossary",
        }}
        searchType={{ type: "glossary", placeholder: "Search contributors" }}
        loginTitle="Please login/sign up to start a course or view your progress."
        rightActionCard={{
          heading: "Interested in contributing?",
          desc: "We’re looking for talented contributors who can offer expert insights and creative flair. Reach out if you think your are the right fit.",
          buttonName: "Email us at careers@mavefi.com",
          link: "mailto:careers@mavefi.com",
        }}
      />
      {/* <div className="partnerTitleSec">
        <div className="row glossaryTitleSecRow">
          <div className="col-lg-12 col-xl-8 glossaryTitleCol ">
            <TitleCard2
              image="/images/svg-icons/trophy.svg"
              title="Contributors"
              content="We are looking for contributors who want to share their expertise, knowledge and insights with people eager to embrace the possibilities of the decentralised economy. If that’s you, contact us today!"
              type="glossary"
            />
            <PageSearch placeholder="Search contributors" searchCallBack={searchCallBack} />
          </div>
          <div className="col-lg-12 col-xl-4  ps-xl-0 glossaryTitleCol">
            <div className="h-100 titleCard faqCompleted my-md-0 card">
              <div className="faqComp updatedCard">
                <h4>Interested in contributing?</h4>
                <p className="mb-0 font-12">
                  We’re looking for talented contributors who can offer expert insights and creative flair. Reach out if
                  you think your are the right fit.
                  <div className="emailBtn">
                    <a href="mailto:careers@mavefi.com" style={{ textDecoration: "none" }}>
                      Email us at <u>careers@mavefi.com</u>
                    </a>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="allPartnerCard">
        <div className="allUpdateCardList allLearnEarnCardList">
          <div className="updates_card_section updatesCardSection mb-3">
            {records.length > 0 && (
              <ScrollMoreProvider
                className="row updatesCardSectionRow"
                id="careersList"
                reachedBottomCall={reachedBottomCall}
                useWindowScroll={true}
              >
                {records.map((c) => {
                  return <ContributorCard key={c._id} contributor={c} />;
                })}
              </ScrollMoreProvider>
            )}
            {records.length < recordsCount && <Loading isSmall={true} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contributors;
