import { useContext, useEffect, useState } from "react";

import NewsService from "../../services/NewsService";
import ScrollMoreProvider from "../../shared/Scroll/ScrollMoreProvider";
import TitleCard2 from "../../shared/TitleCard2";
import GlobalContext from "../../context/GlobalContext";
import Loading from "../../shared/Loading";
import { Helmet } from "react-helmet";
import LoginSignupCard from "../../shared/LoginSignupCard";
import NewsCard from "./NewsCard";
import PageHeader from "../../shared/PageHeader";

const serv = new NewsService();
export default function News() {
  const globalCtx = useContext(GlobalContext);
  const [searchDtl, setSearchDtl] = globalCtx.searchDtl;
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const [setting, setSetting] = globalCtx.setting;
  const [records, setRecords] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [recordsCount, setRecordsCount] = useState(0);
  const [activeFilterList, setActiveFilterList] = useState([]);
  const [search, setSearch] = useState({
    filter: { searchText: searchDtl, is_active: true },
    start: 0,
    length: 20,
  });
  let loading = false;
  const [filterListObj, setFilterListObj] = useState({
    publishers: [],
    languages: [{ check: false, value: "English" }],
  });
  const sortArray = [
    { method: { createdAt: "desc" }, label: "Recently Added" },
    { method: { news_title: "asc" }, label: "A to Z" },
    { method: { news_title: "desc" }, label: "Z to A" },
    { method: { viewCount: "desc" }, label: "Most viewed" },
    { method: { bookmarkCount: "desc" }, label: "Most bookmarked" },
    { method: { createdAt: "asc" }, label: "Oldest" },
  ];
  const [filterObj, setFilterObj] = useState({});
  useEffect(() => {
    getList();
    filterList();
  }, [search]);

  useEffect(() => {
    searchCallBack(searchDtl);
  }, [searchDtl]);

  async function getList() {
    if (loading === false && (recordsCount == 0 || recordsCount > records.length)) {
      loading = true;
      let respData = await serv.listAll(search);
      setRecords(recordsCount == 0 ? respData.data : [...records, ...respData.data]);
      setRecordsCount(respData.total);
      setShowLoading(false);
    }
  }

  function reachedBottomCall() {
    let searchTemp = { ...search };
    searchTemp.start = search.start + search.length;
    setSearch(searchTemp);
  }

  async function searchCallBack(value, filters, sortBy) {
    let respData = await serv.getFilterList();
    let searchTemp = { ...search };
    searchTemp.start = 0;
    if (filters !== undefined) {
      searchTemp.filter = filters;
    }
    searchTemp.filter.searchText = value;
    searchTemp.filter.is_active = true;
    searchTemp.sort = { createdAt: "desc" };
    // let filterList = [];
    // if (filters !== undefined) {
    //   if (filters?.publishers?.length > 0) {
    //     searchTemp.filter.publishers = filters.publishers;
    //     filterList.push({
    //       type: "author",
    //       value: [
    //         ...filters.publishers.map((i) => respData.data.publishers.filter((j) => j._id == i)[0].publisher_name),
    //       ],
    //     });
    //     // filterList.push(...filters.author)
    //   }

    //   if (filters?.languages?.length > 0) {
    //     searchTemp.filter.languages = filters.languages;
    //     filterList.push({ type: "languages", value: filters.languages });
    //     // filterList.push(...filters.languages)
    //   }
    // }
    filterList();
    if (sortBy) {
      searchTemp.sort = sortBy;
    }
    setRecordsCount(0);
    setSearch(searchTemp);
  }
  async function filterList() {
    let respData = await serv.getFilterList();
    let publisherValues = [];
    let publisher = [];
    let languageValues = [];
    let language = [];
    respData.data.publisher.map((item) => {
      let publisherValue = { check: false, _id: item._id, publisher_name: item.publisher_name };

      if (search?.filter?.publishers?.includes(item._id)) {
        publisherValue.check = true;
        publisher.push(item._id);
      }
      publisherValues.push(publisherValue);
    });
    filterListObj.languages.map((item) => {
      let languageValue = item;

      if (search.filter?.languages?.includes(item.value)) {
        languageValue.check = true;
        language.push(item.value);
      }
      languageValues.push(languageValue);
    });
    let tempFilterObj = {
      publishers: publisher,
      languages: language,
    };
    let tempFilterListObj = {
      publishers: publisherValues,
      languages: languageValues,
    };
    setFilterObj({ ...tempFilterObj });
    setFilterListObj({ ...tempFilterListObj });
  }
  if (showLoading) {
    return <Loading />;
  }

  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <Helmet>
        <title>{setting?.seo?.video?.title || ""}</title>
        <meta name="description" content={setting?.seo?.video?.meta_description || ""} />
        <meta name="keywords" content={setting?.seo?.video?.tags || ""} />
        <meta property="og:title" content={setting?.seo?.video?.title || ""} />
        <meta property="og:description" content={setting?.seo?.video?.meta_description || ""} />
        <meta property="og:image" content={setting?.seo?.social_share_image} />
      </Helmet>
      <PageHeader
        allowedFilter={true}
        type="course"
        searchCallBack={searchCallBack}
        filterListObj={filterListObj}
        filterObj={filterObj}
        search={search}
        sortArray={sortArray}
        newsLetter={true}
        height98={true}
        topW100={true}
        titleCard={{
          // image: "/images/svg-icons/video-camera.svg",
          title: "📰  News",
          content: "Find all your favourite web3 news publications in a single place.",
          type: "glossary",
        }}
        searchType={{ type: "news", placeholder: "Search News" }}
        loginTitle="Please login/sign up to contribute a video to our library."
        rightActionCard={{
          heading: "Submit a web3 video",
          desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been theindustry's standard",
          buttonName: "Contribute",
        }}
      />
      <div className="allBlogCard">
        <div className="allUpdateCardList allLearnEarnCardList">
          <div className="updates_card_section updatesCardSection mb-3">
            {records.length > 0 && (
              <ScrollMoreProvider
                className="row updatesCardSectionRow"
                id="glossary list"
                reachedBottomCall={reachedBottomCall}
                useWindowScroll={true}
              >
                {records.map((b, idx) => {
                  if (idx == 9) {
                    return (
                      <>
                        <div className="col-sm-6 col-lg-6 col-xl-3 col-xxl-2 cardsBox cardsBoxNews">
                          <div className="updates_card updateCard updateCardBlog updateCardBlogCustom">
                            <amp-ad
                              width="226"
                              height="240"
                              type="adsense"
                              data-ad-client="ca-pub-1429924092740823"
                              data-ad-slot="8890481255"
                              data-auto-format="rspv"
                              data-full-width=""
                            >
                              <div overflow=""></div>
                            </amp-ad>
                          </div>
                        </div>
                        <NewsCard key={b._id} news={b} />
                      </>
                    );
                  } else {
                    return <NewsCard key={b._id} news={b} />;
                  }
                })}
              </ScrollMoreProvider>
            )}
            {records.length < recordsCount && <Loading isSmall={true} />}
          </div>
        </div>
      </div>
    </div>
  );
}
