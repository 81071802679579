import React from "react";
function fnBrowserDetect() {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
  } else {
    browserName = "No browser detection";
  }

  return browserName;
}

function NoMobile() {
  const bn = fnBrowserDetect();
  return (
    <div className="w-100 clearfix mainSideMobileView" id="mainSideMobileView">
      <div className="mainSideMobileView_child">
        <div className={"miannew_mob " + (bn == "safari" ? "miannew_mob_iphone" : "")}>
          <div className="logo_img" style={{ textAlign: "center" }}>
            <img src="/images/logo-high-reso.svg" width="141px" />
          </div>
          <div className="miannew_mob_content">
            <div className="middlecontentIMg">
              <img src="/images/image/Mobile_site_banner_3.png" className="img-fluid" />
            </div>
            <h4>Hey, Mobile Friend!</h4>
            <p>
              The MaveFi webapp is best viewed on a laptop or desktop computer. We do not support usage of our webapp on
              mobile at this moment!
            </p>
            <div className="twitter_div">
              <p className="mb-0">Check us out on Twitter meanwhile?</p>
              <a href="https://twitter.com/joinmavefi" target="_blank">
                <img src="/images/twitter_icon.svg" style={{ marginRight: "7px" }} />
                <span>@joinMaveFi</span>
              </a>
            </div>
          </div>
          <div className="copyrightTag">
            <p className="mb-0">Copyright MaveFi Inc, 2022. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoMobile;
