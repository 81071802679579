import { useEffect, useState } from "react";
import UpdateService from "../../services/UpdateService";
import Loading from "../../shared/Loading";
import ScrollMoreProvider from "../../shared/Scroll/ScrollMoreProvider";
import util from "../../util/util";

const serv = new UpdateService();

function Updates() {
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [search, setSearch] = useState({
    filter: { searchText: "", is_active: true },
    start: 0,
    length: 20,
  });
  let loading = false;

  useEffect(() => {
    getList();
  }, [search]);

  async function getList() {
    if (
      loading === false &&
      (recordsCount == 0 || recordsCount > records.length)
    ) {
      loading = true;
      let respData = await serv.listAll(search);
      setRecords([...records, ...respData.data]);
      setRecordsCount(respData.total);
    }
  }

  function reachedBottomCall() {
    let searchTemp = { ...search };
    searchTemp.start = search.start + search.length;
    setSearch(searchTemp);
  }

  function searchCallBack(value) {
    let searchTemp = { ...search };
    searchTemp.start = 0;
    searchTemp.filter.searchText = value;
    setRecords([]);
    setRecordsCount(0);
    setSearch(searchTemp);
  }

  return (
    <div className="notificationInfoBox">
      <div className="loginInfoSection">
        <div className="infoUpdateSec mx-auto">
          <div className="infoUpdateHeader text-white d-flex align-items-center justify-content-between">
            <div className="infoUpHeader">
              <h5 className="mb-0">Updates from the team</h5>
            </div>
            {/* <div className="infoUpdateCounter">
              <button
                type="button"
                className="infoUpCounter d-flex align-items-center text-white"
              >
                <div className="infoCounterIcon">
                  <img
                    src="/images/svg-icons/rocket.svg"
                    alt=""
                    className="img-fluid me-1"
                  />
                </div>
                <div className="infoCounterText">
                  <span>
                    {Intl.NumberFormat("en-IN", {
                      maximumSignificantDigits: 3,
                    }).format(recordsCount)}
                  </span>
                </div>
              </button>
            </div> */}
          </div>
          {records.length > 0 ? (
            <ScrollMoreProvider
              className="infoUpdateNotification border border-end-0"
              id="notification"
              reachedBottomCall={reachedBottomCall}
              useWindowScroll={false}
            >
              <>
                {records.length > 0 &&
                  records.map((update) => {
                    return (
                      <div className="notificationBox">
                        <div className="notificationHeader d-flex align-items-center mb-1">
                          <div className="notifHeading">
                            <h6 className="mb-0">{update.word}</h6>
                          </div>
                          <div className="notifTime ms-auto">
                            <p className="mb-0">
                              {util.timeSince(update.createdAt)}
                            </p>
                          </div>
                        </div>
                        <div className="notificationHeaderPara">
                          <p className="mb-0">{update.update}</p>
                        </div>
                      </div>
                    );
                  })}
              </>
            </ScrollMoreProvider>
          ) : (
            <div className="infoUpdateNotification border border-end-0 d-flex">
              <Loading isSmall={false} isMedium={true} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Updates;
