export default function DummyCard({ cardCount }) {
  return (
    <div className="allUpdateCardList allLearnEarnCardList">
      <div className="updates_card_section updatesCardSection mb-3">
        <div className="row updatesCardSectionRow lazyLoader" id="load_data_courses">
          {[...Array(cardCount).keys()].map((el) => (
            <div className="col-sm-6 col-lg-6 col-xl-3 col-xxl-2 cardsBox pb-md-4 pb-3 load_data_message">
              <div className="updates_card learnEarnCard">
                <div className="updates_box">
                  <p style={{ marginBottom: "12px" }}>
                    <span
                      className="content-placeholder bannerImgloader"
                      style={{
                        width: "100%",
                        height: "109px",
                        borderRadius: "8px",
                      }}
                    >
                      &nbsp;
                    </span>
                  </p>
                  <p
                    style={{
                      width: "100%",
                      height: "12px",
                      marginBottom: "6px",
                    }}
                  >
                    <span
                      className="content-placeholder"
                      style={{
                        width: "100%",
                        height: "12px",
                        borderRadius: "4px",
                      }}
                    >
                      &nbsp;
                    </span>
                  </p>
                  <h4
                    style={{
                      width: "100%",
                      height: "16px",
                      borderRadius: "4px",
                      marginBottom: "5px",
                    }}
                  >
                    <span className="content-placeholder" style={{ width: "100%", height: "10px" }}>
                      &nbsp;
                    </span>
                  </h4>
                  <p
                    style={{
                      width: "100%",
                      height: "68px",
                      marginBottom: "20px",
                      borderRadius: "4px",
                    }}
                  >
                    <span className="content-placeholder" style={{ width: "100%", height: "68px" }}>
                      &nbsp;
                    </span>
                  </p>
                  <p
                    style={{
                      width: "100%",
                      height: "28px",
                      borderRadius: "8px",
                      marginBottom: "0px",
                    }}
                  >
                    <span className="content-placeholder" style={{ width: "100%", height: "28px" }}>
                      &nbsp;
                    </span>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
