import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { useNavigate, Link } from "react-router-dom";
import Signup from "../../popups/Signup/Signup";
import Login from "../../popups/Login/Login";
import SignupOtp from "../../popups/SignupOtp/SignupOtp";
import SignupUserName from "../../popups/SignupUserName/SignupUserName";
import ForgotPassword from "../../popups/ForgotPassword/ForgotPassword";
import ChangePassword from "../../popups/ChangePassword/ChangePassword";
import ResetPasswordOtp from "../../popups/ResetPasswordOtp/ResetPasswordOtp";
function SupportSignUP() {
  const globalCtx = useContext(GlobalContext);
  const [isAuthentiCated, setIsAuthentiCated ] = globalCtx.auth;
  useEffect(()=>{
    if(isAuthentiCated==true){
      navigate("/support")
    }
  },[isAuthentiCated])
  const [showSignupPopup, setShowSignupPopup] = useState(false);
  const [showLoginOtpPopup, setShowLoginOtpPopup] = useState(false);
  const [showSignupOtpPopup, setShowSignupOtpPopup] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showFPPopup, setShowFPPopup] = useState(false);
  const [resetOtp, setResetOtp] = useState({});
  const [showChangePaswrdPopup, setShowChangePaswrdPopup] = useState(false);
  const [showSignupUserNamePopup, setShowSignupUserNamePopup] = useState(false);
  const [showChangePaswrdOtpPopup, setShowChangePaswrdOtpPopup] =
    useState(false);
  const [toggleNewPaswrd, setToggleNewPaswrd] = useState(false);
  const navigate = useNavigate();
  function showSignup() {
    setShowSignupPopup(!showSignupPopup);
  }
  function showSignupOtp() {
    setShowSignupOtpPopup(!showSignupOtpPopup);
  }
  function showLogin() {
    setShowLoginPopup(!showLoginPopup);
  }
  function showSignupOtp() {
    setShowSignupOtpPopup(!showSignupOtpPopup);
  }
  function showSignupUserName() {
    setShowSignupUserNamePopup(!showSignupUserNamePopup);
  }
  function showFP() {
    setShowFPPopup(!showFPPopup);
  }
  function showChangePaswrdOtp() {
    setShowChangePaswrdOtpPopup(!showChangePaswrdOtpPopup);
  }
  function showNewPassword() {
    setToggleNewPaswrd(!toggleNewPaswrd);
  }
  function showChangePassword() {
    setShowChangePaswrdPopup(!showChangePaswrdPopup);
  }
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <div className="support_second_sec mb-4">
        <div className="row glossaryTitleSecRow">
          <div className="col-lg-12 col-xl-8 glossaryTitleCol">
            <div className="support_form card p-0">
              <div className="support_sec_newHead p_20">
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <div className="my-0 titleCard p-0">
                    <h4 className="mb-2 letterSpacing0">
                      <img
                        src="/images/support/online_Support.svg"
                        alt="book"
                        className="img-fluid"
                      />{" "}
                      Support{" "}
                    </h4>
                    <p className="mb-0 letterSpacing0">Have questions about MaveFi?</p>
                  </div>
                </div>
              </div>
              <div className="p_20 not_loginSection d-flex align-items-center justify-content-center">
                <div className="loginSingIn_btn">
                  <p className="mb-3 letterSpacing0">
                    Please login/signup to view your tickets or open a new one.
                  </p>
                  <div className="dGridBtn">
                    <div className="d-grid">
                      <button
                        onClick={() => setShowLoginPopup(true)}
                        type="button"
                        className="btn btn-outline-secondary btn-block letterSpacing0"
                      >
                        Login
                      </button>
                    </div>
                  </div>
                  <div className="modelSignBtn mt-2">
                    <div className="d-grid">
                      <button
                        type="submit"
                        onClick={() => setShowSignupPopup(true)}
                        className="btn btn-primary btn-block letterSpacing0"
                      >
                        Sign up
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-xl-4 ps-xl-0 glossaryTitleCol">
            <div className="faqCompleted my-md-0 card">
            <div className="needHelp">
                <div className="needHelpHead d-flex align-items-center">
                  <div className="needHelpHeading">
                    <h5 className="mb-0 letterSpacing0">
                      Can not find the answers to your questions?{" "}
                    </h5>
                  </div>
                </div>
                <ul className="needHelpList">
                  <li className="helpList d-flex align-items-center">
                    <div className="needHelpIcon">
                      <img
                        src="/images/support/Mail.svg"
                        alt="needHelpIcon"
                        className="img-fluid"
                      />
                    </div>
                    <div className="needHelpHeading">
                      <p className="mb-0 letterSpacing0">
                        Email us at{" "}
                        <a href="mailto:support@mavefi.com" className="letterSpacing0">
                          support@mavefi.com
                        </a>
                      </p>
                    </div>
                  </li>
                  <li className="helpList d-flex align-items-center">
                    <div className="needHelpIcon">
                      <img
                        src="/images/svg-icons/twitter-squared.svg"
                        alt="needHelpIcon"
                        className="img-fluid"
                      />
                    </div>
                    <div className="needHelpHeading">
                      <p className="mb-0 letterSpacing0">
                        or Tweet us{" "}
                        <a
                          href="https://twitter.com/joinmavefi"
                          target="_blank"
                          className="letterSpacing0"
                        >
                          @MaveFiSupport
                        </a>
                      </p>
                    </div>
                  </li>
                  <li className="helpList d-flex align-items-center">
                    <div className="needHelpIcon">
                      <img
                        src="/images/support/askquestion.svg"
                        alt="needHelpIcon"
                        className="img-fluid"
                      />
                    </div>
                    <div className="needHelpHeading">
                      <p className="mb-0 letterSpacing0">
                        or Visit <Link to="/faq" className="letterSpacing0">our Faqs</Link>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSignupPopup && (
        <Signup
          onClose={() => {
            showSignup();
          }}
          onFinish={() => {
            showSignup();
            showSignupOtp();
          }}
          onShowLogin={() => {
            showSignup();
            showLogin();
          }}
        />
      )}
      {showSignupOtpPopup && (
        <SignupOtp
          onClose={() => {
            showSignupOtp();
          }}
          onFinish={() => {
            showSignupOtp();
            showSignupUserName();
          }}
          onEdit={() => {
            showSignupOtp();
            showSignup();
          }}
        />
      )}
      {showSignupUserNamePopup && (
        <SignupUserName
          onClose={() => {
            showSignupUserName();
          }}
        />
      )}
      {showChangePaswrdOtpPopup && (
        <ResetPasswordOtp
          otpDetail={resetOtp}
          onClose={(obj) => {
            showChangePaswrdOtp();
            setResetOtp({ ...obj });
            showNewPassword();
          }}
          // onFinish={() => {
          //   showNewPassword();
          //   showLogin();
          // }}
        />
      )}
      {showChangePaswrdPopup && (
        <ChangePassword
          onClose={showChangePassword}
          // onFinish={() => {
          //   showNewPassword();
          //   showLogin();
          // }}
        />
      )}
      {showLoginPopup && (
        <Login
          onClose={showLogin}
          onShowSignup={() => {
            showLogin();
            showSignup();
          }}
          onShowForgotPassword={() => {
            showLogin();
            showFP();
          }}
          setOtp={setShowLoginOtpPopup}
        />
      )}
      {showFPPopup && (
        <ForgotPassword
          onClose={showFP}
          onFinish={(obj) => {
            showFP();
            setResetOtp({ ...obj });
            showChangePaswrdOtp();
          }}
        />
      )}
    </div>
  );
}

export default SupportSignUP;
