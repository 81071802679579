import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import referEarnService from "../../services/ReferEarnService";
import GlobalContext from "../../context/GlobalContext";
import moment from "moment";
const serv = new referEarnService();

function ReferAndEarn({ onClose }) {
  const globalCtx = useContext(GlobalContext);
  const [user, setUser] = globalCtx.user;
  const [earnedCoinList, setEarnedCoinList] = useState([]);
  useEffect(() => {
    getEarnedCoinList();
  }, []);
  const getEarnedCoinList = async () => {
    try {
      let obj = {
        filter: {
          user_id: user._id,
        },
      };
      let resp = await serv.listEarnedCoins(obj);
      if (resp) {
        setEarnedCoinList(resp.data);
        console.log(resp);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //   const digitConverter = (value) => {
  //     if (value < 10) {
  //       return "00" + value;
  //     } else if (value < 100) {
  //       return "0" + value;
  //     } else {
  //       return value;
  //     }
  //   };

  return (
    <>
      <div
        className="modal commanModel SignupPopup showBlock"
        id="Signin"
        onClick={(e) => {
          if (e.target == e.currentTarget) {
            onClose();
          }
        }}
      >
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center ">
            <div
              className="modal-dialog"
              style={{ position: "absolute", top: "60px", right: "150px" }}
            >
              <div className="modal-content modal-content-custom dropdownShadow">
                {/* <!-- Modal Header --> */}
                <div
                  className="modal-header"
                  style={{ padding: "14px 18px", height: "47px" }}
                >
                  <h4 className="modal-title modal-title-custom">Wallet</h4>
                  <button
                    type="button"
                    className="btn-close btn-close-custom"
                    onClick={() => {
                      onClose();
                    }}
                  ></button>
                </div>
                {/* <!-- Modal body --> */}
                <div
                  className="refer_earn_count-wallet"
                  style={{ border: "none", paddingBottom: "0px" }}
                >
                  <div className="coursesPoints">
                    <div className="coursesLogo d-flex align-items-center">
                      <div className="wallet-main">
                        <img
                          src="/images/svg-icons/black-profile.svg"
                          alt="logoicon"
                          className="img-fluid"
                        />
                      </div>
                      <p className="mb-0">Coins</p>
                    </div>
                  </div>
                  <div className="coursesReferred">
                    <div className="mainCoursesReferred d-flex align-items-center">
                      <div className="courBtnInfo flex-grow-1">
                        <p
                          className="coursesReferredCode mb-0"
                          style={{ textAlign: "right", fontSize: "15px" }}
                        >
                          {user.reward}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="refer_earn_count-wallet"
                // style={{ paddingBottom: "20px", paddingTop: "6px" }}
                >
                  <div className="coursesPoints">
                    <h4>Learn Rewards</h4>
                    <div className="coursesLogo d-flex align-items-center">
                      <div className="coinPoints">
                        <p className="mb-0">
                          {user.courseReward ? user.courseReward : 0}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="coursesPoints">
                    <h4>Referral Rewards</h4>
                    <div className="coursesLogo d-flex align-items-center">
                      <div className="coinPoints">
                        <p className="mb-0">
                          {user.referalReward ? user.referalReward : 0}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="coursesPoints">
                    <h4>Bonus Rewards</h4>
                    <div className="coursesLogo d-flex align-items-center">
                      <div className="coinPoints">
                        <p className="mb-0">
                          {user.bonusReward ? user.bonusReward : 0}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="modal-body p-0
                "
                  style={{ height: "210px" }}
                >
                  <ul className="nav rewardList">
                    {earnedCoinList &&
                      earnedCoinList.map((item, idx) => {
                        return (
                          <li>
                            <div className="flexDiv">
                              <div className="rewardPoint">+{item?.reward}</div>
                              <div className="rewardPointRight">
                                {item?.type == "course" ? (
                                  <h4>
                                    Learn Reward for{" "}
                                    {item?.course_id?.course_name}
                                  </h4>
                                ) : item?.type == "refer" ? (
                                  <h4>
                                    Refferal Reward for{" "}
                                    {item?.refered_user_id?.full_name}
                                  </h4>
                                ) : (
                                  <h4>
                                    Bonus Reward for{" "}
                                    {item?.bonus_title}
                                  </h4>
                                )}
                                <p>
                                  {moment(item?.createdAt).format(
                                    "DD[th] MMM, YYYY - hh:mm A"
                                  )}
                                </p>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                {/* <!-- Modal footer --> */}
                <div className="modal-footer justify-content-center">
                  <button className="btn-wallet-custom">Deposit</button>
                  <button className="btn-wallet-custom">Withdraw</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="modal-backdrop show"></div> */}
    </>
  );
}

export default ReferAndEarn;
