import { useState, useEffect, useContext } from "react";
import UserService from "../services/UserService";
import axios from "axios";
import { useLocation } from "react-router-dom";
import {
  LoginSocialFacebook,
  LoginSocialGithub,
  LoginSocialInstagram,
  LoginSocialTwitter,
  LoginSocialGoogle,
} from "reactjs-social-login";
import { InstagramLoginButton, GoogleLoginButton } from "react-social-login-buttons";
export function FacebookAuth({ handleSocialConnect, btn }) {
  return (
    <LoginSocialFacebook
      appId={process.env.REACT_APP_FACEBOOK_ID}
      fieldsProfile={"id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"}
      redirect_uri={process.env.REACT_APP_REDIRECT_URL + "profile"}
      onResolve={({ provider, data }) => {
        handleSocialConnect(provider, data);
      }}
      onReject={(err) => {
        console.log(err);
      }}
    >
      {btn()}
      {/* <a className="nav-link profileSidebarBtn" href="javascript:void(0);">
        Connect
      </a> */}
    </LoginSocialFacebook>
  );
}
export function InstagramAuth({ handleSocialConnect }) {
  return (
    <LoginSocialInstagram
      client_id={process.env.REACT_APP_INSTAGRAM_ID || ""}
      client_secret={process.env.REACT_APP_INSTAGRAM_SECRET || ""}
      redirect_uri={process.env.REACT_APP_REDIRECT_URL + "profile"}
      onResolve={({ provider, data }) => {
        handleSocialConnect(provider, data);
      }}
      onReject={(err) => {
        console.log(err);
      }}
    >
      {/* <InstagramLoginButton /> */}
      <a className="nav-link profileSidebarBtn" href="javascript:void(0);">
        Connect
      </a>
    </LoginSocialInstagram>
  );
}
export function TwitterAuth({ handleSocialConnect, btn }) {
  return (
    <LoginSocialTwitter
      client_id={process.env.REACT_APP_TWITTER_ID || ""}
      client_secret={process.env.REACT_APP_TWITTER_SECRET || ""}
      redirect_uri={process.env.REACT_APP_REDIRECT_URL + "profile"}
      onResolve={({ provider, data }) => {
        handleSocialConnect(provider, data);
      }}
      onReject={(err) => {
        console.log(err);
      }}
    >
      {btn()}
      {/* <a className="nav-link profileSidebarBtn" href="javascript:void(0);">
        Connect
      </a> */}
    </LoginSocialTwitter>
  );
}
export function GitHubAuth({ handleSocialConnect }) {
  return (
    <LoginSocialGithub
      client_id={process.env.REACT_APP_GITHUB_ID || ""}
      client_secret={process.env.REACT_APP_GITHUB_SECRET || ""}
      redirect_uri={process.env.REACT_APP_REDIRECT_URL + "profile"}
      onResolve={({ provider, data }) => {
        handleSocialConnect(provider, data);
      }}
      onReject={(err) => {
        console.log(err);
      }}
    >
      <a className="nav-link profileSidebarBtn" href="javascript:void(0);">
        Connect
      </a>
    </LoginSocialGithub>
  );
}
export function GoogleAuth({ handleGoogleLogin, btn }) {
  const serv = new UserService();
  return (
    <LoginSocialGoogle
      client_id={process.env.REACT_APP_GOOGLE_ID}
      // onLoginStart={onLoginStart}
      redirect_uri={process.env.REACT_APP_SIGNIN_REDIRECT_URL}
      scope="openid profile email"
      discoveryDocs="claims_supported"
      access_type="offline"
      onResolve={async ({ provider, data }) => {
        console.log("data", data);
        handleGoogleLogin(provider, data);
      }}
      onReject={(err) => {
        console.log(err);
      }}
    >
      {btn()}
      {/* <GoogleLoginButton /> */}
    </LoginSocialGoogle>
  );
}
export function DiscordAuth({ handleSocialConnect, btn }) {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const code = query.get("code");
  let url = process.env.REACT_APP_DISCORD_URL;
  useEffect(() => {
    if (code) {
      handleGetCredentials();
    }
  }, [code]);
  const handleGetCredentials = async () => {
    const params = new URLSearchParams();
    params.append("client_id", process.env.REACT_APP_DISCORD_ID);
    params.append("client_secret", process.env.REACT_APP_DISCORD_SECRET);
    params.append("grant_type", "authorization_code");
    params.append("code", code);
    params.append("redirect_uri", process.env.REACT_APP_REDIRECT_URL + "profile");
    params.append("scope", "identify");
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://discord.com/api/v10/oauth2/token",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      data: params,
    };

    axios.request(config).then((response) => {
      console.log(JSON.stringify(response.data));
      axios
        .get(`https://discordapp.com/api/users/@me`, {
          headers: { Authorization: `Bearer ${response.data.access_token}` },
        })
        .then((resp) => {
          let access_token = response.data.access_token;
          handleSocialConnect("discord", { ...resp.data, access_token });
          console.log(resp.data);
        });
    });
  };
  return (
    <a
      className="nav-link profileSidebarBtn"
      href={url}
      target="_blank"
      // onClick={handleGetCredentials}
    >
      Connect
    </a>
  );
}
export function EthereumWallet({ handleSocialConnect }) {
  const getEthereumDetails = () => {
    if (window.ethereum) {
      window.ethereum.request({ method: "eth_requestAccounts" }).then((result) => {
        handleSocialConnect("ethereum", result[0]);
      });
    } else {
      alert("Please install MetaMask to proceed...");
    }
  };
  return (
    <a className="nav-link profileSidebarBtn" href="javascript:void(0);" onClick={getEthereumDetails}>
      Connect
    </a>
  );
}
export function SolanaWallet({ handleSocialConnect }) {
  const getSolanaDetails = async () => {
    if (window.solana) {
      let solana = window.solana;
      if (solana.isPhantom) {
        let res = await solana.connect();
        // console.log(res.publicKey.toString());
        handleSocialConnect("solana", res.publicKey.toString());
      }
    } else {
      alert("Please install phantom to proceed...");
    }
  };
  return (
    <a className="nav-link profileSidebarBtn" href="javascript:void(0);" onClick={getSolanaDetails}>
      Connect
    </a>
  );
}
export function StackWallet({ handleSocialConnect }) {
  const getStackDetails = async () => {
    if (window.HiroWalletProvider) {
      let hiro = await window.btc?.request("getAddresses");
      let address = "";
      hiro.result.addresses.forEach((item) => {
        if (item.symbol == "STX") {
          address = item.address;
        }
      });
      handleSocialConnect("stack", address);
    } else {
      alert("Please install Hiro wallet to proceed...");
    }
  };
  return (
    <a className="nav-link profileSidebarBtn" href="javascript:void(0);" onClick={getStackDetails}>
      Connect
    </a>
  );
}

// export function googleAuth() {
//   const rootUrl = "https://accounts.google.com/o/oauth2/v2/auth";
//   const options = {
//     redirect_uri: "http://localhost:3000/",
//     client_id: "582499309647-7ifoqga678ioalfs6b0kfj3n04p7q1tg.apps.googleusercontent.com",
//     access_type: "offline",
//     response_type: "code",
//     prompt: "consent",
//     scope: ["https://www.googleapis.com/auth/userinfo.profile", "https://www.googleapis.com/auth/userinfo.email"].join(
//       " "
//     ),
//   };

//   const qs = new URLSearchParams(options);
//   return `${rootUrl}?${qs.toString()}`;
// }
