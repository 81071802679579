import { useState, useEffect, useContext } from "react";
import AccountService from "../../services/AccountService";
import RequestKycService from "../../services/RequestKycService";
import { Helmet } from "react-helmet";
import { GlobalContext } from "../../context/GlobalContext";
import moment from "moment";
import SignupUserName from "../../popups/SignupUserName/SignupUserName";
import CropImage from "../../popups/cropImage/CropImage";
import EditProfile from "../../popups/EditProfile/EditProfile";
import WorkExperience from "../../popups/WorkExperience/WorkExperience";
import Project from "../../popups/Project/Project";
import Education from "../../popups/Education/Education";
import RequestKYC from "../../popups/RequestKYC/RequestKYC";
import { useParams } from "react-router-dom";
import SocialShare from "../../shared/SocialShare";
import Skill from "../../popups/Skill/Skill";
import {
  FacebookAuth,
  GitHubAuth,
  InstagramAuth,
  TwitterAuth,
  DiscordAuth,
  EthereumWallet,
  SolanaWallet,
  StackWallet,
} from "../../shared/TwoWayAuth";
const serv = new AccountService();
const kycServ = new RequestKycService();
function Account() {
  const globalCtx = useContext(GlobalContext);
  const [user, setUser] = globalCtx.user;
  const params = useParams();
  const [setting, setSetting] = globalCtx.setting;
  const [showSignupUserNamePopup, setShowSignupUserNamePopup] = useState(false);
  const [allowEdit, setAllowEdit] = useState(false);
  const [showCoverImageCropPopup, setShowCoverImageCropPopup] = useState(false);
  const [showProfileEditPopup, setShowProfileEditPopup] = useState(false);
  const [showWorkExperiencePopup, setShowWorkExperiencePopup] = useState(false);
  const [workExperienceId, setWorkExperienceId] = useState("");
  const [showProjectPopup, setShowProjectPopup] = useState(false);
  const [showSkillPopup, setShowSkillPopup] = useState(false);
  const [showEducationPopup, setShowEducationPopup] = useState(false);
  const [showKYCPopup, setShowKYCPopup] = useState(false);
  const [ProjectId, setProjectId] = useState("");
  const [skillId, setSkillId] = useState("");
  const [educationId, setEducationId] = useState("");
  const [imageToCrop, setImageToCrop] = useState(undefined);
  const [croppedImage, setCroppedImage] = useState(undefined);
  const [accountObj, setAccountObj] = new useState();
  const [isKycVerified, setIsKycVerified] = useState(false);

  useEffect(() => {
    if (user?._id && !params.uname) {
      setAllowEdit(true);
    }
    getAccountDetails();
    getKYCDetails();
  }, [params.uname, user?._id]);

  const getAccountDetails = async () => {
    let id = params.uname || user?._id;

    try {
      let resp = await serv.getUser(id);
      if (resp.data) {
        let new_obj = {
          full_name: resp.data?.full_name,
          user_name: resp.data?.user_name,
          mobile_no: resp.data?.mobile_no,
          email: resp.data?.email,
          gender: resp.data?.gender,
          reward: resp.data?.reward,
          date_of_birth: resp.data?.date_of_birth
            ? moment(resp.data?.date_of_birth).format("MMMM DD, YYYY")
            : undefined,
          createdAt: moment(resp.data?.createdAt).format("MMMM YYYY"),
          location: resp.data?.location,
          ethereum_wallet: resp.data?.ethereum_wallet,
          solana_wallet: resp.data?.solana_wallet,
          stack_wallet: resp.data?.stack_wallet,
          twitter_username: resp.data.twitter_username ? resp.data.twitter_username : "",
          instagram_username: resp.data.instagram_username ? resp.data.instagram_username : "",
          facebook_username: resp.data.facebook_username ? resp.data.facebook_username : "",
          github_username: resp.data.github_username ? resp.data.github_username : "",
          discord_username: resp.data.discord_username ? resp.data.discord_username : "",
          website: resp.data.website ? resp.data.website : "",
          bio: resp.data?.bio,
          file: resp.data?.file,
          cover_image: resp.data?.cover_image,
          work_experience: resp.data?.work_experience,
          project: resp.data?.project,
          education: resp.data?.education,
          skill: resp.data?.skill,
          kyc_verified: resp.data?.kyc_verified,
          show_location: resp.data?.show_location,
          show_date_of_birth: resp.data?.show_date_of_birth,
        };
        setAccountObj(new_obj);
        setUser({ ...user, ...new_obj });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getKYCDetails = async () => {
    let id = user._id;
    try {
      let resp = await kycServ.getDetails(id);
      if (resp.data && resp.data.kycVerified) {
        setIsKycVerified(true);
      }
    } catch (err) {
      console.log(err);
    }
  };
  function showSignupUserName() {
    setShowSignupUserNamePopup(!showSignupUserNamePopup);
  }
  // const onUploadCoverFile = (event) => {
  //   if (event.target.files && event.target.files.length > 0) {
  //     const reader = new FileReader();

  //     reader.addEventListener("load", () => {
  //       const image = reader.result;

  //       setImageToCrop(image);
  //     });

  //     reader.readAsDataURL(event.target.files[0]);
  //   }
  // };
  function showCoverImageCrop() {
    setShowCoverImageCropPopup(!showCoverImageCropPopup);
  }
  function showProfileEdit() {
    setShowProfileEditPopup(!showProfileEditPopup);
  }
  function showWorkExperience() {
    setShowWorkExperiencePopup(!showWorkExperiencePopup);
  }
  function showProject() {
    setShowProjectPopup(!showProjectPopup);
  }
  function showKYC() {
    setShowKYCPopup(!showKYCPopup);
  }
  // const onSubmit = async (values, { resetForm }) => {
  //   let id = user._id;
  //   const formData = new FormData();
  //   formData.append("full_name", values.full_name);
  //   formData.append("mobile_no", values.mobile_no);
  //   formData.append("email", values.email);
  //   formData.append("gender", values.gender);
  //   formData.append("date_of_birth", values.date_of_birth);
  //   formData.append("location", values.location);
  //   formData.append("ethereum_wallet", values.ethereum_wallet);
  //   formData.append("solana_wallet", values.solana_wallet);
  //   values.twitter_username &&
  //     formData.append("twitter_username", values.twitter_username);
  //   values.instagram_username &&
  //     formData.append("instagram_username", values.instagram_username);
  //   values.discord_username &&
  //     formData.append("discord_username", values.discord_username);
  //   formData.append("bio", values.bio);
  //   formData.append("_id", id);
  //   values.website && formData.append("website", values.website);
  //   formData.append("file", values.file);
  //   formData.append("cover_image", croppedImage || values.cover_image);
  //   try {
  //     const resp = await serv.editUser(formData);
  //     resetForm();
  //     getAccountDetails();
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // const formik = useFormik({
  //   initialValues: accountObj,
  //   validateOnBlur: true,
  //   onSubmit,
  //   validationSchema: ValidateSchema,
  //   enableReinitialize: true,
  // });
  const handleSocialConnect = async (provider, data) => {
    try {
      const obj = {};
      if (provider == "twitter") {
        obj.twitter_username = {
          access_token: data.access_token,
          user_name: data.username,
        };
      } else if (provider == "facebook") {
        obj.facebook_username = {
          access_token: data.accessToken,
          user_name: data.name,
        };
      } else if (provider == "instagram") {
        obj.instagram_username = {
          access_token: data.access_token,
          user_name: data.username,
        };
      } else if (provider == "github") {
        obj.github_username = {
          access_token: data.access_token,
          user_name: data.login,
        };
      } else if (provider == "discord") {
        obj.discord_username = {
          access_token: data.access_token,
          user_name: data.username,
        };
      } else if (provider == "ethereum") {
        obj.ethereum_wallet = data;
      } else if (provider == "solana") {
        obj.solana_wallet = data;
      } else if (provider == "stack") {
        obj.stack_wallet = data;
      }
      obj._id = user?._id;
      await serv.editUser(obj).then((resp) => {
        if (resp) {
          getAccountDetails();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const connectBtn = () => {
    return (
      <a className="nav-link profileSidebarBtn" href="javascript:void(0);">
        Connect
      </a>
    );
  };
  return (
    <>
      <Helmet>
        “Username” - Profiles | MaveFi
        <title>{accountObj?.user_name ? accountObj?.user_name + "-" + "Profiles | Mavefi" : "Profiles | Mavefi"}</title>
        <meta name="description" content={accountObj?.bio || ""} />
        <meta name="keywords" content={accountObj?.user_name} />
        <meta
          property="og:title"
          content={accountObj?.user_name ? accountObj?.user_name + "-" + "Profiles | Mavefi" : "Profiles | Mavefi"}
        />
        <meta property="og:description" content={accountObj?.bio || ""} />
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta name="twitter:site" content={"@" + accountObj?.user_name}></meta>
        <meta name="og:image" content={accountObj?.file}></meta>
      </Helmet>
      <div className="ljSectionData w-100 clearfix" id="ljSectionData">
        <div className="support_second_sec mavefiProfileSection mavefiProfileSectionCustom">
          <div className="row">
            <div className="col-lg-12 col-xl-8 glossaryTitleCol glossaryTitleColCustom">
              <div className="profileLeftContant">
                <div className="profilePicSection">
                  <div className="profileBannerPic">
                    <a href="javascript:void(0);" id="uploadFileSec">
                      {accountObj?.cover_image ? (
                        <img
                          src={accountObj.cover_image}
                          alt="upload-icon"
                          width="100%"
                          className="img-fluid profileBannerPic "
                        />
                      ) : (
                        <div className="uploadCoverPicture text-center">
                          {/* <img src="/images/svg-icons/upload-profile-pic-icon.svg" alt="upload-icon" className="img-fluid uploadIcon" /> */}
                          {/* <h4 className="mb-0">Upload Cover Picture</h4>
                          <span>880 x 200 px</span> */}
                        </div>
                      )}
                      <input type="file" name id="uploadImgFile" className="d-none" />
                    </a>
                  </div>
                  <div className="profileImageSec">
                    <a href="javascript:void(0);" id="uploadProfileImgSec">
                      {accountObj?.file ? (
                        <div className="uploadCoverPicture text-center">
                          <img
                            src={accountObj.file}
                            alt="upload-icon"
                            className="img-fluid"
                            style={{ height: "auto" }}
                          />
                        </div>
                      ) : (
                        <div className="uploadCoverPicture text-center">
                          {/* <img src="/images/svg-icons/upload-profile-pic-icon.svg" alt="upload-icon" className="img-fluid uploadIcon" /> */}
                          {/* <h4 className="mb-0">Upload Profile Picture</h4> */}
                        </div>
                      )}
                    </a>
                    <input type="file" name id="uploadPicFile" className="d-none" />
                  </div>
                </div>
                <div className="profileBtnNav profileBtnNavCustom profilePgPadding">
                  {!allowEdit && (
                    <ul className="nav">
                      <li className="nav-item">
                        <SocialShare
                          twitter={{
                            text: accountObj?.user_name,
                            url: window.location.origin + "/profile/" + accountObj?.user_name,
                          }}
                          facebook={{
                            text: accountObj?.user_name,
                            url: window.location.origin + "/profile/" + accountObj?.user_name,
                          }}
                          website={{
                            title: accountObj?.user_name,
                            url: window.location.origin + "/profile/" + accountObj?.user_name,
                          }}
                          userProfile={true}
                          ImageURL={accountObj?.file}
                        />
                        {/* <a className="nav-link shareUserProfileBtn" href="#">
                          {" "}
                          <img
                            src="/images/svg-icons/shareIcon.svg"
                            alt="lightning-bolt"
                            className="img-fluid"
                          />{" "}
                        </a> */}
                      </li>
                    </ul>
                  )}
                  {allowEdit && (
                    <ul className="nav">
                      {/* <li className="nav-item">
                        <a className="nav-link shareUserProfileBtn" href="#">
                          {" "}
                          <img
                            src="/images/svg-icons/shareIcon.svg"
                            alt="lightning-bolt"
                            className="img-fluid"
                          />{" "}
                        </a>
                      </li> */}
                      <li className="nav-item">
                        <div className="shareIcon">
                          <div className="cardBtnIcon infoIconBtn">
                            <div className="shareUserProfileBtn headerBtnSec  d-flex">
                              <SocialShare
                                topGap={true}
                                twitter={{
                                  text: accountObj?.user_name,
                                  url: window.location.origin + "/profile/" + accountObj?.user_name,
                                }}
                                facebook={{
                                  text: accountObj?.user_name,
                                  url: window.location.origin + "/profile/" + accountObj?.user_name,
                                }}
                                website={{
                                  title: accountObj?.user_name,
                                  url: window.location.origin + "/profile/" + accountObj?.user_name,
                                }}
                                ImageClass="img-fluid"
                                ImageURL="/images/svg-icons/shareIcon.svg"
                              />
                            </div>
                          </div>
                        </div>
                      </li>

                      <li className="nav-item">
                        <a className="nav-link goPro" href="#">
                          {" "}
                          <img
                            src="/images/svg-icons/lightning-bolt.svg"
                            alt="lightning-bolt"
                            className="img-fluid"
                          />{" "}
                          Go Pro
                        </a>
                      </li>

                      {!isKycVerified && (
                        <li className="nav-item">
                          <a className="nav-link requestKyc" href="#" onClick={showKYC}>
                            Request KYC
                          </a>
                        </li>
                      )}
                      <li className="nav-item">
                        <a className="nav-link editProfile" href="#" onClick={showProfileEdit}>
                          Edit profile
                        </a>
                      </li>
                    </ul>
                  )}
                </div>
                <div className="profileName profilePgPadding">
                  <div className="profNam profNamCustom d-flex align-items-center">
                    <h4 className="mb-0 letterSpacing0">{accountObj?.full_name}</h4>{" "}
                    {accountObj?.kyc_verified && (
                      <img src="/images/svg-icons/tick-icon.svg" alt="tick-icon" className="img-fluid tickIcon" />
                    )}
                  </div>
                  <div className="profUserName margintop2">
                    <p>@{accountObj?.user_name}</p>
                  </div>
                  <div className="aboutUser aboutUserCustom">
                    <p className="mb-0">
                      {accountObj?.bio
                        ? accountObj.bio.slice(0, 250)
                        : "Tell the world your story using this bio space."}
                    </p>
                  </div>
                  {accountObj?.website && (
                    <div className="linkIcon">
                      <p>
                        <a
                          href={
                            accountObj.website.indexOf("http") == 0
                              ? accountObj.website
                              : "http://" + accountObj.website
                          }
                          target="_blank"
                        >
                          <img src="/images/svg-icons/link.svg" alt="link" className="img-fluid linkIcon" />{" "}
                          {accountObj?.website}{" "}
                        </a>
                      </p>
                    </div>
                  )}
                </div>
                <div className="aboutList aboutListCustom profilePgPadding">
                  <ul className="nav">
                    {!accountObj?.show_location && accountObj?.location && (
                      <li className="nav-item">
                        <a className="nav-link">
                          <img src="/images/svg-icons/location.svg" alt="location" className="img-fulid img-unselect" />{" "}
                          <span>{accountObj?.location}</span>
                        </a>
                      </li>
                    )}
                    {!accountObj?.show_date_of_birth && accountObj?.date_of_birth && (
                      <li className="nav-item">
                        <a className="nav-link">
                          <img
                            src="/images/svg-icons/birthday-cake.svg"
                            alt="birthday-cake"
                            className="img-fulid img-unselect"
                          />{" "}
                          <span>Born {accountObj?.date_of_birth}</span>
                        </a>
                      </li>
                    )}
                    <li className="nav-item">
                      <a className="nav-link">
                        <img src="/images/svg-icons/calendar.svg" alt="calendar" className="img-fulid img-unselect" />{" "}
                        <span>Joined {accountObj?.createdAt}</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link">
                        <img
                          src="/images/svg-icons/mavifi-icon.svg"
                          alt="mavifi-icon"
                          className="img-fulid img-unselect"
                        />{" "}
                        <span>Coin Balance: {accountObj?.reward}</span>
                      </a>
                    </li>
                  </ul>
                </div>
                {(allowEdit || accountObj?.work_experience?.length > 0) && (
                  <>
                    <hr className="horizProfLine" />
                    <div className="workExp workExpCustom profilePgPadding">
                      <img src="/images/icons/work_experience.svg" />
                      <h4>Work Experience</h4>
                    </div>
                    <div className="profileBox profilePgPadding profileBoxCustom">
                      {accountObj?.work_experience?.map((item) => {
                        return (
                          <div className="profileBoxInner profileBoxInnerCustom">
                            <div className="profileCards">
                              {allowEdit && (
                                <a
                                  href="javascript:void(0);"
                                  onClick={() => {
                                    setWorkExperienceId(item._id);
                                    showWorkExperience();
                                  }}
                                  className="img-fulid editProfCard editProfCardCustom"
                                >
                                  <img src="/images/svg-icons/edit-black.svg" alt className="img-fulid" />
                                </a>
                              )}
                              <div className="profileCardHead d-flex align-items-center profileCardHeadCustom">
                                <div className="profileImgCard">
                                  <img
                                    src={item.logo ? item.logo : "/images/icons/blank-small-profile.svg"}
                                    alt="profile"
                                    className="img-fluid"
                                    style={{ borderRadius: "60px" }}
                                  />
                                </div>
                                <div className="profileContantCard profileContantCardCustom">
                                  <h4 className="projectLineBreak">{item?.company_name}</h4>
                                  <p className="mb-0">{item?.description}</p>
                                </div>
                              </div>
                              <div className="profileCardFooter d-flex align-items-center justify-content-between profileCardFooterCustom">
                                <div className="profileCardLeft">
                                  <h6 className="mb-0">{item?.position}</h6>
                                </div>
                                <div className="profileCardRight">
                                  <a
                                    href="javascript:void(0);"
                                    className={
                                      item?.present
                                        ? "btn btnLightGreen btnLightCustom"
                                        : "btn btnLightOrange btnLightCustom"
                                    }
                                  >
                                    {item?.present ? "Present" : `${item.duration} months`}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {allowEdit && (
                        <div className="profAddCards">
                          <a href="javascript:void:(0);" onClick={showWorkExperience}>
                            <div className="profileCards text-center">
                              <img src="/images/svg-icons/plus-math.svg" alt className="img-fluid" />
                              <div className="workExperience">
                                <p className="mb-0">Add Work Experience</p>
                              </div>
                            </div>
                          </a>
                        </div>
                      )}
                    </div>
                  </>
                )}
                {(allowEdit || accountObj?.project?.length > 0) && (
                  <>
                    <hr className="horizProfLine" />
                    <div className="workExp workExpCustom profilePgPadding">
                      <img src="/images/icons/project.svg" />
                      <h4>Projects</h4>
                    </div>

                    <div className="profileBox profilePgPadding profileBoxCustom">
                      {accountObj?.project?.map((item) => {
                        return (
                          <div className="profileBoxInner profileBoxInnerCustom">
                            <div className="profileCards">
                              {allowEdit && (
                                <a
                                  href="javascript:void(0);"
                                  onClick={() => {
                                    setProjectId(item._id);
                                    setShowProjectPopup("Projects");
                                  }}
                                  className="img-fulid editProfCard editProfCardCustom"
                                >
                                  <img src="/images/svg-icons/edit-black.svg" alt className="img-fulid" />
                                </a>
                              )}
                              <div className="profileCardHead d-flex align-items-center profileCardHeadCustom">
                                <div className="profileImgCard">
                                  <img
                                    src={item.logo ? item.logo : "/images/icons/blank-small-profile.svg"}
                                    alt="profile"
                                    className="img-fluid"
                                    style={{
                                      borderRadius: "60px",
                                      marginBottom: "12px",
                                    }}
                                  />
                                </div>
                                <div className="profileContantCard profileContantCardCustom">
                                  <h4 className="projectLineBreak">{item?.project_name}</h4>
                                  <p className="mb-0">{item?.description}</p>
                                  <a
                                    href={"//" + item?.web_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="webLinkProf projectLineBreak"
                                  >
                                    {item?.web_link}
                                  </a>
                                </div>
                              </div>
                              <div className="profileCardFooter d-flex align-items-center justify-content-between profileCardFooterCustom">
                                <div className="profileCardLeft">
                                  <h6 className="mb-0">{item?.type}</h6>
                                </div>
                                <div className="profileCardRight">
                                  <a
                                    href="javascript:void(0);"
                                    className={
                                      item?.status == "Beta"
                                        ? "btn btnLightOrange btnLightCustom"
                                        : item.status == "Under Dev"
                                        ? "btn btnLightGrey btnLightCustom"
                                        : "btn btnLightGreen btnLightCustom"
                                    }
                                  >
                                    {item?.status}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {allowEdit && (
                        <div className="profAddCards">
                          <a href="javascript:void:(0);" onClick={() => setShowProjectPopup("Projects")}>
                            <div className="profileCards text-center">
                              <img src="/images/svg-icons/plus-math.svg" alt className="img-fluid" />
                              <div className="workExperience">
                                <p className="mb-0">Add Project</p>
                              </div>
                            </div>
                          </a>
                        </div>
                      )}
                    </div>
                  </>
                )}
                {(allowEdit || accountObj?.skill?.length > 0) && (
                  <>
                    <hr className="horizProfLine" />
                    <div className="workExp workExpCustom profilePgPadding">
                      <img src="/images/icons/skill.svg" />
                      <h4>
                        Skills <span className="skillCounter">({accountObj?.skill?.length}/5)</span>
                      </h4>
                    </div>

                    <div className="profileBox profilePgPadding skillBoxCustom">
                      {accountObj?.skill?.map((item) => {
                        return (
                          <div className="profileBoxInner profileBoxInnerCustom">
                            <div className="profileCards">
                              {allowEdit && (
                                <a
                                  href="javascript:void(0);"
                                  onClick={() => {
                                    setSkillId(item._id);
                                    setShowSkillPopup("Skills");
                                  }}
                                  className="img-fulid editProfCard editProfCardCustom"
                                >
                                  <img src="/images/svg-icons/edit-black.svg" alt className="img-fulid" />
                                </a>
                              )}
                              <div className="profileCardHead profileCardHeadCustom profileSkillHead">
                                <div className="profileImgCard skillImgCard">
                                  <img
                                    src={item.logo ? item.logo : "/images/icons/blank-small-profile.svg"}
                                    alt="profile"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="profileContantCard profileContantCardCustom">
                                  <h4 className="text-center">{item?.skill_name}</h4>
                                  <p className="mb-0">{item?.level}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {allowEdit && accountObj?.skill?.length < 5 && (
                        <div className="profAddCards">
                          <a href="javascript:void:(0);" onClick={() => setShowSkillPopup("Skills")}>
                            <div className="profileCards text-center">
                              <img src="/images/svg-icons/plus-math.svg" alt className="img-fluid" />
                              <div className="workExperience">
                                <p className="mb-0">Add Skill</p>
                              </div>
                            </div>
                          </a>
                        </div>
                      )}
                    </div>
                  </>
                )}
                {(allowEdit || accountObj?.education?.length > 0) && (
                  <>
                    <hr className="horizProfLine" />
                    <div className="workExp workExpCustom profilePgPadding">
                      <img src="/images/icons/education.svg" />
                      <h4>Education</h4>
                    </div>
                    <div className="profileBox profilePgPadding profileBoxCustom">
                      {accountObj?.education?.map((item) => {
                        return (
                          <div className="profileBoxInner profileBoxInnerCustom">
                            <div className="profileCards">
                              {/* {allowEdit && (
                                <a
                                  href="javascript:void(0);"
                                  onClick={() => {
                                    setEducationId(item._id);
                                    setShowEducationPopup("Education");
                                  }}
                                  className="img-fulid editProfCard editProfCardCustom editEducationCardCustom"
                                >
                                  <img
                                    src="/images/svg-icons/edit-black.svg"
                                    alt
                                    className="img-fulid"
                                  />
                                </a>
                              )} */}
                              <div className="profileCardHead d-flex align-items-center justify-content-between profileCardHeadCustom">
                                <div className="profileContantCard profileContantCardCustom">
                                  <h4 className="projectLineBreak">{item?.school_name}</h4>
                                  <p className="mb-0">{item?.degree}</p>
                                  {/* <a
                                    href="javascript:void(0);"
                                    className="webLinkProf"
                                    >
                                    {item?.web_link}
                                  </a> */}
                                </div>
                                <div className="profileImgCard">
                                  <img
                                    src={item.logo ? item.logo : "/images/icons/blank-small-profile.svg"}
                                    alt="profile"
                                    className="img-fluid"
                                    style={{
                                      borderRadius: "60px",
                                      marginBottom: "12px",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="profileCardFooter d-flex align-items-center justify-content-between profileCardFooterCustom">
                                <div className="profileCardLeft">
                                  <h6 className="mb-0">{`${item?.start_month} ${item?.start_year} - ${item?.end_month} ${item?.end_year}`}</h6>
                                </div>
                                <div className="profileCardRight">
                                  {allowEdit && (
                                    <a
                                      href="javascript:void(0);"
                                      onClick={() => {
                                        setEducationId(item._id);
                                        setShowEducationPopup("Education");
                                      }}
                                      className="img-fulid "
                                    >
                                      <img src="/images/svg-icons/edit-black.svg" alt className="img-fulid" />
                                    </a>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {allowEdit && (
                        <div className="profAddCards">
                          <a href="javascript:void:(0);" onClick={() => setShowEducationPopup("Education")}>
                            <div className="profileCards text-center">
                              <img src="/images/svg-icons/plus-math.svg" alt className="img-fluid" />
                              <div className="workExperience">
                                <p className="mb-0">Add Education</p>
                              </div>
                            </div>
                          </a>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-12 col-xl-4 ps-xl-0 pe-xl-0 glossaryTitleCol">
              <div className="publicSlideAccount publicSliderAccountCustom profileSidebar">
                {/*Socials*/}
                <div className="profileSidebarCard">
                  <div className="profileSidebarHead d-flex align-items-center justify-content-between">
                    <h5 className="mb-0">Socials</h5>
                    {/* <div className="comingBtn">
                      <span className="btn">COMING SOON</span>
                    </div> */}
                  </div>
                  <div className="profileSidebarContant">
                    <ul className="nav flex-column">
                      {/* <li className="nav-item">
                        <div className="profileSocialIcons d-flex align-items-center justify-content-between">
                          <div className="profSocialIcons profSocialIconsCustom">
                            <p className="mb-0">
                              <img src="/images/social-media-icon/twitter.svg" alt="Twitter" className="img-fluid" />{" "}
                              <span className="profSocialName">Twitter</span>
                            </p>
                          </div>
                          <div className="rightContant">
                            {accountObj &&
                            accountObj.twitter_username.user_name &&
                            accountObj.twitter_username.user_name !== "" ? (
                              <p className="mb-0">
                                <span>Connected: {accountObj.twitter_username?.user_name}</span>{" "}
                                <a
                                  href="javascript:void(0);"
                                  onClick={() => handleSocialConnect("twitter", { access_token: "", username: "" })}
                                >
                                  <img src="/images/social-media-icon/close.svg" alt="close" className="img-fulid" />
                                </a>
                              </p>
                            ) : (
                              <TwitterAuth handleSocialConnect={handleSocialConnect} btn={connectBtn} />
                            )}
                          </div>
                        </div>
                      </li> */}
                      <li className="nav-item">
                        <div className="profileSocialIcons d-flex align-items-center justify-content-between">
                          <div className="profSocialIcons profSocialIconsCustom">
                            <p className="mb-0">
                              <img src="/images/social-media-icon/facebook.svg" alt="Twitter" className="img-fluid" />{" "}
                              <span className="profSocialName">Facebook</span>
                            </p>
                          </div>
                          <div className="rightContant">
                            {accountObj &&
                            accountObj.facebook_username?.user_name &&
                            accountObj.facebook_username?.user_name !== "" ? (
                              <p className="mb-0">
                                <span>Connected: {accountObj.facebook_username?.user_name}</span>{" "}
                                <a
                                  href="javascript:void(0);"
                                  onClick={() => handleSocialConnect("facebook", { accessToken: "", name: "" })}
                                >
                                  <img src="/images/social-media-icon/close.svg" alt="close" className="img-fulid" />
                                </a>
                              </p>
                            ) : (
                              <FacebookAuth handleSocialConnect={handleSocialConnect} btn={connectBtn} />
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="nav-item">
                        <div className="profileSocialIcons d-flex align-items-center justify-content-between">
                          <div className="profSocialIcons profSocialIconsCustom">
                            <p className="mb-0">
                              <img src="/images/social-media-icon/instagram.svg" alt="Twitter" className="img-fluid" />{" "}
                              <span className="profSocialName">Instagram</span>
                            </p>
                          </div>
                          <div className="rightContant">
                            {accountObj &&
                            accountObj.instagram_username?.user_name &&
                            accountObj.instagram_username?.user_name !== "" ? (
                              <p className="mb-0">
                                <span>Connected: {accountObj.instagram_username?.user_name}</span>{" "}
                                <a
                                  href="javascript:void(0);"
                                  onClick={() => handleSocialConnect("instagram", { access_token: "", username: "" })}
                                >
                                  <img src="/images/social-media-icon/close.svg" alt="close" className="img-fulid" />
                                </a>
                              </p>
                            ) : (
                              <InstagramAuth handleSocialConnect={handleSocialConnect} />
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="nav-item">
                        <div className="profileSocialIcons d-flex align-items-center justify-content-between">
                          <div className="profSocialIcons profSocialIconsCustom">
                            <p className="mb-0">
                              <img src="/images/social-media-icon/discord.svg" alt="Twitter" className="img-fluid" />{" "}
                              <span className="profSocialName">Discord</span>
                            </p>
                          </div>
                          <div className="rightContant">
                            {accountObj &&
                            accountObj.discord_username?.user_name &&
                            accountObj.discord_username?.user_name !== "" ? (
                              <p className="mb-0">
                                <span>Connected: {accountObj.discord_username?.user_name}</span>{" "}
                                <a
                                  href="javascript:void(0);"
                                  onClick={() => handleSocialConnect("discord", { access_token: "", username: "" })}
                                >
                                  <img src="/images/social-media-icon/close.svg" alt="close" className="img-fulid" />
                                </a>
                              </p>
                            ) : (
                              <DiscordAuth handleSocialConnect={handleSocialConnect} btn={connectBtn} />
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="nav-item">
                        <div className="profileSocialIcons d-flex align-items-center justify-content-between">
                          <div className="profSocialIcons profSocialIconsCustom">
                            <p className="mb-0">
                              <img src="/images/svg-icons/githubIcon.svg" alt="Twitter" className="img-fluid" />{" "}
                              <span className="profSocialName">Github</span>
                            </p>
                          </div>
                          <div className="rightContant">
                            {accountObj &&
                            accountObj.github_username?.user_name &&
                            accountObj.github_username?.user_name !== "" ? (
                              <p className="mb-0">
                                <span>Connected: {accountObj.github_username?.user_name}</span>{" "}
                                <a
                                  href="javascript:void(0);"
                                  onClick={() => handleSocialConnect("github", { access_token: "", login: "" })}
                                >
                                  <img src="/images/social-media-icon/close.svg" alt="close" className="img-fulid" />
                                </a>
                              </p>
                            ) : (
                              <GitHubAuth handleSocialConnect={handleSocialConnect} />
                            )}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                {/*Socials*/}
                {/*Wallets*/}
                <div className="profileSidebarCard">
                  <div className="profileSidebarHead d-flex align-items-center justify-content-between">
                    <h5 className="mb-0">Wallets</h5>
                    {/* <div className="comingBtn">
                      <span className="btn">COMING SOON</span>
                    </div> */}
                  </div>
                  <div className="profileSidebarContant">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <div className="profileSocialIcons d-flex align-items-center justify-content-between">
                          <div className="profSocialIcons profSocialIconsCustom">
                            <p className="mb-0">
                              <img src="/images/svg-icons/ethereum.svg" alt="ethereum" className="img-fluid" />{" "}
                              <span className="profSocialName">Ethereum</span>
                            </p>
                          </div>
                          <div className="rightContant">
                            {accountObj && accountObj.ethereum_wallet && accountObj.ethereum_wallet !== "" ? (
                              <p className="mb-0">
                                <span>
                                  Connected:{" "}
                                  {accountObj.ethereum_wallet.slice(0, 4) +
                                    "..." +
                                    accountObj.ethereum_wallet.slice(-4)}
                                </span>{" "}
                                <a href="javascript:void(0);" onClick={() => handleSocialConnect("ethereum", "")}>
                                  <img src="/images/social-media-icon/close.svg" alt="close" className="img-fulid" />
                                </a>
                              </p>
                            ) : (
                              <EthereumWallet handleSocialConnect={handleSocialConnect} />
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="nav-item">
                        <div className="profileSocialIcons d-flex align-items-center justify-content-between">
                          <div className="profSocialIcons profSocialIconsCustom">
                            <p className="mb-0">
                              <img src="/images/svg-icons/solana_logo1.svg" alt="solana" className="img-fulid" />{" "}
                              <span className="profSocialName">Solana</span>
                            </p>
                          </div>
                          <div className="rightContant">
                            {accountObj && accountObj.solana_wallet && accountObj.solana_wallet !== "" ? (
                              <p className="mb-0">
                                <span>
                                  Connected:{" "}
                                  {accountObj.solana_wallet.slice(0, 4) + "..." + accountObj.solana_wallet.slice(-4)}
                                </span>{" "}
                                <a href="javascript:void(0);" onClick={() => handleSocialConnect("solana", "")}>
                                  <img src="/images/social-media-icon/close.svg" alt="close" className="img-fulid" />
                                </a>
                              </p>
                            ) : (
                              <SolanaWallet handleSocialConnect={handleSocialConnect} />
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="nav-item">
                        <div className="profileSocialIcons d-flex align-items-center justify-content-between">
                          <div className="profSocialIcons profSocialIconsCustom">
                            <p className="mb-0">
                              <img src="/images/svg-icons/stacks.svg" alt="solana" className="img-fulid" />{" "}
                              <span className="profSocialName">Stacks</span>
                            </p>
                          </div>
                          <div className="rightContant">
                            {accountObj && accountObj.stack_wallet && accountObj.stack_wallet !== "" ? (
                              <p className="mb-0">
                                <span>
                                  Connected:{" "}
                                  {accountObj.stack_wallet.slice(0, 4) + "..." + accountObj.stack_wallet.slice(-4)}
                                </span>{" "}
                                <a href="javascript:void(0);" onClick={() => handleSocialConnect("stack", "")}>
                                  <img src="/images/social-media-icon/close.svg" alt="close" className="img-fulid" />
                                </a>
                              </p>
                            ) : (
                              <StackWallet handleSocialConnect={handleSocialConnect} />
                            )}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                {/*Wallets*/}
                {/*Public Profile*/}
                {/* <div className="profileSidebarCard">
                  <div className="profileSidebarHead d-flex align-items-center justify-content-between">
                    <h5 className="mb-0">Public Profile</h5>
                    <div className="comingBtn">
                      <span className="btn">COMING SOON</span>
                    </div>
                  </div>
                  <div className="profileSidebarContant">
                    <div className="publicBanner">
                      <img
                        src="/images/svg-icons/publicBanner.svg"
                        alt="banner"
                        className="w-100 img-fluid"
                      />
                    </div>
                  </div>
                </div> */}
                {/*Public Profile*/}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showSignupUserNamePopup && (
        <SignupUserName
          onClose={() => {
            showSignupUserName();
          }}
          isUpdate={true}
        />
      )}
      {showCoverImageCropPopup && (
        <CropImage
          imageToCrop={imageToCrop}
          onImageCropped={(croppedImage) => {
            setCroppedImage(croppedImage);
            showCoverImageCrop();
          }}
          onClose={() => {
            showCoverImageCrop();
          }}
          isUpdate={true}
        />
      )}
      {showProfileEditPopup && (
        <EditProfile
          onClose={() => {
            showProfileEdit();
            window.location.reload();
            // getAccountDetails();
          }}
        />
      )}
      {showWorkExperiencePopup && (
        <WorkExperience
          experienceId={workExperienceId}
          onClose={() => {
            showWorkExperience();
            setWorkExperienceId("");
            getAccountDetails();
          }}
        />
      )}
      {showProjectPopup && (
        <Project
          projectType={showProjectPopup}
          projectId={ProjectId}
          onClose={() => {
            setShowProjectPopup(false);
            setProjectId("");
            getAccountDetails();
          }}
        />
      )}
      {showEducationPopup && (
        <Education
          educationId={educationId}
          onClose={() => {
            setShowEducationPopup(false);
            setEducationId("");
            getAccountDetails();
          }}
        />
      )}
      {showSkillPopup && (
        <Skill
          skillId={skillId}
          onClose={() => {
            setShowSkillPopup(false);
            setSkillId("");
            getAccountDetails();
          }}
        />
      )}
      {showKYCPopup && (
        <RequestKYC
          onClose={() => {
            showKYC();
          }}
        />
      )}
    </>
  );
}

export default Account;
