import { Link } from "react-router-dom";

function RightActionCard({ heading, desc, buttonName, link, handlePopUp, richText }) {
  return (
    <div className="titleCard submitSlang card coursesCompletedCustom">
      {richText ? (
        richText
      ) : (
        <>
          <h4 className="letterSpacing0">{heading}</h4>
          <p className="mb-0 letterSpacing0">{desc}</p>
          {link ? (
            <a href={link} className="btn btnInfo">
              {buttonName}
            </a>
          ) : (
            <a href="javascript:void(0)" className="btn btnInfo letterSpacing0" onClick={handlePopUp}>
              {buttonName}
            </a>
          )}
        </>
      )}
    </div>
  );
}
export default RightActionCard;
