import moment from "moment";
import Countdown from "react-countdown";
import { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CourseService from "../../services/CourseService";
import UserCourseService from "../../services/UserCourseService";
import util from "../../util/util";
import Loading from "../../shared/Loading";
import GlobalContext from "../../context/GlobalContext";
import SocialShare from "../../shared/SocialShare";
import { Helmet } from "react-helmet";

const serv = new CourseService();
const userCourseServ = new UserCourseService();

function CourseDetail() {
  const globalCtx = useContext(GlobalContext);
  const { updateBookmark, checkBookmark } = globalCtx;
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const [user, setUser] = globalCtx.user;
  const { courseId } = useParams();
  const [course, setCourse] = useState(null);
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(true);
  const [userCourse, setUserCourse] = useState(null);
  const [userReward, setUserReward] = useState(null);
  const [difference, setDifference] = useState(null);
  const [author, setAuthor] = useState(null);
  const [viewVideo, setViewVideo] = useState(false);
  const [visible, setVisible] = useState(false);
  const [lsnIdx, setLsnIdx] = useState(0);
  const [isRewardClaimed, setIsRewardClaimed] = useState(false);
  useEffect(() => {
    if (courseId) {
      getDetails();
    }
  }, [courseId]);
  useEffect(() => {
    if (userReward && userReward.failed_time) {
      let date = new Date();
      setDifference(moment.duration(moment(userReward.failed_time).add(24, "hours").diff(moment(date))));
    }
  }, [userReward]);

  useEffect(() => {
    if (showLoading == false && window.location.href.includes("#")) {
      let href = window.location.href.split("#");
      setTimeout(() => {
        _scrollToLesson(href[1]);
      }, 500);
    }
  }, [showLoading]);

  async function handleComplete() {
    try {
      let obj = {
        user_id: user._id,
        course_id: courseId,
        status: 70,
      };
      let resp = await userCourseServ.editUserCourse(obj);
      if (resp.data) {
        setVisible(true);
        setUserCourse({ ...userCourse, status: 70 });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function redirectToQizz() {
    navigate("/courses/quiz/" + courseId);
  }

  function scrollToLesson(e) {
    e.preventDefault();
    let href = window.jQuery(e.target).attr("href").split("#");
    _scrollToLesson(href[1]);
  }

  function _scrollToLesson(target) {
    let $target = window.jQuery("#" + target);
    let $header = window.jQuery(".contantSection");
    // var $stickyheader = window.jQuery(".detailMenuOuterSec");
    // window.jQuery("html, body").animate(
    //   {
    //     scrollTop: $target.offset().top - $header.height() - 20 + "px",
    //   },
    //   "slow"
    // );
    window.scrollTo(0, $target.offset().top - $header.height() - 20);
    window.jQuery(".clickHere.active").removeClass("active");
    window.jQuery("click" + target).removeClass("active");
  }

  async function getDetails() {
    const respData = await serv.getCourse(courseId);
    if (respData && respData.data && respData.data.is_active) {
      setCourse(respData.data);
      if (respData.userCourse) {
        setUserCourse(respData.userCourse);
        setAuthor(respData.author);
        setIsRewardClaimed(respData.isRewardClaimed);
        setShowLoading(false);
        setUserReward(respData.courseReward);
      }
    } else {
      navigate("/notfound");
    }
  }

  if (!util.getUserLocal()) {
    navigate("/courses/info/" + courseId);
  }

  if (showLoading || !course) {
    return <Loading />;
  }

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      setDifference(moment.duration(0));
      return true;
    } else {
      return (
        <>
          Retry in {hours}:{minutes}:{seconds}
        </>
      );
    }
  };
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <Helmet>
        <title>Learn {course.metadata?.title ? course.metadata?.title : ""} - MaveFi</title>
        <meta name="description" content={course.metadata?.description ? course.metadata?.description : ""} />
        <meta name="keywords" content={course.metadata?.tags ? course.metadata?.tags : ""} />
      </Helmet>
      <div className="coursesDetailSec">
        <div className="coursesDetailInner d-flex">
          <div className="detailSec card">
            <div className="backArrow">
              <Link to={"/courses/info/" + courseId}>
                <img src="/images/svg-icons/backIcon.svg" alt="back-icon" className="img-fluid backIcon" />
                <img src="/images/svg-icons/backIcon-active.svg" alt="back-icon" className="img-fluid backIconActive" />
              </Link>
            </div>
            <div className="pageMainTopHead">
              <div className="pageTopHead">
                <div className="pageTopHeadInner">
                  <div className="pageTopHeading">
                    <h2>{course.course_name}</h2>
                  </div>
                  <div className="postBySec">
                    <div className="postBy">
                      <div className="mainIconSec">
                        <div className="iconSec">
                          <div className="iconOuterSec">
                            <div className="iconInnerSec">
                              <img
                                src="/images/svg-icons/courses-detail-author.svg"
                                alt="mave-logo"
                                className="img-fluid"
                              />
                            </div>
                            <div className="iconTxtSec">
                              <p className="mb-0">Author:</p>
                            </div>
                          </div>
                          <div className="iconRightTxt">
                            <p className="mb-0 authorHover">
                              <Link to={"/profile/" + author.user_name}>{author.full_name}</Link>
                            </p>
                          </div>
                        </div>
                        <div className="iconSec">
                          <div className="iconOuterSec">
                            <div className="iconInnerSec">
                              <img
                                src="/images/svg-icons/courses-detail-time.svg"
                                alt="mave-logo"
                                className="img-fluid"
                              />
                            </div>
                            <div className="iconTxtSec">
                              <p className="mb-0">Read Time:</p>
                            </div>
                          </div>
                          <div className="iconRightTxt">
                            <p className="mb-0">{course.read_time} mins</p>
                          </div>
                        </div>
                        <div className="iconSec">
                          <div className="iconOuterSec">
                            <div className="iconInnerSec">
                              <img
                                src="/images/svg-icons/courses-detail-tag.svg"
                                alt="mave-logo"
                                className="img-fluid"
                              />
                            </div>
                            <div className="iconTxtSec">
                              <p className="mb-0">Tags:</p>
                            </div>
                          </div>
                          <div className="iconRightTxt">
                            <p className="mb-0">{course.tags.join(", ")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="shareIcon">
                      <div className="cardBtnIcon infoIconBtn">
                        <div className="headerBtnSec d-flex">
                          {isAuthentiCated && (
                            <div className="modeBtn">
                              <a
                                href="javascript:void(0)"
                                className=""
                                onClick={() => {
                                  updateBookmark(course._id, "course");
                                }}
                              >
                                {checkBookmark(course._id) ? (
                                  <img
                                    src="/images/svg-icons/bookmark-active.svg"
                                    alt="bookmark"
                                    className="img-fluid bookmarkActive"
                                  />
                                ) : (
                                  <img
                                    src="/images/svg-icons/bookmark-normal.svg"
                                    alt="bookmark"
                                    className="img-fluid bookmarkNormal"
                                  />
                                )}
                                {/* <img
                                src="/images/svg-icons/bookmark-hover.svg"
                                alt="bookmark"
                                className="img-fluid bookmarkHover"
                              /> */}
                              </a>
                            </div>
                          )}
                          <SocialShare
                            twitter={{
                              text: course.course_name,
                              url: window.location.origin + "/courses/info/" + course._id,
                            }}
                            facebook={{
                              title: course.course_name,
                              url: window.location.origin + "/courses/info/" + course._id,
                            }}
                            website={{
                              title: course.course_name,
                              url: window.location.origin + "/courses/info/" + course._id,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="coursesDetailsContent" id="intro">
                <div className="detailsHeading">
                  <h5>INTRO</h5>
                </div>
                <div className="detailsPara">
                  <p>{course.course_desc}</p>
                </div>
                {course.show_course_video && course.course_video && course.course_video !== "" ? (
                  <div className="introVideoSec">
                    <div className="introVideoInnerSec d-flex">
                      <div className="introDetailVideo">
                        <div className="playVideoBtn">
                          <img
                            src={course.cover_image || "/images/svg-icons/videoBgPlay.svg"}
                            alt="videoBgPlay"
                            className="w-100 img-fluid"
                          />
                          <button
                            id="playBtn"
                            className="playBtn"
                            onClick={() => {
                              setViewVideo(true);
                            }}
                          >
                            {" "}
                            <img src="/images/svg-icons/play-button-circled.svg" alt="play-button" />
                          </button>
                        </div>
                        {viewVideo == true && (
                          <div id="lightbox" style={{ display: viewVideo ? " block" : "" }}>
                            <i
                              id="close-btn"
                              className="fa fa-times"
                              onClick={() => {
                                setViewVideo(false);
                              }}
                            ></i>
                            <div id="video-wrapper">
                              <video id="video" autoplay muted controls>
                                <source
                                  src={course.course_video || "https://www.w3schools.com/html/movie.mp4"}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                              {/* <iframe
                              id="video"
                              src="https://www.youtube.com/embed/9xwazD5SyVg"
                              frameborder="0"
                              allowfullscreen
                            ></iframe> */}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="introVideoContent">
                        <div className="videoInfoContent">
                          <div className="videoTopContent">
                            <img src="/images/svg-icons/video-call.svg" alt="play-button" />
                            <h4>Watch the video</h4>
                            <p>Prefer watching instead of reading? This video covers the whole course. Watch it now.</p>
                          </div>
                          <div className="videoBottomContent">
                            <h2>Video Credits:</h2>
                            <div className="videoContentEmail">
                              <h5 className="mb-0">Nathan Mooney</h5>
                              <p className="mb-0">@twitterhandle</p>
                            </div>
                            <div className="videoContentEmail">
                              <h5 className="mb-0">Shubham Kumar</h5>
                              <p className="mb-0">@twitterhandle</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {course.lessons.map((l, idx) => {
                return (
                  <div className="coursesDetailsContent" id={"lesson" + (idx + 1)}>
                    <div className="detailsHeading">
                      <h5>LESSON {idx + 1}</h5>
                    </div>
                    <div className="detailsQuest">
                      <h4>{l.lesson}</h4>
                    </div>
                    <div className="detailsPara" dangerouslySetInnerHTML={{ __html: l.desc }}></div>
                  </div>
                );
              })}

              <div className="markComplete">
                {userCourse.status < 70 && (
                  <a
                    href="javascript:void(0)"
                    type="button"
                    className="btn btn-outline-secondary d-flex align-items-center mb-2 lh-30"
                    onClick={() => {
                      handleComplete();
                    }}
                  >
                    <img src="/images/svg-icons/mark-light.svg" alt="rectangle" className="img-fluid markLightActive" />{" "}
                    <img src="/images/svg-icons/mark-dark.svg" alt="rectangle" className="img-fluid markDarkActive" />{" "}
                    MARK AS COMPLETED
                  </a>
                )}
                {userCourse.status == 70 && (
                  <a type="button" className="btn btnGreen d-flex align-items-center mb-2 lh-30">
                    COURSE COMPLETED
                  </a>
                )}
                {/* {userCourse.status > 70 && (
                  <a className="btn btnRed d-flex align-items-center mb-2 lh-30">
                    Retry Reward Test in 23:59:37
                  </a>
                )} */}
              </div>
              {userCourse.status >= 70 && (
                <div className="claimSec">
                  <div className="claimRewardSec startSec">
                    <div className="claimRewardinnerSec">
                      <h5>CLAIM YOUR REWARD</h5>
                      <h5 className="subhead">Earn {course.rewards} Coins</h5>
                      <p>
                        Looks like you have completed a course. You can now take a quick test to claim your course
                        rewards. This test provides multiple answer choices for each question, but only one of them is
                        correct. So choose wisely.
                      </p>
                    </div>
                    {isRewardClaimed && userCourse.status == 100 ? (
                      <div className="btnStartSec">
                        <a type="button" className="btn btnRewardsClaimed btnRewardsClaimedCustom">
                          Rewards Claimed
                        </a>
                      </div>
                    ) : (
                      <div className="btnStartSec">
                        <a
                          type="button"
                          className={
                            "btn btn-primary btnStart " +
                            (difference && difference._milliseconds > 0 ? "timeleft disabled " : "") +
                            (userCourse.status >= 70 || "disabled")
                          }
                          onClick={userCourse.status >= 70 && redirectToQizz}
                        >
                          {difference && difference._milliseconds > 0 ? (
                            <Countdown date={Date.now() + difference._milliseconds} renderer={renderer} />
                          ) : (
                            "Claim Rewards"
                          )}
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="claimRewardSec browseSec">
                    <div className="claimRewardinnerSec">
                      <h6>LEARN THE SLANG</h6>
                      <h5 className="subhead">Browse Glossary</h5>
                      <p>Browse our glossary to learn & discover the web3 slangs. So no more missing out.</p>
                    </div>
                    <div className="btnBrowseSec">
                      <Link type="button" className="btn btn-primary btnBrowse" to="/glossary">
                        Browse
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="detailMenuSec">
            <div className="sticky positonDetail">
              <div className="pageSideToggle d-block d-xl-none">
                <div className="pageSidebarToggle">
                  <img src="/images/svg-icons/sideArrow.svg" alt="back-icon" className="img-fluid" />
                </div>
              </div>
              <div className="detailMenuOuterSec card" id="sidebar">
                <div className="detailMenuInnerSec">
                  <div className="detailMenuHeading">
                    <h4>CONTENT TABLE</h4>
                  </div>
                  <div className={"detailSideLink" + (!isRewardClaimed ? " detailMenuLink " : "")}>
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a
                          href={"/courses/course/" + course._id + "/#intro"}
                          className={"nav-link clickHere clickintro" + (lsnIdx == 0 ? " active" : "")}
                          onClick={(e) => {
                            setLsnIdx(0);
                            scrollToLesson(e);
                          }}
                        >
                          Intro
                        </a>
                      </li>
                      {course.lessons.map((l, idx) => (
                        <li className="nav-item">
                          <a
                            className={
                              "nav-link clickHere clicklesson" + (idx + 1) + (lsnIdx == idx + 1 ? " active" : "")
                            }
                            href={"/courses/course/" + course._id + "/#lesson" + (idx + 1)}
                            onClick={(e) => {
                              setLsnIdx(idx + 1);
                              scrollToLesson(e);
                            }}
                          >
                            Lesson {idx + 1}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {!isRewardClaimed && (
                    <div className="rewardTest detailSideLink">
                      <ul className="nav flex-column">
                        <li className="nav-item">
                          <a
                            // className={
                            //   "btn btn-primary btnStart " +
                            //   (difference && difference._milliseconds > 0 ? "timeleft disabled " : "") +
                            //   (userCourse.status >= 70 || "disabled")
                            // }
                            // onClick={userCourse.status >= 70 && redirectToQizz}

                            className={
                              "nav-link " +
                              ((userCourse.status < 70 || (difference && difference._milliseconds > 0)) && " disabled")
                              // (difference && difference._milliseconds > 0 && " disabled")
                            }
                            onClick={userCourse.status >= 70 && redirectToQizz}
                            style={{
                              color: userCourse.status < 70 ? "rgba(0, 0, 0, 0.25)" : "inherit",
                            }}
                          >
                            Reward Test{" "}
                            {userCourse.status < 70 && (
                              <img src="/images/svg-icons/lock-disabled.svg" className="img-fluid" />
                            )}
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="detailMenuOuterSec  card customAddCard" id="sidebar">
                <div className="detailMenuInnerSec">
                  <amp-ad
                    width="275"
                    height="330"
                    type="adsense"
                    data-ad-client="ca-pub-1429924092740823"
                    data-ad-slot="8890481255"
                    data-auto-format="rspv"
                    data-full-width=""
                  >
                    <div overflow=""></div>
                  </amp-ad>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseDetail;
