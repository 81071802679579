import React, { useEffect, useState, useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { Link, useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import moment from "moment";
import Loading from "../../shared/Loading";
import SupportTicketService from "../../services/SupportTicketService";
function SupportList() {
  const navigate = useNavigate();
  const globalCtx = useContext(GlobalContext);
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  let loading = false;
  useEffect(() => {
    if (!isAuthentiCated) {
      navigate("/support_signup");
    } else {
      getListData();
    }
  }, []);
  const supportTicketServ = new SupportTicketService();
  const [supportList, setSupportList] = useState([]);
  const [records, setRecords] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [recordsCount, setRecordsCount] = useState(0);

  async function getListData() {
    try {
      if (loading === false && (recordsCount == 0 || recordsCount > records.length)) {
        let response = await supportTicketServ.supportTicketlist({});
        if (response) {
          setSupportList(response.data);
        }
        setRecords(recordsCount == 0 ? response.data : [...records, ...response.data]);
        setRecordsCount(response.total);
        setShowLoading(false);
      }
    } catch (err) {
      throw err;
    }
  }
  // if (showLoading) {
  //   return <Loading />;
  // }
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <Helmet>
        <title>Support | MaveFi</title>
      </Helmet>
      <div className="support_second_sec mb-4">
        <div className="row glossaryTitleSecRow">
          <div className="col-lg-12 col-xl-8 glossaryTitleCol">
            <div className="support_form card p-0">
              <div className="support_sec_newHead p_20">
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <div className="my-0 titleCard p-0 pb-2 pb-sm-0">
                    <h4 className="mb-2 letterSpacing0">
                      <img src="/images/support/online_Support.svg" alt="book" className="img-fluid" /> Support{" "}
                    </h4>
                    <p className="mb-0 letterSpacing0">Have questions about MaveFi?</p>
                  </div>
                  <div className="newTicketBtn">
                    <Link to="/support_new_ticket" className="d-inline-block letterSpacing0 lineHeight17">
                      <img
                        src="/images/support/ticket_window.svg"
                        className="me-2 d-inline-block"
                        style={{ marginBottom: "2px" }}
                      />{" "}
                      Open a new ticket
                    </Link>
                  </div>
                </div>
              </div>
              <div className="p_20 support_statusSec">
                {showLoading ? (
                  <Loading isAdaptive={true} />
                ) : (
                  supportList?.map((listValue, index) => {
                    return (
                      <div
                        className="supportStatus d-flex justify-content-between align-items-end letterSpacing0"
                        onClick={() => navigate("/support_ticket_expand/" + listValue?._id)}
                      >
                        <div className="supportStatus_left">
                          <h4 className="letterSpacing0">{listValue?.subject}</h4>
                          {listValue?.status == 1 && (
                            <div className="status_content green_status">
                              <img src="/images/support/ticket-status.svg" className="me-1" />
                              <span className="letterSpacing0">Status: </span>
                              <span className="letterSpacing0">Open</span>
                            </div>
                          )}
                          {listValue?.status == 2 && (
                            <div className="status_content gray_status">
                              <img src="/images/support/ticket-status.svg" className="me-1" />
                              <span className="letterSpacing0">Status: </span>
                              <span className="letterSpacing0">Replied</span>
                            </div>
                          )}
                          {listValue?.status == 3 && (
                            <div className="status_content gray_status">
                              <img src="/images/support/ticket-status.svg" className="me-1" />
                              <span className="letterSpacing0">Status: </span>
                              <span className="letterSpacing0">Not Replied</span>
                            </div>
                          )}
                          {listValue?.status == 4 && (
                            <div className="status_content red_status">
                              <img src="/images/support/ticket_close.svg" className="me-1" />
                              <span className="letterSpacing0">Status: </span>
                              <span className="letterSpacing0">Closed</span>
                            </div>
                          )}
                        </div>

                        <div className="Status_date">
                          <p className="mb-0 letterSpacing0">{moment(listValue?.updatedAt).format("DD MMM 'YY")}</p>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-xl-4 ps-xl-0 glossaryTitleCol">
            <div className="faqCompleted my-md-0 card">
              <div className="needHelp">
                <div className="needHelpHead d-flex align-items-center">
                  <div className="needHelpHeading">
                    <h5 className="mb-0 letterSpacing0">Can not find the answers to your questions? </h5>
                  </div>
                </div>
                <ul className="needHelpList">
                  <li className="helpList d-flex align-items-center">
                    <div className="needHelpIcon">
                      <img src="/images/support/Mail.svg" alt="needHelpIcon" className="img-fluid" />
                    </div>
                    <div className="needHelpHeading">
                      <p className="mb-0 letterSpacing0">
                        Email us at{" "}
                        <a href="mailto:support@mavefi.com" className="letterSpacing0">
                          support@mavefi.com
                        </a>
                      </p>
                    </div>
                  </li>
                  <li className="helpList d-flex align-items-center">
                    <div className="needHelpIcon">
                      <img src="/images/svg-icons/twitter-squared.svg" alt="needHelpIcon" className="img-fluid" />
                    </div>
                    <div className="needHelpHeading">
                      <p className="mb-0 letterSpacing0">
                        or Tweet us{" "}
                        <a href="https://twitter.com/joinmavefi" target="_blank" className="letterSpacing0">
                          @MaveFiSupport
                        </a>
                      </p>
                    </div>
                  </li>
                  <li className="helpList d-flex align-items-center">
                    <div className="needHelpIcon">
                      <img src="/images/support/askquestion.svg" alt="needHelpIcon" className="img-fluid" />
                    </div>
                    <div className="needHelpHeading">
                      <p className="mb-0 letterSpacing0">
                        or Visit{" "}
                        <Link to="/faq" className="letterSpacing0">
                          our Faqs
                        </Link>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {records?.length < recordsCount && <Loading />}
    </div>
  );
}

export default SupportList;
