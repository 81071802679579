import { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import Updates from "./Updates";
import TitleCard from "../../shared/TitleCard";
import BlogCard from "../Blogs/BlogCard";
import CourseCard from "../Courses/CourseCard";
import BlogService from "../../services/BlogService";
import CourseService from "../../services/CourseService";
import NewsSubscriptionService from "../../services/NewsSubscriptionService";
import GlobalContext from "../../context/GlobalContext";
import OwlCarousel from "react-owl-carousel";
import DummyCard from "../../shared/DummyCard";
import { Helmet } from "react-helmet";
import NewsletterSubscriptionSuccess from "../../popups/NewsletterSubscription/NewsletterSubscriptionSuccess";
import CustomLinkService from "../../services/CustomLinkService";

const serv = new BlogService();
const courseServ = new CourseService();
const newsSubServ = new NewsSubscriptionService();
const customLinkServ = new CustomLinkService();

function HomePage() {
  const globalCtx = useContext(GlobalContext);
  const [passwordToken, setPasswordToken] = globalCtx.passwordToken;
  const [referToken, setReferToken] = globalCtx.referToken;
  const [setting, setSetting] = globalCtx.setting;
  const [user, setUser] = globalCtx.user;
  const [blogs, setBlogs] = useState([]);
  const [course, setCourse] = useState([]);
  const { passwordtoken, refertoken, token, customUrl } = useParams();
  const [showNewsletterSuccess, setShowNewsLetterSuccess] = useState(false);
  const [search, setSearch] = useState({
    filter: { is_active: 1 },
    start: 0,
    length: 10,
  });
  const [courseSearch, setCourseSearch] = useState({
    filter: { searchText: "", is_active: true },
    sort: { createdAt: "desc" },
    start: 0,
    length: 20,
  });
  async function getBlogsList() {
    let respData = await serv.listAll(search);
    setBlogs(respData.data);
  }
  async function getCourseList() {
    let respData = await courseServ.listAll(search);
    setCourse(respData.data);
  }
  useEffect(() => {
    let search = window.location.search;
    if (customUrl && customUrl.length <= 6) {
      getFullUrl();
    } else if (search.includes("utm_campaign") && search.includes("utm_source")) {
      updateUrlCount(search);
    }
  }, [customUrl]);
  useEffect(() => {
    loadCoursesBlogs();
  }, [search, courseSearch]);
  useEffect(() => {
    if (passwordtoken) {
      setPasswordToken(passwordtoken);
    }
    if (refertoken) {
      setReferToken(refertoken);
    }
    if (token) {
      autherizeNewsletterLink();
    }
  }, []);
  async function getFullUrl() {
    try {
      let obj = {
        url: window.location.href,
      };
      await customLinkServ.getCustomLinkByUrl(obj).then((resp) => {
        if (resp.data) {
          window.location.replace(resp.data.campaignUrl);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  async function updateUrlCount(search) {
    try {
      let obj = {
        search: search,
      };
      await customLinkServ.updateUrlCount(obj).then((resp) => {
        console.log("count updated successfully!");
      });
    } catch (error) {
      console.log(error);
    }
  }
  async function autherizeNewsletterLink() {
    try {
      await newsSubServ.verifyEmail(token).then((resp) => {
        if (resp) {
          setShowNewsLetterSuccess(true);
          console.log("subscription verified!!");
        }
      });
    } catch (err) {
      console.log(err);
    }
  }
  async function loadCoursesBlogs() {
    await getCourseList();
    await getBlogsList();
  }
  return (
    <>
      <div className="ljSectionData w-100 clearfix" id="ljSectionData">
        <Helmet>
          <title>{setting?.seo?.home?.title || ""}</title>
          <meta name="description" content={setting?.seo?.home?.meta_description || ""} />
          <meta name="keywords" content={setting?.seo?.home?.tags || ""} />
          <meta property="og:title" content={setting?.seo?.home?.title || ""} />
          <meta property="og:description" content={setting?.seo?.home?.meta_description || ""} />
          <meta property="og:image" content={setting?.seo?.social_share_image} />
        </Helmet>
        <div className="maveSection">
          {user && !user.is_complete && (
            <div className="incompleteHead">
              <div className="incompleteTitle">
                <p>Hello {user?.full_name} 👋, Welcome to MaveFi. Complete your profile now & receive&nbsp;</p>
                <img src="/images/single_log.png" />
                <p>&nbsp;50 bonus coins.</p>
              </div>
              <Link className="incompeletButton" to="/profile">
                Complete your profile
              </Link>
            </div>
          )}
          <div className="row maveSectionRow">
            <div className="col-md-7 col-xl-8 maveSectionCol">
              <div className="maveSlider">
                {/* <!-- Carousel --> */}
                <div id="maviCarousel" className="carousel slide" data-bs-ride="carousel">
                  {/* <!-- Indicators/dots --> */}
                  <div className="carousel-indicators">
                    {setting.sliders &&
                      setting.sliders.map((item, idx) => (
                        <button
                          type="button"
                          data-bs-target="#maviCarousel"
                          data-bs-slide-to={idx}
                          className={idx == 0 ? "active" : ""}
                        ></button>
                      ))}
                    {/* <button
                    type="button"
                    data-bs-target="#maviCarousel"
                    data-bs-slide-to="1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#maviCarousel"
                    data-bs-slide-to="2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#maviCarousel"
                    data-bs-slide-to="3"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#maviCarousel"
                    data-bs-slide-to="4"
                  ></button> */}
                  </div>
                  {/* <!-- The slideshow/carousel --> */}
                  <div className="carousel-inner">
                    {setting.sliders &&
                      setting.sliders.map((item, idx) => (
                        <div className={`carousel-item ${idx == 0 && "active"}`}>
                          <img
                            src={item.slider_image}
                            alt="Los Angeles"
                            className="img-fluid w-100 slider-custom-image"
                          />
                        </div>
                      ))}
                    {/* <div className="carousel-item">
                    <img
                      src="/images/slider3.png"
                      alt="Chicago"
                      className="img-fluid w-100"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src="/images/slider3.png"
                      alt="New York"
                      className="img-fluid w-100"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src="/images/slider3.png"
                      alt="Chicago"
                      className="img-fluid w-100"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src="/images/slider3.png"
                      alt="New York"
                      className="img-fluid w-100"
                    />
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-xl-4 maveSectionCol">
              <Updates />
            </div>
          </div>
        </div>
        <TitleCard
          title='Recently Added <img src="/images/svg-icons/learn-earn.svg" alt="" className="img-fluid" /> Courses'
          content="The tech driving the Web3 revolution is often explained using technical jargon that leaves people confused and uninterested. At MaveFi, we’re determined to make learning an enjoyable and rewarding experience with a series of courses that cover Web3 topics in plain English."
        />
        {course.length > 0 && (
          <div className="allSliderCardList allUpdateCardList">
            <div className="updates_card_section mb-3">
              <div className="row">
                <div className="col">
                  <OwlCarousel
                    className="owl-carousel multiBlogSlider hompageSlider"
                    autoplay={true}
                    autoplaySpeed={10000}
                    loop={true}
                    margin={15}
                    responsiveClass={true}
                    dots={false}
                    nav={false}
                    responsive={{
                      0: {
                        items: 1.2,
                      },
                      400: {
                        items: 2.2,
                      },
                      600: {
                        items: 3.2,
                      },
                      992: {
                        items: 3.2,
                      },
                      1200: {
                        items: 4.3,
                      },
                      1440: {
                        items: 5.3,
                      },
                    }}
                  >
                    {course.map((c) => (
                      <CourseCard key={c._id} course={c} />
                    ))}
                  </OwlCarousel>
                  {/* <div className="owl-carousel multiBlogSlider hompageSlider">
                  {course.map((c) => (
                    <CourseCard key={c._id} course={c} />
                  ))}
                </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
        {course.length < 1 && <DummyCard cardCount={5} />}
        <TitleCard
          title='From the <img src="/images/svg-icons/blog.svg" alt="" className="img-fluid" />  Blog'
          content="Whether you’re interested in web3/crypto/nfts/defi/blockchain or want to  discover our latest product updates, stories & partnership announcements, this is where you’ll find them all. (Written by the MaveFi team & contributors)"
        />

        {blogs.length > 0 && (
          <div className="allSliderCardList">
            <div className="updates_card_section">
              <div className="row">
                <OwlCarousel
                  className="owl-carousel multiBlogSlider hompageSlider"
                  loop={true}
                  autoplay={true}
                  autoplaySpeed={10000}
                  margin={15}
                  responsiveClass={true}
                  dots={false}
                  nav={false}
                  responsive={{
                    0: {
                      items: 1.2,
                    },
                    400: {
                      items: 2.2,
                    },
                    600: {
                      items: 3.2,
                    },
                    992: {
                      items: 3.2,
                    },
                    1200: {
                      items: 4.3,
                    },
                    1440: {
                      items: 5.3,
                    },
                  }}
                >
                  {blogs.map((b) => (
                    <BlogCard blog={b} />
                  ))}
                </OwlCarousel>
                {/* <div className="owl-carousel multiBlogSlider hompageSlider">
                {blogs.map((b) => (
                  <BlogCard blog={b} />
                ))}
              </div> */}
              </div>
            </div>
          </div>
        )}
        {blogs.length < 1 && <DummyCard cardCount={5} />}
      </div>
      {showNewsletterSuccess && <NewsletterSubscriptionSuccess onClose={() => setShowNewsLetterSuccess(false)} />}
    </>
  );
}

export default HomePage;
