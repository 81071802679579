import { useContext, useEffect, useState } from "react";

import VideoService from "../../services/VideoService";
import ScrollMoreProvider from "../../shared/Scroll/ScrollMoreProvider";
import TitleCard2 from "../../shared/TitleCard2";
import GlobalContext from "../../context/GlobalContext";
import Loading from "../../shared/Loading";
import { Helmet } from "react-helmet";
import LoginSignupCard from "../../shared/LoginSignupCard";
import VideoCard from "./VideoCard";
import PageHeader from "../../shared/PageHeader";

const serv = new VideoService();
export default function Blogs() {
  const globalCtx = useContext(GlobalContext);
  const [searchDtl, setSearchDtl] = globalCtx.searchDtl;
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const [setting, setSetting] = globalCtx.setting;
  const [records, setRecords] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [recordsCount, setRecordsCount] = useState(0);
  const [search, setSearch] = useState({
    filter: { searchText: searchDtl, is_active: true },
    start: 0,
    length: 20,
  });
  let loading = false;
  const [filterListObj, setFilterListObj] = useState({
    channels: [],
    tags: [],
    status: [
      { check: true, value: "New" },
      { check: true, value: "Started" },
      { check: true, value: "Completed" },
    ],
    rewards: [
      { check: false, value: "Yes" },
      { check: false, value: "No" },
    ],
    languages: [{ check: false, value: "English" }],
  });
  const sortArray = [
    { method: { createdAt: "desc" }, label: "Recently Added" },
    { method: { video_title: "asc" }, label: "A to Z" },
    { method: { video_title: "desc" }, label: "Z to A" },
    { method: { viewCount: "desc" }, label: "Most viewed" },
    { method: { bookmarkCount: "desc" }, label: "Most bookmarked" },
    { method: { createdAt: "asc" }, label: "Oldest" },
  ];
  const [filterObj, setFilterObj] = useState({});
  useEffect(() => {
    getList();
    filterList();
  }, [search]);

  useEffect(() => {
    searchCallBack(searchDtl);
  }, [searchDtl]);

  async function getList() {
    if (loading === false && (recordsCount == 0 || recordsCount > records.length)) {
      loading = true;
      let respData = await serv.listAll(search);
      setRecords(recordsCount == 0 ? respData.data : [...records, ...respData.data]);
      setRecordsCount(respData.total);
      setShowLoading(false);
    }
  }

  function reachedBottomCall() {
    let searchTemp = { ...search };
    searchTemp.start = search.start + search.length;
    setSearch(searchTemp);
  }

  function searchCallBack(value, filters, sortBy) {
    let searchTemp = { ...search };
    searchTemp.start = 0;
    if (filters !== undefined) {
      searchTemp.filter = filters;
    }
    searchTemp.filter.searchText = value;
    searchTemp.filter.is_active = true;
    searchTemp.sort = { createdAt: "desc" };

    if (sortBy) {
      searchTemp.sort = sortBy;
    }
    setRecordsCount(0);
    setSearch(searchTemp);
  }
  function actionCallBack() {
    let searchTemp = { ...search };
    searchTemp.start = 0;
    setRecordsCount(0);
    setSearch(searchTemp);
  }
  async function filterList() {
    let respData = await serv.getFilterList();
    let tagValues = [];
    let tag = [];
    let channelValues = [];
    let channel = [];
    let statusValues = [];
    let statuss = [];
    let languageValues = [];
    let language = [];
    respData.data.tags.map((item) => {
      let tagValue = { check: false, value: item };

      if (search.filter?.tags?.includes(item)) {
        tagValue.check = true;
        tag.push(item);
      }
      tagValues.push(tagValue);
    });
    respData.data.channel.map((item) => {
      let channelValue = { check: false, value: item };

      if (search?.channel?.includes(item)) {
        channelValue.check = true;
        channel.push(item);
      }
      channelValues.push(channelValue);
    });
    filterListObj.status.map((item) => {
      let statusValue = item;

      if (search.filter?.status?.includes(item.value)) {
        statusValue.check = true;
        statuss.push(item.value);
      }
      statusValues.push(statusValue);
    });
    filterListObj.languages.map((item) => {
      let languageValue = item;

      if (search.filter?.languages?.includes(item.value)) {
        languageValue.check = true;
        language.push(item.value);
      }
      languageValues.push(languageValue);
    });
    let tempFilterObj = {
      channel: channel,
      tags: tag,
      status: statuss,
      languages: language,
    };
    let tempFilterListObj = {
      status: statusValues,
      channel: channelValues,
      tags: tagValues,
      languages: languageValues,
    };
    if (!isAuthentiCated) {
      delete tempFilterObj.status;
      delete tempFilterListObj.status;
    }
    setFilterObj({ ...tempFilterObj });
    setFilterListObj({ ...tempFilterListObj });
  }
  if (showLoading) {
    return <Loading />;
  }
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <Helmet>
        <title>{setting?.seo?.video?.title || ""}</title>
        <meta name="description" content={setting?.seo?.video?.meta_description || ""} />
        <meta name="keywords" content={setting?.seo?.video?.tags || ""} />
        <meta property="og:title" content={setting?.seo?.video?.title || ""} />
        <meta property="og:description" content={setting?.seo?.video?.meta_description || ""} />
        <meta property="og:image" content={setting?.seo?.social_share_image} />
      </Helmet>
      <PageHeader
        allowedFilter={true}
        type="course"
        searchCallBack={searchCallBack}
        filterListObj={filterListObj}
        filterObj={filterObj}
        search={search}
        sortArray={sortArray}
        topW100={true}
        newsLetter={true}
        height98={true}
        titleCard={{
          image: "/images/svg-icons/video-camera.svg",
          title: "Videos",
          content: "A curated library of web3 related videos from all over the internet.",
          type: "glossary",
        }}
        searchType={{ type: "video", placeholder: "Search Video" }}
        loginTitle="Please login/sign up to contribute a video to our library."
        rightActionCard={{
          heading: "Submit a web3 video",
          desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been theindustry's standard",
          buttonName: "Contribute",
        }}
      />
      {/* <div className="faqTitleSec">
        <div className="row glossaryTitleSecRow">
          <div className="col-lg-12 col-xl-8 glossaryTitleCol">
            <TitleCard2
              image="/images/svg-icons/video-camera.svg"
              title="Videos"
              content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it."
              type="glossary"
            />
            <VideoSearch
              placeholder="Search Video"
              searchCallBack={searchCallBack}
              searchText={search.filter.searchText}
              searchFilter={search.filter}
            />
          </div>
          <div className="col-lg-12 col-xl-4 ps-xl-0 glossaryTitleCol">
            {isAuthentiCated ? (
              <div className="titleCard submitSlang card">
                <h4>
                  <img
                    src="/images/svg-icons/video-black.svg"
                    style={{ display: "inline-block", marginRight: "10px" }}
                  />
                  Submit a web3 video
                </h4>
                <p className="mb-0">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard.
                </p>
                <a
                  href="javascript:void(0)"
                  className="btn btnInfo"
                  //   onClick={handleVideoPopup}
                >
                  Contribute
                </a>
              </div>
            ) : (
              <LoginSignupCard title="Please login/sign up to contribute a video to our library." />
            )}
          </div>
        </div>
      </div> */}
      <div className="allBlogCard">
        <div className="allUpdateCardList allLearnEarnCardList">
          <div className="updates_card_section updatesCardSection mb-3">
            {records.length > 0 && (
              <ScrollMoreProvider
                className="row updatesCardSectionRow"
                id="glossary list"
                reachedBottomCall={reachedBottomCall}
                useWindowScroll={true}
              >
                {records.map((b, idx) => {
                  if (idx == 13) {
                    return (
                      <>
                        <div className={"col-sm-6 col-lg-6 col-xl-3 col-xxl-2  cardsBox cardsBoxVideo "}>
                          <div className="updates_card updateCard updateCardBlog updateCardBlogCustom">
                            <amp-ad
                              width="226"
                              height="199"
                              type="adsense"
                              data-ad-client="ca-pub-1429924092740823"
                              data-ad-slot="8890481255"
                              data-auto-format="rspv"
                              data-full-width=""
                            >
                              <div overflow=""></div>
                            </amp-ad>
                          </div>
                        </div>
                        <VideoCard key={b._id} video={b} search={search} actionCallBack={actionCallBack} />
                      </>
                    );
                  } else {
                    return <VideoCard key={b._id} video={b} search={search} actionCallBack={actionCallBack} />;
                  }
                })}
              </ScrollMoreProvider>
            )}
            {records.length < recordsCount && <Loading isSmall={true} />}
          </div>
        </div>
      </div>
    </div>
  );
}
