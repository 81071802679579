import React, { createContext, useState, useEffect } from "react";
import BookmarkService from "../services/BookmarkService";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const bms = new BookmarkService();
  const [isAuthentiCated, setIsAuthentiCated] = useState(localStorage.getItem("token") ? true : false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [searchDtl, setSearchDtl] = useState("");
  const [bookmarks, setBookmarks] = useState([]);
  const [passwordToken, setPasswordToken] = useState("");
  const [referToken, setReferToken] = useState("");
  const [referEarn, setReferEarn] = useState();
  const [showLoginSignup, setShowLoginSignup] = useState({ value: "" });
  const [firstTimeView, setFirstTimeView] = useState(localStorage.getItem("firstTimeView") ? "no" : "yes");
  const [setting, setSetting] = useState({});
  const [policies, setPolicies] = useState([]);

  const updateBookmark = (bookMarkId, type) => {
    if (bookmarks.indexOf(bookMarkId) > -1) {
      // remove book mark
      bookmarks.splice(bookmarks.indexOf(bookMarkId), 1);
      setBookmarks([...bookmarks]);
      bms.removeBookmark(bookMarkId, type);
    } else {
      // add bookmark
      setBookmarks([...bookmarks, bookMarkId]);
      bms.addBookmark(bookMarkId, type);
    }
  };
  useEffect(() => {
    if (user && user._id) {
      getBookmark();
    }
  }, []);
  const getBookmark = () => {
    bms.listAllBookmark({ filter: { user_id: user._id } }).then((resp) => {
      resp = resp.data[0];
      let tempData = [];
      tempData = [...bookmarks, ...resp.blogs, ...resp.news, ...resp.videos, ...resp.courses];
      setBookmarks([...tempData]);
    });
  };
  const checkBookmark = (id) => {
    if (bookmarks.indexOf(id) > -1) {
      return " active";
    }
    return "";
  };

  return (
    <GlobalContext.Provider
      value={{
        searchDtl: [searchDtl, setSearchDtl],
        // courseChange: [courses, setCourses],
        auth: [isAuthentiCated, setIsAuthentiCated],
        updateBookmark,
        checkBookmark,
        bookmarks,
        setBookmarks,
        user: [user, setUser],
        passwordToken: [passwordToken, setPasswordToken],
        referToken: [referToken, setReferToken],
        referEarn: [referEarn, setReferEarn],
        showLoginSignup: [showLoginSignup, setShowLoginSignup],
        firstTimeView: [firstTimeView, setFirstTimeView],
        setting: [setting, setSetting],
        policies: [policies, setPolicies],
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
export default GlobalContext;
