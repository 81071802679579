import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import SupportTicketService from "../../services/SupportTicketService";
import Loading from "../../shared/Loading";

function SupportReply() {
  const { ticketId } = useParams();
  const navigate = useNavigate();
  const supportTicketServ = new SupportTicketService();
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [recordsCount, setRecordsCount] = useState(0);
  let loading = false;
  useEffect(() => {
    getTicketDetails(ticketId);
  }, []);
  const [ticketDetail, setTicketDetail] = useState([]);
  const getTicketDetails = async (ticketId) => {
    try {
      if (loading === false && (recordsCount == 0 || recordsCount > records.length)) {
        let response = await supportTicketServ.getSupportTicketDetails(ticketId);
        if (response) {
          setTicketDetail(response.data);
        }
        setRecords(recordsCount == 0 ? response.data : [...records, ...response.data]);
        setRecordsCount(response.total);
        setShowLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [value, setValue] = useState({
    attachments: [],
    reply: "",
    supportedId: "",
  });
  const [readFile, setReadFile] = useState("");
  const onSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    let obj = {
      reply: values.reply,
      supportId: ticketId,
      attachments: values.attachments,
    };
    const formData = new FormData();
    formData.append("reply", obj.reply);
    formData.append("supportId", obj.supportId);
    formData.append("attachments", obj.attachments);
    supportTicketServ.addChat(formData).then((res) => {
      if (res.err) {
        console.log(res.err);
      } else {
        resetForm();
        setReadFile("");
        navigate("/support_ticket_expand/" + ticketId);
      }
      setIsLoading(false);
    });
  };
  const ValidateSchema = Yup.object({
    reply: Yup.string().required("Message field should not be empty."),
  });
  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  console.log(readFile);
  if (showLoading) {
    return <Loading />;
  }
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <div className="support_second_sec mb-4">
        <div className="row glossaryTitleSecRow">
          <div className="col-lg-12 col-xl-8 glossaryTitleCol">
            <div className="support_form card p-0">
              <div className="support_sec_newHead">
                <div className="support_header d-flex align-items-end flex-wrap">
                  <div className="prev_btns p_20 h-100">
                    <a onClick={() => navigate("/support_ticket_expand/" + ticketId)}>
                      {" "}
                      <img src="/images/support/prev_icon.svg" className="img-fluid" />
                    </a>
                  </div>
                  <div className="my-0 titleCard p_20">
                    <h4 className="mb-2 letterSpacing0">{ticketDetail?.subject}</h4>
                    {ticketDetail?.status == 1 && (
                      <p className="mb-0 header_status green_status">
                        <span className="letterSpacing0">Status:</span> <span className="letterSpacing0">Open</span>
                      </p>
                    )}
                    {ticketDetail?.status == 2 && (
                      <p className="mb-0 header_status  green_status gray_status">
                        <span style={{ color: "gray" }}>Status:</span> <span style={{ color: "gray" }}>Replied</span>
                      </p>
                    )}
                    {ticketDetail?.status == 3 && (
                      <p className="mb-0 header_status  green_status gray_status">
                        <span style={{ color: "gray" }} className="letterSpacing0">
                          Status:
                        </span>{" "}
                        <span style={{ color: "gray" }} className="letterSpacing0">
                          Not Replied
                        </span>
                      </p>
                    )}
                    {ticketDetail?.status == 4 && (
                      <p className="mb-0 header_status   green_status">
                        <span style={{ color: "red" }} className="letterSpacing0">
                          Status:
                        </span>{" "}
                        <span style={{ color: "red" }} className="letterSpacing0">
                          Closed
                        </span>
                      </p>
                    )}
                  </div>
                  <div className="ticket_idHead ms-auto p_20">
                    <p className="mb-0 letterSpacing0">Ticket ID: {ticketDetail?.ticketNo}</p>
                  </div>
                </div>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="support_statusSec walletuserPro p_20">
                  <div className="new_TicketInput">
                    <div className="mb-3">
                      <label htmlFor="messageText" className="form-label letterSpacing0">
                        Message
                      </label>
                      <textarea
                        className="form-control letterSpacing0"
                        rows={5}
                        id="messageText"
                        placeholder="Enter reply message"
                        name="reply"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.reply}
                      />
                    </div>
                    {formik.touched.reply && formik.errors.reply ? (
                      <div className="formik-errors bg-error">{formik.errors.reply}</div>
                    ) : null}
                  </div>
                  <div className="new_TicketInput">
                    <label htmlFor="attachments" className="form-label d-block letterSpacing0">
                      Attachments
                    </label>
                    <div className="input-group custom-file-button">
                      <label className="input-group-text uploadfile_icon letterSpacing0" htmlFor="inputticketFile">
                        <img src="/images/support/ticket_upload_icon.svg" alt="upload to Cloud" className="img-fluid" />
                      </label>
                      <input
                        type="text"
                        className="form-control file_input_replacement letterSpacing0"
                        placeholder="Click here to upload"
                        id="inputticketFile"
                        readOnly
                        name="readonly"
                        value={readFile}
                      />
                      <input
                        type="file"
                        className="w-100 fileInput letterSpacing0"
                        name="attachments"
                        onChange={(event) => {
                          formik.setFieldValue("attachments", event.currentTarget.files[0]);
                          setReadFile(event.currentTarget.files[0].name);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="close_replyBtn p_20">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="pt-0">
                        <button
                          className={
                            "b_radius15 letterSpacing0 supportReplyBtn replyBtnBg  btn" +
                            (!(formik.isValid && formik.dirty) || isLoading ? " disabled" : "")
                          }
                        >
                          Send Reply {isLoading && <Loading isInline={true} />}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-12 col-xl-4 ps-xl-0 glossaryTitleCol">
            <div className="faqCompleted my-md-0 card">
              <div className="needHelp">
                <div className="needHelpHead d-flex align-items-center">
                  <div className="needHelpHeading">
                    <h5 className="mb-0 letterSpacing0">Can not find the answers to your questions? </h5>
                  </div>
                </div>
                <ul className="needHelpList">
                  <li className="helpList d-flex align-items-center">
                    <div className="needHelpIcon">
                      <img src="/images/support/Mail.svg" alt="needHelpIcon" className="img-fluid" />
                    </div>
                    <div className="needHelpHeading">
                      <p className="mb-0 letterSpacing0">
                        Email us at{" "}
                        <a href="mailto:support@mavefi.com" className="letterSpacing0">
                          support@mavefi.com
                        </a>
                      </p>
                    </div>
                  </li>
                  <li className="helpList d-flex align-items-center">
                    <div className="needHelpIcon">
                      <img src="/images/svg-icons/twitter-squared.svg" alt="needHelpIcon" className="img-fluid" />
                    </div>
                    <div className="needHelpHeading">
                      <p className="mb-0 letterSpacing0">
                        or Tweet us{" "}
                        <a href="https://twitter.com/joinmavefi" target="_blank" className="letterSpacing0">
                          @MaveFiSupport
                        </a>
                      </p>
                    </div>
                  </li>
                  <li className="helpList d-flex align-items-center">
                    <div className="needHelpIcon">
                      <img src="/images/support/askquestion.svg" alt="needHelpIcon" className="img-fluid" />
                    </div>
                    <div className="needHelpHeading">
                      <p className="mb-0 letterSpacing0">
                        or Visit{" "}
                        <Link to="/faq" className="letterSpacing0">
                          our Faqs
                        </Link>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {records?.length < recordsCount && <Loading />}
    </div>
  );
}

export default SupportReply;
