import { useContext } from "react";
import GlobalContext from "../context/GlobalContext";

function LoginSignupCard({ title }) {
  const globalCtx = useContext(GlobalContext);
  const [showLoginSignup, setShowLoginSignup] = globalCtx.showLoginSignup;
  return (
    <div className="h-100 titleCard coursesCompleted my-md-0 login-signup-card submitSlang card">
      <div className="coursesComp">
        <p className="progressLearn progressLearnCustom mb-3" style={{ lineHeight: "145%" }}>
          {title}
        </p>
        <button
          className="btn btnLogin btnInfo btnInfoCustom"
          onClick={() => {
            setShowLoginSignup({ value: "login" });
          }}
        >
          Login
        </button>
        <button
          className="btn btnInfo btnInfoCustomBlue mt-10"
          onClick={() => {
            setShowLoginSignup({ value: "signup" });
          }}
        >
          Sign up
        </button>
      </div>
    </div>
  );
}

export default LoginSignupCard;
