import { useContext, useState, useEffect } from "react";
import UserService from "../../services/UserService";
import { useFormik } from "formik";
import * as Yup from "yup";
import GlobalContext from "../../context/GlobalContext";
import util from "../../util/util";
import Loading from "../../shared/Loading";
const serv = new UserService();
const ValidateSchema = Yup.object({
  user_name: Yup.string()
    .max(15, "Your username must be shorter than 15 characters.")
    .min(4, "Your username must be longer than 4 characters.")
    .required("User name is a required field"),
});

function UpdateUserName({ onClose, isUpdate }) {
  const globalCtx = useContext(GlobalContext);
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const [user, setUser] = globalCtx.user;
  const [isAvailable, setIsAvailable] = useState(false);
  const [isAvailableLoaded, setIsAvailableLoaded] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [signupObj, setSignupObj] = new useState({
    user_name: "",
  });
  const [userNameError, setUserNameError] = useState("");
  const [updatedUserName, setUpdatedUserName] = useState("");
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    getSuggestion(user.user_name);
    userNameContainFunc();
  }, [updatedUserName]);
  const userNameContainFunc = () => {
    const pattern = /^[a-zA-Z0-9_]*$/;
    if (pattern.test(updatedUserName)) {
      setUserNameError("");
      setIsError(true);
    } else {
      setUserNameError("Your username can only contains letters, numbers and '_'");
      setIsError(false);
    }
  };
  let verifyUserName = async (value) => {
    if (value.length > 3) {
      setIsAvailable(false);
      setIsAvailableLoaded(true);
      setErrorMsg("");
      try {
        const resp = await serv.verifyUser(value);
        if (resp) {
          setIsAvailable(resp.message == "Available");
          setIsAvailableLoaded(false);
        }
      } catch (err) {}
    }
  };
  const getSuggestion = async (value) => {
    try {
      const resp = await serv.userSuggestion(value);
      if (resp.data) {
        setSuggestionList([...resp.data]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onSubmit = async (values) => {
    setIsLoading(true);
    let data = user;
    let obj = { ...values };
    obj.email = data.email;
    obj._id = data._id;

    try {
      const resp = await serv.edit(obj);
      if (resp.result) {
        let localData = { ...user };
        localData.user_name = resp.result.user_name;
        localStorage.setItem("user", JSON.stringify(localData));
        setIsAuthentiCated(true);
        setUser(localData);
        setIsLoading(false);
        onClose();
      } else {
        setErrorMsg(resp.error);
        setIsLoading(false);
      }
    } catch (err) {
      setErrorMsg(err.message);
      setIsLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: signupObj,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <>
      <div
        className="modal commanModel SignupPopup showBlock"
        id="Signup"
        onClick={(e) => {
          if (isUpdate === true && e.target == e.currentTarget) {
            onClose();
          }
        }}
      >
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center" style={{ background: "rgba(0, 0, 0, 0.5)" }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">{isUpdate === true ? "Update username" : "Welcome to MaveFi"}</h4>
                  {isUpdate === true && (
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => {
                        onClose();
                      }}
                    ></button>
                  )}
                </div>
                <div className="modal-body">
                  <div className="connectWalletOuter">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="modelFormInput " style={{ marginBottom: "10px" }}>
                        <label className="updateUsernameLabelCustom" for="signupfname">
                          Current
                        </label>
                        <div
                          className={
                            "input-group btn-border-custom" +
                            (formik.touched.full_name && formik.errors.full_name ? " border-red" : "")
                          }
                        >
                          {/* <span className="input-group-text pe-0">
                                                        <img src="/images/svg-icons/at-sign.svg" />
                                                    </span> */}
                          <input
                            type="text"
                            className="form-control btn-border-custom inputCustomColor"
                            placeholder="johndoe"
                            name="name"
                            disabled
                            // onChange={(e) => {
                            //     formik.handleChange(e);
                            //     verifyUserName(e.target.value);
                            // }}
                            // onBlur={formik.handleBlur}
                            value={user.user_name}
                          />
                        </div>
                      </div>
                      <div className="modelFormInput" style={{ marginBottom: "10px" }}>
                        <label className="updateUsernameLabelCustom" for="signupfname">
                          New
                        </label>
                        <div
                          className={
                            "input-group btn-border-custom" +
                            (formik.touched.full_name && formik.errors.full_name ? " border-red" : "")
                          }
                        >
                          {/* <span className="input-group-text pe-0">
                                                        <img src="/images/svg-icons/at-sign.svg" />
                                                    </span> */}
                          <input
                            type="text"
                            className="form-control btn-border-custom"
                            placeholder="Enter new username"
                            name="user_name"
                            onChange={(e) => {
                              formik.handleChange(e);
                              verifyUserName(e.target.value);
                              setUpdatedUserName(e.target.value);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.user_name}
                          />
                          {formik.values.user_name.length > 0 &&
                            (isAvailableLoaded ? (
                              <span className="input-group-text ps-0 userTxt GreenClr">
                                <Loading isInline={true} />
                              </span>
                            ) : isAvailable && isError ? (
                              <span className="input-group-text ps-0 userTxt GreenClr">
                                <p className="userNameAvailable">Available</p>
                              </span>
                            ) : (
                              <span className="input-group-text ps-0 userTxt RedClr">
                                <p className="userNameNotAvailable">Not Available</p>
                              </span>
                            ))}
                        </div>
                        {formik.touched.user_name && formik.errors.user_name ? (
                          <div className="formik-errors bg-error">{formik.errors.user_name}</div>
                        ) : null}
                        {userNameError != "" && <p style={{ fontSize: "10px", color: "#FF0000" }}>{userNameError}</p>}
                      </div>
                      <div className="user-suggestion">
                        <p>Suggestions:&nbsp;</p>
                        {suggestionList &&
                          suggestionList.map((i, idx) => (
                            <p>
                              <a href="#" onClick={() => formik.setFieldValue("user_name", i)}>
                                {i}
                              </a>
                              {suggestionList.length - 1 > idx && <span>,&nbsp;</span>}
                            </p>
                          ))}
                      </div>
                      {errorMsg && <div className="formik-errors bg-error text-center">{errorMsg}</div>}
                      <div className="modelSignBtn">
                        <div className="d-grid">
                          <button
                            type="submit"
                            className={
                              "btn btn-primary btn-block btn-border-custom" +
                              (!(formik.isValid && formik.dirty && isAvailable && isError) || isLoading
                                ? " disabled"
                                : "")
                            }
                          >
                            Done {isLoading && <Loading isInline={true} />}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="modal-backdrop show"></div> */}
    </>
  );
}

export default UpdateUserName;
