import React, { createContext, useState, useEffect } from "react";
export const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
  const d = new Date();
  const time = d.getSeconds();
  const [scrolledToBottom, setScrolledToBottom] = useState(time);
  return (
    <ScrollContext.Provider
      value={{
        scrollChange: [scrolledToBottom, setScrolledToBottom],
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
};
export default ScrollContext;
