import util from "../util/util";

export default class UserAnswerService {
    addUserAnswer(payload) {
        return util
            .sendApiRequest("/useranswer", "POST", true, payload)
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }

    async listAll(payload) {
        try {
            return await util.sendApiRequest(
                "/useranswer/list/",
                "POST",
                true,
                payload
            );
        } catch (err) {
            throw err;
        }
    }
}
