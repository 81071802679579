import { ScrollProvider } from "../../context/ScrollContext";
import ScrollMore from "./ScrollMore";
function ScrollMoreProvider({
  children,
  className,
  id,
  reachedBottomCall,
  useWindowScroll,
}) {
  return (
    <ScrollProvider>
      <ScrollMore
        className={className}
        id={id}
        reachedBottomCall={reachedBottomCall}
        useWindowScroll={useWindowScroll}
      >
        {children}
      </ScrollMore>
    </ScrollProvider>
  );
}

export default ScrollMoreProvider;
