import util from "../util/util";
import axios from "axios";
export default class SettingService {
    getDetails() {
        return util
            .sendApiRequest("/setting/", "GET", true)
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }
    async addrecord(formData) {
        const token = window.user ? window.user.token : "no-token";
        const config = {
            headers: {
                content: "multipart/form-data",
                Authorization: "Bearer " + token,
            },
        };

        try {
            const response = await axios.post(
                process.env.REACT_APP_API_BASEURL + "/setting",
                formData,
                config
            );
            if (response.err) {
                throw new Error(response.err);
            } else {
                return response;
            }
        } catch (err) {
            throw err;
        }
    }
    async updateRecord(formData) {
        const token = window.user ? window.user.token : "no-token";
        const config = {
            headers: {
                content: "multipart/form-data",
                Authorization: "Bearer " + token,
            },
        };

        try {
            const response = await axios.put(
                process.env.REACT_APP_API_BASEURL + "/setting",
                formData,
                config
            );
            if (response.err) {
                throw new Error(response.err);
            } else {
                return response;
            }
        } catch (err) {
            throw err;
        }
    }
    deleteRecord(dataId) {
        return util.sendApiRequest("/setting", "DELETE", true).then(
            (response) => {
                return response;
            },
            (error) => {
                throw new Error(error);
            }
        );
    }
}
