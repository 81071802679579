import React, { useEffect, useState, useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { useNavigate, Link, useParams } from "react-router-dom";
import SupportTicketService from "../../services/SupportTicketService";
import moment from "moment";
import Loading from "../../shared/Loading";
// import { ToastContainer, toast } from "react-toastify";
function SupportTicketExpand() {
  const { ticketId } = useParams();
  const navigate = useNavigate();
  const supportTicketServ = new SupportTicketService();
  const globalCtx = useContext(GlobalContext);
  const [user, setUser] = globalCtx.user;
  const [records, setRecords] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [recordsCount, setRecordsCount] = useState(0);
  let loading = false;
  useEffect(() => {
    getTicketDetails(ticketId);
  }, []);
  useEffect(() => {
    if (showLoading == false) {
      var objDiv = document.getElementById("messages");
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  });
  const [ticketDetail, setTicketDetail] = useState([]);
  const [replies, setReplies] = useState([]);
  const getTicketDetails = async (ticketId) => {
    try {
      if (loading === false && (recordsCount == 0 || recordsCount > records.length)) {
        let response = await supportTicketServ.getSupportTicketDetails(ticketId);
        if (response) {
          setTicketDetail(response.data);
          setReplies(response.data.replies);
        }
        setRecords(recordsCount == 0 ? response.data : [...records, ...response.data]);
        setRecordsCount(response.total);
        setShowLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updateStatus = async () => {
    const formData = new FormData();
    formData.append("_id", ticketId);
    formData.append("status", 4);
    let response = await supportTicketServ.updateRecord(formData);
    if (response.data) {
      navigate("/support");
    }
  };
  // if (showLoading) {
  //   return <Loading />;
  // }
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <div className="support_second_sec mb-4">
        <div className="row glossaryTitleSecRow">
          <div className="col-lg-12 col-xl-8 glossaryTitleCol">
            <div className="support_form card p-0">
              <div className="support_sec_newHead">
                <div className="support_header d-flex align-items-end flex-wrap">
                  <div className="prev_btns p_20 h-100">
                    <Link to="/support">
                      {" "}
                      <img src="/images/support/prev_icon.svg" className="img-fluid" />
                    </Link>
                  </div>
                  <div className="my-0 titleCard p_20">
                    <h4 className="mb-2 letterSpacing0">{ticketDetail?.subject}</h4>
                    {ticketDetail?.status == 1 && (
                      <p className="mb-0 header_status green_status">
                        <span className="letterSpacing0">Status:</span> <span className="letterSpacing0">Open</span>
                      </p>
                    )}
                    {ticketDetail?.status == 2 && (
                      <p className="mb-0 header_status  green_status gray_status">
                        <span style={{ color: "gray" }} className="letterSpacing0">
                          Status:
                        </span>{" "}
                        <span style={{ color: "gray" }} className="letterSpacing0">
                          Replied
                        </span>
                      </p>
                    )}
                    {ticketDetail?.status == 3 && (
                      <p className="mb-0 header_status  green_status gray_status">
                        <span style={{ color: "gray" }} className="letterSpacing0">
                          Status:
                        </span>{" "}
                        <span style={{ color: "gray" }} className="letterSpacing0">
                          Not Replied
                        </span>
                      </p>
                    )}
                    {ticketDetail?.status == 4 && (
                      <p className="mb-0 header_status   green_status">
                        <span style={{ color: "red" }} className="letterSpacing0">
                          Status:
                        </span>{" "}
                        <span style={{ color: "red" }} className="letterSpacing0">
                          Closed
                        </span>
                      </p>
                    )}
                  </div>
                  <div className="ticket_idHead ms-auto p_20">
                    <p className="mb-0 letterSpacing0">Ticket ID: {ticketDetail?.ticketNo}</p>
                  </div>
                </div>
              </div>
              <div className="support_statusSec walletuserPro" id="messages">
                {showLoading ? (
                  <Loading isAdaptive={true} />
                ) : (
                  replies?.map((v, i) => {
                    return (
                      <div className="uesrTicketCard p_20">
                        <div className="uesrTicketCardtop d-flex justify-content-between align-items-center">
                          <div className="tickeeUserPro">
                            {v?.from.role[0] == "admin" || v.fromBot ? (
                              <img src="/images/support/ticketuserProfile.svg" />
                            ) : (
                              <img
                                src={v?.from?.file || "/images/svg-icons/defaultUserIcon.svg"}
                                className="profileUserPic"
                              />
                            )}
                            <span className="marLeft letterSpacing0">
                              {v.fromBot
                                ? "Mavefi Bot"
                                : v?.from.role[0] == "admin"
                                ? "Mavefi Support"
                                : v?.from.full_name}
                            </span>
                          </div>
                          <div>
                            <h6 className="letterSpacing0">
                              {v?.isRead ? (
                                <img src="/images/svg-icons/dubbleTickIcon.svg" className="me-1" />
                              ) : (
                                <img src="/images/svg-icons/singleBlueTickIcon.svg" className="me-1" />
                              )}
                              {moment(v?.replyDate).format("DD MMM 'YY")}
                            </h6>
                          </div>
                        </div>
                        <div className="uesrTicketCardBottom">
                          <p className="mb-3 lineBreak letterSpacing0">{v?.reply}</p>
                          {v?.attachments[0]?.length > 1 && (
                            <div>
                              {v?.attachments[0]?.toUpperCase().split(".").slice(-1) == "SVG" ||
                              v?.attachments[0]?.toUpperCase().split(".").slice(-1) == "PNG" ||
                              v?.attachments[0]?.toUpperCase().split(".").slice(-1) == "JPG" ? (
                                <a href={v?.attachments} target="_blank">
                                  <img src={v?.attachments} className="replyImg" />
                                </a>
                              ) : (
                                <a href={v?.attachments} target="_blank" className="letterSpacing0">
                                  <div className="sreenshot_number d-flex align-items-center">
                                    <div className="img_formate letterSpacing0">
                                      {v?.attachments[0]?.toUpperCase().split(".").slice(-1)}
                                    </div>
                                    <div className="img_Type letterSpacing0">
                                      {v?.attachments[0]?.split(".")[v?.attachments[0].split(".").length - 2]}
                                    </div>
                                  </div>
                                </a>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
              {ticketDetail?.status != 4 && (
                <div className="close_replyBtn p_20">
                  <div className="row mnx_2">
                    <div className="col-sm-6 px-2 pb-3 pb-sm-0">
                      <div className="closeticketBtn closeBtnHover">
                        <a onClick={updateStatus} className="d-block text-center closeBtnHover letterSpacing0">
                          Close this ticket
                        </a>
                      </div>
                    </div>
                    <div className="col-sm-6 px-2">
                      <div className="newTicketBtn hoverCurser">
                        <a
                          onClick={() => navigate("/support_reply/" + ticketId)}
                          className="d-block text-center letterSpacing0"
                        >
                          {" "}
                          Reply
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-12 col-xl-4 ps-xl-0 glossaryTitleCol">
            <div className="faqCompleted my-md-0 card">
              <div className="needHelp">
                <div className="needHelpHead d-flex align-items-center">
                  <div className="needHelpHeading">
                    <h5 className="mb-0 letterSpacing0">Can not find the answers to your questions? </h5>
                  </div>
                </div>
                <ul className="needHelpList">
                  <li className="helpList d-flex align-items-center">
                    <div className="needHelpIcon">
                      <img src="/images/support/Mail.svg" alt="needHelpIcon" className="img-fluid" />
                    </div>
                    <div className="needHelpHeading">
                      <p className="mb-0 letterSpacing0">
                        Email us at{" "}
                        <a href="mailto:support@mavefi.com" className="letterSpacing0">
                          support@mavefi.com
                        </a>
                      </p>
                    </div>
                  </li>
                  <li className="helpList d-flex align-items-center">
                    <div className="needHelpIcon">
                      <img src="/images/svg-icons/twitter-squared.svg" alt="needHelpIcon" className="img-fluid" />
                    </div>
                    <div className="needHelpHeading">
                      <p className="mb-0 letterSpacing0">
                        or Tweet us{" "}
                        <a href="https://twitter.com/joinmavefi" target="_blank" className="letterSpacing0">
                          @MaveFiSupport
                        </a>
                      </p>
                    </div>
                  </li>
                  <li className="helpList d-flex align-items-center">
                    <div className="needHelpIcon">
                      <img src="/images/support/askquestion.svg" alt="needHelpIcon" className="img-fluid" />
                    </div>
                    <div className="needHelpHeading">
                      <p className="mb-0 letterSpacing0">
                        or Visit{" "}
                        <Link to="/faq" className="letterSpacing0">
                          our Faqs
                        </Link>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              {records?.length < recordsCount && <Loading />}
            </div>
            {/* <div className="close_ticketRedBtn">
              <a onClick={updateStatus} className="d-block">
                <img
                  src="/images/support/cross_red.svg"
                  className="me-1 align-text-top"
                />{" "}
                Close Ticket
              </a>
            </div> */}
          </div>
        </div>
      </div>
      {/* <ToastContainer/> */}
    </div>
  );
}

export default SupportTicketExpand;
