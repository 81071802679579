import { useContext, useState, useEffect } from "react";
import FeedbackService from "../../services/FeedbackService";
import { useFormik, Field } from "formik";
import * as Yup from "yup";
import { FileUploader } from "react-drag-drop-files";
import GlobalContext from "../../context/GlobalContext";
import Loading from "../../shared/Loading";
import moment from "moment";
const fileTypes = ["PDF", "DOC", "DOCX"];
const serv = new FeedbackService();
const ValidateSchema = Yup.object({
  subject: Yup.string(),
  description: Yup.string()
    .min(50, "we need 50 characters to submit the form")
    .required("Description is a required field"),
  attachments: Yup.string(),
  accept: Yup.boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted."),
});

function CareerApplicantPopup({ onClose, userId }) {
  const globalCtx = useContext(GlobalContext);
  const [user, setUser] = globalCtx.user;
  const [success, setSuccess] = useState(false);
  const [successResp, setSuccessResp] = useState(false);
  const [latestDate, setLatestDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [signupObj, setSignupObj] = new useState({
    subject: "",
    description: "",
    attachments: "",
    accept: true,
  });
  useEffect(() => {
    if (user && user.settings && user.settings.terms_of_service !== undefined) {
      setSignupObj({ ...signupObj, accept: user.settings.terms_of_service });
    }
    handleCheckFeedback();
  }, [user]);
  const handleCheckFeedback = async () => {
    let formData = { createdBy: userId };
    try {
      const resp = await serv.checkFeedback(formData);
      if (resp.date) {
        setLatestDate(moment(resp.date).format("Do MMM"));
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onSubmit = async (values) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("subject", values.subject);
    formData.append("description", values.description);
    formData.append("attachments", values.attachments);
    formData.append("createdBy", userId);
    try {
      const resp = await serv.addData(formData);
      if (resp.data.date) {
        setLatestDate(moment(resp.data.date).format("Do MMM"));
      } else {
        setSuccessResp(true);
      }
      setSuccess(true);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: signupObj,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <>
      <div
        className="modal commanModel SignupPopup showBlock"
        id="Signup"
        onClick={() => {
          onClose();
        }}
      >
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center">
            <div className="modal-dialog">
              <div
                className="modal-content"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {/* <!-- Modal Header --> */}
                <div className="modal-header letterSpacing0 feedBackHeading">
                  <h4 className="modal-title letterSpacing0">Feedback</h4>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                      onClose();
                    }}
                  ></button>
                </div>
                {/* <!-- Modal body --> */}
                {latestDate !== "" ? (
                  <div className="modal-body padding20">
                    <div className="connectWalletOuter">
                      <div className="modelSignBtn margintop15">
                        <div className="d-grid success-glossary success-career">
                          <div className="margin0">
                            <img src="/images/svg-icons/green-tick.svg" className="margintop0 marginBottom" />
                          </div>
                          <div>
                            <p className="coinHeading letterSpacing0">Already completed</p>
                            <p className="letterSpacing0 marginBottom15">
                              You have already submitted your weekly feedback. Please come back next week to submit
                              feedback & claim more coins.
                            </p>
                            <span className="feedBackBottomPara letterSpacing0 margin0 btnStyle">
                              Check again on {latestDate}
                            </span>
                          </div>
                          <button
                            onClick={onClose}
                            className="feedback-success-btn margintop28 letterSpacing0 feedbackSubmitBtn borderRadius12"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : !success ? (
                  <div className="modal-body padding20b18">
                    <div className="connectWalletOuter">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="modelFormInput minusMargintop5 modelFormInputFeedback">
                          <label for="signupfname" className="letterSpacing0 feedbackPopupLebel">
                            Subject
                          </label>
                          <div
                            className={
                              "input-group borderRadius12" +
                              (formik.touched.subject && formik.errors.subject ? " border-red" : "")
                            }
                          >
                            <input
                              type="text"
                              className="form-control letterSpacing0 "
                              placeholder="Please enter the subject"
                              name="subject"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.subject}
                            />
                          </div>
                          {formik.touched.subject && formik.errors.subject ? (
                            <div className="formik-errors bg-error">{formik.errors.subject}</div>
                          ) : null}
                        </div>
                        <div className="modelFormInput minusMargintop2">
                          <label for="signupemail" className="letterSpacing0 feedbackPopupLebel">
                            Description
                          </label>
                          <div
                            className={
                              "input-group letterSpacing0 borderRadius12" +
                              (formik.touched.description && formik.errors.description ? " border-red" : "")
                            }
                          >
                            <textarea
                              rows={3}
                              cols={25}
                              className="form-control letterSpacing0"
                              placeholder="Please enter the description"
                              name="description"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.description}
                            />
                            {/* <input
                                                        type="textarea"
                                                        rows="4"
                                                    /> */}
                          </div>
                          {formik.touched.description && formik.errors.description ? (
                            <div className="formik-errors bg-error">{formik.errors.description}</div>
                          ) : null}
                        </div>
                        <div className="modelFormInput margint2zero">
                          <label htmlFor="attachments" className="letterSpacing0 feedbackPopupLebel ">
                            Upload (Optional)
                          </label>
                          <div>
                            <FileUploader
                              handleChange={(file) => {
                                formik.setTouched({
                                  ...formik.touched,
                                  attachments: true,
                                });
                                formik.setFieldValue("attachments", file);
                              }}
                              name="attachments"
                              // types={fileTypes}
                              classes="w-100 margin0 "
                            >
                              <div
                                className={formik.touched.attachments && formik.errors.attachments ? " border-red" : ""}
                              >
                                <div className=" flex w-100 upload-flex redientBackground">
                                  <div className="uploadimg ">
                                    <img src="/images/svg-icons/uploadImgIcon.svg" />
                                  </div>
                                  <div className="letterSpacing0 uploadtext" style={{ width: "184px" }}>
                                    Please click here or drag & drop
                                    <br />
                                    files here to upload them.
                                    {formik.touched.attachments && !formik.errors.attachments ? (
                                      <>
                                        <br />
                                        Current selected file: <br />
                                        {formik.values.attachments.name}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </FileUploader>
                          </div>
                          {formik.touched.attachments && formik.errors.attachments ? (
                            <div className="formik-errors bg-error">{formik.errors.attachments}</div>
                          ) : null}
                        </div>
                        {/* <div className="modelFormInput">
                          <div
                            className={
                              "form-check" +
                              (formik.errors.accept ? " border-red" : "")
                            }
                          >
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="accept"
                                checked={formik.values.accept}
                                onClick={() =>
                                  formik.setFieldValue(
                                    "accept",
                                    !formik.values.accept
                                  )
                                }
                                value={formik.values.accept}
                              />{" "}
                              Accept our{" "}
                              <a href="/legal/terms-of-service" target="_blank">
                                Terms of Service
                              </a>{" "}
                              &{" "}
                              <a href="/legal/privacy-policy" target="_blank">
                                Privacy Policy
                              </a>
                            </label>
                          </div>
                          {formik.errors.accept ? (
                            <div className="formik-errors bg-error">
                              {formik.errors.accept}
                            </div>
                          ) : null}
                        </div> */}
                        <div className="modelSignBtn margintop25">
                          <div className="d-grid">
                            <button
                              type="submit"
                              className={
                                "btn btn-primary btn-block letterSpacing0 borderRadius12" +
                                (Object.keys(formik.errors).length !== 0 ||
                                !formik.touched ||
                                formik.values.description == "" ||
                                isLoading
                                  ? " disabled"
                                  : "")
                              }
                            >
                              Claim 25 COINS {isLoading && <Loading isInline={true} />}
                            </button>
                          </div>
                        </div>
                      </form>
                      <p className="feedbackTipMsg letterSpacing0">Tip: Submit feedback once a week to earn rewards.</p>
                    </div>
                  </div>
                ) : (
                  <div className="modal-body padding20">
                    <div className="connectWalletOuter">
                      <div className="modelSignBtn margintop15">
                        <div className="d-grid success-glossary success-career">
                          <div className="margin0">
                            <img src="/images/svg-icons/green-tick.svg" className="margintop0 marginBottom" />
                          </div>
                          {successResp ? (
                            <div>
                              <p className="coinHeading letterSpacing0">+25 COINS</p>
                              <p className="letterSpacing0 marginBottom15">
                                Your weekly feedback has been received successfully. We thank you for helping us improve
                                our platform.
                              </p>
                              <span className="feedBackBottomPara letterSpacing0 margin0 btnStyle">
                                Do fill this again next week
                              </span>
                            </div>
                          ) : (
                            <div>
                              <p className="coinHeading letterSpacing0">Already completed</p>
                              <p className="letterSpacing0 marginBottom15">
                                You have already submitted your weekly feedback. Please come back next week to submit
                                feedback & claim more coins.
                              </p>
                              <span className="feedBackBottomPara letterSpacing0 margin0 btnStyle">
                                Check again on {latestDate}
                              </span>
                            </div>
                          )}
                          <button
                            onClick={onClose}
                            className="feedback-success-btn margintop28 letterSpacing0 feedbackSubmitBtn borderRadius12"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* <!-- Modal footer --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show" onClick={onClose}></div>
    </>
  );
}

export default CareerApplicantPopup;
