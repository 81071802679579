import { useState, useContext } from "react";
import GlobalContext from "../context/GlobalContext";
import { Link } from "react-router-dom";
import NewsSubscriptionService from "../services/NewsSubscriptionService";
import { useFormik } from "formik";
import * as Yup from "yup";

const serv = new NewsSubscriptionService();
const ValidateSchema = Yup.object({
  email: Yup.string().required("Email is a required field").email(),
  name: Yup.string().required("Name is a required field"),
});
function Footer() {
  const globalCtx = useContext(GlobalContext);
  const [setting, setSetting] = globalCtx.setting;
  const [policies, setPolicies] = globalCtx.policies;
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const [newsletter, setNewsLetter] = useState({
    email: "",
    name: "",
  });
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  async function onSubmit(values) {
    try {
      let obj = { ...values };
      const respData = await serv.addEmail(obj);
      console.log("data", respData);
      if (respData.data) {
        setNewsLetter({ name: "", email: "" });
        setSuccess(true);
      } else {
        setErrMsg(respData.err[0]);
      }
    } catch (error) {
      error = JSON.parse(error.message);
      console.log("error", error, error.err[0]);
      setErrMsg(error.err[0]);
    }
  }
  const handleKeypress = (e) => {
    // e.preventDefault()
    // if (e.keyCode === 13) {
    //   submitEmail();
    // }
  };
  const formik = useFormik({
    initialValues: newsletter,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <footer className="footer_sec w-100 clearfix">
      {!isAuthentiCated && (
        <div className="first_footer">
          {!success && errMsg == "" ? (
            <div className="firstFooterRow firstFooterRowCustom">
              <div className="firstFooterCol firstFooterflex">
                <div className="newsletterIcon">
                  <img src="/images/svg-icons/newsletterIcon.svg" alt="img" />
                </div>
                <div className="subscribe_left  subscribe_left-custom">
                  <h1>Subscribe to our Newsletter</h1>
                  <p>
                    To be the first to hear about the latest news and developments from MaveFi and the world of Web3.
                  </p>
                </div>
              </div>
              <div className="firstFooterCol firstFooterSubscribe_">
                <div className="subscribe_form">
                  <form id="subscribeForm subscribeFormCustom" onSubmit={formik.handleSubmit}>
                    <div className="input-group">
                      <div>
                        <input
                          className={"form-control" + (formik.touched.name && formik.errors.name ? " border-red" : "")}
                          id="newsletterInput1"
                          style={{ width: "250px", height: "45px" }}
                          placeholder="Enter your Full Name"
                          name="name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className="formik-errors bg-error">{formik.errors.name}</div>
                        ) : null}
                      </div>
                      <div>
                        <input
                          style={{ borderRadius: "0px" }}
                          id="newsletterInput2"
                          className={
                            "form-control" + (formik.touched.email && formik.errors.email ? " border-red" : "")
                          }
                          placeholder="Enter your Email Address"
                          name="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="formik-errors bg-error">{formik.errors.email}</div>
                        ) : null}
                      </div>
                      <button
                        style={{
                          height: "44px",
                          borderRadius: "0 12px 12px 0",
                        }}
                        className="btn"
                        type="submit"
                      >
                        SUBSCRIBE
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : errMsg == "" ? (
            <div className="firstFooterRow customFirstFooterRow">
              <img src="/images/svg-icons/handshake.svg" width={60} height={60} />
              <p>THANK YOU FOR SUBSCRIBING TO OUR NEWSLETTER.</p>
            </div>
          ) : (
            <div className="firstFooterRow customFirstFooterRow">
              <img src="/images/svg-icons/handshake.svg" width={60} height={60} />
              <p>{errMsg}</p>
            </div>
          )}
        </div>
      )}
      <div className="footer_bottom">
        <div className="main_foot footer_list">
          <div className="row footerRow">
            <div className="col-12 col-sm-12 col-md-4  footerCol">
              <div className="footer_main_text">
                <div className="footer_logo">
                  <img
                    src={setting?.brand?.footer_logo || "/images/icons/footer-logo.png"}
                    className="img-fluid"
                    style={{ width: "36px" }}
                  />
                </div>
                <div className="logo_bottom_text">
                  <h4>Welcome to Web3 👋</h4>
                  <p>{setting?.brand?.sort_description}</p>
                </div>
                <div className="copyright_tag">
                  <p>© 2023 MaveFi Inc. All Rights Reserved.</p>
                </div>
              </div>
            </div>
            <div className="col-4 col-sm-4 col-md-3 mt-4 mt-md-0 footerCol">
              <ul className="list-unstyled">
                <li>
                  <p>Company</p>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                {/* <li>
                  <Link to="/careers" className="disabled">
                    Careers
                  </Link>
                </li> */}
                <li>
                  <Link to="/partners">Partners</Link>
                </li>
                <li className="disable">
                  <Link to="/contributors" className="disabled">
                    Contributors
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-4 col-sm-4 col-md-3 mt-4 mt-md-0 footerCol">
              <ul className="list-unstyled">
                <li>
                  <p>Discover</p>
                </li>
                <li>
                  <Link to="/courses">Learn</Link>
                </li>
                <li>
                  <Link to="/glossary">Glossary</Link>
                </li>
                <li>
                  <Link to="/videos" className="">
                    Videos
                  </Link>
                </li>
                <li>
                  <Link to="/" className="disabled">
                    News
                  </Link>
                </li>
                {/* <li>
                  <a href="javascript:void(0)" className="disabled">
                    Profiles
                  </a>
                </li>

                <li>
                  <a href="javascript:void(0)" className="disabled">
                    Rankings
                  </a>
                </li> */}
                {/* <li>
                  <a href="javascript:void(0)" className="disabled">
                    Tools Suite
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="disabled">
                    Earn on web3
                  </a>
                </li> */}
              </ul>
            </div>
            <div className="col-4 col-sm-4 col-md-2 mt-4 mt-md-0 footerCol">
              <ul className="list-unstyled">
                <li>
                  <p>Resources</p>
                </li>
                <li>
                  <a href="javascript:void(0)" className="disabled">
                    Docs
                  </a>
                </li>
                <li>
                  <Link to="/blogs">Blog</Link>
                </li>
                {/* <li>
                  <a href="javascript:void(0)" className="disabled">
                    Notion
                  </a>
                </li> */}
                <li>
                  <a href="javascript:void(0)" className="disabled">
                    Newsletter
                  </a>
                </li>
                <li>
                  <Link to="/faq">Faq</Link>
                </li>
                <li>
                  <Link to="/support">Support</Link>
                </li>
              </ul>
            </div>
            <div className="col-4 col-sm-4 col-md-3 mt-4 mt-xl-0 footerCol">
              <ul className="list-unstyled">
                <li>
                  <p>Legal</p>
                </li>
                {policies.map((item) => {
                  return (
                    <li>
                      <Link to={`/legal/${item._id}`}>{item.title}</Link>
                    </li>
                  );
                })}
                {/* <li>
                  <Link to="/legal/risks">Risks</Link>
                </li> */}
                {/* <li>
                  <Link to="/legal/disclaimer">Disclaimer</Link>
                </li>
                <li>
                  <Link to="/legal/trademarks">Trademarks</Link>
                </li>
                <li>
                  <Link to="/legal/cookie-policy">Cookie Policy</Link>
                </li>
                <li>
                  <Link to="/legal/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/legal/terms-of-service">Terms of Service</Link>
                </li> */}
              </ul>
            </div>
            <div className="col-8 col-sm-8 col-md-4 mt-4 mt-xl-0 footerCol">
              <div className="social_link">
                <div className="social_heading">
                  <p>Social Links</p>
                </div>
                <div className="foot_social_icon">
                  {setting?.brand?.social_links?.twitter?.is_active && (
                    <a
                      href={setting?.brand?.social_links?.twitter?.text || "https://twitter.com/joinMaveFi"}
                      target="_blank"
                    >
                      {" "}
                      <img src="/images/svg-icons/twt.svg" className="img-fluid" />
                    </a>
                  )}
                  {setting?.brand?.social_links?.discord?.is_active && (
                    <a
                      href={setting?.brand?.social_links?.discord?.text || "https://discord.com/invite/GapqAvGF"}
                      target="_blank"
                    >
                      <img src="/images/svg-icons/discord.svg" className="img-fluid" />
                    </a>
                  )}
                  {setting?.brand?.social_links?.instagram?.is_active && (
                    <a
                      href={setting?.brand?.social_links?.instagram?.text || "https://www.instagram.com/joinmavefi/"}
                      target="_blank"
                    >
                      <img src="/images/svg-icons/insta.svg" className="img-fluid" />
                    </a>
                  )}
                  {setting?.brand?.social_links?.facebook?.is_active && (
                    <a
                      href={setting?.brand?.social_links?.facebook?.text || "https://www.facebook.com/joinMaveFi/"}
                      target="_blank"
                    >
                      {" "}
                      <img src="/images/svg-icons/facebook.svg" className="img-fluid" />
                    </a>
                  )}
                  {setting?.brand?.social_links?.youtube?.is_active && (
                    <a
                      href={setting?.brand?.social_links?.youtube?.text || "https://www.youtube.com/@joinmavefi"}
                      target="_blank"
                    >
                      <img src="/images/svg-icons/youtubenew.svg" className="img-fluid" />
                    </a>
                  )}
                  {setting?.brand?.social_links?.linkedin?.is_active && (
                    <a
                      href={
                        setting?.brand?.social_links?.linkedin?.text || "https://www.linkedin.com/company/joinmavefi"
                      }
                      target="_blank"
                    >
                      <img src="/images/svg-icons/linkedin.svg" className="img-fluid" />
                    </a>
                  )}
                </div>
                {/* <div className="social_heading d-block d-md-none mt-4">
                  <p>Quick Settings</p>
                </div>
                <div className="footerSelect">
                  <div className="lang_select">
                    <div className="img-select">
                      <span
                        className="img-option this-logo"
                        style={{
                          background:
                            "url(images/svg-icons/language-skill.svg) no-repeat",
                        }}
                      ></span>
                      <select>
                        <option value="English">English</option>
                      </select>
                    </div>
                  </div>
                  <div className="system_select">
                    <div className="img-select">
                      <span
                        className="img-option this-logo"
                        style={{
                          background:
                            "url(images/svg-icons/computer.svg) no-repeat",
                        }}
                      ></span>
                      <select>
                        <option value="System">Light Mode</option>
                      </select>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
