import { useState, useContext } from "react";
import UserService from "../../services/UserService";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loading from "../../shared/Loading";

const serv = new UserService();
const ValidateSchema = Yup.object({
  email: Yup.string().required("Email is a required field").email(),
});
function ForgotPassword({ onClose, onFinish }) {
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [obj, setObj] = new useState({
    email: "",
  });

  const onSubmit = async (values) => {
    setIsLoading(true);
    let obj = { ...values };
    try {
      const resp = await serv.forgotPassword(obj);
      if (resp?.result) {
        setIsLoading(false);
        onFinish(obj);
      } else {
        setIsLoading(false);
        setErrorMsg(resp.err);
      }
    } catch (err) {
      setIsLoading(false);
      err = JSON.parse(err);
      setErrorMsg(err.err);
    }
  };

  const formik = useFormik({
    initialValues: obj,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  return (
    <>
      <div
        className="modal commanModel SignupPopup showBlock"
        id="forgotPassword"
        onClick={() => {
          onClose();
        }}
      >
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center">
            <div className="modal-dialog">
              <div
                className="modal-content"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={{ height: "358px" }}
              >
                {/* <!-- Modal Header --> */}
                <div className="modal-header">
                  <h4 className="modal-title">Forgot Password?</h4>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                      onClose();
                    }}
                  ></button>
                </div>
                {/* <!-- Modal body --> */}
                <div className="modal-body">
                  <div className="connectWalletOuter">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="modelFormInput" style={{ marginBottom: "95px" }}>
                        <label for="signinemail">Email address</label>
                        <div
                          className={"input-group" + (formik.touched.email && formik.errors.email ? " border-red" : "")}
                        >
                          <span className="input-group-text pe-0">
                            <img src="/images/svg-icons/email-gray-icon.svg" />
                          </span>
                          <input
                            className="form-control"
                            placeholder="john@mavefi.com"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                        </div>
                        {formik.touched.email && formik.errors.email ? (
                          <div className="formik-errors bg-error">{formik.errors.email}</div>
                        ) : null}
                      </div>
                      {errorMsg && <div className="formik-errors bg-error text-center">{errorMsg}</div>}
                      <div className="modelSignBtn modelSignBtn-custom">
                        <div className="d-grid">
                          <button
                            type="submit"
                            className={
                              "btn btn-primary btn-block" +
                              (Object.keys(formik.touched).length == 0 ||
                              Object.keys(formik.errors).length !== 0 ||
                              !formik.touched ||
                              isLoading
                                ? " disabled"
                                : "")
                            }
                          >
                            Send OTP {isLoading && <Loading isInline={true} />}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
}

export default ForgotPassword;
