import { useState, useContext, useEffect } from "react";
function NewsletterSubscriptionSuccess({ onClose }) {
  return (
    <>
      <div className="modal commanModel testPassedPopup showBlock" id="testPassedPopup">
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="modalContant">
                    <div className="modelTopBox">
                      {/* <div className="modelSubHeading">
                        <span>REWARDS RECEIVED</span>
                      </div> */}
                      {/* <div className="modelHeading">
                        <h2>+ MAVE COINS</h2>
                      </div> */}
                      <div className="modelSpinningIcon">
                        <div className="spinnerIcon">
                          <img src="/images/svg-icons/circle-logo.svg" alt="circleLogo" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                    <div className="modelMiddleContant">
                      <h4 className="mb-0">Congratulations!</h4>
                      <p className="mb-0">Your subscribed email for newsletter is verified</p>
                    </div>
                    <div className="modelBtn d-grid">
                      <a type="button" className="btn btn-block closeBtn" onClick={onClose}>
                        Close
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
}

export default NewsletterSubscriptionSuccess;
