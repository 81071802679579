import { useContext, useEffect, useState } from "react";
import AccountService from "../../services/AccountService";
import { useFormik } from "formik";
import { GlobalContext } from "../../context/GlobalContext";
import * as Yup from "yup";
import moment from "moment";
import Loading from "../../shared/Loading";
const serv = new AccountService();
const ValidateSchema = Yup.object({
  logo: Yup.string(),
  school_name: Yup.string().required("School Name is a required field"),
  degree: Yup.string().required("Degree is a required field"),
  study_field: Yup.string().required("field of study is a required field"),
  start_month: Yup.string(),
  start_year: Yup.string().required("Start year is a required field"),
  end_month: Yup.string(),
  end_year: Yup.string().required("End year is a required field"),
});
let month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
let currentYear = new Date().getFullYear() + 5;
let years = [];
while (1980 <= currentYear) {
  years.push(currentYear--);
}
function Education({ onClose, educationId }) {
  const globalCtx = useContext(GlobalContext);
  const [user, setUser] = globalCtx.user;
  const [education, setEducation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [accountObj, setAccountObj] = new useState({
    logo: "",
    school_name: "",
    degree: "",
    study_field: "",
    start_month: "",
    start_year: "",
    end_month: "",
    end_year: "",
  });
  useEffect(() => {
    getAccountDetails();
  }, []);
  const getAccountDetails = async () => {
    let id = user._id;

    try {
      let resp = await serv.getUser(id);
      if (resp.data.education) {
        setEducation(resp.data.education);
        if (educationId !== "") {
          resp.data.education.map((item) => {
            if (item._id == educationId) {
              let new_obj = {
                logo: item.logo,
                school_name: item.school_name,
                degree: item.degree,
                study_field: item.study_field,
                start_month: item.start_month,
                start_year: item.start_year,
                end_month: item.end_month,
                end_year: item.end_year,
              };
              setAccountObj(new_obj);
            }
          });
        }
        // setUser({ ...user, ...new_obj });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    let id = user._id;
    const formData = new FormData();
    let experienceArr;
    if (educationId !== "") {
      formData.append("educationId", educationId);
      experienceArr = education.map((item) => {
        if (educationId == item._id) {
          item = {
            school_name: values.school_name,
            degree: values.degree,
            study_field: values.study_field,
            start_month: values.start_month,
            start_year: values.start_year,
            end_month: values.end_month,
            end_year: values.end_year,
            logo: values.logo,
            _id: educationId,
          };
        }
        return item;
      });
    } else {
      experienceArr = [
        ...education,
        {
          school_name: values.school_name,
          degree: values.degree,
          study_field: values.study_field,
          start_month: values.start_month,
          start_year: values.start_year,
          end_month: values.end_month,
          end_year: values.end_year,
          logo: values.logo,
        },
      ];
    }
    formData.append("education", JSON.stringify(experienceArr));
    if (values.logo) {
      formData.append("logo", values.logo);
    }
    formData.append("_id", id);
    formData.append("email", user.email);
    try {
      const resp = await serv.editUser(formData);
      setIsLoading(false);
      resetForm();
      getAccountDetails();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };
  const handleDelete = async () => {
    const formData = new FormData();
    let experienceArr = education.filter((item) => item._id !== educationId);

    formData.append("education", JSON.stringify(experienceArr));
    formData.append("_id", user._id);
    formData.append("email", user.email);
    try {
      const resp = await serv.editUser(formData);
      getAccountDetails();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };
  const formik = useFormik({
    initialValues: accountObj,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <>
      <div
        className="modal editModal showBlock"
        id="workExperience"
        onClick={(e) => {
          if (e.target == e.currentTarget) {
            onClose();
          }
        }}
      >
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center">
            <div className="modal-dialog add_experienceModal">
              <div className="modal-content">
                <div className="modal-header align-items-baseline">
                  <h4 className="modal-title"> Add Educations</h4>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => onClose()} />
                </div>
                {/* Modal body */}
                <div className="modal-body p-0">
                  <div className="profilemodal_contet">
                    <div className="requestKyc_form mt-0">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="row editformgrid">
                          <div className="col-12 editformcol">
                            <div className="commonfield d-flex justify-content-between align-items-center">
                              <label>Upload Logo</label>
                              <input
                                type="file"
                                className="form-control"
                                id="uploadlogoFile"
                                hidden
                                onChange={(event) => {
                                  formik.setFieldValue("logo", event.currentTarget.files[0]);
                                }}
                              />
                              <label for="uploadlogoFile" className="upload_Logo" id="UploaderLogodiv">
                                {/* <input type="file" className="form-control" id="uploadlogoFile" placeholder="Date of birth" hidden /> */}
                                {formik.values.logo ? (
                                  typeof formik.values.logo == "string" ? (
                                    <img
                                      src={formik.values.logo}
                                      style={{
                                        height: "80px",
                                        width: "auto",
                                        borderRadius: "100px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={URL.createObjectURL(formik.values.logo)}
                                      style={{
                                        height: "80px",
                                        width: "auto",
                                        borderRadius: "100px",
                                      }}
                                    />
                                  )
                                ) : (
                                  <img src="images/svg-icons/addcamera.svg" />
                                )}
                              </label>
                            </div>
                          </div>
                          <div className="col-12 editformcol">
                            <div className="commonfield customBottomMargin">
                              <label htmlFor="fullname">Enter School Name</label>
                              {/* <input type="text" className="form-control" id="fullname" placeholder="Company name" /> */}
                              <input
                                type="text"
                                className={
                                  "form-control" +
                                  (formik.touched.school_name && formik.errors.school_name ? " border-red" : "")
                                }
                                placeholder="School Name"
                                name="school_name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.school_name}
                              />
                              {formik.touched.school_name && formik.errors.school_name ? (
                                <div className="formik-errors bg-error">{formik.errors.school_name}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12 editformcol">
                            <div className="commonfield customBottomMargin">
                              <div className="d-flex justify-content-between">
                                <label htmlFor="fullname">Degree</label>
                                {/* <span className="character_count">
                                  {formik.values.description.length}/40
                                </span> */}
                              </div>
                              {/* <input type="text" className="form-control" id="fullname" placeholder="One line description" /> */}
                              <input
                                type="text"
                                className={
                                  "form-control" + (formik.touched.degree && formik.errors.degree ? " border-red" : "")
                                }
                                placeholder="Ex. Bachelor's"
                                name="degree"
                                // maxLength={40}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.degree}
                              />
                              {formik.touched.degree && formik.errors.degree ? (
                                <div className="formik-errors bg-error">{formik.errors.degree}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12 editformcol">
                            <div className="commonfield customBottomMargin">
                              <label htmlFor="fullname">Field of Study</label>
                              {/* <input type="text" className="form-control" id="fullname" placeholder="Position" /> */}
                              <input
                                type="text"
                                className={
                                  "form-control" +
                                  (formik.touched.study_field && formik.errors.study_field ? " border-red" : "")
                                }
                                placeholder="Ex. Business"
                                name="study_field"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.study_field}
                              />
                              {formik.touched.study_field && formik.errors.study_field ? (
                                <div className="formik-errors bg-error">{formik.errors.study_field}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12 editformcol">
                            <div className="commonfield mb-2 commonfieldCustom">
                              <label htmlFor="fullname">Start Date</label>
                              <div className="input_group position-relative input-m-year">
                                {/* <input type="text" className="form-control" id="fullname" placeholder={0} /> */}
                                <div className="editprof_select img-select">
                                  <select
                                    className={
                                      "form-control" +
                                      (formik.touched.start_month && formik.errors.start_month ? " border-red" : "")
                                    }
                                    name="start_month"
                                    onChange={formik.handleChange}
                                    value={formik.values.start_month}
                                  >
                                    <option>Select Month</option>
                                    {month.map((item) => {
                                      return <option value={item}>{item}</option>;
                                    })}
                                  </select>
                                  {formik.touched.start_month && formik.errors.start_month ? (
                                    <div className="formik-errors bg-error">{formik.errors.start_month}</div>
                                  ) : null}
                                </div>
                                <div className="editprof_select img-select">
                                  <select
                                    className={
                                      "form-control" +
                                      (formik.touched.start_year && formik.errors.start_year ? " border-red" : "")
                                    }
                                    name="start_year"
                                    onChange={formik.handleChange}
                                    value={formik.values.start_year}
                                  >
                                    <option>Select Year</option>
                                    {years.map((item) => {
                                      return <option value={item}>{item}</option>;
                                    })}
                                  </select>
                                  {formik.touched.start_year && formik.errors.start_year ? (
                                    <div className="formik-errors bg-error">{formik.errors.start_year}</div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 editformcol">
                            <div className="commonfield mb-2 commonfieldCustom">
                              <label htmlFor="fullname">End Date (or expected)</label>
                              <div className="input_group position-relative input-m-year">
                                {/* <input type="text" className="form-control" id="fullname" placeholder={0} /> */}
                                <div className="editprof_select img-select">
                                  <select
                                    className={
                                      "form-control" +
                                      (formik.touched.end_month && formik.errors.end_month ? " border-red" : "")
                                    }
                                    name="end_month"
                                    onChange={formik.handleChange}
                                    value={formik.values.end_month}
                                  >
                                    <option>Select Month</option>
                                    {month.map((item) => {
                                      return <option value={item}>{item}</option>;
                                    })}
                                  </select>
                                  {formik.touched.end_month && formik.errors.end_month ? (
                                    <div className="formik-errors bg-error">{formik.errors.end_month}</div>
                                  ) : null}
                                </div>
                                <div className="editprof_select img-select">
                                  <select
                                    className={
                                      "form-control" +
                                      (formik.touched.end_year && formik.errors.end_year ? " border-red" : "")
                                    }
                                    name="end_year"
                                    onChange={formik.handleChange}
                                    value={formik.values.end_year}
                                  >
                                    <option>Select Year</option>
                                    {years.map((item) => {
                                      return <option value={item}>{item}</option>;
                                    })}
                                  </select>
                                  {formik.touched.end_year && formik.errors.end_year ? (
                                    <div className="formik-errors bg-error">{formik.errors.end_year}</div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 editformcol">
                            <div className="Profileclear_saveBtn d-flex profileclearCustomsmall">
                              <div className="Profileclear_Btn">
                                <button
                                  type="reset"
                                  onClick={handleDelete}
                                  className="btn btn-block"
                                  style={{ padding: 0 }}
                                >
                                  <img src="images/svg-icons/delete_img.svg" />
                                </button>
                              </div>
                              <div className="modelSignBtn editprofile_save mt-0 w-100">
                                <button
                                  type="submit"
                                  className={
                                    "btn btn-primary btn-block w-100" +
                                    (!(formik.isValid && formik.dirty) || isLoading ? " disabled" : "")
                                  }
                                >
                                  Save {isLoading && <Loading isInline={true} />}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
}

export default Education;
