import { useContext, useState, useEffect } from "react";
import GlossaryService from "../../services/GlossaryService";
import { useFormik, Field } from "formik";
import * as Yup from "yup";
import util from "../../util/util";
import GlobalContext from "../../context/GlobalContext";
import Loading from "../../shared/Loading";

const serv = new GlossaryService();
const ValidateSchema = Yup.object({
  word: Yup.string().required("word is a required field"),
  definition: Yup.string().required("definition is a required field"),
  accept: Yup.boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted."),
});

function GlossaryPopup({ onClose }) {
  const globalCtx = useContext(GlobalContext);
  const [user, setUser] = globalCtx.user;
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [signupObj, setSignupObj] = new useState({
    word: "",
    definition: "",
    accept: true,
  });
  useEffect(() => {
    if (user && user.settings && user.settings.terms_of_service !== undefined) {
      setSignupObj({ ...signupObj, accept: user.settings.terms_of_service });
    }
  }, [user]);
  const onSubmit = async (values) => {
    setIsLoading(true)
    let user = util.getUserLocal();
    let obj = { ...values };
    obj.createdBy = user._id;
    obj.is_active = false;
    obj.createdByAdmin = false;
    obj.approved = false;
    try {
      const resp = await serv.addGlossary(obj);
      if (resp) {
        // onClose();
        setSuccess(true);
      }
      setIsLoading(false)
    } catch (err) { }
  };
  const formik = useFormik({
    initialValues: signupObj,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  return (
    <>
      <div
        className="modal commanModel SignupPopup showBlock"
        id="Signup"
        onClick={() => {
          onClose();
        }}
      >
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center">
            <div className="modal-dialog">
              <div
                className="modal-content"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {/* <!-- Modal Header --> */}
                <div className="modal-header">
                  <h4 className="modal-title">Submit a Word/Slang</h4>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                      onClose();
                    }}
                  ></button>
                </div>
                {/* <!-- Modal body --> */}
                {!success ? (
                  <div className="modal-body">
                    <div className="connectWalletOuter">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="modelFormInput">
                          <label for="signupfname">Word/slang</label>
                          <div
                            className={
                              "input-group" +
                              (formik.touched.word && formik.errors.word
                                ? " border-red"
                                : "")
                            }
                          >
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter word here"
                              name="word"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.word}
                            />
                          </div>
                          {formik.touched.word && formik.errors.word ? (
                            <div className="formik-errors bg-error">
                              {formik.errors.word}
                            </div>
                          ) : null}
                        </div>
                        <div className="modelFormInput">
                          <label for="signupemail">Description/Meaning</label>
                          <div
                            className={
                              "input-group" +
                              (formik.touched.definition &&
                                formik.errors.definition
                                ? " border-red"
                                : "")
                            }
                          >
                            <textarea
                              rows={4}
                              cols={25}
                              className="form-control"
                              placeholder="Enter the description here"
                              name="definition"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.definition}
                            />
                            {/* <input
                                                        type="textarea"
                                                        rows="4"
                                                    /> */}
                          </div>
                          {formik.touched.definition &&
                            formik.errors.definition ? (
                            <div className="formik-errors bg-error">
                              {formik.errors.definition}
                            </div>
                          ) : null}
                        </div>
                        <div className="modelFormInput">
                          <div
                            className={
                              "form-check" +
                              (formik.errors.accept ? " border-red" : "")
                            }
                          >
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="accept"
                                checked={formik.values.accept}
                                onClick={() =>
                                  formik.setFieldValue(
                                    "accept",
                                    !formik.values.accept
                                  )
                                }
                                value={formik.values.accept}
                              />{" "}
                              Accept our{" "}
                              <a href="/legal/terms-of-service" target="_blank">
                                Terms of Service
                              </a>{" "}
                              &{" "}
                              <a href="/legal/privacy-policy" target="_blank">
                                Privacy Policy
                              </a>
                            </label>
                          </div>
                          {formik.errors.accept ? (
                            <div className="formik-errors bg-error">
                              {formik.errors.accept}
                            </div>
                          ) : null}
                        </div>
                        <div className="modelSignBtn">
                          <div className="d-grid">
                            <button
                              type="submit"
                              className={
                                "btn btn-primary btn-block" +
                                (Object.keys(formik.errors).length !== 0 ||
                                  Object.keys(formik.touched).length === 0 || isLoading
                                  ? " disabled"
                                  : "")
                              }
                            >
                              Submit{isLoading && <Loading isInline={true} />}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                ) : (
                  <div className="modal-body">
                    <div className="connectWalletOuter">
                      <div className="modelSignBtn">
                        <div className="d-grid success-glossary">
                          <div>
                            <img src="/images/svg-icons/green-tick.svg" />
                          </div>
                          <div>
                            <p>
                              Your submission has been received successfully. We thank you for helping us grow this library.
                            </p>
                          </div>
                          <button
                            onClick={onClose}
                          // className={
                          //   "btn"
                          // }
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* <!-- Modal footer --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
}

export default GlossaryPopup;
