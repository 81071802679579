import util from "../util/util";
export default class UserService {
  signup(signupObj) {
    return util
      .sendApiRequest("/user", "POST", true, signupObj)
      .then(
        (response) => {
          if (!response.error) {
            return response;
          } else {
            return response;
          }
        },
        (error) => {
          throw new Error(error);
        }
      )
      .catch((e) => {
        throw e;
      });
  }
  sendOtp(obj) {
    return util
      .sendApiRequest("/user/sendotp", "POST", true, obj)
      .then(
        (response) => {
          return response;
        },
        (error) => {
          throw new Error(error);
        }
      )
      .catch((e) => {
        throw e;
      });
  }
  sendLoginOtp(email) {
    return util
      .sendApiRequest("/user/login/otp", "POST", true, { email: email })
      .then(
        (response) => {
          return response;
        },
        (error) => {
          throw new Error(error);
        }
      )
      .catch((e) => {
        throw e;
      });
  }
  verifyUser(user_name) {
    return util
      .sendApiRequest("/user/verifyusername", "POST", true, {
        user_name: user_name,
      })
      .then(
        (response) => {
          return response;
        },
        (error) => {
          throw new Error(error);
        }
      )
      .catch((e) => {
        throw e;
      });
  }
  userSuggestion(user_name) {
    return util
      .sendApiRequest("/user/getsuggestion", "POST", true, {
        user_name: user_name,
      })
      .then(
        (response) => {
          return response;
        },
        (error) => {
          throw new Error(error);
        }
      )
      .catch((e) => {
        throw e;
      });
  }

  login(loginObj) {
    return util
      .sendApiRequest("/user/login", "POST", true, loginObj)
      .then(
        (response) => {
          if (!response.error) {
            return response;
          } else {
            return response;
          }
        },
        (error) => {
          throw new Error(error);
        }
      )
      .catch((e) => {
        throw e;
      });
  }
  socialLoginSignUp(data) {
    return util
      .sendApiRequest("/user/sociallogin", "POST", true, data)
      .then(
        (response) => {
          if (!response.error) {
            return response;
          } else {
            return response;
          }
        },
        (error) => {
          throw new Error(error);
        }
      )
      .catch((e) => {
        throw e;
      });
  }
  verifyLoginOtp(loginObj) {
    return util
      .sendApiRequest("/user/login/verifyotp", "POST", true, loginObj)
      .then(
        (response) => {
          if (!response.error) {
            return response;
          } else {
            return response;
          }
        },
        (error) => {
          throw new Error(error);
        }
      )
      .catch((e) => {
        throw e;
      });
  }
  verifyForgotPasswordOtp(loginObj) {
    return util
      .sendApiRequest("/user/verifyotp", "POST", true, loginObj)
      .then(
        (response) => {
          if (!response.error) {
            return response;
          } else {
            return response;
          }
        },
        (error) => {
          throw new Error(error);
        }
      )
      .catch((e) => {
        throw e;
      });
  }

  verifyBetaPassword(password) {
    return util
      .sendApiRequest("/user/verifybetapassword", "POST", true, password)
      .then(
        (response) => {
          if (!response.error) {
            return response;
          } else {
            return response;
          }
        },
        (error) => {
          throw new Error(error);
        }
      )
      .catch((e) => {
        throw e;
      });
  }

  async forgotPassword(forgotpassword) {
    try {
      return await util.sendApiRequest("/forgot", "POST", true, forgotpassword);
    } catch (err) {
      throw err;
    }
  }

  resetPassword(payload) {
    return util
      .sendApiRequest("/reset", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        throw e;
      });
  }

  changePassword(payload) {
    return util
      .sendApiRequest("/changepassword", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        throw e;
      });
  }

  userListApi(data) {
    const activity = Object.keys(data).reduce((object, key) => {
      if (data[key] !== "") {
        object[key] = data[key];
      }
      return object;
    }, {});

    return util.sendApiRequest("/list", "POST", true, activity).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }

  deleteUserRecordApi(dataId) {
    return util.sendApiRequest("/" + dataId, "DELETE", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  getUser(dataId) {
    return util
      .sendApiRequest("/" + dataId, "GET", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  getResetUser(token) {
    return util
      .sendApiRequest("/reset/" + token, "GET", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  edit(payload) {
    return util.sendApiRequest("/", "PUT", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  updateSetting(payload) {
    return util.sendApiRequest("/updatesetting", "POST", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }

  // Get csvdata
  getCsvData(searchObj) {
    const body = Object.keys(searchObj).reduce((object, key) => {
      if (searchObj[key] !== "") {
        object[key] = searchObj[key];
      }
      return object;
    }, {});
    return util.sendApiRequest("/csvData", "POST", true, body).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  listAllSearchData(data) {
    const activity = Object.keys(data).reduce((object, key) => {
      if (data[key] !== "") {
        object[key] = data[key];
      }
      return object;
    }, {});

    return util.sendApiRequest("/getsearchdata", "POST", true, activity).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }

  userList(data) {
    const activity = Object.keys(data).reduce((object, key) => {
      if (data[key] !== "") {
        object[key] = data[key];
      }
      return object;
    }, {});

    return util.sendApiRequest("/list", "POST", true, activity).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  loginTimer(payload) {
    return util.sendApiRequest("/logintimer", "POST", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  saveSearchKeyword(payload) {
    return util.sendApiRequest("/searchkeyword", "POST", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
}
