import { useState } from "react";

function GlossaryCard({ glossary }) {
  const [flip, setFlip] = useState(false);
  return (
    <div className="col-sm-6 col-lg-6 col-xl-3 col-xxl-2 cardsBox pb-md-4 pb-3">
      <div className="mainUpBox">
        <div className={"mainUpdatesBox" + (flip ? " flipped" : "")}>
          <div className="updates_card glossaryCard front">
            <div className="updates_box">
              <div className="box_heading d-flex justify-content-start">
                <h4 className="m-0">{glossary.word}</h4>
              </div>
              <div className="box_content text-left">
                <p className="m-0">
                  {glossary.definition.substring(0, 162) + " "}
                  {glossary.definition.length > 162 && (
                    <span
                      onClick={() => {
                        setFlip(!flip);
                      }}
                      className="showMore"
                    >
                      show more
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="updates_card glossaryCard back">
            <div className="updates_box updatesBoxPara">
              <a
                href="javascript:void(0);"
                className="closeIcon"
                onClick={() => {
                  setFlip(!flip);
                }}
              >
                <img
                  src="/images/svg-icons/close.svg"
                  alt="close"
                  className="img-fluid"
                />
              </a>
              <div className="box_content text-left">
                <p className="m-0">{glossary.definition}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GlossaryCard;
