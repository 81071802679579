function Loading({ isSmall, isMedium, isAdaptive, isInline = false }) {
  return isInline ? (
    <img className="loading-img" src="/images/icons/loading.gif" />
  ) : (
    <div
      className={
        isSmall
          ? "loading-container small"
          : isMedium
          ? "loading-container medium"
          : isAdaptive
          ? "loading-container adaptive"
          : "loading-container"
      }
    >
      <img className="loading-img" src="/images/icons/loading.gif" />
      <span className="loading">Loading</span>
    </div>
  );
}

export default Loading;
