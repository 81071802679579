import util from "../util/util";

export default class UserCourseService {
  addUserCourse(payload) {
    return util
      .sendApiRequest("/usercourse", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  editUserCourse(payload) {
    return util
      .sendApiRequest("/usercourse", "PUT", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
}
