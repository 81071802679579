import util from "../util/util";

export default class CourseService {
  updateViewCount(payload) {
    return util
      .sendApiRequest("/course/updateviewcount", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  updateUserEnrolled(payload) {
    return util
      .sendApiRequest("/course/updateuserenrolled", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  async listAll(data, user_id) {
    try {
      const course = Object.keys(data).reduce((object, key) => {
        if (data[key] !== "") {
          object[key] = data[key];
        }
        return object;
      }, {});
      if (course.filter.status && course.filter.status.length > 0) {
        course.user_id = user_id;
      }
      return await util.sendApiRequest("/course/list", "POST", true, course);
    } catch (err) {
      throw err;
    }
  }
  async rewardedCourse(data, user_id) {
    try {
      const course = Object.keys(data).reduce((object, key) => {
        if (data[key] !== "") {
          object[key] = data[key];
        }
        return object;
      }, {});
      // if (course.filter.status && course.filter.status.length > 0) {
      //   course.user_id = user_id;
      // }
      return await util.sendApiRequest("/course/rewardedCourse", "POST", true, course);
    } catch (err) {
      throw err;
    }
  }
  async status(data) {
    try {
      return await util.sendApiRequest("/course/status", "POST", true, data);
    } catch (err) {
      throw err;
    }
  }

  async getDetails(courseId) {
    try {
      return await util.sendApiRequest("/course/" + courseId, "GET", true);
    } catch (err) {
      throw err;
    }
  }

  async getFilterList() {
    try {
      return await util.sendApiRequest("/course/filterlist", "GET", true);
    } catch (err) {
      throw err;
    }
  }

  async getCourse(courseId) {
    try {
      if (util.getUserLocal()) {
        return await util.sendApiRequest("/course/usercourse/" + courseId, "GET", true);
      } else {
        return await util.sendApiRequest("/course/" + courseId, "GET", true);
      }
    } catch (err) {
      // throw err;
      return err;
    }
  }
  updateShareLog(payload) {
    return util
      .sendApiRequest("/course/updatesharelog", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
}
