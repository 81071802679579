import moment from "moment";
import { useEffect, useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useParams, useNavigate } from "react-router-dom";
import VideoService from "../../services/VideoService";
import NewsSubscriptionService from "../../services/NewsSubscriptionService";
import GlobalContext from "../../context/GlobalContext";
import Loading from "../../shared/Loading";
import SocialShare from "../../shared/SocialShare";
import { Helmet } from "react-helmet";
let nfObject = new Intl.NumberFormat("en-US");
const serv = new VideoService();
const subsServ = new NewsSubscriptionService();

function VideoDetail() {
  const navigate = new useNavigate();
  const globalCtx = useContext(GlobalContext);
  const { updateBookmark, checkBookmark } = globalCtx;
  // const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const [showActiveBtn, setShowActiveBtn] = useState(false);
  const [showDiscription, setShowDiscription] = useState("");
  const { videoId } = useParams();
  const [showLoading, setShowLoading] = useState(true);
  const [video, setVideo] = useState(null);
  const [btnText, setBtnText] = useState("show more");
  // const [emailActive, setEmailActive] = useState(false);
  // const [showSussessPopup, setShowSuccessPopup] = useState(false);
  // const [value, setValue] = useState({
  //     name: "",
  //     email: "",
  // });
  useEffect(() => {
    if (videoId) {
      getDetails();
    }
  }, [videoId]);

  // get notification button logic
  // 0 - cancel button
  // 1 - active button
  // 2 - get notification button

  const [getNotificationBtn, setGetNotificationBtn] = useState(2);

  async function getDetails() {
    const respData = await serv.getDetails(videoId);
    if (respData.data && respData.data.is_active) {
      await serv.updateViewCount({ _id: videoId });
      setVideo(respData.data);
      if (respData.data.channel_id?.is_notification_active) {
        setGetNotificationBtn(1);
      } else {
        setGetNotificationBtn(2);
      }
      if (respData.data.channel_id?.channel_description) {
        if (respData.data.channel_id.channel_description.length > 180) {
          setShowDiscription(respData.data.channel_id?.channel_description?.slice(0, 180));
        } else {
          setShowDiscription(respData.data.channel_id.channel_description);
        }
      } else {
        setShowDiscription("");
      }
      setShowLoading(false);
    } else {
      navigate("/notfound");
    }
  }
  const handleNotification = async () => {
    let response = await serv.updateNotification({ channel_id: video.channel_id._id });
    try {
      if (response) {
        console.log(response.data);
        getDetails();
      }
    } catch (error) {}
  };
  // const ValidateSchema = Yup.object({
  //     name: Yup.string().required("Name is required field"),
  //     email: Yup.string().email("Please enter correct email address").required("Email is required field"),
  // });

  // const onSubmit = async (values, { resetform }) => {
  //     let obj = {
  //         name: values.name,
  //         email: values.email,
  //     };
  //     try {
  //         const respData = await subsServ.addEmail(obj);
  //         if (respData.data) {
  //             setShowSuccessPopup(true);
  //             setTimeout(() => {
  //                 setShowSuccessPopup(false)
  //             }, 3000);
  //             values.name = "";
  //             values.email = "";
  //         }
  //     } catch (error) {
  //         console.log(error);
  //     }
  // };
  // const formik = useFormik({
  //     initialValues: value,
  //     validateOnBlur: true,
  //     onSubmit,
  //     validationSchema: ValidateSchema,
  //     enableReinitialize: true,
  // });
  if (showLoading) {
    return <Loading />;
  }
  const handleShowDiscriptiob = () => {
    if (showDiscription.length > 180) {
      setShowDiscription(video.channel_id.channel_description.slice(0, 180));
    }
    if (showDiscription.length <= 180) {
      setShowDiscription(video.channel_id.channel_description);
    }
    if (btnText == "show more") {
      setBtnText("show less");
    }
    if (btnText == "show less") {
      setBtnText("show more");
    }
  };
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <Helmet>
        <title>{video.video_title ? video.video_title : "Video"} - MaveFi</title>
        <meta name="description" content={video.metadata?.description ? video.metadata?.description : ""} />
        <meta name="keywords" content={video.metadata?.tags ? video.metadata?.tags : ""} />
        <meta property="og:title" content={video.video_title || "Mavefi - Video"} />
        <meta property="og:description" content={video.metadata?.description || ""} />
        <meta property="og:image" content={video.video_image} />
      </Helmet>
      <div className="videoDetailSec">
        <div className="coursesDetailInner d-flex">
          <div className="rightsideDetails">
            <div className="videoDetails">
              <div className="videoTagDetails">
                <div className="backArrow m-0">
                  <Link to="/videos">
                    <img src="/images/svg-icons/backIcon.svg" alt="back-icon" className="img-fluid backIcon" />
                    <img
                      src="/images/svg-icons/backIcon-active.svg"
                      alt="back-icon"
                      className="img-fluid backIconActive"
                    />
                  </Link>
                </div>
                <img
                  src="/images/svg-icons/vertical-dots.svg"
                  className="img-fluid"
                  style={{ width: "4px", height: "15px" }}
                />
              </div>
              {video.tags.map((item) => {
                return <a className="videoTag">#{item} </a>;
              })}
              <h4 className="cus-heading">{video.video_title.slice(0, 30)}</h4>
              <p>
                <span>{nfObject.format(video.views)}</span> views on Youtube • Uploaded on{" "}
                <span>{moment(video?.publishedAt).format("Do MMMM, YYYY")}</span> • Video language:{" "}
                <span>{video?.language}</span>
              </p>
            </div>
            {showDiscription != "" && (
              <div className={"videoDetails channelDetails marginBottom25"}>
                {showDiscription != "" && (
                  <>
                    <h4>About this Channel</h4>
                    <p>
                      {showDiscription}
                      {video?.channel_id?.channel_description?.length > 180 && (
                        <p className="text-primary" style={{ cursor: "pointer" }} onClick={handleShowDiscriptiob}>
                          <u>
                            <b>{btnText}</b>
                          </u>
                        </p>
                      )}
                    </p>
                    {/* <p>
                Languages: <span>{video?.channel_id?.language}</span>
              </p> */}
                  </>
                )}
                <div
                  className={
                    "channelTitle" +
                    (video?.channel_id?.channel_name && video?.channel_id?.channel_banner ? " " : " margin0")
                  }
                >
                  {video?.channel_id?.channel_name && (
                    <div className="channelTitle2">
                      {video?.channel_id?.channel_banner && (
                        <img src={video?.channel_id?.channel_banner} alt="doodle" className="img-fluid" />
                      )}
                      <h3>
                        {video?.channel_id?.channel_name}{" "}
                        {video?.channel_id?.is_verified && (
                          <img
                            src="/images/svg-icons/verifiedIcon.svg"
                            className="verifiedIconImg verifiedIconImgBig"
                          />
                        )}
                      </h3>
                    </div>
                  )}
                  {getNotificationBtn == 0 && (
                    <button
                      className="cancelNotifyBtn"
                      // onMouseOut={() => setShowActiveBtn(false)}
                      onClick={handleNotification}
                      onMouseOut={() => setGetNotificationBtn(1)}
                      onMouseOver={() => setGetNotificationBtn(0)}
                    >
                      <img src="/images/svg-icons/redNotificationIcon.svg" className="img-fluid" /> Cancel
                    </button>
                  )}
                  {getNotificationBtn == 1 && (
                    <button
                      className="activeNotifyBtn"
                      onClick={handleNotification}
                      onMouseOver={() => setGetNotificationBtn(0)}
                      onMouseOut={() => setGetNotificationBtn(1)}
                    >
                      <img src="/images/svg-icons/greenNotificationIcon.svg" className="img-fluid" /> Active
                    </button>
                  )}
                  {getNotificationBtn == 2 && (
                    <button
                      onClick={handleNotification}
                      className={video?.channel_id?.channel_name && video?.channel_id?.channel_banner ? " " : " w-100"}
                    >
                      <img src="/images/svg-icons/bell.svg" className="img-fluid" /> Get Notification
                    </button>
                  )}
                  {/* {!showActiveBtn ? (
                    
                  ) : (
                    <>
                      {!video?.channel_id?.is_notification_active ? (
                        
                      ) : (
                        
                      )}
                    </>
                  )} */}
                </div>
              </div>
            )}
          </div>
          <div className="detailSec card videoView">
            {/* <div className="backArrow">
                            <Link to="/videos">
                                <img
                                    src="/images/svg-icons/backIcon.svg"
                                    alt="back-icon"
                                    className="img-fluid backIcon"
                                />
                                <img
                                    src="/images/svg-icons/backIcon-active.svg"
                                    alt="back-icon"
                                    className="img-fluid backIconActive"
                                />
                            </Link>
                        </div> */}
            {/* <video controls="true">
                            <source src={video?.video_link} type="video/mp4" />
                        </video> */}
            <iframe
              width="100%"
              height="464"
              src={`https://www.youtube.com/embed/${video?.video_id}?autoplay=1&mute=1&enablejsapi=1`}
              frameBorder="0"
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allow="autoplay"
              allowFullScreen
              title="Embedded youtube"
            />
            {/* <img
                            src={video?.video_banner}
                            //"/images/svg-icons/videoBanner.png"
                            alt="videoBanner"
                            className="blogImgBanner img-fluid"
                        /> */}
            <div className="videoContant">
              <div className="videoBanner"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoDetail;
