import util from "../util/util";
export default class BookmarkService {
  listAllBookmark(data) {
    const activity = Object.keys(data).reduce((object, key) => {
      if (data[key] !== "") {
        object[key] = data[key];
      }
      return object;
    }, {});

    return util.sendApiRequest("/bookmark/list", "POST", true, activity).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }

  listAllWithObjects(data) {
    const activity = Object.keys(data).reduce((object, key) => {
      if (data[key] !== "") {
        object[key] = data[key];
      }
      return object;
    }, {});

    return util
      .sendApiRequest("/bookmark/listwithobjs", "POST", true, activity)
      .then(
        (response) => {
          return response;
        },
        (error) => {
          throw error;
        }
      );
  }

  removeBookmark(id, type) {
    const uid = util.getUserLocal()._id;
    if (uid) {
      util
        .sendApiRequest("/bookmark", "DELETE", true, {
          user_id: uid,
          type: type,
          id: id,
        })
        .then(
          (response) => {
            return response;
          },
          (error) => {
            throw error;
          }
        );
    }
  }

  addBookmark(id, type) {
    const uid = util.getUserLocal()._id;

    if (uid) {
      util
        .sendApiRequest("/bookmark", "POST", true, {
          user_id: uid,
          type: type,
          id: id,
        })
        .then(
          (response) => {
            return response;
          },
          (error) => {
            throw error;
          }
        );
    }
  }
}
