import { useContext, useState, useEffect } from "react";
import CareerAptService from "../../services/CareerApplicantService";
import { useFormik, Field } from "formik";
import * as Yup from "yup";
import { FileUploader } from "react-drag-drop-files";
import GlobalContext from "../../context/GlobalContext";
import Loading from "../../shared/Loading";
const fileTypes = ["PDF", "DOC", "DOCX"];

const serv = new CareerAptService();
const ValidateSchema = Yup.object({
  full_name: Yup.string().required("Name is a required field"),
  email: Yup.string().required("Email is a required field").email(),
  attachments: Yup.string().required("Required"),
  accept: Yup.boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted."),
});

function CareerApplicantPopup({ onClose, careerId }) {
  const globalCtx = useContext(GlobalContext);
  const [user, setUser] = globalCtx.user;
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false);
  const [signupObj, setSignupObj] = new useState({
    full_name: "",
    email: "",
    attachments: "",
    accept: true,
  });
  useEffect(() => {
    if (user && user.settings && user.settings.terms_of_service !== undefined) {
      setSignupObj({ ...signupObj, accept: user.settings.terms_of_service });
    }
  }, [user]);
  const onSubmit = async (values) => {
    setIsLoading(true)
    const formData = new FormData();
    formData.append("full_name", values.full_name);
    formData.append("email", values.email);
    formData.append("attachments", values.attachments);
    formData.append("carrerId", careerId);
    try {
      const resp = await serv.addData(formData);
      if (resp) {
        setIsLoading(true)
        setSuccess(true);
      }
    } catch (err) { }
  };
  const formik = useFormik({
    initialValues: signupObj,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  return (
    <>
      <div
        className="modal commanModel SignupPopup showBlock"
        id="Signup"
        onClick={() => {
          onClose();
        }}
      >
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center">
            <div className="modal-dialog">
              <div
                className="modal-content"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {/* <!-- Modal Header --> */}
                <div className="modal-header">
                  <h4 className="modal-title">Apply Now for Content Manager</h4>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                      onClose();
                    }}
                  ></button>
                </div>
                {/* <!-- Modal body --> */}
                {!success ? (
                  <div className="modal-body">
                    <div className="connectWalletOuter">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="modelFormInput">
                          <label for="signupfname">Full name</label>
                          <div
                            className={
                              "input-group" +
                              (formik.touched.full_name &&
                                formik.errors.full_name
                                ? " border-red"
                                : "")
                            }
                          >
                            <span className="input-group-text pe-0">
                              <img src="/images/svg-icons/userGray1.svg" />
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="John Doe"
                              name="full_name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.full_name}
                            />
                          </div>
                          {formik.touched.full_name &&
                            formik.errors.full_name ? (
                            <div className="formik-errors bg-error">
                              {formik.errors.full_name}
                            </div>
                          ) : null}
                        </div>
                        <div className="modelFormInput">
                          <label for="signupemail">Email address</label>
                          <div
                            className={
                              "input-group" +
                              (formik.touched.email && formik.errors.email
                                ? " border-red"
                                : "")
                            }
                          >
                            <span className="input-group-text pe-0">
                              <img src="/images/svg-icons/email-gray-icon.svg" />
                            </span>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="john@mavefi.com"
                              name="email"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                            />
                          </div>
                          {formik.touched.email && formik.errors.email ? (
                            <div className="formik-errors bg-error">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>
                        <div className="modelFormInput">
                          <label htmlFor="attachments">
                            Please upload your resume/work portfolio
                          </label>
                          <FileUploader
                            handleChange={(file) => {
                              formik.setTouched({
                                ...formik.touched,
                                attachments: true,
                              });
                              formik.setFieldValue("attachments", file);
                            }}
                            name="attachments"
                            types={fileTypes}
                            classes="w-100"
                          >
                            <div
                              className={
                                "input-group" +
                                (formik.touched.attachments &&
                                  formik.errors.attachments
                                  ? " border-red"
                                  : "")
                              }
                            >
                              <div className="d-flex flex-column w-100">
                                <div className="uploadimg text-center">
                                  <img src="/images/icons/upload-cloud.png" />
                                </div>
                                <div className="text-center uploadtext">
                                  Click here to drag and drop files
                                  <br />
                                  here to upload them.
                                  {formik.touched.attachments &&
                                    !formik.errors.attachments ? (
                                    <>
                                      <br />
                                      Current selected file:{" "}
                                      {formik.values.attachments.name}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </FileUploader>
                          {formik.touched.attachments &&
                            formik.errors.attachments ? (
                            <div className="formik-errors bg-error">
                              {formik.errors.attachments}
                            </div>
                          ) : null}
                        </div>
                        <div className="modelFormInput">
                          <div
                            className={
                              "form-check" +
                              (formik.errors.accept ? " border-red" : "")
                            }
                          >
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="accept"
                                checked={formik.values.accept}
                                onClick={() =>
                                  formik.setFieldValue(
                                    "accept",
                                    !formik.values.accept
                                  )
                                }
                                value={formik.values.accept}
                              />{" "}
                              Accept our{" "}
                              <a href="/legal/terms-of-service" target="_blank">
                                Terms of Service
                              </a>{" "}
                              &{" "}
                              <a href="/legal/privacy-policy" target="_blank">
                                Privacy Policy
                              </a>
                            </label>
                          </div>
                          {formik.errors.accept ? (
                            <div className="formik-errors bg-error">
                              {formik.errors.accept}
                            </div>
                          ) : null}
                        </div>
                        <div className="modelSignBtn">
                          <div className="d-grid">
                            <button
                              type="submit"
                              className={
                                "btn btn-primary btn-block" +
                                (Object.keys(formik.errors).length !== 0 ||
                                  Object.keys(formik.touched).length === 0
                                  ? " disabled" || isLoading
                                  : "")
                              }
                            >
                              Submit {isLoading && <Loading isInline={true} />}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                ) : (
                  <div className="modal-body">
                    <div className="connectWalletOuter">
                      <div className="modelSignBtn">
                        <div className="d-grid success-glossary success-career">
                          <div>
                            <img src="/images/svg-icons/green-tick.svg" />
                          </div>
                          <div>
                            <p>
                              Your application has been received successfully.
                              Our concerned team representatives will now review
                              your application & then get in touch with you at a
                              suitable time.
                            </p>
                          </div>
                          <button
                            onClick={onClose}
                          // className={
                          //   "btn"
                          // }
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* <!-- Modal footer --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
}

export default CareerApplicantPopup;
