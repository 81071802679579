import { useContext } from "react";
import { Link } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import SocialShare from "../../shared/SocialShare";

function CourseCard({ course, searchCallBack, search }) {
  const globalCtx = useContext(GlobalContext);
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const { updateBookmark, checkBookmark } = globalCtx;
  const handleTagFilter = (t) => {
    let searchTemp = search.filter;
    searchTemp["tags"] = searchTemp.tags ? [...searchTemp.tags, t] : [t];
    searchCallBack(search.searchText, searchTemp, search.sort);
  };
  return (
    <div className="col-sm-4 col-lg-4 col-xl-3 col-xxl-2 cardsBox">
      <div className="h-100 learnEarnBox">
        <div className="updates_card learnEarnCard learnEarnCardCustom">
          <div className="updates_box">
            <div className="learnImageBox">
              <Link to={"/courses/info/" + course._id}>
                <div className="learnImageInnerBox">
                  <img
                    src={course.cover_image || "/images/svg-icons/rectangle.svg"}
                    alt="rectangle"
                    className="img-fluid"
                    // style={{
                    //   height: "109px",
                    //   width: "202px",
                    //   // objectFit: "cover",
                    // }}
                  />
                </div>
              </Link>
            </div>
            <div className="learnEarnTag">
              <div className="learnTagIcon">
                <img src="/images/svg-icons/tag-window.svg" alt="tagicon" className="img-fluid" />
              </div>
              <div className="learnTag">
                <ul className="nav">
                  {course.tags.map((t) => {
                    return (
                      <li className="nav-item">
                        <a href="javascript:void(0);" className="customTag" onClick={() => handleTagFilter(t)}>
                          {t}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="box_heading d-flex justify-content-start">
              <Link to={"/courses/info/" + course._id}>
                <h4 className="m-0">{course.course_name}</h4>
              </Link>
            </div>
            <div className="box_content text-left">
              <Link to={"/courses/info/" + course._id}>
                {/* <p className="m-0">
                </p> */}
                <p
                  className="m-0"
                  dangerouslySetInnerHTML={{
                    __html:
                      course.course_name.length < 30
                        ? course.course_desc.replace(/<[^>]*>?/gm, "").substring(0, 112)
                        : course.course_desc.replace(/<[^>]*>?/gm, "").substring(0, 90),
                  }}
                />
              </Link>
            </div>
            <div className="cardBtnIcon">
              <div className="headerBtnSec d-flex">
                <div className="btnOutline">
                  {course.isRewardClaimed ? (
                    <button className="reward-claimed">Reward Claimed</button>
                  ) : course.retry_time ? (
                    <button className="btn reward-claimed-time">
                      {course.retry_time > 1 ? "Retry in " + course.retry_time + " hours" : "Retry in 1 hour"}
                    </button>
                  ) : (
                    <a href="javascript:void(0);" className="btn btn-outline-secondary">
                      Earn {course.rewards} coins
                    </a>
                  )}
                </div>
                {isAuthentiCated && (
                  <div className="modeBtn">
                    <a
                      href="javascript:void(0)"
                      className=""
                      onClick={() => {
                        updateBookmark(course._id, "course");
                      }}
                    >
                      {checkBookmark(course._id) ? (
                        <img
                          src="/images/svg-icons/bookmark-active.svg"
                          alt="bookmark"
                          className="img-fluid bookmarkActive"
                        />
                      ) : (
                        <img
                          src="/images/svg-icons/bookmark-normal.svg"
                          alt="bookmark"
                          className="img-fluid bookmarkNormal"
                        />
                      )}
                      {/* <img
                      src="/images/svg-icons/bookmark-hover.svg"
                      alt="bookmark"
                      className="img-fluid bookmarkHover"
                    /> */}
                    </a>
                  </div>
                )}
                <SocialShare
                  twitter={{
                    text: course.course_name,
                    url: window.location.origin + "/courses/info/" + course._id,
                  }}
                  facebook={{
                    title: course.course_name,
                    url: window.location.origin + "/courses/info/" + course._id,
                  }}
                  website={{
                    title: course.course_name,
                    url: window.location.origin + "/courses/info/" + course._id,
                  }}
                />
              </div>
            </div>
          </div>
          {/* <amp-ad
              width="226"
              height="320"
              type="adsense"
              data-ad-client="ca-pub-1429924092740823"
              data-ad-slot="8890481255"
              data-auto-format="rspv"
              data-full-width=""
            >
              <div overflow=""></div>
            </amp-ad> */}
        </div>
      </div>
    </div>
  );
}

export default CourseCard;
