import util from "../util/util";
import axios from "axios";

export default class AccountService {
  getUser(userId) {
    return util
      .sendApiRequest("/" + userId, "GET", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  walletAddress(payload) {
    return util
      .sendApiRequest("/", "PUT", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  async editUser(payload) {
    const token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "no-token";
    const config = {
      headers: {
        content: "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    };
    try {
      const response = await axios.put(
        process.env.REACT_APP_API_BASEURL + "/",
        payload,
        config
      );
      if (response.err) {
        throw new Error(response.err);
      } else {
        return response;
      }
    } catch (err) {
      throw err;
    }
  }
}
