import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import UserService from "../../services/UserService";
import { useFormik } from "formik";
import * as Yup from "yup";
import GlobalContext from "../../context/GlobalContext";
import Loading from "../../shared/Loading";
import { GoogleAuth, FacebookAuth, TwitterAuth } from "../../shared/TwoWayAuth";
const serv = new UserService();

function SocialSignupLogin({ onClose, onShowSignup, onShowForgotPassword, setOtp, onShowLogin, socialType }) {
  const globalCtx = useContext(GlobalContext);
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const [user, setUser] = globalCtx.user;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const handleSocialLogin = async (provider, data) => {
    try {
      data.provider = provider;
      await serv.socialLoginSignUp(data).then((resp) => {
        console.log(resp);
        if (resp.error) {
          console.log(resp.error);
        } else {
          localStorage.setItem("token", resp.token);
          // localStorage.setItem("token", resp.token + "___" + provider);
          localStorage.setItem("user", JSON.stringify(resp.data));
          setUser(resp.data);
          setIsAuthentiCated(true);
          onClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleTwitterLogin = async (provider, data) => {
    console.log(data);
    try {
      await serv.twitterLoginSignUp(data).then((resp) => {
        console.log(resp);
        localStorage.setItem("token", resp.token);
        localStorage.setItem("user", JSON.stringify(resp.data));
        setUser(resp.data);
        setIsAuthentiCated(true);
        onClose();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const googleBtn = () => {
    return (
      <button type="submit" className={"w-100 btn btn-primary btn-block social-login-btn social-comp"}>
        <img src="/images/svg-icons/google-sign.svg" className="img-fluid" />
        {socialType == "login" ? "Login" : "Signup"} with Google
        {/* {isLoading && <Loading isInline={true} />} */}
      </button>
    );
  };
  const facebookBtn = () => {
    return (
      <button type="submit" className={"w-100 btn btn-primary btn-block social-login-btn social-comp"}>
        <img src="/images/svg-icons/facebook-sign.svg" className="img-fluid" />
        {socialType == "login" ? "Login" : "Signup"} with Facebook
        {/* {isLoading && <Loading isInline={true} />} */}
      </button>
    );
  };
  const twitterBtn = () => {
    return (
      <button type="submit" className={"w-100 btn btn-primary btn-block social-login-btn social-comp"}>
        <img src="/images/svg-icons/twitter-sign.svg" className="img-fluid" />
        {socialType == "login" ? "Login" : "Signup"} with Twitter
        {/* {isLoading && <Loading isInline={true} />} */}
      </button>
    );
  };
  return (
    <>
      <div
        className="modal commanModel SignupPopup showBlock"
        id="Signin"
        onClick={() => {
          onClose();
        }}
      >
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center">
            <div className="modal-dialog">
              <div
                className="modal-content"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {/* <!-- Modal Header --> */}
                <div className="modal-header">
                  <h4 className="modal-title">
                    {socialType == "login" ? "Login" : "Signup"} with{" "}
                    <img src="/images/svg-icons/social-combo-black.svg" className="img-fluid" />
                  </h4>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                      onClose();
                    }}
                  ></button>
                </div>
                {/* <!-- Modal body --> */}
                <div className="modal-body pt-0">
                  <div className="connectWalletOuter">
                    <div className="modelSignBtn modelsocialsign">
                      <div className="d-grid">
                        <GoogleAuth handleGoogleLogin={handleSocialLogin} btn={googleBtn} />
                        <FacebookAuth handleSocialConnect={handleSocialLogin} btn={facebookBtn} />
                        {/* <TwitterAuth handleSocialConnect={handleTwitterLogin} btn={twitterBtn} /> */}
                        <button
                          type="button"
                          onClick={() => {
                            socialType == "login" ? onShowLogin() : onShowSignup();
                          }}
                          className={"btn btn-primary btn-block social-login-btn switch-btn"}
                        >
                          <img src="/images/svg-icons/email-signup.svg" className="img-fluid" />
                          {socialType == "login" ? "Login" : "Signup"} with Username/Email address
                          {/* {isLoading && <Loading isInline={true} />} */}
                        </button>
                      </div>
                    </div>
                    {errorMsg && <div className="formik-errors bg-error text-center">{errorMsg}</div>}
                  </div>
                </div>
                {/* <!-- Modal footer --> */}
                <div className="modal-footer justify-content-center">
                  <div className="footerLink">
                    <p className="mb-0">
                      {socialType == "login" ? "Don't have an account? " : "Already have an account? "}
                      <Link
                        to="#"
                        onClick={() => {
                          socialType == "login" ? onShowSignup() : onShowLogin();
                        }}
                      >
                        {socialType == "login" ? "Sign up" : "Login"} now
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
}

export default SocialSignupLogin;
