import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import CourseService from "../../services/CourseService";
import UserCourseService from "../../services/UserCourseService";
import Loading from "../../shared/Loading";
import SocialShare from "../../shared/SocialShare";
import FeedbackService from "../../services/FeedbackService";
import { Helmet } from "react-helmet";

const feedbackServ = new FeedbackService();
const serv = new CourseService();
const userCourseServ = new UserCourseService();

function CourseInfo() {
  const globalCtx = useContext(GlobalContext);
  const { updateBookmark, checkBookmark } = globalCtx;
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const [user, setUser] = globalCtx.user;
  const [showLoading, setShowLoading] = useState(true);
  const { courseId } = useParams();
  const navigate = useNavigate();
  const [accepted, setAccepted] = useState(true);
  const [course, setCourse] = useState(null);
  const [userCourse, setUserCourse] = useState(null);
  const [author, setAuthor] = useState(null);
  const [isRewardClaimed, setIsRewardClaimed] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [viewCount, setViewCount] = useState("");
  useEffect(() => {
    if (courseId) {
      getDetails();
    }
  }, [courseId]);
  useEffect(() => {
    if (user && user.settings && user.settings.terms_of_service !== undefined) {
      setAccepted(user.settings.terms_of_service);
    }
  }, [user]);

  async function getDetails() {
    const respData = await serv.getCourse(courseId);
    if (respData && respData.data && respData.data.is_active) {
      if (viewCount == "") {
        await serv.updateViewCount({ _id: courseId });
        setViewCount(courseId);
      }
      setCourse(respData.data);
      setAuthor(respData.author);
      if (respData.userCourse) {
        setUserCourse(respData.userCourse);
      }
      setIsRewardClaimed(respData.isRewardClaimed);
      setShowLoading(false);
    } else {
      navigate("/notfound");
    }
  }

  async function handleStartCourse(e) {
    try {
      let obj = {
        course_id: courseId,
        status: 10,
      };
      let response = await userCourseServ.addUserCourse(obj);
      let usr_enrolled_increment = await serv.updateUserEnrolled(obj);
      if (response.data) {
        navigate("/courses/course/" + courseId);
      }
    } catch (err) {
      console.log(err);
    }
  }
  async function handleFeedback() {
    try {
      const formData = new FormData();
      formData.append("subject", " ");
      formData.append("courseId", courseId);
      formData.append("description", feedback);
      let response = await feedbackServ.addData(formData);
      if (response.data._id != "") {
        setFeedbackSubmitted(true);
      }
    } catch (err) {
      console.log(err);
    }
  }

  if (showLoading || !course) {
    return <Loading />;
  }
  return (
    <div className="ljSectionData w-100 clearfix " id="ljSectionData">
      <Helmet>
        <title>{course.metadata?.title ? course.metadata.title : "Learn"} - MaveFi</title>
        <meta name="description" content={course.metadata?.description ? course.metadata?.description : ""} />
        <meta name="keywords" content={course.metadata?.tags ? course.metadata?.tags : ""} />
        <meta property="og:title" content={course.course_name || "Mavefi - Course"} />
        <meta property="og:description" content={course.metadata?.description || ""} />
        <meta property="og:image" content={course.cover_image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={course.course_name || "Mavefi - Course"} />
        <meta name="twitter:description" content={course.metadata?.description || ""} />
        <meta name="twitter:image" content={course.cover_image} />
        <meta name="twitter:image:width" content={240} />
        <meta name="twitter:image:height" content={240} />
      </Helmet>
      <div className="learnEarnSingle card">
        <div className="infoRow d-flex">
          <div className="infoCol infoCol1">
            <div className="singleInfo singleSec">
              <div className="singleInfoInner">
                <div className="backArrow">
                  <Link to="/courses">
                    <img src="/images/svg-icons/backIcon.svg" alt="back-icon" className="img-fluid backIcon" />
                    <img
                      src="/images/svg-icons/backIcon-active.svg"
                      alt="back-icon"
                      className="img-fluid backIconActive"
                    />
                  </Link>
                </div>
                <div className="infoSec d-flex">
                  <div className="infoSecImg">
                    <img
                      src={course.cover_image || "/images/svg-icons/rectangle.svg"}
                      alt="rectangle"
                      className="w-100 img-fluid"
                      style={{
                        height: "224px",
                        width: "100%",
                        borderRadius: "8px",
                        // objectFit: "cover",
                      }}
                    />
                  </div>
                  <div className="infoSecContent">
                    <div className="infoHeading">
                      <h4>INFORMATION</h4>
                    </div>
                    <div className="infomationSec d-flex align-items-center justify-content-between">
                      <div className="infoIconContent">
                        <div className="infoIcon">
                          <img src="/images/svg-icons/mave-logo.svg" alt="mave-logo" className="img-fluid" />
                        </div>
                        <div className="infoIconTxt">
                          <p className="mb-0">Course Reward</p>
                        </div>
                      </div>
                      <div className="coinsPoint">
                        {isRewardClaimed ? (
                          <p className="mb-0 rewardClaimedCustom">Rewards Claimed</p>
                        ) : (
                          <p className="mb-0">{course.rewards} COINS</p>
                        )}
                      </div>
                    </div>
                    <div className="infomationProgressSec">
                      <div className="infomationSec d-flex align-items-center justify-content-between">
                        <div className="infoIconContent">
                          <div className="infoIcon">
                            <img src="/images/svg-icons/progress.svg" alt="mave-logo" className="img-fluid" />
                          </div>
                          <div className="infoIconTxt">
                            <p className="mb-0">Progress</p>
                          </div>
                        </div>
                        <div className="coinsPoint">
                          <p className="mb-0">{(userCourse && userCourse.status) || "0"}% Complete</p>
                        </div>
                      </div>
                      <div className="progressBar">
                        <div className="progress" style={{ height: "6px" }}>
                          <div
                            className="progress-bar"
                            style={{
                              width: ((userCourse && userCourse.status) || "0") + "%",
                              height: "6px",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="infomationSec d-flex align-items-center justify-content-between">
                      <div className="infoIconContent">
                        <div className="infoIcon">
                          <img src="/images/svg-icons/tag-window-gray.svg" alt="mave-logo" className="img-fluid" />
                        </div>
                        <div className="infoIconTxt">
                          <p className="mb-0">Tags</p>
                        </div>
                      </div>
                      <div className="coinsPoint">
                        <p className="mb-0">{course.tags.join(", ")}</p>
                      </div>
                    </div>
                    <div className="infomationSec d-flex align-items-center justify-content-between">
                      <div className="infoIconContent">
                        <div className="infoIcon">
                          <img src="/images/svg-icons/hand-with-pen-gray.svg" alt="mave-logo" className="img-fluid" />
                        </div>
                        <div className="infoIconTxt">
                          <p className="mb-0">Author</p>
                        </div>
                      </div>
                      <div className="coinsPoint">
                        <p className="mb-0 authorHover">
                          <Link to={"/profile/" + author.user_name}>{author.full_name}</Link>
                        </p>
                      </div>
                    </div>
                    <div className="cardBtnIcon infoIconBtn">
                      <div className="headerBtnSec d-flex">
                        <div className="btnOutline">
                          <a href="javascript:void(0)" className="btn btn-outline-secondary">
                            User Counter: <b>{course.users_enrolled}</b>
                          </a>
                        </div>
                        {isAuthentiCated && (
                          <div className="modeBtn">
                            <a
                              href="javascript:void(0)"
                              className=""
                              onClick={() => {
                                updateBookmark(course._id, "course");
                              }}
                            >
                              {checkBookmark(course._id) ? (
                                <img
                                  src="/images/svg-icons/bookmark-active.svg"
                                  alt="bookmark"
                                  className="img-fluid bookmarkActive"
                                />
                              ) : (
                                <img
                                  src="/images/svg-icons/bookmark-normal.svg"
                                  alt="bookmark"
                                  className="img-fluid bookmarkNormal"
                                />
                              )}
                              {/* <img
                                src="/images/svg-icons/bookmark-hover.svg"
                                alt="bookmark"
                                className="img-fluid bookmarkHover"
                              /> */}
                            </a>
                          </div>
                        )}
                        <SocialShare
                          twitter={{
                            text: course.course_name,
                            url: window.location.href,
                          }}
                          facebook={{
                            title: course.course_name,
                            url: window.location.href,
                          }}
                          website={{
                            title: course.course_name,
                            url: window.location.href,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="metamaskContent metamaskContentCustom">
                  <h2>{course.course_name}</h2>
                  <div className="metamaskContent metamaskDescCustom">
                    <p>{course.course_desc}</p>
                  </div>
                </div>
                <div className="singleLearnPoints">
                  <h3>WHAT WILL YOU LEARN?</h3>
                  <div className="learnListPoint">
                    {course.course_lesson.split(/\r?\n/).map((wl) => {
                      return (
                        <div className="learnListInner d-flex align-items-center">
                          <div className="learnListIcon">
                            <img src="/images/svg-icons/hand.svg" alt="share-rounded" className="img-fluid" />
                          </div>
                          <div className="learnListTxt">
                            <p className="mb-0">{wl.replace("👉", "")}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="infoCol infoCol2">
            <div className="singleDisclaimer singleSec">
              <div className="singleDisclaimerInner">
                <div className="mainSingleDisclaimer">
                  <div className="singleDisclaimerContent">
                    <div className="singleDiscTxt">
                      <h2>DISCLAIMER</h2>
                      <p>
                        This course has been built by members of our core team and our community contributors. We try
                        our best to make sure that 100% of the content is exact & up to date but could have missed out
                        certain things.{" "}
                      </p>
                      <p>
                        So please feel free to share your valuable feedback with us. We use your feedback to better our
                        team and our platform.
                      </p>
                    </div>
                    <div className="singleLangSelect">
                      <div className="selectLang d-flex align-items-center justify-content-between">
                        <div className="langHeading">
                          <h4 className="mb-0">Select Language</h4>
                        </div>
                        <div className="infoSelectLang">
                          <select className="formSelect">
                            <option value="English">English</option>
                          </select>
                        </div>
                      </div>
                      <div className="infoAccept">
                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="remember"
                              checked={accepted}
                              onClick={() => setAccepted(!accepted)}
                            />
                            <p className="mb-0">
                              Accept our{" "}
                              <a href="/legal/terms-of-service" target="_blank">
                                Terms of Service
                              </a>{" "}
                              &{" "}
                              <a href="/legal/privacy-policy" target="_blank">
                                Privacy Policy
                              </a>
                            </p>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="startCoursesBtn d-grid">
                      <button
                        className={
                          "btn btn-primary btn-block" + (!isAuthentiCated || !accepted ? " disabled-link" : "")
                        }
                        onClick={handleStartCourse}
                      >
                        {isAuthentiCated ? "Start Course" : "Login to continue"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="infoFeedback">
                  <div className="infoFeedbackInner">
                    <div className="infoFeedbackHeading">
                      <h4 className="mb-0">Please provide us with your feedback for this course.</h4>
                    </div>
                    {!feedbackSubmitted ? (
                      <>
                        <div className="infoFeedbackCommentBox">
                          <textarea
                            className="form-control"
                            rows="5"
                            id="comment"
                            name="feedback"
                            placeholder="Enter your feedback here"
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                          ></textarea>
                        </div>
                        <div className={(feedback !== "" ? "startCoursesBtn" : "infoFeedbackSubmit") + " d-grid"}>
                          <button
                            type="button"
                            className={
                              "btn btn-block " + (feedback !== "" ? "btn-primary" : "btn-outline-primary disabled-link")
                            }
                            onClick={feedback !== "" ? handleFeedback : ""}
                          >
                            Submit
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="infoFeedbackCommentBox">
                          <textarea
                            className="form-control feedbackSubmit"
                            rows="5"
                            id="comment"
                            name="feedback"
                            placeholder="Enter your feedback here"
                            value="Thank you. Your feedback for this course has been received successfully. 🤝"
                            disabled={true}
                          ></textarea>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseInfo;
