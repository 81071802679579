import { useContext, useEffect, useState } from "react";
import GlobalContext from "../context/GlobalContext";
import UserService from "../services/UserService";
import { Link, useNavigate, useLocation } from "react-router-dom";
import SearchResult from "../pages/SearcheResult/SearchResult";

const userServ = new UserService();

function SearchBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const globalCtx = useContext(GlobalContext);
  const [searchDtl, setSearchDtl] = globalCtx.searchDtl;
  const [allOptions, setAllOptions] = useState({});
  const [showResults, setShowResults] = useState(false);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    if (location.pathname != "/search/block") {
      setSearchText("");
    }
  }, [location]);
  async function searchRecords(e) {
    let searchText = e == "" ? "" : e.target.value;

    setSearchText(searchText);
    if (searchText === "") {
      setShowResults(true);
      let prevSearch = JSON.parse(localStorage.getItem("recentSearch"));
      setAllOptions(prevSearch);
      return;
    } else {
      try {
        let obj = {
          start: 0,
          length: 3,
          searchText: searchText,
        };
        let response = await userServ.listAllSearchData(obj);
        let objMap = {
          update: ["UPDATES", "word", "update", "", "/updates"],
          course: ["COURSES", "course_name", "course_desc", "cover_image", "/courses"],
          glossary: ["GLOSSARY", "word", "definition", "", "/glossary"],
          mint: ["MINTS", "name", "desc", "", "/mints"],
          newsletter: ["NEWSLETTERS", "name", "description", "", "/newsletters"],
          profile: ["PROFILES", "name", "desc", "", "/profiles"],
          user: ["ACCOUNTS", "full_name", "user_name", "file", "/users"],
          blog: ["BLOGS", "blog_title", "content", "blog_image", "/blogs"],
          // video: ["VIDEOS", "video_title", "video_title", "video_banner", "/videos"],
          video: ["VIDEOS", "video_title", "channel_name", "video_banner", "/videos"],
          news: ["NEWS", "news_title", "publisher_name", "news_image", "/news", "news_link"],
        };

        let allOptions = {};
        let totalMegaRecords = 0;
        if (response.megaResult) {
          for (const [key, value] of Object.entries(response.megaResult)) {
            response.megaResult[key].data = value.data.map((el) => {
              console.log(el);
              return {
                value: el._id,
                title: el[objMap[key][1]],
                content: el[objMap[key][2]],
                image: objMap[key][3] ? el[objMap[key][3]] : "",
                external_link: objMap[key][5] ? el[objMap[key][5]] : "",
                type: key,
              };
            });
            allOptions[key] = {
              label: objMap[key][0],
              isImage: objMap[key][3] ? true : false,
              options: response.megaResult[key].data,
              total: response.megaResult[key].total,
              viewAllLink: objMap[key][4],
            };
            totalMegaRecords = totalMegaRecords + response.megaResult[key].total;
          }
        }
        if (totalMegaRecords > 0) {
          setShowResults(true);
        } else {
          setShowResults(false);
        }
        setAllOptions(allOptions);
      } catch (err) {
        console.log(err);
      }
    }
  }
  let handleRecentSearch = (item) => {
    // console.log(item);
    let prevSearch = JSON.parse(localStorage.getItem("recentSearch"));
    if (prevSearch && prevSearch.length > 0) {
      let searchArray = [item, ...prevSearch];
      if (searchArray.length > 10) {
        searchArray.length = 10;
      }
      localStorage.setItem("recentSearch", JSON.stringify(searchArray));
    } else {
      localStorage.setItem("recentSearch", JSON.stringify([item]));
    }
  };
  let handleClearRecentSearch = (index) => {
    let prevSearch = JSON.parse(localStorage.getItem("recentSearch"));
    if (prevSearch && prevSearch.length > index) {
      prevSearch.splice(index, 1);
      localStorage.setItem("recentSearch", JSON.stringify(prevSearch));
      setAllOptions(prevSearch);
    }
  };
  const handleSearchClick = (navigateTo, value) => {
    let id = value.value;
    setShowResults(false);
    setSearchDtl(searchText);
    if (navigateTo === "glossary") {
      navigate("/glossary");
    } else if (navigateTo === "update") {
      navigate("/updates" + (id ? "/" + id : ""));
    } else if (navigateTo === "mint") {
      navigate("/mints" + (id ? "/" + id : ""));
    } else if (navigateTo === "profile") {
      navigate("/profiles" + (id ? "/" + id : ""));
    } else if (navigateTo === "video") {
      navigate("/videos" + (id ? "/" + id : ""));
    } else if (navigateTo === "news") {
      if (id) {
        window.open(value.external_link, "_blank");
      } else {
        navigate("/news");
      }
    } else if (navigateTo === "newsletter") {
      navigate("/newsletters" + (id ? "/" + id : ""));
    } else if (navigateTo === "user") {
      navigate("/profile" + (value?.content ? "/" + value?.content : ""));
    } else if (navigateTo === "course") {
      if (id) {
        navigate("/courses/info/" + id);
      } else {
        navigate("/courses");
      }
    } else if (navigateTo === "blog") {
      navigate("/blogs" + (id ? "/" + id : ""));
    }
  };
  const handleSaveSearchKeyword = async (keyword) => {
    try {
      let obj = {
        keyword: keyword,
      };
      await userServ.saveSearchKeyword(obj).then((resp) => {
        // console.log(resp);
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (
        e.key === "/" &&
        document.activeElement.tagName !== "INPUT" &&
        document.activeElement.tagName !== "TEXTAREA"
      ) {
        if (document.getElementById("globalSearch") !== document.activeElement) {
          e.preventDefault();
          document.getElementById("globalSearch").focus();
        } else {
          return true;
        }
      }
    });
  });

  return (
    <div className="col-xl-6 col-lg-6 headerSearchBar ms-lg-0 ms-auto position-relative">
      <div className="mobSearch has-clear">
        <div className={"input-group" + (showResults ? " active" : "")}>
          <button className="btn" type="button" style={{ zIndex: 9 }}>
            <img src="/images/svg-icons/search.svg" alt="logo" className="img-fluid" />
          </button>
          <input
            id="globalSearch"
            type="text"
            className="form-control border-0"
            placeholder="Search"
            // onChange={(e) => searchRecords(e.target.value)}
            onChange={searchRecords}
            onClick={searchRecords}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setShowResults(false);
                setSearchDtl(searchText);
                handleSaveSearchKeyword(searchText);
                handleRecentSearch(searchText);
                navigate("/search/" + searchText, { state: { search: searchText } });
              }
            }}
            value={searchText}
          />
          {showResults ? (
            <span
              className="close form-control-clear"
              onClick={() => {
                setShowResults(false);
                setSearchText("");
              }}
            >
              <img src="/images/svg-icons/crossIcon2.svg" alt="search" className="img-fluid height11" />
            </span>
          ) : (
            <span className="close form-control-clear focus-btn">/</span>
          )}
        </div>
      </div>
      <div
        className="searchResult"
        style={{
          display: showResults ? "block" : "none",
        }}
      >
        <div className="searchResultInner">
          {allOptions && allOptions.length == undefined ? (
            <>
              {Object.entries(allOptions).map((entry, index) => {
                const key = entry[0];
                const value = entry[1];
                if (!value.total) {
                  return <></>;
                }
                const records = value.options.map((option) => {
                  return (
                    <div
                      onClick={() => {
                        handleRecentSearch(option);
                        handleSearchClick(option.type, option);
                      }}
                      role="button"
                    >
                      <div className="relatedSearchInner d-flex align-items-center">
                        {value.isImage && (
                          <div
                            className={
                              "relatedSearchImage " +
                              (key == "user" ? "files_img" : key == "video" || key == "news" ? "fix_img" : "")
                            }
                          >
                            <img
                              src={option.image || "/images/svg-icons/rectangle.svg"}
                              alt="blogImg"
                              className="img-fluid"
                              style={
                                {
                                  // height: "auto",
                                  // width: "102px",
                                  // objectFit: "cover",
                                }
                              }
                            />
                          </div>
                        )}
                        <div
                          className={
                            "relatedSearchPara relatedSearchParaCustom " +
                            (key == "video" || key == "news" ? "searchParaNews_video" : "")
                          }
                        >
                          <h4 className="mb-0">{option.title}</h4>
                          {key == "user" ? (
                            <p className="mb-0">@{option.content}</p>
                          ) : key == "video" || key == "news" ? (
                            <p
                              className="mb-0"
                              dangerouslySetInnerHTML={{
                                __html: option.content,
                              }}
                            ></p>
                          ) : (
                            <p
                              className="mb-0"
                              dangerouslySetInnerHTML={{
                                __html: option.content.replace(/<[^>]*>?/gm, "").substring(0, 130),
                              }}
                            ></p>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                });
                return (
                  <>
                    <div
                      className={
                        index === 0
                          ? "searchResultTopHeading searchResultHeading"
                          : "searchResultMiddleHeading searchResultHeading"
                      }
                    >
                      <h4 className="mb-0">{value.label}</h4>
                    </div>
                    <div className={"relatedSearch" + (value.isImage ? "" : " relatedSearchWithoutImg")}>
                      {records}
                      {value.label != "ACCOUNTS" ? (
                        <div className="searchResultViewMore d-grid">
                          <button
                            type="button"
                            className="btn btn-primary btn-block"
                            onClick={() => {
                              handleSearchClick(key, value);
                            }}
                          >
                            View all {value.total} results
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                );
              })}
              <div className="searchResultMiddleHeading searchResultHeading pressEnter">
                <h4 className="mb-0">Press Enter to search all items</h4>
              </div>
            </>
          ) : (
            <>
              <div className={"searchResultTopHeading searchResultHeading"}>
                <h4 className="mb-0">RECENT</h4>
              </div>
              <div className={"relatedSearch relatedSearchCustom "}>
                {allOptions &&
                  allOptions.map((entry, index) => {
                    if (typeof entry == "string") {
                      return (
                        <div className="relatedSearchItemCustom">
                          <div
                            style={{ width: "90%" }}
                            onClick={() => {
                              setShowResults(false);
                              navigate("/search/" + entry, {
                                state: { search: entry },
                              });
                            }}
                          >
                            {entry}
                          </div>
                          <img
                            onClick={() => handleClearRecentSearch(index)}
                            src="/images/svg-icons/crossIcon2.svg"
                            alt="search"
                            className="img-fluid height11"
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div role="button">
                          <div className="relatedSearchInner d-flex align-items-center relatedSearchInnerCustom">
                            {entry.type !== "glossary" && (
                              <div
                                className={"relatedSearchImage " + (entry.type == "user" && "files_img")}
                                onClick={() => {
                                  // handleRecentSearch(option)
                                  handleSearchClick(entry.type, entry.value);
                                }}
                              >
                                {entry.type == "user" ? (
                                  <img
                                    src={entry.image || "/images/svg-icons/rectangle.svg"}
                                    alt="blogImg"
                                    className="img-fluid"
                                  />
                                ) : (
                                  <img
                                    src={entry.image || "/images/svg-icons/rectangle.svg"}
                                    alt="blogImg"
                                    style={{ height: "55px", width: "102px" }}
                                  />
                                )}
                              </div>
                            )}
                            <div
                              className="relatedSearchPara relatedSearchParaCustom"
                              style={{ minWidth: "73%" }}
                              onClick={() => {
                                // handleRecentSearch(option)
                                handleSearchClick(entry.type, entry);
                              }}
                            >
                              <h4 className={entry.type == "user" ? "mb-0" : "mb-1"}>{entry.title}</h4>
                              {entry.type == "user" ? (
                                <p className="mb-0">@{entry.content}</p>
                              ) : entry.type == "video" || entry.key == "news" ? (
                                <p
                                  className="mb-0"
                                  dangerouslySetInnerHTML={{
                                    __html: entry.content,
                                  }}
                                ></p>
                              ) : (
                                <p
                                  className="mb-0"
                                  dangerouslySetInnerHTML={{
                                    __html: entry.content.replace(/<[^>]*>?/gm, "").substring(0, 130),
                                  }}
                                ></p>
                              )}
                            </div>
                            <img
                              onClick={() => handleClearRecentSearch(index)}
                              src="/images/svg-icons/crossIcon2.svg"
                              alt="search"
                              className="img-fluid height11"
                            />
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className="bodyOverlay"
        style={{
          display: showResults ? "block" : "none",
        }}
        onClick={() => {
          setShowResults(false);
          setSearchText("");
        }}
      ></div>
    </div>
  );
}

export default SearchBar;
