import { Link } from "react-router-dom";
import util from "../../util/util";
import { useState, useEffect } from "react";
import VideoAction from "../../shared/VideoAction";
import { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import VideoService from "../../services/VideoService";
let nfObject = new Intl.NumberFormat("en-US");
function VideoCard({ video, search, actionCallBack }) {
  useEffect(() => {
    fixVideoLengthFormat();
  }, []);
  const globalCtx = useContext(GlobalContext);
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const { updateBookmark, checkBookmark } = globalCtx;
  const [videoLength, setVideoLength] = useState(video.videoLength);
  const fixVideoLengthFormat = () => {
    if (video.videoLength.split(":")[1].length == 1) {
      setVideoLength(video.videoLength.split(":")[0] + ":0" + video.videoLength.split(":")[1]);
    }
  };
  const [showBottomDetails, setShowBottomDetails] = useState(false);
  useEffect(() => {
    if (showBottomDetails) {
    }
  }, [showBottomDetails]);
  return (
    <div
      className={"col-sm-6 col-lg-6 col-xl-3 col-xxl-2  cardsBox cardsBoxVideo "}
      // onMouseOver={() => setShowBottomDetails(true)}
      onMouseLeave={() => setShowBottomDetails(false)}
    >
      <div className="updates_card updateCard updateCardBlog updateCardBlogCustom">
        {/* <Link to={"/videos/" + video._id}> */}
        <div className="updates_box upDatesBox upBlogBox videoBannerCard">
          <Link to={"/videos/" + video._id}>
            <div className="sliderBoxImg text-center">
              <img
                src={video.video_banner}
                alt=""
                className="img-fluid"
                // style={{ height: "120px", width: "206px" }}
              />
              <p>{videoLength}</p>
            </div>
          </Link>
          <div className="d-flex boxHeadingGroup">
            <div className="box_heading d-flex justify-content-between">
              <h4 className="m-0 blogHeading">{video.video_title}</h4>
              <VideoAction videoDetail={video} search={search} actionCallBack={actionCallBack} />
              {/* <div className="dropdown">
                <img
                  src="/images/svg-icons/vertical-dots.svg"
                  data-bs-toggle="dropdown"
                  className="img-fluid"
                  style={{ width: "10px", height: "15px" }}
                />
                <ul className="dropdown-menu videoDropDown">
                  <li>
                    <a className="dropdown-item" href="javascript:void(0);">
                      <img src="/images/svg-icons/vertical-dots.svg" alt="share-now" className="img-fluid me-1" /> Play
                      video
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="javascript:void(0);">
                      <img src="/images/svg-icons/vertical-dots.svg" alt="share-now" className="img-fluid me-1" /> View
                      Youtube Channel
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="javascript:void(0);">
                      <img src="/images/svg-icons/vertical-dots.svg" alt="share-now" className="img-fluid me-1" />{" "}
                      Bookmark
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="javascript:void(0);">
                      <img src="/images/svg-icons/vertical-dots.svg" alt="share-now" className="img-fluid me-1" /> Share
                    </a>
                  </li>
                  <hr />
                  <li>
                    <a className="dropdown-item" href="javascript:void(0);">
                      <img src="/images/svg-icons/vertical-dots.svg" alt="share-now" className="img-fluid me-1" /> Not
                      interested
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="javascript:void(0);">
                      <img src="/images/svg-icons/vertical-dots.svg" alt="share-now" className="img-fluid me-1" /> Don’t
                      recommend channel
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="javascript:void(0);">
                      <img src="/images/svg-icons/vertical-dots.svg" alt="share-now" className="img-fluid me-1" />{" "}
                      Report
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
            <div className="youtubeViewCount">
              <h4>{nfObject.format(video.views)} views on YouTube</h4>
            </div>
            {showBottomDetails && (
              <div className="d-flex marginTop29 justify-content-between">
                <div className="channelName">
                  <img src={video?.video_banner} />
                  <span>
                    {video?.channel_name?.length > 15 ? (
                      <>{video?.channel_name?.slice(0, 12)}...</>
                    ) : (
                      video?.channel_name
                    )}
                  </span>
                </div>
                <div className="d-flex">
                  <div
                    className="bookmarkDiv"
                    onClick={() => {
                      updateBookmark(video._id, "video");
                    }}
                  >
                    {/* <img src="/images/svg-icons/bookMarkIcon.svg"/> */}
                    {checkBookmark(video._id) ? (
                      <img
                        src="/images/svg-icons/bookmark-active.svg"
                        alt="bookmark"
                        className="img-fluid bookmarkActive"
                      />
                    ) : (
                      <img
                        src="/images/svg-icons/bookmark-normal.svg"
                        alt="bookmark"
                        className="img-fluid bookmarkNormal"
                      />
                    )}
                  </div>
                  <div className="bookmarkDiv marginLeft8">
                    <img src="/images/svg-icons/youtubeShareIcon.svg" />
                  </div>
                </div>
              </div>
            )}
            {/* <div
                className="box_heading box_post d-flex align-items-center justify-content-between"
                style={{ marginTop: "auto" }}
              >
                <div className="postingName">
                  <img
                    src="/images/svg-icons/doodle.svg"
                    alt="doodle"
                    className="img-fluid"
                  />
                  <p className="mb-0">{video.channel_name}</p>
                </div>
                <div className="postDay">
                  <p className="mb-0">{util.timeSince(video.publishedAt)}</p>
                </div>
              </div> */}
          </div>
        </div>
        {/* </Link> */}
      </div>
    </div>
  );
}

export default VideoCard;
