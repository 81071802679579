import React, { useContext, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "../pages/Home/HomePage";
import Legal from "../pages/Legal/Legal";
import { GlobalContext } from "../context/GlobalContext";
import DefaultLayout from "../containers/DefaultLayout";
import Glossary from "../pages/Glossary/Glossary";
import About from "../pages/About/About";
import Careers from "../pages/Careers/Careers";
import Partners from "../pages/Partners/Partners";
import Contributors from "../pages/Contributors/Contributors";
import Faq from "../pages/Faq/Faq";
import SupportList from "../pages/Support/SupportList";
import OpenNewTicket from "../pages/Support/OpenNewTicket";
import SupportTicketExpand from "../pages/Support/SupportTicketExpand";
import Blogs from "../pages/Blogs/Blogs";
import Videos from "../pages/Videos/Videos";
import BlogArticle from "../pages/Blogs/BlogArticle";
import Account from "../pages/Account/Account";
import NotFound from "../pages/NotFound/NotFound";
import Courses from "../pages/Courses/Courses";
import CourseDetail from "../pages/Courses/CourseDetail";
import CourseInfo from "../pages/Courses/CourseInfo";
import BookmarkService from "../services/BookmarkService";
import Bookmark from "../pages/Bookmark/Bookmark";
import SearchResult from "../pages/SearcheResult/SearchResult";
import util from "../util/util";
import CourseQuiz from "../pages/Courses/CourseQuiz";
import BetaPassword from "../pages/BetaPassword/BetaPassword";
import UserService from "../services/UserService";
import SettingService from "../services/SettingService";
import PolicyService from "../services/PolicyService";
import SupportSignUP from "../pages/Support/SupportSignUP";
import SupportReply from "../pages/Support/SupportReply";
import VideoDetail from "../pages/Videos/VideoDetail";
import News from "../pages/News/News";
import NftProfiles from "../pages/NftProfiles/NftProfiles";
import NftProfileDetail from "../pages/NftProfiles/NftProfileDetail";

const serv = new BookmarkService();
const policyServ = new PolicyService();
const userServ = new UserService();
const settingServ = new SettingService();
function AllRoutes() {
  const globalCtx = useContext(GlobalContext);
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const [firstTimeView, setFirstTimeView] = globalCtx.firstTimeView;
  const [setting, setSetting] = globalCtx.setting;
  const [policies, setPolicies] = globalCtx.policies;
  useEffect(() => {
    if (isAuthentiCated == true) {
      getBookmarkList();
    }
  }, [isAuthentiCated]);

  async function getBookmarkList() {
    let user = util.getUserLocal();
    let search = {
      filter: {
        user_id: user._id,
      },
    };
    let respData = await serv.listAllBookmark(search);
    if (respData && respData.data.length > 0) {
      globalCtx.setBookmarks([...respData.data[0].blogs, ...respData.data[0].courses, ...respData.data[0].profiles]);
    }
  }
  async function getSetting() {
    let respData = await settingServ.getDetails();
    if (respData.data) {
      setSetting(respData.data);
    }
  }
  async function getPolicyTitle() {
    let respData = await policyServ.listAllTitle({});
    if (respData.data) {
      setPolicies(respData.data);
    }
  }
  const updateTimer = async () => {
    try {
      if (isAuthentiCated) {
        let resp = await userServ.loginTimer({ time: 1 });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSetting();
    getPolicyTitle();
    const interval = setInterval(updateTimer, 60000);
    return () => clearInterval(interval);
  }, []);

  return setting?.general?.is_protected && firstTimeView == "yes" ? (
    <BetaPassword />
  ) : (
    <DefaultLayout>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/reset/:passwordtoken" element={<HomePage />} />
        <Route exact path="/legal/:page" element={<Legal />} />
        <Route exact path="/glossary" element={<Glossary />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/courses" element={<Courses />} />
        <Route exact path="/courses/info/:courseId" element={<CourseInfo />} />
        <Route exact path="/courses/quiz/:courseId" element={<CourseQuiz />} />
        <Route exact path="/courses/course/:courseId" element={<CourseDetail />} />
        <Route exact path="/careers" element={<Careers />} />
        <Route exact path="/partners" element={<Partners />} />
        <Route exact path="/contributors" element={<Contributors />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/support" element={<SupportList />} />
        <Route exact path="/support_new_ticket" element={<OpenNewTicket />} />
        <Route exact path="/support_signup" element={<SupportSignUP />} />
        <Route exact path="/support_reply/:ticketId" element={<SupportReply />} />
        <Route exact path="/support_ticket_expand/:ticketId" element={<SupportTicketExpand />} />
        <Route exact path="/blogs/:blogId" element={<BlogArticle />} />
        <Route exact path="/blogs" element={<Blogs />} />
        <Route exact path="/videos" element={<Videos />} />
        <Route exact path="/videos/:videoId" element={<VideoDetail />} />
        <Route exact path="/news" element={<News />} />
        <Route exact path="/bookmarks" element={isAuthentiCated ? <Bookmark /> : <Navigate to="/" />} />
        <Route exact path="/search/:id" element={<SearchResult />} />
        <Route exact path="/search" element={<SearchResult />} />
        <Route exact path="/profile" element={isAuthentiCated ? <Account /> : <Navigate to="/" />} />
        <Route exact path="/profile/:uname" element={<Account />} />
        <Route exact path="/notfound" element={<NotFound />} />
        <Route exact path="/refer/:refertoken" element={<HomePage />} />
        <Route exact path="/newsletter/active/:token" element={<HomePage />} />
        <Route exact path="/nftprofile" element={<NftProfiles />} />
        <Route exact path="/nftprofile/:id" element={<NftProfileDetail />} />
        <Route exact path="/:customUrl" element={<HomePage />} />
        <Route path="*" element={<Navigate to="/notfound" />} />
      </Routes>
    </DefaultLayout>
  );
}

export default AllRoutes;
