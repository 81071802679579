import { useContext, useEffect, useState } from "react";
import GlossaryCard from "./GlossaryCard";
import GlossaryService from "../../services/GlossaryService";
import ScrollMoreProvider from "../../shared/Scroll/ScrollMoreProvider";
import TitleCard2 from "../../shared/TitleCard2";
import PageSearch from "../../shared/PageSearch";
import GlobalContext from "../../context/GlobalContext";
import GlossaryPopup from "../../popups/Glossary/Glossary";
import Loading from "../../shared/Loading";
import LoginSignupCard from "../../shared/LoginSignupCard";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import PageHeader from "../../shared/PageHeader";

const serv = new GlossaryService();

function Glossary() {
  const globalCtx = useContext(GlobalContext);
  const [searchDtl, setSearchDtl] = globalCtx.searchDtl;
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const [setting, setSetting] = globalCtx.setting;
  const [records, setRecords] = useState([]);
  const [showGlossaryPopup, setShowGlossaryPopup] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [recordsCount, setRecordsCount] = useState(0);
  const [sortLabel, setSortLabel] = useState("Recently Added");
  const [sortBy, setSortBy] = useState({ createdAt: "desc" });
  const [search, setSearch] = useState({
    filter: { searchText: searchDtl, is_active: true, approved: true},
    start: 0,
    length: 16,
    sortBy: { createdAt: "desc" },
  });
  let loading = false;

  useEffect(() => {
    getList();
  }, [search]);

  useEffect(() => {
    searchCallBack(searchDtl);
  }, [searchDtl, sortBy]);
  let handleGlossaryPopup = () => {
    setShowGlossaryPopup(!showGlossaryPopup);
  };
  async function getList() {
    if (loading === false && (recordsCount == 0 || recordsCount > records.length)) {
      loading = true;
      let respData = await serv.listAll(search);
      setRecords(recordsCount == 0 ? respData.data : [...records, ...respData.data]);
      setRecordsCount(respData.total);
      setShowLoading(false);
    }
  }

  function reachedBottomCall() {
    let searchTemp = { ...search };
    searchTemp.start = search.start + search.length;
    setSearch(searchTemp);
  }

  function searchCallBack(value) {
    let searchTemp = { ...search };
    searchTemp.start = 0;
    searchTemp.filter.searchText = value;
    setRecordsCount(0);
    setSearch(searchTemp);
  }
  function sortHandler(sort, label) {
    let searchTemp = { ...search };
    searchTemp.start = 0;
    searchTemp.sortBy = sort;
    setRecordsCount(0);
    setSearch(searchTemp);
    setSortBy(sort);
    setSortLabel(label);
  }
  if (showLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="ljSectionData w-100 clearfix glossarylist" id="ljSectionData">
        <Helmet>
          <title>{setting?.seo?.glossary?.title || ""}</title>
          <meta name="description" content={setting?.seo?.glossary?.meta_description || ""} />
          <meta name="keywords" content={setting?.seo?.glossary?.tags || ""} />
          <meta property="og:title" content={setting?.seo?.glossary?.title || ""} />
          <meta property="og:description" content={setting?.seo?.glossary?.meta_description || ""} />
          <meta property="og:image" content={setting?.seo?.social_share_image} />
        </Helmet>
        <PageHeader
          type="glossary"
          searchCallBack={searchCallBack}
          search={{ ...search, sortLabel, sortHandler }}
          handlePopUp={handleGlossaryPopup}
          titleCard={{
            image: "/images/svg-icons/glossary.svg",
            title: "Glossary",
            content:
              "We don’t want to bamboozle you with jargon you don’t understand. If you come across a word or phrase you don’t understand, this is where you’ll find a concise definition.",
            type: "glossary",
          }}
          searchType={{ type: "glossary", placeholder: "Search glossary" }}
          loginTitle="Please login/sign up to contribute a word to our library."
          rightActionCard={{
            heading: "Submit a Word/Slang",
            desc: "There are new words/slangs coined everyday in the web3 space & you can help us index them by submitting your entries below. ",
            buttonName: "Contribute",
            handlePopUp: handleGlossaryPopup,
          }}
        />
        {/* <div className="glossaryTitleSec">
          <div className="row glossaryTitleSecRow">
            <div className="col-md-8 glossaryTitleCol">
              <TitleCard2
                image="/images/svg-icons/glossary.svg"
                title="Glossary"
                type="glossary"
                content="We don’t want to bamboozle you with jargon you don’t understand. If you come across a word or phrase you don’t understand, this is where you’ll find a concise definition."
              />
              <div className="filterSec d-flex align-items-center glosaary-search-custom">
                <PageSearch
                  placeholder="Search glossary"
                  searchCallBack={searchCallBack}
                  searchText={search.filter.searchText}
                />
                <div className="mostbookmarked">
                  <div className="dropdown">
                    <Link to="#" className="btnBookmarkedToggle" data-bs-toggle="dropdown">
                      <img src="/images/svg-icons/most-bookmarked.svg" alt="search" className="img-fluid" />
                      {sortLabel}
                      <img
                        src="/images/svg-icons/collapse-arrow.svg"
                        alt="search"
                        className="img-fluid collapse-arrow"
                      />
                    </Link>
                    <ul className="dropdown-menu">
                      <li>
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={() => sortHandler({ createdAt: "desc" }, "Recently Added")}
                        >
                          Recently added
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#" onClick={() => sortHandler({ word: "asc" }, "A to Z")}>
                          A to Z
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#" onClick={() => sortHandler({ word: "desc" }, "Z to A")}>
                          Z to A
                        </Link>
                      </li>

                      <li>
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={() => sortHandler({ createdAt: "asc" }, "Oldest")}
                        >
                          Oldest
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 ps-md-0 glossaryTitleCol">
              {isAuthentiCated ? (
                <div className="titleCard submitSlang card">
                  <h4>Submit a Word/Slang</h4>
                  <p className="mb-0">
                    New words/slangs are created everyday in this space & you can help us index them by submitting your
                    entries below.
                  </p>
                  <a href="javascript:void(0)" className="btn btnInfo" onClick={handleGlossaryPopup}>
                    Contribute
                  </a>
                </div>
              ) : (
                <LoginSignupCard title="Please login/sign up to contribute a word to our library." />
              )}
            </div>
          </div>
        </div> */}
        <div className="allGlossaryCard">
          <div className="allUpdateCardList allGlossaryCardList">
            <div className="updates_card_section updatesCardSection mb-3">
              {records.length > 0 && (
                <ScrollMoreProvider
                  className="row updatesCardSectionRow"
                  id="glossary list"
                  reachedBottomCall={reachedBottomCall}
                  useWindowScroll={true}
                >
                  {records.map((g) => {
                    return <GlossaryCard key={g._id} glossary={g} />;
                  })}
                </ScrollMoreProvider>
              )}
              {records.length < recordsCount && <Loading isSmall={true} />}
            </div>
          </div>
        </div>
      </div>
      {isAuthentiCated && showGlossaryPopup && <GlossaryPopup onClose={handleGlossaryPopup} />}
    </>
  );
}

export default Glossary;
