import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import referEarnService from "../../services/ReferEarnService";
import userService from "../../services/UserService";
import GlobalContext from "../../context/GlobalContext";
const serv = new referEarnService();
const userServ = new userService();

function ReferAndEarn({ onClose }) {
  const globalCtx = useContext(GlobalContext);
  const [user, setUser] = globalCtx.user;
  const [setting, setSetting] = globalCtx.setting
  const [url, setUrl] = useState(window.location.origin);
  const[buttonText, setButtonText]= useState("Copy Link")
  const [token, setToken] = useState("");
  useEffect(() => {
    sendToken();
    getUser();
  }, []);
  const getUser = async () => {
    try {
      let resp = await userServ.getUser(user._id)
      if (resp.data) {
        setUser(resp.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const sendToken = async () => {
    let tokenReferance =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let createToken = [...Array(6)].reduce(
      (a) => a + tokenReferance[~~(Math.random() * tokenReferance.length)],
      ""
    );
    let respData = await serv.saveReferEarnRecord({ token: createToken });
    if (respData && respData.data) {
      setToken(respData.data.token);
    }
  };
  return (
    <>
      <div
        className="modal commanModel SignupPopup showBlock"
        id="Signin"
        onClick={() => {
          onClose();
        }}
      >
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center">
            <div className="modal-dialog">
              <div
                className="modal-content"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {/* <!-- Modal Header --> */}
                <div className="modal-header">
                  <h4 className="modal-title">Refer & Earn</h4>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                      onClose();
                    }}
                  ></button>
                </div>
                {/* <!-- Modal body --> */}
                <div
                  className="refer_earn_count"
                // style={{ paddingBottom: "20px", paddingTop: "6px" }}
                >
                  <div className="coursesPoints">
                    <h4>REFERRAL REWARDS</h4>
                    <div className="coursesLogo d-flex align-items-center">
                      <div className="learnLogoIcon">
                        <img
                          src="/images/single_log.png"
                          alt="logoicon"
                          className="img-fluid"
                        />
                      </div>
                      <div className="coinPoints">
                        <p className="mb-0">{user?.referalReward || 0} COINS</p>
                      </div>
                    </div>
                  </div>
                  <div className="coursesReferred">
                    <h4>USERS REFERRED</h4>
                    <div className="mainCoursesReferred d-flex align-items-center">
                      <div className="courBtnInfo flex-grow-1">
                        <p
                          className="coursesReferredCode mb-0"
                          style={{ textAlign: "right", fontSize: "15px" }}
                        >
                          {user?.userReffered || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="connectWalletOuter">
                    <div
                      className="modelFormInput"
                    // style={{ paddingTop: "20px" }}
                    >
                      <label for="signinemail">Unique Invite Link</label>
                      <div
                        className="input-group"
                        style={{ alignItems: "baseline", letterSpacing: 0 }}
                      >
                        <input
                          style={{ letterSpacing: 0 }}
                          className="form-control"
                          placeholder="https://mavefi.com/invite/fnif3e"
                          name="email"
                          value={`${url}/refer/${token}`}
                        />
                        <span
                          className="groupText"
                          onClick={() =>{
                            navigator.clipboard.writeText(
                              `${url}/refer/${token}`
                            )
                            setButtonText("Copied to clipboard")
                            setTimeout(()=>{
                              onClose()
                            },2000)
                          }    
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <span className="custom-connect copy-link-custom">
                            {buttonText}
                          </span>
                        </span>
                      </div>
                    </div>

                    <div className="modelSignBtn" style={{ marginTop: "15px" }}>
                      <div className="d-flex share-flex">
                        <a
                          href={
                            "https://www.facebook.com/sharer/sharer.php?u=" +
                            encodeURI(`${url}/refer/${token}`)
                          }
                          className={"btn btn-primary btn-block custom-share"}
                          style={{ background: "#4A66AD" }}
                          target="_blank"
                        >
                          Share&thinsp;
                          <img src="/images/svg-icons/facebook_icon.svg" />
                        </a>
                        <a
                          href={
                            "http://twitter.com/share?url=" +
                            encodeURI(`${url}/refer/${token}`)
                          }
                          className={"btn btn-primary btn-block custom-share"}
                          style={{
                            background: "#1DA1F2",
                            border: "1px solid rgba(114, 123, 130, 0.25)",
                          }}
                          target="_blank"
                        >
                          Tweet&thinsp;
                          <img src="/images/svg-icons/twitter_icon.svg" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Modal footer --> */}
                <div className="modal-footer justify-content-center">
                  <div className="footerLink">
                    <p className="mb-0 custom-popup-footer">
                      For every friend who signs up using your invite link, we
                      will reward you with {setting?.general?.refer_reward} COINS.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
}

export default ReferAndEarn;
