import util from "../util/util";

export default class NewsSubscriptionService {
  addEmail(email) {
    return util.sendApiRequest("/newsletters/", "POST", true, email).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  verifyEmail(token) {
    return util.sendApiRequest("/newsletters/verify/" + token, "POST", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
}
