import { useContext, useEffect, useState } from "react";
import KycService from "../../services/RequestKycService";
import { useFormik } from "formik";
import { GlobalContext } from "../../context/GlobalContext";
import * as Yup from "yup";
import moment from "moment";
import Loading from "../../shared/Loading";
const serv = new KycService();
const ValidateSchema = Yup.object({
  full_name: Yup.string().required("Name is a required field"),
  address: Yup.string().required("address is a required field"),
  country: Yup.string().required("Country is a required field"),
  date_of_birth: Yup.string().required("Date of birth is a required field"),
  doc_front: Yup.string().required("doc front side is required"),
  doc_back: Yup.string().required("doc front side is required"),
  // accept: Yup.boolean().required("The terms and conditions must be accepted.")
  accept: Yup.boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted."),
});
function RequestKyc({ onClose }) {
  const globalCtx = useContext(GlobalContext);
  const [user, setUser] = globalCtx.user;
  const [prevInfo, setPrevInfo] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [frontImgPrev, setFrontImgPrev] = useState("");
  const [backImgPrev, setBackImgPrev] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(true);
  const [accountObj, setAccountObj] = new useState({
    full_name: "",
    address: "",
    country: "",
    date_of_birth: "",
    doc_front: "",
    doc_back: "",
    accept: true,
  });
  useEffect(() => {
    getKYCDetails();
    getCountryList();
  }, []);
  const getKYCDetails = async () => {
    let id = user._id;

    try {
      let resp = await serv.getDetails(id);
      if (resp.data) {
        let new_obj = {
          full_name: resp.data?.full_name,
          address: resp.data?.address,
          country: resp.data.country,
          // date_of_birth: new Date(resp.data?.date_of_birth),
          date_of_birth: moment(resp.data?.date_of_birth).format("YYYY-MM-DD"),
          doc_front: resp.data?.doc_front,
          doc_back: resp.data?.doc_back,
          accept: true,
        };
        setBackImgPrev(resp.data.doc_back);
        setFrontImgPrev(resp.data.doc_front);
        setAccountObj(new_obj);
        setPrevInfo(true);
        // setUser({ ...user, ...new_obj });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getCountryList = async () => {
    try {
      let resp = await serv.listAllCountries({});
      if (resp.data) {
        setCountryList([...resp.data]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    let id = user._id;
    const formData = new FormData();
    formData.append("full_name", values.full_name);
    formData.append("address", values.address);
    formData.append("country", values.country);
    formData.append("date_of_birth", values.date_of_birth);
    formData.append("doc_front", values.doc_front);
    formData.append("doc_back", values.doc_back);
    formData.append("userId", id);
    try {
      // if (prevInfo) {
      //     const resp = await serv.editDetail(formData);
      // } else {
      const resp = await serv.addDetail(formData);
      setShowSuccessPopup(false);
      setIsLoading(false);
      resetForm();
      getKYCDetails();
    } catch (err) {
      console.log(err);
    }
  };
  const formik = useFormik({
    initialValues: accountObj,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  return (
    <>
      {showSuccessPopup ? (
        <div
          className="modal editModal showBlock popupBackground"
          id="requestKyc"
          onClick={(e) => {
            if (e.target == e.currentTarget) {
              onClose();
            }
          }}
        >
          <div className="vertical-alignment-helper">
            <div className="vertical-align-center">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header align-items-baseline">
                    <div>
                      <h4 className="modal-title">
                        <img src="images/svg-icons/twitter-verified-badge.svg" className="me-1" /> Request KYC
                      </h4>
                      <p className="mb-0">
                        Complete user verification for future benefits. <img src="images/svg-icons/eye_icon.png" />
                      </p>
                    </div>
                    <button onClick={() => onClose()} type="button" className="btn-close" data-bs-dismiss="modal" />
                  </div>
                  {/* Modal body */}
                  <div className="modal-body p-0">
                    <div className="profilemodal_contet">
                      <div className="requestKyc_form mt-0">
                        <h6 className="editform_heading">Basic Details</h6>
                        <form onSubmit={formik.handleSubmit}>
                          <div className="row editformgrid">
                            <div className="col-md-6 editformcol">
                              <div className="commonfield customBottomMargin">
                                <label htmlFor="fullname">Name</label>
                                {/* <input type="text" className="form-control" id="fullname" placeholder="Full name" /> */}
                                <input
                                  type="text"
                                  className={
                                    "form-control" +
                                    (formik.touched.full_name && formik.errors.full_name ? " border-red" : "")
                                  }
                                  placeholder="Full Name"
                                  name="full_name"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.full_name}
                                />
                                {formik.touched.full_name && formik.errors.full_name ? (
                                  <div className="formik-errors bg-error">{formik.errors.full_name}</div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6 editformcol">
                              <div className="commonfield customBottomMargin">
                                <label htmlFor="dobirth">Date of birth</label>
                                {/* <input type="text" className="form-control" id="dobirth" placeholder="Date of birth" /> */}
                                <input
                                  type="date"
                                  className={
                                    "form-control " +
                                    (formik.touched.date_of_birth && formik.errors.date_of_birth ? " border-red" : "")
                                  }
                                  placeholder="25 May 2001"
                                  name="date_of_birth"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.date_of_birth}
                                />
                                {formik.touched.date_of_birth && formik.errors.date_of_birth ? (
                                  <div className="formik-errors bg-error">{formik.errors.date_of_birth}</div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6 editformcol">
                              <div className="commonfield customBottomMargin">
                                <label htmlFor="address">Address</label>
                                {/* <textarea className="form-control" rows={5} id="address" placeholder="Address" defaultValue={""} /> */}
                                <textarea
                                  rows={5}
                                  cols={23}
                                  className={
                                    "form-control " +
                                    (formik.touched.address && formik.errors.address ? " border-red" : "")
                                  }
                                  placeholder="Address"
                                  name="address"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.address}
                                />
                                {formik.touched.address && formik.errors.address ? (
                                  <div className="formik-errors bg-error">{formik.errors.address}</div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6 editformcol">
                              <div className="commonfield customBottomMargin">
                                <label htmlFor="select_country">Country</label>
                                <div className="editprof_select img-select">
                                  <span
                                    className="img-option this-logo"
                                    style={{
                                      background: "url(images/svg-icons/Globe.svg) no-repeat",
                                    }}
                                  />

                                  <select
                                    className={
                                      "form-control" +
                                      (formik.touched.country && formik.errors.country ? " border-red" : "")
                                    }
                                    name="country"
                                    onChange={formik.handleChange}
                                    value={formik.values.country}
                                  >
                                    <option>Select Country</option>
                                    {countryList.length > 0 &&
                                      countryList.map((item, idx) => (
                                        <option value={item.country_name} key={idx}>
                                          {item.country_name}
                                        </option>
                                      ))}
                                  </select>
                                  {formik.touched.country && formik.errors.country ? (
                                    <div className="formik-errors bg-error">{formik.errors.country}</div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <h6 className="editform_heading">Identity Proof</h6>
                          <div className="row editformgrid">
                            <div className="col-md-6 editformcol">
                              <div className="commonfield customBottomMargin">
                                <label>Front Side</label>
                                {/* <input type="file" className="form-control" id="front_sideFirst" placeholder="Date of birth" hidden /> */}
                                <input
                                  type="file"
                                  className={
                                    "form-control" +
                                    (formik.touched.doc_front && formik.errors.doc_front ? " border-red" : "")
                                  }
                                  id="front_sideFirst"
                                  hidden
                                  onChange={(event) => {
                                    formik.setFieldValue("doc_front", event.currentTarget.files[0]);
                                    setFrontImgPrev(URL.createObjectURL(event.target.files[0]));
                                  }}
                                />
                                {formik.touched.doc_front && formik.errors.doc_front ? (
                                  <div className="formik-errors bg-error">{formik.errors.doc_front}</div>
                                ) : null}
                                <label
                                  className="upload_identy paddingLeft"
                                  for="front_sideFirst"
                                  id="image_uploderfirst"
                                >
                                  {frontImgPrev == "" ? (
                                    <img src="images/svg-icons/addcamera.svg" className="marginLeft20" />
                                  ) : (
                                    <img src={frontImgPrev} className="prevImg" />
                                  )}
                                  <span>Upload front side</span>
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6 editformcol">
                              <div className="commonfield customBottomMargin">
                                <label>Back Side</label>
                                {/* <input
                                type="file"
                                className="form-control"
                                id="front_sideSecond"
                                placeholder="Date of birth"
                                hidden
                                onChange={(event) => {
                                  formik.setFieldValue(
                                    "doc_back",
                                    event.currentTarget.files[0]
                                  );
                                  setBackImgPrev(
                                    URL.createObjectURL(event.target.files[0])
                                  );
                                }}
                              /> */}
                                <input
                                  type="file"
                                  className={
                                    "form-control" +
                                    (formik.touched.doc_back && formik.errors.doc_back ? " border-red" : "")
                                  }
                                  id="front_sideSecond"
                                  hidden
                                  onChange={(event) => {
                                    formik.setFieldValue("doc_back", event.currentTarget.files[0]);
                                    setBackImgPrev(URL.createObjectURL(event.target.files[0]));
                                  }}
                                />
                                {formik.touched.doc_back && formik.errors.doc_back ? (
                                  <div className="formik-errors bg-error">{formik.errors.doc_back}</div>
                                ) : null}
                                <label
                                  for="front_sideSecond"
                                  className="upload_identy paddingLeft"
                                  id="image_uploderSecond"
                                >
                                  {backImgPrev == "" ? (
                                    <img src="images/svg-icons/addcamera.svg" className="marginLeft20" />
                                  ) : (
                                    <img src={backImgPrev} className="prevImg" />
                                  )}
                                  {}
                                  <span>Upload back side</span>
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6 editformcol editformcolcustom">
                              <p className="idProof_para">
                                We accept National ID Cards, National ID Proofs &amp; Passports for identity
                                verification.
                              </p>
                              <div className="accept_terms">
                                <div className="form-group">
                                  <input
                                    id="css"
                                    className="form-check-input"
                                    type="checkbox"
                                    name="accept"
                                    checked={formik.values.accept}
                                    onClick={() => formik.setFieldValue("accept", !formik.values.accept)}
                                    // value={formik.values.accept}
                                  />
                                  <label htmlFor="css" />
                                  <span className="acceptterms_text">
                                    Accept our <a href="javascript:void(0)">Terms of Service &amp; Disclaimer</a>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 editformcol">
                              <div className="modelSignBtn editprofile_save">
                                <button
                                  type="submit"
                                  className={
                                    "btn btn-primary btn-block" +
                                    (!(formik.isValid && formik.dirty) || isLoading ? " disabled" : "")
                                  }
                                >
                                  Submit {isLoading && <Loading isInline={true} />}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="modal editModal showBlock popupBackground"
          id="requestKyc"
          onClick={(e) => {
            if (e.target == e.currentTarget) {
              onClose();
            }
          }}
        >
          <div className="vertical-alignment-helper">
            <div className="vertical-align-center">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header align-items-baseline">
                    <div>
                      <h4 className="modal-title">
                        <img src="images/svg-icons/twitter-verified-badge.svg" className="me-1" /> Request KYC
                      </h4>
                      <p className="mb-0">
                        Complete user verification for future benefits. <img src="images/svg-icons/eye_icon.png" />
                      </p>
                    </div>
                    <button onClick={() => onClose()} type="button" className="btn-close" data-bs-dismiss="modal" />
                  </div>
                  {/* Modal body */}
                  <div className="modal-body p-0">
                    <div className="profilemodal_contet">
                      <div className="requestKyc_form mt-0 kycSuccessMsg">
                        <img src="/images/svg-icons/confirmIcon.svg" />
                        <p className="kycSuccMess">
                          Your kyc application has been received successfully. Someone from our team will take a look &
                          approve it soon.
                        </p>
                      </div>
                      <div className="kycSuccBtn">
                        <button onClick={() => onClose()}>Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RequestKyc;
