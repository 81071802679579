import { useContext, useEffect, useState } from "react";
import AccountService from "../../services/AccountService";
import SkillService from "../../services/SkillService";
import { useFormik } from "formik";
import { GlobalContext } from "../../context/GlobalContext";
import * as Yup from "yup";
import moment from "moment";
import Loading from "../../shared/Loading";
const serv = new AccountService();
const skillServ = new SkillService();
const ValidateSchema = Yup.object({
  skill_name: Yup.string().required("Skill is a required field"),
  logo: Yup.string(),
  level: Yup.string().required("Level is a required field"),
});
let level = [
  "Beginner (0-1 year)",
  "Junior (1-2 years)",
  "Professional (2-5 years)",
  "Intermediate (5-10 years)",
  "Expert (10+ years)",
];
function Skill({ onClose, skillId }) {
  const globalCtx = useContext(GlobalContext);
  const [user, setUser] = globalCtx.user;
  const [skill, setSkill] = useState([]);
  const [skillsList, setSkillsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [accountObj, setAccountObj] = new useState({
    skill_name: "",
    logo: "",
    level: "",
  });
  useEffect(() => {
    getAccountDetails();
  }, []);
  const getAccountDetails = async () => {
    let id = user._id;

    try {
      let resp = await serv.getUser(id);
      if (resp.data.skill) {
        setSkill(resp.data.skill);
        if (skillId !== "") {
          resp.data.skill.map((item) => {
            if (item._id == skillId) {
              let new_obj = {
                skill_name: item.skill_name,
                logo: item.logo,
                level: item.level,
              };
              setAccountObj(new_obj);
            }
          });
        }
        // setUser({ ...user, ...new_obj });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getSkillList = async (searchText) => {
    try {
      let obj = {
        filter: {
          searchText: searchText,
        },
      };
      let resp = await skillServ.listAll(obj);
      if (resp.data) {
        setSkillsList(resp.data);
        // setUser({ ...user, ...new_obj });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    let id = user._id;
    const formData = new FormData();
    let experienceArr;
    if (skillId !== "") {
      formData.append("skillId", skillId);
      experienceArr = skill.map((item) => {
        if (skillId == item._id) {
          item = {
            skill_name: item.skill_name,
            logo: item.logo,
            level: item.level,
            _id: skillId,
          };
        }
        return item;
      });
    } else {
      experienceArr = [
        ...skill,
        {
          skill_name: values.skill_name,
          logo: values.logo,
          level: values.level,
        },
      ];
    }
    formData.append("skill", JSON.stringify(experienceArr));
    if (values.logo) {
      formData.append("logo", values.logo);
    }
    formData.append("_id", id);
    formData.append("email", user.email);
    try {
      const resp = await serv.editUser(formData);
      setIsLoading(false);
      resetForm();
      getAccountDetails();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };
  const handleDelete = async () => {
    const formData = new FormData();
    let experienceArr = skill.filter((item) => item._id !== skillId);

    formData.append("skill", JSON.stringify(experienceArr));
    formData.append("_id", user._id);
    formData.append("email", user.email);
    try {
      const resp = await serv.editUser(formData);
      getAccountDetails();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };
  const formik = useFormik({
    initialValues: accountObj,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  console.log(skillsList);
  return (
    <>
      <div
        className="modal editModal showBlock"
        id="workExperience"
        onClick={(e) => {
          if (e.target == e.currentTarget) {
            onClose();
          }
        }}
      >
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center">
            <div className="modal-dialog add_experienceModal">
              <div className="modal-content">
                <div className="modal-header align-items-baseline">
                  <h4 className="modal-title modal-title-skill"> Add Skill</h4>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => onClose()} />
                </div>
                {/* Modal body */}
                <div className="modal-body p-0">
                  <div className="profilemodal_contet">
                    <div className="requestKyc_form mt-0 skillBodyCustom paddingTop15">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="row editformgrid">
                          <div className="col-12 editformcol">
                            {formik.values.logo !== "" ? (
                              <>
                                <div className="skillLogoImg">
                                  <div className="skillLogo">
                                    <img src={formik.values.logo} className="img-fluid" />
                                  </div>
                                  <p>{formik.values.skill_name}</p>
                                </div>
                              </>
                            ) : (
                              <div
                                className={
                                  "commonfield " +
                                  (skillsList.length > 0 && formik.values.skillId !== "" && "rmvBottomBorder")
                                }
                              >
                                <label htmlFor="fullname">Search Skill</label>
                                {/* <input type="text" className="form-control" id="fullname" placeholder="Company name" /> */}
                                <input
                                  type="text"
                                  className={
                                    "form-control" +
                                    (formik.touched.skill_name && formik.errors.skill_name ? " border-red" : "")
                                  }
                                  placeholder="Ex. Sales"
                                  name="skill_name"
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                    getSkillList(e.target.value);
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.skill_name}
                                />
                                {formik.touched.skill_name && formik.errors.skill_name ? (
                                  <div className="formik-errors bg-error">{formik.errors.skill_name}</div>
                                ) : null}
                              </div>
                            )}
                            {skillsList.length > 0 && formik.values.skillId !== "" && (
                              <div className="skillListMain">
                                <ul>
                                  {skillsList.map((item) => {
                                    return (
                                      <li
                                        onClick={() => {
                                          formik.setFieldValue("skill_name", item?.name);
                                          formik.setFieldValue("logo", item?.image);
                                          setSkillsList([]);
                                        }}
                                      >
                                        {item?.name}
                                      </li>
                                    );
                                  })}
                                </ul>
                                <p>Select the one that fits best for your purpose.</p>
                              </div>
                            )}
                          </div>
                          {formik.values.logo !== "" && (
                            <>
                              <div className="col-12 editformcol">
                                <div className="commonfield mb-2 commonfieldCustom levelCustom">
                                  <label htmlFor="fullname">Level</label>
                                  <div className="input_group position-relative input-m-year">
                                    {/* <input type="text" className="form-control" id="fullname" placeholder={0} /> */}
                                    <div className="editprof_select img-select">
                                      <select
                                        className={
                                          "form-control" +
                                          (formik.touched.level && formik.errors.level ? " border-red" : "")
                                        }
                                        name="level"
                                        onChange={formik.handleChange}
                                        value={formik.values.level}
                                      >
                                        <option>Select level</option>
                                        {level.map((item) => {
                                          return <option value={item}>{item}</option>;
                                        })}
                                      </select>
                                      {formik.touched.level && formik.errors.level ? (
                                        <div className="formik-errors bg-error">{formik.errors.level}</div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 editformcol">
                                <div className="Profileclear_saveBtn d-flex profileclearCustomsmall">
                                  <div className="Profileclear_Btn">
                                    <button
                                      type="reset"
                                      onClick={handleDelete}
                                      className="btn btn-block"
                                      style={{ padding: 0 }}
                                    >
                                      <img src="images/svg-icons/delete_img.svg" />
                                    </button>
                                  </div>
                                  <div className="modelSignBtn editprofile_save mt-0 w-100">
                                    <button
                                      type="submit"
                                      className={
                                        "btn btn-primary btn-block w-100" +
                                        (!(formik.isValid && formik.dirty) || isLoading ? " disabled" : "")
                                      }
                                    >
                                      Save {isLoading && <Loading isInline={true} />}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
}

export default Skill;
