function AboutCard({ title, richtext, image }) {
  return (
    <div className="titleCard card titleAboutCard col">
      <div className="d-flex flex-row">
        <div className="aboutcard-left">
          <h4>{title}</h4>
          <div dangerouslySetInnerHTML={{ __html: richtext }}></div>
        </div>
        <div className="aboutcard-right">{image && <img src={image} className="img-fluid aboutImage" alt="about"></img>}</div>
      </div>
    </div>
  );
}

export default AboutCard;
