import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { GlobalContext } from "../context/GlobalContext";
import UserService from "../services/UserService";
import Loading from "../shared/Loading";

const serv = new UserService();
const ValidateSchema = Yup.object({
  language: Yup.string(),
  appearance: Yup.string(),
  currency: Yup.string(),
});
function DefaultLayout({ children }) {
  const globalCtx = useContext(GlobalContext);
  const [user, setUser] = globalCtx.user;
  const [accountOpen, setAccountOpen] = useState(false);
  const [settingOpen, setSettingOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [settingObj, setSettingObj] = new useState({
    language: "",
    appearance: "",
    currency: "",
    push_notification: "",
    email_notification: "",
    terms_of_service: "",
  });

  useEffect(() => {
    getUser();
  }, [user]);
  const formatGroupLabel = (data) => (
    <div>
      <span>{data.label}</span>
    </div>
  );

  const accountOpenHandler = () => {
    setAccountOpen(!accountOpen);
  };
  const onClose = () => {
    setSettingOpen(!settingOpen);
  };
  const getUser = async () => {
    let id = user._id;
    try {
      const resp = await serv.getUser(id);
      if (resp.data && resp.data.settings) {
        setSettingObj(resp.data.settings);
        if (
          JSON.stringify(user) !== JSON.stringify(resp.data) ||
          user.reward != resp.data.reward
        ) {
          setUser(resp.data);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onSubmit = async (values) => {
    setIsLoading(true);
    let obj = { _id: user._id, settings: values };
    try {
      const resp = await serv.updateSetting(obj);
      if (resp.message) {
        onClose();
        setUser(resp.result);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const formik = useFormik({
    initialValues: settingObj,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <main className="w-100 clearfix mainSection d-flex">
      <Sidebar />
      <div className="w-100 clearfix rightSection">
        <Header />
        {children}
        <Footer />
      </div>
      {/* <!-- Settings Popup Start--> */}
      {/* <!-- The Modal --> */}
      <div className="modal commanModel settingsPopup" id="settingsPopup">
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center">
            <div className="modal-dialog">
              <div className="modal-content">
                <form onSubmit={formik.handleSubmit}>
                  {/* <!-- Modal Header --> */}
                  <div className="modal-header">
                    <h4 className="modal-title letterSpacing0">Settings</h4>
                    <button
                      type="button"
                      className="btn-close"
                      // onClick={() => {
                      //   onClose();
                      // }}
                      data-bs-dismiss="modal"
                    ></button>
                  </div>
                  {/* <!-- Modal body --> */}
                  <div className="modal-body settingPaddingFix">
                    <div className="settingsOptOuter marginBottom79">
                      <div className="settingsOpt d-flex align-items-center justify-content-between">
                        <div className="settingsOptRight">
                          <p className="mb-0 settingP letterSpacing0">
                            Language:
                          </p>
                        </div>
                        <div className="settingsOptLeft">
                          <div className="selectForm backgroundPosition settingSelectBox selectLang d-flex align-items-center">
                            <select
                              className="form-select paddingLeft38 border-0"
                              name="language"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.language}
                            >
                              <option
                                value="English"
                                className="settingO letterSpacing0"
                              >
                                English
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="settingsOpt d-flex align-items-center justify-content-between">
                        <div className="settingsOptRight">
                          <p className="mb-0 settingP letterSpacing0">
                            Appearance:
                          </p>
                        </div>
                        <div className="settingsOptLeft">
                          <div className="selectForm backgroundPosition settingSelectBox selectSystem d-flex align-items-center">
                            <select
                              className="form-select paddingLeft38 border-0 height36"
                              name="appearance"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.appearance}
                            >
                              {/* <option value="Dark Mode">Dark Mode</option> */}
                              <option
                                value="Light Mode"
                                className="settingO letterSpacing0"
                              >
                                Light Mode
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {/* <div className="settingsOpt d-flex align-items-center justify-content-between">
                        <div className="settingsOptRight">
                          <p className="mb-0">Currency:</p>
                        </div>
                        <div className="settingsOptLeft">
                          <div className="selectform d-flex align-items-center">
                            <select
                              className="form-select border-0"
                              name="currency"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.currency}
                            >
                              <option value="ETH-USD">ETH-USD</option>
                              <option value="INR">INR</option>
                            </select>
                          </div>
                        </div>
                      </div> */}
                      <div className="settingsOpt d-flex align-items-center justify-content-between">
                        <div className="settingsOptRight">
                          <p className="mb-0 settingP letterSpacing0">
                            Push Notifications:
                          </p>
                        </div>
                        <div className="settingsOptLeft">
                          <div className="press">
                            <input
                              type="checkbox"
                              id="pushNotifications"
                              className="cbx hidden letterSpacing0"
                              name="push_notification"
                              checked={formik.values.push_notification}
                              onClick={() =>
                                formik.setFieldValue(
                                  "push_notification",
                                  !formik.values.push_notification
                                )
                              }
                              value={formik.values.push_notification}
                            />
                            <label
                              for="pushNotifications"
                              className="lbl"
                            ></label>
                          </div>
                        </div>
                      </div>
                      <div className="settingsOpt d-flex align-items-center justify-content-between">
                        <div className="settingsOptRight">
                          <p className="mb-0 settingP letterSpacing0">
                            Email notifications:
                          </p>
                        </div>
                        <div className="settingsOptLeft">
                          <div className="press">
                            <input
                              type="checkbox"
                              id="notificationsEmail"
                              className="cbx hidden letterSpacing0"
                              name="email_notification"
                              checked={formik.values.email_notification}
                              onClick={() =>
                                formik.setFieldValue(
                                  "email_notification",
                                  !formik.values.email_notification
                                )
                              }
                              value={formik.values.email_notification}
                            />
                            <label
                              for="notificationsEmail"
                              className="lbl"
                            ></label>
                          </div>
                        </div>
                      </div>
                      <div className="settingsOpt d-flex align-items-center justify-content-between">
                        <div className="settingsOptRight">
                          <p className="mb-0 settingP letterSpacing0">
                            Auto accept Terms of Serivce:
                          </p>
                        </div>
                        <div className="settingsOptLeft">
                          <div className="press">
                            <input
                              type="checkbox"
                              id="terms_of_service"
                              className="cbx hidden letterSpacing0"
                              name="terms_of_service"
                              checked={formik.values.terms_of_service}
                              onClick={() =>
                                formik.setFieldValue(
                                  "terms_of_service",
                                  !formik.values.terms_of_service
                                )
                              }
                              value={formik.values.terms_of_service}
                            />
                            <label
                              for="terms_of_service"
                              className="lbl"
                            ></label>
                          </div>
                        </div>
                      </div>
                      {/* <div className="settingsOpt d-flex align-items-center justify-content-between">
                      <div className="settingsOptRight">
                        <p className="mb-0">Newsletter on Email:</p>
                      </div>
                      <div className="settingsOptLeft">
                        <div className="press">
                          <input
                            type="checkbox"
                            id="newsletterEmail"
                            className="cbx hidden"
                            checked
                          />
                          <label for="newsletterEmail" className="lbl"></label>
                        </div>
                      </div>
                    </div> */}
                    </div>
                  </div>
                  {/* <!-- Modal footer --> */}
                  <div className="modal-footer d-grid">
                    <button
                      className={
                        "b_radius15 letterSpacing0 supportReplyBtn btn-primary btn letterSpacing0" +
                        (!(formik.isValid && formik.dirty) || isLoading
                          ? " disabled"
                          : "")
                      }
                    >
                      SAVE {isLoading && <Loading isInline={true} />}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Settings Popup End--> */}
    </main>
  );
}

export default DefaultLayout;
