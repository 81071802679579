import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Loading from "../../shared/Loading";
import SupportTicketService from "../../services/SupportTicketService";

function OpenNewTicket() {
  const supportTicketServ = new SupportTicketService();
  const navigate = useNavigate();
  const globalCtx = useContext(GlobalContext);
  const [user, setUser] = globalCtx.user;
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState({
    full_name: "",
    email_address: "",
    twitter_username: "",
    wallet_address: "",
    discord_username: "",
    subject: "",
    message: "",
    attachments: "",
  });
  // console.log(user)
  const [readFile, setReadFile] = useState("");
  const setReplyFunc = (replyformData) => {
    supportTicketServ.addChat(replyformData).then((res) => {
      if (res.err) {
        console.log(res.err);
      } else {
        setReadFile("");
        navigate("/support_ticket_expand/" + res.data.data._id);
      }
    });
  };
  const onSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    let obj = {
      full_name: user.full_name,
      email_address: user.email,
      twitter_username: user.twitter_username,
      wallet_address: user.wallet_address,
      discord_username: user.discord_username,
      subject: values.subject,
      message: values.message,
      attachments: values.attachments,
    };
    const formData = new FormData();
    formData.append("full_name", obj.full_name);
    formData.append("email_address", obj.email_address);
    formData.append("twitter_username", obj.twitter_username);
    formData.append("wallet_address", obj.wallet_address);
    formData.append("discord_username", obj.discord_username);
    formData.append("subject", obj.subject);
    formData.append("message", obj.message);
    formData.append("attachments", obj.attachments);
    supportTicketServ.addSupportTicketRecord(formData).then((res) => {
      if (res.err) {
        console.log(res.err);
      } else {
        const replyformData = new FormData();
        replyformData.append("reply", obj.message);
        replyformData.append("bot", true);
        replyformData.append("supportId", res.data.data._id);
        replyformData.append("attachments", obj.attachments);
        setReplyFunc(replyformData);
        resetForm();
      }
      setIsLoading(false);
    });
  };
  const ValidateSchema = Yup.object({
    subject: Yup.string().required("Subject field should not be empty."),
    message: Yup.string().required("Message field should not be empty."),
  });
  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <div className="support_second_sec mb-4">
        <div className="row glossaryTitleSecRow">
          <div className="col-lg-12 col-xl-8 glossaryTitleCol">
            <div className="support_form card p-0">
              <div className="support_sec_newHead">
                <div className="support_header d-flex align-items-center flex-wrap">
                  <div className="prev_btns p_20 h-100 border-bottom-0">
                    <Link to="/support">
                      {" "}
                      <img src="images/support/prev_icon.svg" className="img-fluid" />
                    </Link>
                  </div>
                  <div className="my-0 titleCard border-0 p_20">
                    <h4 className="mb-2 letterSpacing0">
                      <img
                        src="images/support/new_Ticket.svg"
                        alt="book"
                        className="img-fluid"
                        style={{ marginBottom: "2px" }}
                      />{" "}
                      Open a new ticket{" "}
                    </h4>
                    <p className="mb-0 letterSpacing0">We’ll reply as soon as possible.</p>
                  </div>
                </div>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="p_20 support_FormSec">
                  <div className="new_TicketInput">
                    <div className="mb-3">
                      <label htmlFor="subject" className="form-label letterSpacing0">
                        Subject
                      </label>
                      <input
                        type="text"
                        className="form-control letterSpacing0"
                        id="subject"
                        placeholder="Enter Subject"
                        name="subject"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.subject}
                      />
                    </div>
                    {formik.touched.subject && formik.errors.subject ? (
                      <div className="formik-errors bg-error">{formik.errors.subject}</div>
                    ) : null}
                  </div>
                  <div className="new_TicketInput">
                    <div className="mb-3 mt-3">
                      <label htmlFor="messageText" className="form-label letterSpacing0">
                        Message
                      </label>
                      <textarea
                        className="form-control letterSpacing0"
                        rows={5}
                        id="messageText"
                        placeholder="Enter Message"
                        defaultValue={""}
                        name="message"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.message}
                      />
                    </div>
                    {formik.touched.message && formik.errors.message ? (
                      <div className="formik-errors bg-error">{formik.errors.message}</div>
                    ) : null}
                  </div>
                  <div className="new_TicketInput">
                    <label htmlFor="attachments" className="form-label d-block">
                      Attachments
                    </label>
                    <div className="input-group custom-file-button">
                      <label className="input-group-text uploadfile_icon" htmlFor="inputticketFile">
                        <img src="images/support/ticket_upload_icon.svg" alt="upload to Cloud" className="img-fluid" />
                      </label>
                      <input
                        type="text"
                        className="form-control file_input_replacement letterSpacing0"
                        placeholder="Click here to upload"
                        id="inputticketFile"
                        readOnly
                        value={readFile}
                      />
                      <input
                        type="file"
                        className="w-100 fileInput letterSpacing0"
                        name="attachments"
                        onChange={(event) => {
                          formik.setFieldValue("attachments", event.currentTarget.files[0]);
                          setReadFile(event.currentTarget.files[0].name);
                        }}
                      />
                    </div>
                  </div>
                  <div className="pt-4">
                    <button
                      className={
                        "b_radius15 letterSpacing0 supportReplyBtn replyBtnBg btn" +
                        (!(formik.isValid && formik.dirty) || isLoading ? " disabled" : "")
                      }
                    >
                      SUBMIT {isLoading && <Loading isInline={true} />}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-12 col-xl-4 ps-xl-0 glossaryTitleCol">
            <div className="faqCompleted my-md-0 card">
              <div className="needHelp">
                <div className="needHelpHead d-flex align-items-center">
                  <div className="needHelpHeading">
                    <h5 className="mb-0 letterSpacing0">Can not find the answers to your questions? </h5>
                  </div>
                </div>
                <ul className="needHelpList">
                  <li className="helpList d-flex align-items-center">
                    <div className="needHelpIcon">
                      <img src="/images/support/Mail.svg" alt="needHelpIcon" className="img-fluid" />
                    </div>
                    <div className="needHelpHeading">
                      <p className="mb-0 letterSpacing0">
                        Email us at{" "}
                        <a href="mailto:support@mavefi.com" className="letterSpacing0">
                          support@mavefi.com
                        </a>
                      </p>
                    </div>
                  </li>
                  <li className="helpList d-flex align-items-center">
                    <div className="needHelpIcon">
                      <img src="/images/svg-icons/twitter-squared.svg" alt="needHelpIcon" className="img-fluid" />
                    </div>
                    <div className="needHelpHeading">
                      <p className="mb-0 letterSpacing0">
                        or Tweet us{" "}
                        <a href="https://twitter.com/joinmavefi" target="_blank" className="letterSpacing0">
                          @MaveFiSupport
                        </a>
                      </p>
                    </div>
                  </li>
                  <li className="helpList d-flex align-items-center">
                    <div className="needHelpIcon">
                      <img src="/images/support/askquestion.svg" alt="needHelpIcon" className="img-fluid" />
                    </div>
                    <div className="needHelpHeading">
                      <p className="mb-0 letterSpacing0">
                        or Visit{" "}
                        <Link to="/faq" className="letterSpacing0">
                          our Faqs
                        </Link>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpenNewTicket;
