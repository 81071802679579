import { useState, useContext, useEffect } from "react";
import referEarnService from "../../services/ReferEarnService";
const serv = new referEarnService();
function PassedQuizPopup({ name, reward, onClose }) {
  const [url, setUrl] = useState(window.location.origin);
  const [text, setText] = useState("");
  useEffect(() => {
    sendToken();
  }, []);
  const sendToken = async () => {
    let tokenReferance = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let createToken = [...Array(6)].reduce((a) => a + tokenReferance[~~(Math.random() * tokenReferance.length)], "");
    let respData = await serv.saveReferEarnRecord({ token: createToken });
    if (respData && respData.data) {
      let token = respData.data.token;
      setText(
        "http://twitter.com/share?text=I just received 10 Coins for completing a course on @joinmavefi They have a free library of web3 courses. You can also sign up using this link -&url= " +
          encodeURI(`${url}/refer/${token}`)
      );
    }
  };
  return (
    <>
      <div className="modal commanModel testPassedPopup showBlock" id="testPassedPopup">
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="modalContant">
                    <div className="modelTopBox">
                      <div className="modelSubHeading">
                        <span>REWARDS RECEIVED</span>
                      </div>
                      <div className="modelHeading">
                        <h2>+{reward} MAVE COINS</h2>
                      </div>
                      <div className="modelSpinningIcon">
                        <div className="spinnerIcon">
                          <img src="/images/svg-icons/circle-logo.svg" alt="circleLogo" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                    <div className="modelMiddleContant">
                      <h4 className="mb-0">
                        You have passed the Reward Test{" "}
                        <img src="/images/svg-icons/reward-test.png" alt="reward-test" className="img-fluid" />
                      </h4>
                      <p className="mb-0">
                        <strong>{reward} Mave Coins</strong> have been added to your account as reward for successfully
                        completing the course <strong>“{name}”</strong>.
                      </p>
                    </div>
                    <div className="modelBtn d-grid">
                      <a className="btn btn-block shareBtn passShareBtnCustom" href={text} target="_blank">
                        <img src="/images/svg-icons/twitter_icon.svg" width={18} height={16} />
                        &nbsp;Share with your friends
                      </a>
                      <a type="button" className="btn btn-block closeBtn" onClick={onClose}>
                        Close
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
}

export default PassedQuizPopup;
