import { useContext, useEffect, useState } from "react";
import BlogCard from "./BlogCard";
import BlogService from "../../services/BlogService";
import ScrollMoreProvider from "../../shared/Scroll/ScrollMoreProvider";
import TitleCard2 from "../../shared/TitleCard2";
import PageSearch from "../../shared/PageSearch";
import GlobalContext from "../../context/GlobalContext";
import Loading from "../../shared/Loading";
import { Helmet } from "react-helmet";
import BlogsSearch from "./BlogSearch";
import PageHeader from "../../shared/PageHeader";
const serv = new BlogService();

function Blogs() {
  const globalCtx = useContext(GlobalContext);
  const [searchDtl, setSearchDtl] = globalCtx.searchDtl;
  const [setting, setSetting] = globalCtx.setting;
  const [records, setRecords] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [recordsCount, setRecordsCount] = useState(0);
  const [search, setSearch] = useState({
    filter: { searchText: searchDtl, is_active: true },
    start: 0,
    length: 20,
  });
  const [filterListObj, setFilterListObj] = useState({
    author: [],
    tags: [],
    category: [],
    languages: [{ check: false, value: "English" }],
  });
  const [filterObj, setFilterObj] = useState({});
  let loading = false;

  useEffect(() => {
    getList();
    filterList();
  }, [search]);

  useEffect(() => {
    searchCallBack(searchDtl);
  }, [searchDtl]);

  async function getList() {
    if (loading === false && (recordsCount == 0 || recordsCount > records.length)) {
      loading = true;
      let respData = await serv.listAll(search);
      setRecords(recordsCount == 0 ? respData.data : [...records, ...respData.data]);
      setRecordsCount(respData.total);
      setShowLoading(false);
    }
  }

  function reachedBottomCall() {
    let searchTemp = { ...search };
    searchTemp.start = search.start + search.length;
    setSearch(searchTemp);
  }

  function searchCallBack(value, filters, sortBy) {
    let searchTemp = { ...search };
    searchTemp.start = 0;
    if (filters !== undefined) {
      searchTemp.filter = filters;
    }
    searchTemp.filter.searchText = value;
    searchTemp.sort = { createdAt: "desc" };

    if (sortBy) {
      searchTemp.sort = sortBy;
    }
    setRecordsCount(0);
    setSearch(searchTemp);
  }
  async function filterList() {
    let respData = await serv.getFilterList();
    let catData = await serv.BlogCatList({});
    let tagValues = [];
    let tag = [];
    let authorValues = [];
    let author = [];
    let categoryValues = [];
    let category = [];
    let languageValues = [];
    let language = [];
    respData.data.tags.map((item) => {
      let tagValue = { check: false, value: item };

      if (search.filter?.tags?.includes(item)) {
        tagValue.check = true;
        tag.push(item);
      }
      tagValues.push(tagValue);
    });
    catData.data.map((item) => {
      let categoryValue = { check: false, _id: item._id, name: item.name };

      if (search.filter?.category_id?.includes(item._id)) {
        categoryValue.check = true;
        category.push(item._id);
      }
      categoryValues.push(categoryValue);
    });
    respData.data.author.map((item) => {
      let authorValue = {
        check: false,
        user_name: item.user_name,
        _id: item._id,
      };

      if (search.filter?.author?.includes(item._id)) {
        authorValue.check = true;
        author.push(item._id);
      }
      authorValues.push(authorValue);
    });
    filterListObj.languages.map((item) => {
      let languageValue = item;

      if (search.filter?.languages?.includes(item.value)) {
        languageValue.check = true;
        language.push(item.value);
      }
      languageValues.push(languageValue);
    });
    // setAuthor(authorValues);
    // setFilterAuthor(author);
    // setCategory(categoryValues);
    // setFilterCategory(category);
    // setTags(tagValues);
    // setFilterTags(tag);
    // setLanguages(languageValues);
    // setFilterLanguages(language);
    setFilterObj({
      author: author,
      category: category,
      tags: tag,
      languages: language,
    });
    setFilterListObj({
      author: authorValues,
      category: categoryValues,
      tags: tagValues,
      languages: languageValues,
    });
  }
  if (showLoading) {
    return <Loading />;
  }

  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <Helmet>
        <title>{setting?.seo?.blog?.title || ""}</title>
        <meta name="description" content={setting?.seo?.blog?.meta_description || ""} />
        <meta name="keywords" content={setting?.seo?.blog?.tags || ""} />
        <meta property="og:title" content={setting?.seo?.blog?.title || ""} />
        <meta property="og:description" content={setting?.seo?.blog?.meta_description || ""} />
        <meta property="og:image" content={setting?.seo?.social_share_image} />
      </Helmet>
      <PageHeader
        allowedFilter={true}
        type="blog"
        searchCallBack={searchCallBack}
        search={search}
        filterListObj={filterListObj}
        filterObj={filterObj}
        titleCard={{
          image: "/images/svg-icons/blog.svg",
          title: "Blog",
          content:
            "Our blog is packed with useful news, reviews and the latest developments from the world of Web3. Expert contributors share their insights to broaden the knowledge of our users. (Written by the MaveFi team & contributors)",
          type: "glossary",
        }}
        searchType={{ type: "blog", placeholder: "Search blogs" }}
        loginTitle="Please login/sign up to start a course or view your progress."
        rightActionCard={{
          heading: "Contact the Blog Team",
          desc: "Want to request an article, analysis, give us an exclusive interview or just get in touch with our blog team? Reach out.",
          buttonName: "Email us at blog@mavefi.com",
          link: "mailto:blog@mavefi.com",
        }}
      />
      {/* <div className="glossaryTitleSec">
        <div className="row glossaryTitleSecRow">
          <div className="col-lg-12 col-xl-8 glossaryTitleCol">
            <TitleCard2
              image="/images/svg-icons/blog.svg"
              title="Blog"
              content="Our blog is packed with useful news, reviews and the latest developments from the world of Web3. Expert contributors share their insights to broaden the knowledge of our users. (Written by the MaveFi team & contributors)"
              type="glossary"
            />
            <PageSearch
              placeholder="Search blog"
              searchCallBack={searchCallBack}
              searchText={search.filter.searchText}
            />
            <BlogsSearch
              placeholder="Search blogs"
              searchCallBack={searchCallBack}
              searchText={search.filter.searchText}
              searchFilter={search.filter}
            />
          </div>
          <div className="col-lg-12 col-xl-4 ps-xl-0 glossaryTitleCol">
            <div className="h-100 titleCard faqCompleted my-md-0 card">
              <div className="faqComp blogComp">
                <h4>Contact the Blog Team</h4>
                <p className="mb-0">
                  Want to request an article, analysis, give us an excusive interview or just get in touch with our blog
                  team? Drop us an email at <a href="mailto:hiblog@mavefi.com">blog@mavefi.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="allBlogCard">
        <div className="allUpdateCardList allLearnEarnCardList">
          <div className="updates_card_section updatesCardSection mb-3">
            {records.length > 0 && (
              <ScrollMoreProvider
                className="row updatesCardSectionRow"
                id="glossary list"
                reachedBottomCall={reachedBottomCall}
                useWindowScroll={true}
              >
                {records.map((b) => {
                  return <BlogCard key={b._id} blog={b} />;
                })}
              </ScrollMoreProvider>
            )}
            {records.length < recordsCount && <Loading isSmall={true} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blogs;
