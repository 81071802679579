import TitleCard2 from "../../shared/TitleCard2";
import AboutCard from "./AboutCard";
import AboutCard2 from "./AboutCard2";
import AboutCardVertical from "./AboutCardVertical";
import { Helmet } from "react-helmet";

function About() {
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <Helmet>
        <title>About | MaveFi</title>
      </Helmet>
      <div className="aboutSectionData">
        <div className="glossaryTitleSec">
          <div className="d-flex flex-row gap-20">
            <AboutCard
              title="About"
              richtext={`MaveFi is one of the largest Web3 consumer education and onboarding platform. We want you to join us as you learn, earn and explore the decentralised economy. We are aiming to develop the ultimate consumer onboarding experience for Web3. MaveFi combines incentivised education, a range of Web3 tools and a wide selection of onboarding opportunities designed to onboard new consumers. Sign up today, and broaden your Web3 horizons!`}
              image="/images/image/About.png"
            />
            <AboutCardVertical
              title="MaveFi Pro"
              richtext={`Coming Soon. Follow us on twitter (<a href="https://twitter.com/joinmavefi" target="_blank">@joinmavefi</a>) for updates, as we’re working hard to bring you our pro subscription plans. MaveFi Pro will include a range of access gated tools and resources designed to help you grow in the web3 economy.`}
              image="/images/image/Pro.png"
            />
          </div>
        </div>
        <div className="glossaryTitleSec">
          <div className="d-flex flex-row gap-20">
            <AboutCard
              title="Timeline"
              richtext={`The MaveFi journey began in February 2022, when an initial design draft called LearnJPEG was completed in just three weeks. The initial concept was a platform for NFT education, but the project evolved into something much bigger. Our goal was to educate people about the decentralised economy, and after several redesigns and two months of hard work, MaveFi was born. We’ve been fine-tuning the MaveFi experience since July 2022, and we continue to add new features and content on a daily basis.`}
              image="/images/image/Timeline.png"
            />
            <AboutCard
              title="Mission"
              richtext={`Our mission is simple: To open up the possibilities of Web3 to as many consumers as possible. We aim to achieve our mission by focusing on emerging markets and making Web3 more accessible than ever before. Through our selection of short courses, we want to help consumers navigate the often daunting aspects of crypto/nfts/defi/blockchain/etc. The world is in the grips of a Web3 revolution, and MaveFi is right at the heart of it — making education, tools and opportunities available to anyone who needs them.`}
              image="/images/image/Mission.png"
            />
          </div>
        </div>
        <AboutCard2
          title={"Mobile Apps"}
          richtext={`Stay tuned for news on our mobile apps for iOS and Android. We’re working hard to put the power of MaveFi in the palm of your hand!`}
          image1="/images/image/MobileApp-1.png"
          image2="/images/image/MobileApp-2.png"
          image3="/images/image/MobileApp-3.png"
          image4="/images/image/MobileApp-4.png"
          image5="/images/image/MobileApp-5.png"
        />
      </div>
    </div>
  );
}

export default About;
