import { useContext, useEffect, useState } from "react";
import AccountService from "../../services/AccountService";
import { useFormik } from "formik";
import { GlobalContext } from "../../context/GlobalContext";
import * as Yup from "yup";
import moment from "moment";
import Loading from "../../shared/Loading";
const serv = new AccountService();
const ValidateSchema = Yup.object({
  project_name: Yup.string().required("Project Name is a required field"),
  description: Yup.string().required("Descripton is a required field"),
  web_link: Yup.string().required("web link is a required field"),
  type: Yup.string().required("type is a required field"),
  status: Yup.string().required("status is a required field"),
  logo: Yup.string(),
});
function Project({ onClose, projectId }) {
  const globalCtx = useContext(GlobalContext);
  const [user, setUser] = globalCtx.user;
  const [project, setProject] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [accountObj, setAccountObj] = new useState({
    logo: "",
    project_name: "",
    description: "",
    web_link: "",
    type: "",
    status: "",
  });
  useEffect(() => {
    getAccountDetails();
  }, []);
  const getAccountDetails = async () => {
    let id = user._id;

    try {
      let resp = await serv.getUser(id);
      if (resp.data.project) {
        setProject(resp.data.project);
        if (projectId !== "") {
          resp.data.project.map((item) => {
            if (item._id == projectId) {
              let new_obj = {
                logo: item.logo,
                project_name: item.project_name,
                description: item.description,
                web_link: item.web_link,
                type: item.type,
                status: item.status,
              };
              setAccountObj(new_obj);
            }
          });
        }
        // setUser({ ...user, ...new_obj });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    let id = user._id;
    const formData = new FormData();
    let experienceArr;
    if (projectId !== "") {
      formData.append("projectId", projectId);
      experienceArr = project.map((item) => {
        if (projectId == item._id) {
          item = {
            project_name: values.project_name,
            status: values.status,
            web_link: values.web_link,
            description: values.description,
            type: values.type,
            logo: values.logo,
            _id: projectId,
          };
        }
        return item;
      });
    } else {
      experienceArr = [
        ...project,
        {
          project_name: values.project_name,
          status: values.status,
          web_link: values.web_link,
          description: values.description,
          type: values.type,
          logo: values.logo,
        },
      ];
    }
    formData.append("project", JSON.stringify(experienceArr));
    if (values.logo) {
      formData.append("logo", values.logo);
    }
    formData.append("_id", id);
    formData.append("email", user.email);
    try {
      const resp = await serv.editUser(formData);
      setIsLoading(false);
      resetForm();
      getAccountDetails();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };
  const handleDelete = async () => {
    const formData = new FormData();
    let experienceArr = project.filter((item) => item._id !== projectId);

    formData.append("project", JSON.stringify(experienceArr));
    formData.append("_id", user._id);
    formData.append("email", user.email);
    try {
      const resp = await serv.editUser(formData);
      getAccountDetails();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };
  const formik = useFormik({
    initialValues: accountObj,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <>
      <div
        className="modal editModal showBlock"
        id="workExperience"
        onClick={(e) => {
          if (e.target == e.currentTarget) {
            onClose();
          }
        }}
      >
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center">
            <div className="modal-dialog add_experienceModal">
              <div className="modal-content">
                <div className="modal-header align-items-baseline">
                  <h4 className="modal-title"> Add Projects</h4>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => onClose()} />
                </div>
                {/* Modal body */}
                <div className="modal-body p-0">
                  <div className="profilemodal_contet">
                    <div className="requestKyc_form mt-0">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="row editformgrid">
                          <div className="col-12 editformcol">
                            <div className="commonfield d-flex justify-content-between align-items-center">
                              <label>Upload Logo</label>
                              <input
                                type="file"
                                className="form-control"
                                id="uploadlogoFile"
                                hidden
                                onChange={(event) => {
                                  formik.setFieldValue("logo", event.currentTarget.files[0]);
                                }}
                              />
                              <label for="uploadlogoFile" className="upload_Logo" id="UploaderLogodiv">
                                {/* <input type="file" className="form-control" id="uploadlogoFile" placeholder="Date of birth" hidden /> */}
                                {formik.values.logo ? (
                                  typeof formik.values.logo == "string" ? (
                                    <img
                                      src={formik.values.logo}
                                      style={{
                                        height: "80px",
                                        width: "auto",
                                        borderRadius: "100px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={URL.createObjectURL(formik.values.logo)}
                                      style={{
                                        height: "80px",
                                        width: "auto",
                                        borderRadius: "100px",
                                      }}
                                    />
                                  )
                                ) : (
                                  <img src="images/svg-icons/addcamera.svg" />
                                )}
                              </label>
                            </div>
                          </div>
                          <div className="col-12 editformcol">
                            <div className="commonfield customBottomMargin">
                              <label htmlFor="fullname">Enter Project Name</label>
                              {/* <input type="text" className="form-control" id="fullname" placeholder="Company name" /> */}
                              <input
                                type="text"
                                className={
                                  "form-control" +
                                  (formik.touched.project_name && formik.errors.project_name ? " border-red" : "")
                                }
                                placeholder="Project Name"
                                name="project_name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.project_name}
                              />
                              {formik.touched.project_name && formik.errors.project_name ? (
                                <div className="formik-errors bg-error">{formik.errors.project_name}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12 editformcol">
                            <div className="commonfield customBottomMargin">
                              <div className="d-flex justify-content-between">
                                <label htmlFor="fullname">Enter One Line Description</label>
                                <span className="character_count">{formik.values.description.length}/40</span>
                              </div>
                              {/* <input type="text" className="form-control" id="fullname" placeholder="One line description" /> */}
                              <input
                                type="text"
                                className={
                                  "form-control" +
                                  (formik.touched.description && formik.errors.description ? " border-red" : "")
                                }
                                placeholder="One line description"
                                name="description"
                                maxLength={40}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                              />
                              {formik.touched.description && formik.errors.description ? (
                                <div className="formik-errors bg-error">{formik.errors.description}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12 editformcol">
                            <div className="commonfield customBottomMargin">
                              <label htmlFor="fullname">Enter Website Link</label>
                              {/* <input type="text" className="form-control" id="fullname" placeholder="Position" /> */}
                              <input
                                type="text"
                                className={
                                  "form-control" +
                                  (formik.touched.web_link && formik.errors.web_link ? " border-red" : "")
                                }
                                placeholder="Website link"
                                name="web_link"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.web_link}
                              />
                              {formik.touched.web_link && formik.errors.web_link ? (
                                <div className="formik-errors bg-error">{formik.errors.web_link}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12 editformcol">
                            <div className="commonfield mb-2 commonfieldCustom">
                              <label htmlFor="fullname">Select Type</label>
                              <div className="input_group position-relative">
                                {/* <input type="text" className="form-control" id="fullname" placeholder={0} /> */}
                                <div className="editprof_select img-select">
                                  <select
                                    className={
                                      "form-control" + (formik.touched.type && formik.errors.type ? " border-red" : "")
                                    }
                                    name="type"
                                    onChange={formik.handleChange}
                                    value={formik.values.type}
                                  >
                                    <option>Select Type</option>
                                    <option value="Open Source">Open source</option>
                                    <option value="Licensed">Licensed</option>
                                  </select>
                                  {formik.touched.type && formik.errors.type ? (
                                    <div className="formik-errors bg-error">{formik.errors.type}</div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 editformcol">
                            <div className="commonfield mb-2 commonfieldCustom">
                              <label htmlFor="fullname">Select Status</label>
                              <div className="input_group position-relative">
                                {/* <input type="checkbox" id="still_here" /> */}
                                <div className="editprof_select img-select">
                                  <select
                                    className={
                                      "form-control" +
                                      (formik.touched.status && formik.errors.status ? " border-red" : "")
                                    }
                                    name="status"
                                    onChange={formik.handleChange}
                                    value={formik.values.status}
                                  >
                                    <option>Select status</option>
                                    <option value="Under Dev">Dev</option>
                                    <option value="Beta">Beta</option>
                                    <option value="Live">Live</option>
                                  </select>
                                  {formik.touched.status && formik.errors.status ? (
                                    <div className="formik-errors bg-error">{formik.errors.status}</div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 editformcol">
                            <div className="Profileclear_saveBtn d-flex profileclearCustomsmall">
                              <div className="Profileclear_Btn">
                                <button
                                  type="reset"
                                  onClick={handleDelete}
                                  className="btn btn-block"
                                  style={{ padding: 0 }}
                                >
                                  <img src="images/svg-icons/delete_img.svg" />
                                </button>
                              </div>
                              <div className="modelSignBtn editprofile_save mt-0 w-100">
                                <button
                                  type="submit"
                                  className={
                                    "btn btn-primary btn-block w-100" +
                                    (!(formik.isValid && formik.dirty) || isLoading ? " disabled" : "")
                                  }
                                >
                                  Save {isLoading && <Loading isInline={true} />}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
}

export default Project;
