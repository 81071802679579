import { useContext, useEffect, useState } from "react";
import AccountService from "../../services/AccountService";
import { useFormik } from "formik";
import { GlobalContext } from "../../context/GlobalContext";
import * as Yup from "yup";
import Loading from "../../shared/Loading";
import moment from "moment";
const serv = new AccountService();
const ValidateSchema = Yup.object({
  company_name: Yup.string().required("Company Name is a required field"),
  description: Yup.string().required("Descripton is a required field"),
  position: Yup.string().required("Position is a required field"),
  duration: Yup.string(),
  present: Yup.string(),
  logo: Yup.string(),
  since: Yup.string(),
});
function WorkExperience({ onClose, experienceId }) {
  const globalCtx = useContext(GlobalContext);
  const [user, setUser] = globalCtx.user;
  const [workExperience, setWorkExperience] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [accountObj, setAccountObj] = new useState({
    logo: "",
    company_name: "",
    description: "",
    position: "",
    duration: 0,
    present: true,
    since: "",
  });
  useEffect(() => {
    getAccountDetails();
  }, []);
  const getAccountDetails = async () => {
    let id = user._id;

    try {
      let resp = await serv.getUser(id);
      if (resp.data.work_experience) {
        setWorkExperience(resp.data.work_experience);
        if (experienceId !== "") {
          resp.data.work_experience.map((item) => {
            if (item._id == experienceId) {
              let new_obj = {
                logo: item.logo,
                company_name: item.company_name,
                description: item.description,
                position: item.position,
                duration: item.duration,
                present: item.present,
                since: item.since,
              };
              setAccountObj(new_obj);
            }
          });
        }
        // setUser({ ...user, ...new_obj });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    let id = user._id;
    const formData = new FormData();
    let experienceArr;
    if (experienceId !== "") {
      formData.append("experienceId", experienceId);
      experienceArr = workExperience.map((item) => {
        if (experienceId == item._id) {
          item = {
            company_name: values.company_name,
            duration: values.duration,
            position: values.position,
            description: values.description,
            present: values.present,
            logo: values.logo,
            since: values.since,
            _id: experienceId,
          };
        }
        return item;
      });
    } else {
      experienceArr = [
        ...workExperience,
        {
          company_name: values.company_name,
          duration: values.duration,
          position: values.position,
          description: values.description,
          present: values.present,
          logo: values.logo,
          since: values.since,
        },
      ];
    }
    formData.append("work_experience", JSON.stringify(experienceArr));
    if (values.logo) {
      formData.append("logo", values.logo);
    }
    formData.append("_id", id);
    formData.append("email", user.email);
    try {
      const resp = await serv.editUser(formData);
      setIsLoading(false);
      resetForm();
      getAccountDetails();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };
  const handleDelete = async () => {
    const formData = new FormData();
    let experienceArr = workExperience.filter((item) => item._id !== experienceId);
    formData.append("work_experience", JSON.stringify(experienceArr));
    formData.append("_id", user._id);
    formData.append("email", user.email);
    try {
      const resp = await serv.editUser(formData);
      getAccountDetails();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };
  const formik = useFormik({
    initialValues: accountObj,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <>
      <div
        className="modal editModal showBlock"
        id="workExperience"
        onClick={(e) => {
          if (e.target == e.currentTarget) {
            onClose();
          }
        }}
      >
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center">
            <div className="modal-dialog add_experienceModal">
              <div className="modal-content">
                <div className="modal-header align-items-baseline">
                  <h4 className="modal-title"> Add Work Experience</h4>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => onClose()} />
                </div>
                {/* Modal body */}
                <div className="modal-body p-0">
                  <div className="profilemodal_contet">
                    <div className="requestKyc_form mt-0">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="row editformgrid">
                          <div className="col-12 editformcol">
                            <div className="commonfield d-flex justify-content-between align-items-center">
                              <label>Upload Logo</label>
                              <input
                                type="file"
                                className="form-control"
                                id="uploadlogoFile"
                                hidden
                                onChange={(event) => {
                                  formik.setFieldValue("logo", event.currentTarget.files[0]);
                                }}
                              />
                              <label for="uploadlogoFile" className="upload_Logo" id="UploaderLogodiv">
                                {/* <input type="file" className="form-control" id="uploadlogoFile" placeholder="Date of birth" hidden /> */}
                                {formik.values.logo ? (
                                  typeof formik.values.logo == "string" ? (
                                    <img
                                      src={formik.values.logo}
                                      style={{
                                        height: "80px",
                                        width: "80px",
                                        objectFit: "cover",
                                        borderRadius: "100px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={URL.createObjectURL(formik.values.logo)}
                                      style={{
                                        height: "80px",
                                        width: "80px",
                                        objectFit: "cover",
                                        borderRadius: "100px",
                                      }}
                                    />
                                  )
                                ) : (
                                  <img src="images/svg-icons/addcamera.svg" />
                                )}
                              </label>
                            </div>
                          </div>
                          <div className="col-12 editformcol">
                            <div className="commonfield customBottomMargin">
                              <label htmlFor="fullname">Enter Company Name</label>
                              {/* <input type="text" className="form-control" id="fullname" placeholder="Company name" /> */}
                              <input
                                type="text"
                                className={
                                  "form-control" +
                                  (formik.touched.company_name && formik.errors.company_name ? " border-red" : "")
                                }
                                placeholder="Company Name"
                                name="company_name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.company_name}
                              />
                              {formik.touched.company_name && formik.errors.company_name ? (
                                <div className="formik-errors bg-error">{formik.errors.company_name}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12 editformcol">
                            <div className="commonfield customBottomMargin">
                              <div className="d-flex justify-content-between">
                                <label htmlFor="fullname">Enter One Line Description</label>
                                <span className="character_count">{formik.values.description.length}/40</span>
                              </div>
                              {/* <input type="text" className="form-control" id="fullname" placeholder="One line description" /> */}
                              <input
                                type="text"
                                className={
                                  "form-control" +
                                  (formik.touched.description && formik.errors.description ? " border-red" : "")
                                }
                                placeholder="One line description"
                                name="description"
                                maxLength={40}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                              />
                              {formik.touched.description && formik.errors.description ? (
                                <div className="formik-errors bg-error">{formik.errors.description}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12 editformcol">
                            <div className="commonfield customBottomMargin">
                              <label htmlFor="fullname">Enter Job Role</label>
                              {/* <input type="text" className="form-control" id="fullname" placeholder="Position" /> */}
                              <input
                                type="text"
                                className={
                                  "form-control" +
                                  (formik.touched.position && formik.errors.position ? " border-red" : "")
                                }
                                placeholder="Job Role"
                                name="position"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.position}
                              />
                              {formik.touched.position && formik.errors.position ? (
                                <div className="formik-errors bg-error">{formik.errors.position}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12 editformcol">
                            {formik.values.present ? (
                              <div className="commonfield mb-2">
                                <label htmlFor="fullname">Since</label>
                                <div className="input_group position-relative">
                                  {/* <input type="text" className="form-control" id="fullname" placeholder={0} /> */}
                                  <input
                                    type="date"
                                    className={
                                      "form-control" +
                                      (formik.touched.since && formik.errors.since ? " border-red" : "")
                                    }
                                    placeholder="DD/MM/YYYY"
                                    name="since"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.since}
                                  />
                                  {formik.touched.since && formik.errors.since ? (
                                    <div className="formik-errors bg-error">{formik.errors.since}</div>
                                  ) : null}
                                </div>
                              </div>
                            ) : (
                              <div className="commonfield mb-2">
                                <label htmlFor="fullname">Enter Duration</label>
                                <div className="input_group position-relative">
                                  {/* <input type="text" className="form-control" id="fullname" placeholder={0} /> */}
                                  <input
                                    type="text"
                                    className={
                                      "form-control" +
                                      (formik.touched.duration && formik.errors.duration ? " border-red" : "")
                                    }
                                    placeholder="0"
                                    name="duration"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.duration}
                                  />
                                  {formik.touched.duration && formik.errors.duration ? (
                                    <div className="formik-errors bg-error">{formik.errors.duration}</div>
                                  ) : null}
                                  <span className="custom_groupText">months</span>
                                </div>
                              </div>
                            )}
                            <div className="accept_terms stillHereCheck">
                              <div className="form-group">
                                {/* <input type="checkbox" id="still_here" /> */}
                                <input
                                  id="still_here"
                                  type="checkbox"
                                  className={
                                    "form-control" +
                                    (formik.touched.present && formik.errors.present ? " border-red" : "")
                                  }
                                  name="present"
                                  checked={formik.values.present}
                                  // onChange={formik.handleChange}
                                  onClick={() => formik.setFieldValue("present", !formik.values.present)}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.present}
                                />
                                {formik.touched.present && formik.errors.present ? (
                                  <div className="formik-errors bg-error">{formik.errors.present}</div>
                                ) : null}
                                <label htmlFor="still_here">Still here</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 editformcol">
                            <div className="Profileclear_saveBtn profileclearCustom d-flex">
                              <div className="Profileclear_Btn">
                                <button
                                  type="reset"
                                  onClick={handleDelete}
                                  style={{ padding: 0 }}
                                  className="btn btn-block"
                                >
                                  <img src="images/svg-icons/delete_img.svg" />
                                </button>
                              </div>
                              <div className="modelSignBtn editprofile_save mt-0 w-100">
                                <button
                                  type="submit"
                                  className={
                                    "btn btn-primary btn-block w-100" +
                                    (!(formik.isValid && formik.dirty) || isLoading ? " disabled" : "")
                                  }
                                >
                                  Save {isLoading && <Loading isInline={true} />}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
}

export default WorkExperience;
