import CourseService from "../services/CourseService";
import BlogService from "../services/BlogService";
function SocialShare({ twitter, facebook, website, ImageURL, ImageClass, topGap, userProfile }) {
  const serv = new CourseService();
  const blogServ = new BlogService();
  let twitterurl = "http://twitter.com/share?text=" + twitter.text + "&url=" + encodeURI(twitter.url);
  let facebookurl = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURI(facebook.url) + "&t=" + facebook.title;
  let websiteurl = website.url;

  const handleShareLog = async (to) => {
    try {
      if (website.url.includes("courses")) {
        let obj = {
          shareTo: to,
          courseId: website.url.split("/").pop(),
        };
        await serv.updateShareLog(obj).then((resp) => {
          console.log(resp);
        });
      } else if (website.url.includes("blogs")) {
        let obj = {
          shareTo: to,
          blogId: website.url.split("/").pop(),
        };
        await blogServ.updateShareLog(obj).then((resp) => {
          console.log(resp);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="modeBtn dropdown">
      <a href="javascript:void(0)" className="cardShareIcon" data-bs-toggle="dropdown">
        {userProfile ? (
          <div className="userProfileShare">
            <img
              src="/images/svg-icons/share-rounded-profile.svg"
              alt="share-rounded"
              className="img-fluid shareRoundedActive"
            />{" "}
            Share
          </div>
        ) : (
          <>
            {ImageURL && <img src={ImageURL} className={ImageClass ? ImageClass : ""} alt="share" />}
            {!ImageURL && (
              <>
                <img
                  src="/images/svg-icons/share-rounded-normal.svg"
                  alt="share-rounded"
                  className="img-fluid shareRoundedNormal"
                />
                <img
                  src="/images/svg-icons/share-rounded-active.svg"
                  alt="share-rounded"
                  className="img-fluid shareRoundedActive"
                />
              </>
            )}
          </>
        )}
      </a>
      <ul className={"dropdown-menu " + (topGap && "marginTop5")}>
        <li onClick={() => handleShareLog("twitter")}>
          <a className="dropdown-item" href={twitterurl} target="_blank">
            <img src="/images/svg-icons/twitter-gray.svg" alt="dropdownIcon" className="img-fluid" />{" "}
            <span>Twitter</span>
          </a>
        </li>
        <li onClick={() => handleShareLog("facebook")}>
          <a className="dropdown-item" href={facebookurl} target="_blank">
            <img src="/images/svg-icons/facebook-gray.svg" alt="dropdownIcon" className="img-fluid" />{" "}
            <span>Facebook</span>
          </a>
        </li>
        <li>
          <a
            className="dropdown-item"
            style={{ cursor: "pointer" }}
            href="javascript:void(0)"
            onClick={(e) => {
              navigator.clipboard.writeText(websiteurl);
            }}
          >
            <img src="/images/svg-icons/copy-link.svg" alt="dropdownIcon" className="img-fluid" />{" "}
            <span>Copy link</span>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default SocialShare;
