import { useEffect, useState } from "react";

function PageSearch({ searchText, placeholder, searchCallBack, sortLabel, sortHandler }) {
  const [showCancel, setShowCancel] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(searchText);
  }, [searchText]);

  function changeHandler(value) {
    if (value !== "") {
      setShowCancel(true);
    } else {
      setShowCancel(false);
    }
    setValue(value);
    searchCallBack(value);
  }
  return (
    <>
      <div
        className={
          "filterSec d-flex align-items-center " + (placeholder.includes("glossary") || true ? "searchCustom" : "")
        }
      >
        <div className="glossarySearch has-clear w-100">
          <div className="input-group">
            <span className="input-group-text">
              <img src="/images/svg-icons/search.svg" alt="search" className="img-fluid" />
            </span>
            <input
              value={value}
              type="text"
              className="form-control"
              placeholder={placeholder}
              onChange={(e) => {
                changeHandler(e.target.value);
              }}
            />
            <span className={"input-group-text close form-control-clear" + (showCancel === false ? " hidden" : "")}>
              <img
                src="/images/svg-icons/close.svg"
                alt="search"
                className="img-fluid"
                onClick={() => {
                  changeHandler("");
                }}
              />
            </span>
          </div>
        </div>
      </div>
      {sortLabel && (
        <div className="mostbookmarked">
          <div className="dropdown">
            <a to="#" className="btnBookmarkedToggle" data-bs-toggle="dropdown">
              <img src="/images/svg-icons/most-bookmarked.svg" alt="search" className="img-fluid" />
              {sortLabel}
              <img src="/images/svg-icons/collapse-arrow.svg" alt="search" className="img-fluid collapse-arrow" />
            </a>
            <ul className="dropdown-menu">
              <li>
                <a
                  className="dropdown-item"
                  to="#"
                  onClick={() => sortHandler({ createdAt: "desc" }, "Recently Added")}
                >
                  Recently added
                </a>
              </li>
              <li>
                <a className="dropdown-item" to="#" onClick={() => sortHandler({ word: "asc" }, "A to Z")}>
                  A to Z
                </a>
              </li>
              <li>
                <a className="dropdown-item" to="#" onClick={() => sortHandler({ word: "desc" }, "Z to A")}>
                  Z to A
                </a>
              </li>
              <li>
                <a className="dropdown-item" to="#" onClick={() => sortHandler({ createdAt: "asc" }, "Oldest")}>
                  Oldest
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}
export default PageSearch;
