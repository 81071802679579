import { useEffect, useState } from "react";
import PartnerCard from "./PartnerCard";
import PartnerService from "../../services/PartnerService";
import ScrollMoreProvider from "../../shared/Scroll/ScrollMoreProvider";
import TitleCard2 from "../../shared/TitleCard2";
import PageSearch from "../../shared/PageSearch";
import Loading from "../../shared/Loading";
import { Helmet } from "react-helmet";
import PageHeader from "../../shared/PageHeader";

const serv = new PartnerService();

function Partners() {
  const [records, setRecords] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [recordsCount, setRecordsCount] = useState(0);
  const [sortLabel, setSortLabel] = useState("Recently Added");
  const [sortBy, setSortBy] = useState({ createdAt: "desc" });
  const [search, setSearch] = useState({
    filter: { searchText: "", is_active: true },
    start: 0,
    length: 18,
    sortBy: { createdAt: "desc" },
  });
  let loading = false;

  useEffect(() => {
    getList();
  }, [search]);

  async function getList() {
    if (loading === false && (recordsCount == 0 || recordsCount > records.length)) {
      loading = true;
      let respData = await serv.listAll(search);
      setRecords(recordsCount == 0 ? respData.data : [...records, ...respData.data]);
      setRecordsCount(respData.total);
      setShowLoading(false);
    }
  }

  function reachedBottomCall() {
    let searchTemp = { ...search };
    searchTemp.start = search.start + search.length;
    setSearch(searchTemp);
  }

  function searchCallBack(value) {
    let searchTemp = { ...search };
    searchTemp.start = 0;
    searchTemp.filter.searchText = value;
    setRecordsCount(0);
    setSearch(searchTemp);
  }
  function sortHandler(sort, label) {
    if (label == "A to Z") {
      sort = { name: "asc" };
    } else if (label == "Z to A") {
      sort = { name: "desc" };
    }
    let searchTemp = { ...search };
    searchTemp.start = 0;
    searchTemp.sortBy = sort;
    setRecordsCount(0);
    setSearch(searchTemp);
    setSortBy(sort);
    setSortLabel(label);
  }
  if (showLoading) {
    return <Loading />;
  }

  return (
    <div className="ljSectionData w-100 clearfix glossarylist" id="ljSectionData">
      <Helmet>
        <title>Partners | MaveFi</title>
      </Helmet>
      <PageHeader
        type="blog"
        searchCallBack={searchCallBack}
        search={{ ...search, sortLabel, sortHandler }}
        // handleReferPopUp={handleReferPopUp}
        titleCard={{
          image: "/images/svg-icons/handshake.svg",
          title: "Partners",
          content:
            "We are searching for partners to join us in our quest to educate & onboard users to Web3. If you believe you can help us achieve our goals though a mutualually beneficial partnership, we want to hear from you.",
          type: "glossary",
        }}
        searchType={{ type: "glossary", placeholder: "Search partners" }}
        loginTitle="Please login/sign up to start a course or view your progress."
        rightActionCard={{
          heading: "Interested in partnering with us? ",
          desc: "We are always looking for new amazing partners to help us build & create better. Reach out if you think your are the right fit.",
          buttonName: "Email us at shub@mavefi.com",
          link: "mailto:shub@mavefi.com",
        }}
      />
      {/* <div className="partnerTitleSec">
        <div className="row glossaryTitleSecRow">
          <div className="col-lg-12 col-xl-8 glossaryTitleCol">
            <TitleCard2
              image="/images/svg-icons/handshake.svg"
              title="Partners"
              content="We are searching for partners to join us in our quest to educate & onboard users to Web3. If you believe you can help us achieve our goals though a mutualually beneficial partnership, we want to hear from you."
              type="glossary"
            />
            <PageSearch placeholder="Search partners" searchCallBack={searchCallBack} />
          </div>
          <div className="col-lg-12 col-xl-4 ps-xl-0 glossaryTitleCol">
            <div className="h-100 titleCard faqCompleted my-md-0 card">
              <div className="faqComp updatedCard">
                <h4>Interested in partnering with us?</h4>
                <p className="mb-0">
                  We are always looking for new amazing partners to help us build & create better. If you think your are
                  the right fit, we want to hear from you at <a href="javascript:void(0);">shub@mavefi.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="allPartnerCard">
        <div className="allUpdateCardList allLearnEarnCardList">
          <div className="updates_card_section updatesCardSection mb-3">
            {records.length > 0 && (
              <ScrollMoreProvider
                className="row updatesCardSectionRow"
                id="careersList"
                reachedBottomCall={reachedBottomCall}
                useWindowScroll={true}
              >
                {records.map((p) => {
                  return <PartnerCard key={p._id} partner={p} />;
                })}
              </ScrollMoreProvider>
            )}
            {records.length < recordsCount && <Loading isSmall={true} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
