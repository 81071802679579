import { useContext, useEffect, useState } from "react";
import CourseCard from "./CourseCard";
import CourseService from "../../services/CourseService";
import UserService from "../../services/UserService";
import ScrollMoreProvider from "../../shared/Scroll/ScrollMoreProvider";
import TitleCard2 from "../../shared/TitleCard2";
import GlobalContext from "../../context/GlobalContext";
import CoursesSearch from "./CoursesSearch";
import Loading from "../../shared/Loading";
import LoginSignupCard from "../../shared/LoginSignupCard";
import { Helmet } from "react-helmet";
import PageHeader from "../../shared/PageHeader";

const serv = new CourseService();
const UserServ = new UserService();

function Courses() {
  const globalCtx = useContext(GlobalContext);
  const [searchDtl, setSearchDtl] = globalCtx.searchDtl;
  const [referEarn, setReferEarn] = globalCtx.referEarn;
  const [setting, setSetting] = globalCtx.setting;
  const [user, setUser] = globalCtx.user;
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const [records, setRecords] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [recordsCount, setRecordsCount] = useState(0);
  const [status, setStatus] = useState(null);
  const [activeFilterList, setActiveFilterList] = useState([]);
  const [rewardedList, setRewardedList] = useState([]);
  const [search, setSearch] = useState({
    filter: { searchText: searchDtl, is_active: true },
    sort: { createdAt: "desc" },
    start: 0,
    length: 20,
  });
  let loading = false;
  const [filterListObj, setFilterListObj] = useState({
    author: [],
    tags: [],
    status: [
      { check: false, value: "New" },
      { check: false, value: "Started" },
      { check: false, value: "Completed" },
      { check: false, value: "Rewards Claimed" },
    ],
    rewards: [
      { check: false, value: "Yes" },
      { check: false, value: "No" },
    ],
    languages: [{ check: false, value: "English" }],
  });
  const [filterObj, setFilterObj] = useState({});
  const sortArray = [
    { method: { createdAt: "desc" }, label: "Recently Added" },
    { method: { course_name: "asc" }, label: "A to Z" },
    { method: { course_name: "desc" }, label: "Z to A" },
    { method: { viewCount: "desc" }, label: "Most viewed" },
    { method: { bookmarkCount: "desc" }, label: "Most bookmarked" },
    { method: { createdAt: "asc" }, label: "Oldest" },
  ];
  useEffect(() => {
    getRewardedList();
    getStatus();
  }, []);
  useEffect(() => {
    getList();
    filterList();
  }, [search]);

  // useEffect(() => {
  //   console.log("context search change");
  //   searchCallBack(searchDtl);
  // }, [searchDtl]);

  async function getRewardedList() {
    let respData = await serv.rewardedCourse({});
    setRewardedList(JSON.parse(respData));
    setSearch({ ...search, start: 0 });
    setRecordsCount(0);
    getList();
    // setSearch({
    //   filter: { searchText: searchDtl, is_active: true },
    //   sort: { createdAt: "desc" },
    //   start: 0,
    //   length: 5,
    // });
  }
  async function getList() {
    if (loading === false && (recordsCount == 0 || recordsCount > records.length)) {
      loading = true;
      let searchTemp = search;
      if (searchTemp?.sort?.createdAt == "desc") {
        if (!searchTemp?.filter?.getRewarded) {
          searchTemp.filter.avoidRewarded = rewardedList;
        }
      }
      let respData = await serv.listAll(searchTemp, user?._id);
      setRecords(recordsCount == 0 ? respData.data : [...records, ...respData.data]);
      if (respData.data.length < search.length) {
        searchTemp.filter.getRewarded = rewardedList;
        searchTemp.length = undefined;
        setSearch(searchTemp);
      }
      setRecordsCount(respData.total);
      setShowLoading(false);
    }
  }
  async function getStatus() {
    let id = user?._id;
    if (id) {
      let respData = await UserServ.getUser(id);
      if (respData.data) {
        setUser({ ...respData.data });
      }
      let obj = { user_id: id };
      let courseStatus = await serv.status(obj);
      setStatus(courseStatus.data);
    }
  }

  function reachedBottomCall() {
    let searchTemp = { ...search };
    searchTemp.start = search.start + search.length;
    setSearch(searchTemp);
  }
  function handleReferPopUp() {
    setReferEarn(true);
  }
  async function searchCallBack(value, filters, sortBy) {
    let respData = await serv.getFilterList();
    let searchTemp = { ...search };
    searchTemp.start = 0;
    searchTemp.filter = { is_active: true };
    searchTemp.filter.searchText = value;
    searchTemp.sort = { createdAt: "desc" };
    let filterList = [];
    if (filters !== undefined) {
      if (filters?.tags?.length > 0) {
        searchTemp.filter.tags = filters.tags;
        filterList.push({ type: "tags", value: filters.tags });
      }
      if (filters?.author?.length > 0) {
        searchTemp.filter.author = filters.author;
        filterList.push({
          type: "author",
          value: [...filters.author.map((i) => respData.data.author.filter((j) => j._id == i)[0].user_name)],
        });
        // filterList.push(...filters.author)
      }
      if (filters?.rewards?.length > 0) {
        searchTemp.filter.rewards = filters.rewards;
        filterList.push({ type: "rewards", value: filters.rewards });
        // filterList.push(...filters.rewards)
      }
      if (filters?.status?.length > 0) {
        searchTemp.filter.status = filters.status;
        filterList.push({ type: "status", value: filters.status });
        // filterList.push(...filters.status)
      }
      if (filters?.languages?.length > 0) {
        searchTemp.filter.languages = filters.languages;
        filterList.push({ type: "languages", value: filters.languages });
        // filterList.push(...filters.languages)
      }
      setActiveFilterList([...filterList]);
    }
    if (sortBy) {
      searchTemp.sort = sortBy;
    }

    setRecordsCount(0);
    setShowLoading(true);
    setSearch(searchTemp);
  }
  const handleRemoveFilter = async (obj, item) => {
    let searchTemp = {
      ...search,
      filter: { ...search.filter, [obj.type]: search.filter[obj.type].filter((i) => i !== item) },
    };
    searchTemp.start = 0;
    setRecordsCount(0);
    setShowLoading(true);
    setSearch(searchTemp);
    let activeTemp = activeFilterList.map((i) => {
      return { ...i, value: i.value.filter((j) => j !== item) };
    });
    activeTemp = activeTemp.filter((i) => i.value.length > 0);
    setActiveFilterList([...activeTemp]);
  };

  // if (showLoading) {
  //   return <Loading />;
  // }
  const handleRichText = () => {
    return (
      // <div className=" coursesCompleted coursesCompletedCustom">

      <>
        <div className="coursesComp">
          <p className=" mb-0 letterSpacing0">COURSES COMPLETED</p>
          <h4 className="mb-0 font-600 letterSpacing0">
            {status
              ? `${user.courseCompleted} of ${status.total_course} (${parseFloat(
                  (user.courseCompleted / status.total_course) * 100
                )
                  .toFixed(2)
                  .replace(/\.00$/, "")}% Completed)`
              : " 0 of 20 (0% Completed)"}{" "}
          </h4>
        </div>

        <div className="coursesPoints">
          <p className="mb-0 letterSpacing0">LEARN REWARDS</p>
          <div className="coursesLogo d-flex align-items-center">
            <div className="learnLogoIcon">
              <img src="/images/single_log.png" alt="logoicon" className="img-fluid" />
            </div>
            <div className="coinPoints">
              <h4 className="mb-0 font-600 letterSpacing0">{user?.courseReward || 0} COINS</h4>
            </div>
          </div>
        </div>
        <div className="coursesReferred d-flex flex-row align-items-center justify-content-between">
          <div>
            <p className="coursesReferredCode mb-0 letterSpacing0">USERS REFERRED</p>
            <div className="mainCoursesReferred d-flex align-items-start">
              <div className="courBtnInfo flex-grow-1">
                <h4 className="mb-0 font-600">0</h4>
              </div>
            </div>
          </div>
          <div className="coursesBtnInfo" onClick={handleReferPopUp}>
            <a href="javascript:void(0)" className="btn btnInfo letterSpacing0">
              REFER & EARN
            </a>
          </div>
        </div>
      </>
    );
    {
      // </div>
    }
  };
  async function filterList() {
    let respData = await serv.getFilterList();
    let tagValues = [];
    let tag = [];
    let rewardValues = [];
    let reward = [];
    let authorValues = [];
    let author = [];
    let statusValues = [];
    let statuss = [];
    let languageValues = [];
    let language = [];
    respData.data.tags.map((item) => {
      let tagValue = { check: false, value: item };

      if (search.filter?.tags?.includes(item)) {
        tagValue.check = true;
        tag.push(item);
      }
      tagValues.push(tagValue);
    });
    respData.data.author.map((item) => {
      let authorValue = {
        check: false,
        user_name: item.user_name,
        _id: item._id,
      };

      if (search.filter?.author?.includes(item._id)) {
        authorValue.check = true;
        author.push(item._id);
      }
      authorValues.push(authorValue);
    });
    filterListObj.rewards.map((item) => {
      let rewardValue = item;

      if (search.filter?.rewards?.includes(item.value)) {
        rewardValue.check = true;
        reward.push(item.value);
      }
      rewardValues.push(rewardValue);
    });
    filterListObj.status.map((item) => {
      let statusValue = item;

      if (search.filter?.status?.includes(item.value)) {
        statusValue.check = true;
        statuss.push(item.value);
      }
      statusValues.push(statusValue);
    });
    filterListObj.languages.map((item) => {
      let languageValue = item;

      if (search.filter?.languages?.includes(item.value)) {
        languageValue.check = true;
        language.push(item.value);
      }
      languageValues.push(languageValue);
    });
    let tempFilterObj = {
      author: author,
      tags: tag,
      rewards: reward,
      status: statuss,
      languages: language,
    };
    let tempFilterListObj = {
      status: statusValues,
      author: authorValues,
      tags: tagValues,
      rewards: rewardValues,
      languages: languageValues,
    };
    if (!isAuthentiCated) {
      delete tempFilterObj.status;
      delete tempFilterListObj.status;
    }
    setFilterObj({ ...tempFilterObj });
    setFilterListObj({ ...tempFilterListObj });
  }
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <Helmet>
        <title>{setting?.seo?.course?.title || ""}</title>
        <meta name="description" content={setting?.seo?.course?.meta_description || ""} />
        <meta name="keywords" content={setting?.seo?.course?.tags || ""} />
        <meta property="og:title" content={setting?.seo?.course?.title || ""} />
        <meta property="og:description" content={setting?.seo?.course?.meta_description || ""} />
        <meta property="og:image" content={setting?.seo?.social_share_image} />
      </Helmet>
      <PageHeader
        allowedFilter={true}
        type="course"
        searchCallBack={searchCallBack}
        search={search}
        filterListObj={filterListObj}
        filterObj={filterObj}
        handlePopUp={handleReferPopUp}
        status={status}
        sortArray={sortArray}
        titleCard={{
          image: "/images/svg-icons/learn-earn.svg",
          title: "Learn",
          content:
            "Learn all about crypto, nfts, defi, web3, etc. Our courses are built for the end users & will help you understand everything you need for starting your web3 journey.",
          // content2: "Request a course - learn@mavefi.com",
          type: "glossary",
        }}
        searchType={{ type: "course", placeholder: "Search courses" }}
        loginTitle="Please login/sign up to start a course or view your progress."
        rightActionCard={{
          heading: "Submit a Word/Slang",
          desc: "There are new words/slangs coined everyday in the web3 space & you can help us index them by submitting your entries below. ",
          buttonName: "Contribute",
          richText: handleRichText(),
        }}
      />
      {/* <div className="glossaryTitleSec">
        <div className="row glossaryTitleSecRow">
          <div className="col-lg-12 col-xl-8 glossaryTitleCol">
            <TitleCard2
              image="/images/svg-icons/learn-earn.svg"
              title="Learn"
              content="Learn all about blockchain, smart contracts, and decentralized technology. Our courses will provide you the information you’re looking for."
              content2="Request a course - learn@mavefi.com"
              type="glossary"
            />
            <CoursesSearch
              placeholder="Search courses"
              searchCallBack={searchCallBack}
              searchText={search.filter.searchText}
              searchFilter={search.filter}
            />
          </div>
          <div className="col-lg-12 col-xl-4 ps-xl-0 glossaryTitleCol">
            {isAuthentiCated && user?._id ? (
              <div className="h-100 titleCard  coursesCompleted coursesCompletedCustom learn-stats my-md-0 card">
                <div className="coursesComp">
                  <h4>COURSES COMPLETED</h4>
                  <p className="progressLearn mb-0">
                    {status
                      ? `${user.courseCompleted} of ${status.total_course} (${parseFloat(
                          (user.courseCompleted / status.total_course) * 100
                        )
                          .toFixed(2)
                          .replace(/\.00$/, "")}% Completed)`
                      : " 0 of 20 (0% Completed)"}{" "}
                  </p>
                </div>

                <div className="coursesPoints">
                  <h4>LEARN REWARDS</h4>
                  <div className="coursesLogo d-flex align-items-center">
                    <div className="learnLogoIcon">
                      <img src="/images/single_log.png" alt="logoicon" className="img-fluid" />
                    </div>
                    <div className="coinPoints">
                      <p className="mb-0">{user?.courseReward || 0} COINS</p>
                    </div>
                  </div>
                </div>
                <div className="coursesReferred d-flex flex-row align-items-center justify-content-between">
                  <div>
                    <h4>USERS REFERRED</h4>
                    <div className="mainCoursesReferred d-flex align-items-start">
                      <div className="courBtnInfo flex-grow-1">
                        <p className="coursesReferredCode mb-0">0</p>
                      </div>
                    </div>
                  </div>
                  <div className="coursesBtnInfo" onClick={handleReferPopUp}>
                    <a href="javascript:void(0)" className="btn btnInfo">
                      REFER & EARN
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <LoginSignupCard title="Please login/sign up to start a course or view your progress." />
            )}
          </div>
        </div>
      </div> */}
      <div className="activeFilterList">
        {activeFilterList.map((item) => {
          return item.value.map((i) => {
            return (
              <div className="activeFilter">
                <p>{i}</p>
                <img src="/images/icons/cross.svg" className="img-fluid" onClick={() => handleRemoveFilter(item, i)} />
                {/* <button className="btn"><img src="/images/icons/cross.svg" className="img-fluid" /></button> */}
              </div>
            );
          });
        })}
      </div>
      {showLoading ? (
        <Loading />
      ) : (
        <div className={`allGlossaryCard ${activeFilterList.length > 0 && "allCourseCard"}`}>
          <div className="allUpdateCardList allLearnEarnCardList">
            <div className="updates_card_section updatesCardSection mb-3">
              {records.length > 0 && (
                <ScrollMoreProvider
                  className="row updatesCardSectionRow"
                  id="glossary list"
                  reachedBottomCall={reachedBottomCall}
                  useWindowScroll={true}
                >
                  {records.map((c, idx) => {
                    if (idx == 11) {
                      return (
                        <>
                          <CourseCard key={c._id} course={c} searchCallBack={searchCallBack} search={search} />
                          <div className="col-sm-4 col-lg-4 col-xl-3 col-xxl-2 cardsBox">
                            <div className="h-100 learnEarnBox">
                              <div className="updates_card learnEarnCard learnEarnCardCustom">
                                <amp-ad
                                  width="226"
                                  height="320"
                                  type="adsense"
                                  data-ad-client="ca-pub-1429924092740823"
                                  data-ad-slot="8890481255"
                                  data-auto-format="rspv"
                                  data-full-width=""
                                >
                                  <div overflow=""></div>
                                </amp-ad>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    } else {
                      return <CourseCard key={c._id} course={c} searchCallBack={searchCallBack} search={search} />;
                    }
                  })}
                </ScrollMoreProvider>
              )}
              {records.length < recordsCount && <Loading isSmall={true} />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Courses;
