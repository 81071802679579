import { useEffect, useState, useContext } from "react";
import CourseService from "../../services/CourseService";
import GlobalContext from "../../context/GlobalContext";

const serv = new CourseService();

function CoursesFilter({ onClose, handleFilters, searchFilter }) {
  const globalCtx = useContext(GlobalContext);
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const [enableFilterButton, setEnableFilterButton] = useState(false);
  const [filterTags, setFilterTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [author, setAuthor] = useState([]);
  const [authorSearch, setAuthorSearch] = useState("");
  const [tagSearch, setTagSearch] = useState("");
  const [filterAuthor, setFilterAuthor] = useState([]);
  const [rewards, setRewards] = useState([
    { check: false, value: "Yes" },
    { check: false, value: "No" },
  ]);
  const [languages, setLanguages] = useState([
    { check: false, value: "English" },
  ]);
  const [filterRewards, setFilterRewards] = useState([]);
  const [filterLanguages, setFilterLanguages] = useState([]);
  const [status, setStatus] = useState([
    { check: false, value: "New" },
    { check: false, value: "Started" },
    { check: false, value: "Completed" },
    { check: false, value: "Rewards Claimed" },
  ]);
  const [filterStatus, setFilterStatus] = useState([]);

  useEffect(() => {
    filterList();
  }, []);

  async function filterList() {
    let respData = await serv.getFilterList();
    let tagValues = [];
    let tag = [];
    let rewardValues = [];
    let reward = [];
    let authorValues = [];
    let author = [];
    let statusValues = [];
    let statuss = [];
    let languageValues = [];
    let language = [];
    respData.data.tags.map((item) => {
      let tagValue = { check: false, value: item };

      if (searchFilter?.tags?.includes(item)) {
        tagValue.check = true;
        tag.push(item);
      }
      tagValues.push(tagValue);
    });
    respData.data.author.map((item) => {
      let authorValue = {
        check: false,
        user_name: item.user_name,
        _id: item._id,
      };

      if (searchFilter?.author?.includes(item._id)) {
        authorValue.check = true;
        author.push(item._id);
      }
      authorValues.push(authorValue);
    });
    rewards.map((item) => {
      let rewardValue = item;

      if (searchFilter?.rewards?.includes(item.value)) {
        rewardValue.check = true;
        reward.push(item.value);
      }
      rewardValues.push(rewardValue);
    });
    status.map((item) => {
      let statusValue = item;

      if (searchFilter?.status?.includes(item.value)) {
        statusValue.check = true;
        statuss.push(item.value);
      }
      statusValues.push(statusValue);
    });
    languages.map((item) => {
      let languageValue = item;

      if (searchFilter?.languages?.includes(item.value)) {
        languageValue.check = true;
        language.push(item.value);
      }
      languageValues.push(languageValue);
    });
    setAuthor(authorValues);
    setFilterAuthor(author);
    setTags(tagValues);
    setFilterTags(tag);
    setRewards(rewardValues);
    setFilterRewards(reward);
    setStatus(statusValues);
    setFilterStatus(statuss);
    setLanguages(languageValues);
    setFilterLanguages(language);
  }
  let handleAutor = (event) => {
    if (event.target.checked) {
      setFilterAuthor([...filterAuthor, event.target.value]);
    } else {
      setFilterAuthor(filterAuthor.filter((id) => id !== event.target.value));
    }
    let newAuthor = author.map((item) => {
      if (item._id == event.target.value) {
        item.check = !item.check;
      }
      return item;
    });
    setAuthor(newAuthor);
    setEnableFilterButton(true);
  };
  let handleTag = (event) => {
    if (event.target.checked) {
      setFilterTags([...filterTags, event.target.value]);
    } else {
      setFilterTags(filterTags.filter((tag) => tag !== event.target.value));
    }
    let newTag = tags.map((item) => {
      if (item.value == event.target.value) {
        item.check = !item.check;
      }
      return item;
    });
    setTags(newTag);
    setEnableFilterButton(true);
  };
  let handleRewards = (event) => {
    if (event.target.checked) {
      setFilterRewards([...filterRewards, event.target.value]);
    } else {
      setFilterRewards(
        filterRewards.filter((reward) => reward !== event.target.value)
      );
    }
    let newReward = rewards.map((item) => {
      if (item.value == event.target.value) {
        item.check = !item.check;
      }
      return item;
    });
    setRewards(newReward);
    setEnableFilterButton(true);
  };
  let handleLanguage = (event) => {
    if (event.target.checked) {
      setFilterLanguages([...filterLanguages, event.target.value]);
    } else {
      setFilterLanguages(
        filterLanguages.filter((reward) => reward !== event.target.value)
      );
    }
    let newLanguage = languages.map((item) => {
      if (item.value == event.target.value) {
        item.check = !item.check;
      }
      return item;
    });
    setLanguages(newLanguage);
    setEnableFilterButton(true);
  };
  let handleStatus = (event) => {
    if (event.target.checked) {
      setFilterStatus([...filterStatus, event.target.value]);
    } else {
      setFilterStatus(
        filterStatus.filter((item) => item !== event.target.value)
      );
    }
    let newStatus = status.map((item) => {
      if (item.value == event.target.value) {
        item.check = !item.check;
      }
      return item;
    });
    setStatus(newStatus);
    setEnableFilterButton(true);
  };
  let handleSubmit = (triggerBy) => {
    if (triggerBy == "ApplyFilterButton" && enableFilterButton) {
      handleFilters({
        author: filterAuthor,
        tags: filterTags,
        rewards: filterRewards,
        status: filterStatus,
        languages: filterLanguages,
      });
    }
  };

  return (
    <>
      <div
        className="modal filterModel"
        id="filterModel"
        style={{ display: "block" }}
        onClick={(e) => {
          if (e.target == e.currentTarget) {
            handleSubmit("FilterModel");
            onClose();
          }
        }}
      >
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Filters</h4>
                  <button
                    onClick={(e) => {
                      handleSubmit("CloseButton");
                      onClose();
                    }}
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>

                <div className="modal-body">
                  <div className="accordion" id="accordionExample">
                    {isAuthentiCated ? (
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Course Status
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            {status.map((item) => (
                              <div className="accordionBody">
                                <div className="accordionBodyTxt">
                                  <p className="mb-0">{item.value}</p>
                                </div>
                                <div className="accordionBodyCheckBox">
                                  <div className="form-check">
                                    <input
                                      checked={item.check}
                                      className="form-check-input"
                                      type="checkbox"
                                      value={item.value}
                                      onChange={handleStatus}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Author
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="filterSearch">
                            <div className="headerSearchBar has-clear">
                              <div className="input-group">
                                <button
                                  className="btn"
                                  type="button"
                                  style={{ zIndex: 9 }}
                                >
                                  <img
                                    src="/images/svg-icons/search.svg"
                                    alt="logo"
                                    className="img-fluid"
                                  />
                                </button>
                                <input
                                  type="text"
                                  className="form-control border-0"
                                  placeholder="Search"
                                  onChange={(e) =>
                                    setAuthorSearch(e.target.value)
                                  }
                                />
                                <span className="close form-control-clear hidden">
                                  <img
                                    src="/images/svg-icons/close.svg"
                                    alt="search"
                                    className="img-fluid"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          {author
                            .filter((item) =>
                              item.user_name
                                .toLowerCase()
                                .includes(authorSearch.toLowerCase())
                            )
                            .map((item) => (
                              <div className="accordionBody">
                                <div className="accordionBodyTxt">
                                  <p className="mb-0">{item.user_name}</p>
                                </div>
                                <div className="accordionBodyCheckBox">
                                  <div className="form-check">
                                    <input
                                      checked={item.check}
                                      className="form-check-input"
                                      type="checkbox"
                                      value={item._id}
                                      onChange={handleAutor}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Tags
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="filterSearch">
                            <div className="headerSearchBar has-clear">
                              <div className="input-group">
                                <button
                                  className="btn"
                                  type="button"
                                  style={{ zIndex: 9 }}
                                >
                                  <img
                                    src="/images/svg-icons/search.svg"
                                    alt="logo"
                                    className="img-fluid"
                                  />
                                </button>
                                <input
                                  type="text"
                                  className="form-control border-0"
                                  placeholder="Search"
                                  onChange={(e) => setTagSearch(e.target.value)}
                                />
                                <span className="close form-control-clear hidden">
                                  <img
                                    src="/images/svg-icons/close.svg"
                                    alt="search"
                                    className="img-fluid"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>

                          {tags
                            .filter((item) =>
                              item.value
                                .toLowerCase()
                                .includes(tagSearch.toLowerCase())
                            )
                            .map((item) => (
                              <div className="accordionBody">
                                <div className="accordionBodyTxt">
                                  <p className="mb-0">{item.value}</p>
                                </div>
                                <div className="accordionBodyCheckBox">
                                  <div className="form-check">
                                    <input
                                      checked={item.check}
                                      className="form-check-input"
                                      type="checkbox"
                                      value={item.value}
                                      onChange={handleTag}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          Language
                        </button>
                      </h2>
                      <div
                        id="collapseSeven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSeven"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          {languages.map((item) => (
                            <div className="accordionBody">
                              <div className="accordionBodyTxt">
                                <p className="mb-0">{item.value}</p>
                              </div>
                              <div className="accordionBodyCheckBox">
                                <div className="form-check">
                                  <input
                                    checked={item.check}
                                    className="form-check-input"
                                    type="checkbox"
                                    value={item.value}
                                    onChange={handleLanguage}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          Rewards
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          {rewards.map((item) => (
                            <div className="accordionBody">
                              <div className="accordionBodyTxt">
                                <p className="mb-0">{item.value}</p>
                              </div>
                              <div className="accordionBodyCheckBox">
                                <div className="form-check">
                                  <input
                                    checked={item.check}
                                    className="form-check-input"
                                    type="checkbox"
                                    value={item.value}
                                    onChange={handleRewards}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Modal footer --> */}
                <div className="modal-footer">
                  <button
                    type="button"
                    className={
                      "btn btnFilter" +
                      (enableFilterButton ? "" : " disabled opacity-half")
                    }
                    // disabled="disabled"
                    onClick={(e) => {
                      handleSubmit("ApplyFilterButton");
                      onClose();
                    }}
                  >
                    APPLY FILTERS
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
}

export default CoursesFilter;
