import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FaqService from "../../services/FaqService";
// import CourseFilter from "./CourseFilter";
const faqServ = new FaqService()
function FaqsSearch({
    searchText,
    placeholder,
    searchCallBack,
    searchFilter,
}) {
    const [showCancel, setShowCancel] = useState(false);
    const [value, setValue] = useState("");
    const [sortBy, setSortBy] = useState({});
    const [filter, setFilter] = useState({});
    const [categoryLabel, setCategoryLabel] = useState("All");
    const [categoryList, setCategoryList] = useState([])

    useEffect(() => {
        setValue(searchText);
    }, [searchText]);
    useEffect(() => {
        getCategoryList()
    }, [])
    const getCategoryList = () => {
        try {
            faqServ.categoryList({}).then((resp) => {
                setCategoryList(resp.data)
            }).catch(err => {
                console.log(err);
            })
        } catch (err) {
            console.log(err);
        }
    }

    function changeHandler(value) {
        if (value !== "") {
            setShowCancel(true);
        } else {
            setShowCancel(false);
        }
        setValue(value);
        searchCallBack(value, filter, sortBy);
    }

    function categoryHandler(categoryValue) {
        let cat = { category_id: categoryValue._id }
        setCategoryLabel(categoryValue.name);
        setFilter(cat)
        searchCallBack(value, cat, sortBy);
    }
    return (
        <div className="filterSec d-flex align-items-center">
            <div className="filterSearchInner d-flex">
                {/* <div className="filterIcons headerBtnSec">
                    <div className="modeBtn">
                        <Link
                            onClick={() => setShowFilter(!showFilter)}
                            to="#"
                        >
                            <img
                                src="/images/svg-icons/filter-edit.svg"
                                alt="notification"
                                className="img-fluid"
                            />
                        </Link>
                    </div>

                    {showFilter && (
                        <CourseFilter
                            onClose={() => setShowFilter(!showFilter)}
                            handleFilters={handleFilters}
                            searchFilter={searchFilter}
                        />
                    )}
                </div> */}
                <div className="mostbookmarked">
                    <div className="dropdown">
                        <Link
                            to="#"
                            className="btnBookmarkedToggle btnBookmarkedToggleCustom"
                            data-bs-toggle="dropdown"
                        >
                            <div className="categoryBtn">
                                <p>Category</p>
                                {categoryLabel}
                            </div>
                            <img
                                src="/images/svg-icons/collapse-arrow.svg"
                                alt="search"
                                className="img-fluid collapse-arrow"
                            />
                        </Link>
                        <ul className="dropdown-menu">
                            <li>
                                <Link
                                    className="dropdown-item"
                                    to="#"
                                    onClick={() =>
                                        categoryHandler({ name: "All", _id: "" })
                                    }
                                >
                                    All
                                </Link>
                            </li>
                            {categoryList.length > 0 && categoryList.map((item, idx) => {
                                return (
                                    <li>
                                        <Link
                                            className="dropdown-item"
                                            to="#"
                                            onClick={() =>
                                                categoryHandler(item)
                                            }
                                        >
                                            {item.name}
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <div className="glossarySearch has-clear">
                    <div className="input-group">
                        <span className="input-group-text">
                            <img
                                src="/images/svg-icons/search.svg"
                                alt="search"
                                className="img-fluid"
                            />
                        </span>
                        <input
                            value={value}
                            type="text"
                            className="form-control"
                            placeholder={placeholder}
                            onChange={(e) => {
                                changeHandler(e.target.value);
                            }}
                        />
                        <span
                            className={
                                "input-group-text close form-control-clear" +
                                (showCancel === false ? " hidden" : "")
                            }
                        >
                            <img
                                src="/images/svg-icons/close.svg"
                                alt="search"
                                className="img-fluid"
                                onClick={() => {
                                    changeHandler("");
                                }}
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FaqsSearch;
