import { useEffect } from "react";
import { useLocation, BrowserRouter } from "react-router-dom";
import { GlobalProvider } from "./context/GlobalContext";
import NoMobile from "./NoMobile";
import AllRoutes from "./routers/AllRoutes";

export function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function App() {
  if (window.innerWidth < 800) {
    return <NoMobile />;
  }
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <GlobalProvider>
          <AllRoutes />
        </GlobalProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
