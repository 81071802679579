import { useContext, useState } from "react";

function wellcomeCokie({ onClose }) {
  return (
    <>
      <div className="cookiesPopup" style={{ display: "block" }}>
        <div className="cookiesToast" style={{ zIndex: 10000 }}>
          <div className="h-100 cookiesToastBody p-0 position-relative">
            <div className="toastInnerBody">
              <div className="toastContant position-relative">
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                ></button>
                <div className="jarCookiesOuter d-flex align-items-center">
                  <div className="jarCookiesImages">
                    <img
                      src="/images/svg-icons/jar-with-cookies.svg"
                      alt="cookiesImg"
                      className="img-fluid"
                    />
                  </div>
                  <div className="jarCookiesContant d-flex align-items-center">
                    <div className="cookiesContant">
                      <h5>Did someone say … cookies?</h5>
                      <p className="mb-0">
                        MaveFi and its partners use cookies to provide you with
                        a better, safer and faster service and to support our
                        business. Some cookies are necessary to use our
                        services, improve our services, and make sure they work
                        properly.
                      </p>
                    </div>
                    <div className="cookiesBtn d-flex align-items-center">
                      <button
                        type="button"
                        className="btnCookiesAccept btn"
                        onClick={onClose}
                      >
                        ACCEPT COOKIES
                      </button>
                      <button
                        type="button"
                        className="btnCookiesDecline btn"
                        onClick={onClose}
                      >
                        DECLINE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default wellcomeCokie;
