import { Link, useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
function SidebarLegal() {
  const globalCtx = useContext(GlobalContext);
  const [policies, setPolicies] = globalCtx.policies;
  const params = useParams();
  const page = params.page;
  const [isActive, setIsActive] = useState(false);

  return (
    <div className={"detailMenuSec" + (isActive === true ? " active" : "")}>
      <div className="pageSideToggle d-block d-xl-none">
        <div
          className="pageSidebarToggle"
          onClick={() => {
            setIsActive(!isActive);
          }}
        >
          <img src="/images/svg-icons/sideArrow.svg" alt="back-icon" className="img-fluid" />
        </div>
      </div>
      <div className="sidebarPosition">
        <div className="detailMenuOuterSec  card padding0" id="sidebar">
          <div className="detailMenuInnerSec">
            <div className="detailMenuHeading ">
              <h4 className="margin0 sideNavh4">Legal</h4>
            </div>
            <div className="detailSideLink sideNavUl">
              <ul
                className="nav flex-column"
                onClick={() => {
                  setIsActive(!isActive);
                }}
              >
                {policies.map((item, i, { length }) => {
                  return (
                    <li style={{ transition: "none" }}>
                      <Link
                        to={`/legal/${item.title}`}
                        className={
                          `${i + 1 == length ? "mb-0" : "legalSideLink"} ` +
                          (page === item._id || page === item.title ? "active" : "nonActive")
                        }
                      >
                        {/* {item.title} */}
                        <div className="d-flex justify-content-between w-100 margin0">
                          <p className="margin0 legalNavLi">{item.title}</p>
                          {page === item._id ? (
                            <p className="dotPrimary"></p>
                          ) : (
                            <img src="/images/svg-icons/rightArrow.svg" />
                          )}
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="missingPosicyDiv">
          <h6 className="letterSpacing0 margin0">Are we missing a policy?</h6>
          <p className="margin0 letterSpacing0">
            Get paid to help us create the <br /> missing documents & policies.
            <br /> Contact :
            <a href="mailto:shub@mavefi.com" className="borderBottom">
              shub@mavefi.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
export default SidebarLegal;
