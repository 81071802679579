import { useRef, useEffect, useContext } from "react";
import ScrollContext from "../../context/ScrollContext";

function ScrollMore({
  children,
  className,
  id,
  reachedBottomCall,
  useWindowScroll,
}) {
  const { scrollChange } = useContext(ScrollContext);
  const [scrolledToBottom, setScrolledToBottom] = scrollChange;

  // this useEffect and window listener is use less if useWindowScroll is set to false
  // as we will be using useReef and onScroll of element in that case
  useEffect(() => {
    if (useWindowScroll) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [useWindowScroll]);

  useEffect(() => {
    reachedBottomCall();
  }, [scrolledToBottom]);

  const listInnerRef = useRef();
  const handleScroll = (e) => {
    let scrollTop, scrollHeight, clientHeight, adjustment;
    if (useWindowScroll) {
      scrollTop = window.scrollY;
      scrollHeight = document.body.offsetHeight;
      clientHeight = window.innerHeight;
      adjustment = 350;
    } else {
      scrollTop = listInnerRef.current.scrollTop;
      scrollHeight = listInnerRef.current.scrollHeight;
      clientHeight = listInnerRef.current.clientHeight;
      adjustment = 50;
    }
    console.log(
      "scrollTop + clientHeight >= scrollHeight - adjustment",
      scrollTop +
        " + " +
        clientHeight +
        " >= " +
        scrollHeight +
        " - " +
        adjustment,
      scrollTop + clientHeight >= scrollHeight - adjustment
    );
    if (scrollTop + clientHeight >= scrollHeight - adjustment) {
      const d = new Date();
      let time = d.getSeconds();
      setScrolledToBottom(time);
    }
  };

  return (
    <div
      className={className}
      id={id}
      onScroll={handleScroll} // this onScroll is useless if useWindowScroll is set to true
      // as we will be using window event listener in that case
      ref={listInnerRef}
    >
      {children}
    </div>
  );
}
export default ScrollMore;
