import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import SidebarLegal from "./SidebarLegal";
import LegalDetail from "./LegalDetail";
import PolicyService from "../../services/PolicyService";
import moment from "moment";
const policyServ = new PolicyService();
function Legal() {
  const params = useParams();
  const page = params.page;
  const [policyData, setPolicyData] = useState(null);
  useEffect(() => {
    getPolicyData();
  }, [page]);
  const getPolicyData = async () => {
    try {
      let resp = await policyServ.getDetails(page);
      if (resp.data) {
        setPolicyData(resp.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <div className="commonDetailSec">
        <div className="coursesDetailInner d-flex ">
          <div className="detailSec width77 card padding25 detailSecCustom">
            <div className="commonPageContant">
              <div className="pageTopHead ">
                <div className="pageTopHeadInner">
                  <div className="commonPageHeading ">
                    <h2 className="disHeading letterSpacing0">
                      {policyData?.title ? policyData.title : ""}
                      {page === "risks" && "Risks"}
                      {page === "disclaimer" && "Disclaimer"}
                      {page === "trademarks" && "Trademarks"}
                      {page === "cookie-policy" && "Cookie Policy"}
                      {/* {page === "privacy-policy" && "Privacy Policy"}
                      {page === "terms-of-service" && "Terms of Service"} */}
                    </h2>
                    <p className="">Last revised: {moment(policyData?.updatedAt).format("Do MMMM, YYYY")}</p>
                    <p className="mb-0">
                      Revised by:{" "}
                      {policyData?.author?.cover_image && (
                        <img src={policyData?.author?.cover_imae} className="authorCoverImglegal" />
                      )}
                      <span className={policyData?.author?.cover_image && "legalPageUser"}>
                        {policyData?.author?.full_name}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* {page === "risks" && <Risks />}
            {page === "disclaimer" && <Disclaimer />}
            {page === "trademarks" && <Trademarks />}
            {page === "cookie-policy" && <CookiePolicy />}
            {page === "privacy-policy" && <PrivacyPolicy />}
            {page === "terms-of-service" && <TermsOfService />} */}
            <LegalDetail policyData={policyData} />
          </div>
          <SidebarLegal />
        </div>
      </div>
    </div>
  );
}

export default Legal;
