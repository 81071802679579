import util from '../util/util'
export default class AdminZoneService {
    getNotificationDetails(dataId) {
        return util
            .sendApiRequest("/notification/" + dataId, "GET", true)
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }
    // saveNotificationDetail(payload) {
    //     return util.sendApiRequest("/notification", "POST", true, payload).then(
    //         (response) => {
    //             return response;
    //         },
    //         (error) => {
    //             throw error;
    //         }
    //     );
    // }
    MarkNotificationAsRead(payload) {
        return util.sendApiRequest("/notification/markasread", "POST", true, payload).then(
            (response) => {
                return response;
            },
            (error) => {
                throw error;
            }
        );
    }
    listAll(data) {
        const activity = Object.keys(data).reduce((object, key) => {
            if (data[key] !== "") {
                object[key] = data[key];
            }
            return object;
        }, {});

        return util.sendApiRequest("/notification/list", "POST", true, activity).then(
            (response) => {
                return response;
            },
            (error) => {
                throw error;
            }
        );
    }
    MarkById(id){
        return util.sendApiRequest("/notification/markasread/"+id , "POST", true).then(
          (response)=>{
            console.log(response)
            return response;
          },
          (error)=>{
            throw error
          }
        )
      }
}