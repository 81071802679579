import { useEffect, useState } from "react";
import CareerCard from "./CareerCard";
import CareerService from "../../services/CareerService";
import ScrollMoreProvider from "../../shared/Scroll/ScrollMoreProvider";
import TitleCard2 from "../../shared/TitleCard2";
import PageSearch from "../../shared/PageSearch";
import CareerAptPopup from "../../popups/CareerApplicant/CareerApplicant";
import Loading from "../../shared/Loading";
import { Helmet } from "react-helmet";

const serv = new CareerService();

function Careers() {
  const [records, setRecords] = useState([]);
  const [showCareerApt, setShowCareerApt] = useState(false);
  const [showCareerAptId, setShowCareerAptId] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const [recordsCount, setRecordsCount] = useState(0);
  const [search, setSearch] = useState({
    filter: { searchText: "", is_active: true },
    start: 0,
    length: 18,
  });
  let loading = false;

  useEffect(() => {
    getList();
  }, [search]);

  async function getList() {
    if (
      loading === false &&
      (recordsCount == 0 || recordsCount > records.length)
    ) {
      loading = true;
      let respData = await serv.listAll(search);
      setRecords(
        recordsCount == 0 ? respData.data : [...records, ...respData.data]
      );
      setRecordsCount(respData.total);
      setShowLoading(false);
    }
  }

  function reachedBottomCall() {
    let searchTemp = { ...search };
    searchTemp.start = search.start + search.length;
    setSearch(searchTemp);
  }

  function searchCallBack(value) {
    let searchTemp = { ...search };
    searchTemp.start = 0;
    searchTemp.filter.searchText = value;
    setRecordsCount(0);
    setSearch(searchTemp);
  }
  let handleCareerAptPopup = (id) => {
    console.log(id);
    setShowCareerApt(!showCareerApt);
    setShowCareerAptId(id);
  };

  if (showLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <Helmet>
        <title>Careers | MaveFi</title>
      </Helmet>
        <div className="partnerTitleSec">
          <div className="row glossaryTitleSecRow">
            <div className="col-lg-12 col-xl-8 glossaryTitleCol">
              <TitleCard2
                image="/images/svg-icons/laptop.svg"
                title="Careers"
                content="Do you have skills that can help MaveFi improve our services? Are you passionate about educationg & onboarding new users to the decentralised economy? Join us!"
                type="glossary"
              />
              <PageSearch
                placeholder="Search careers"
                searchCallBack={searchCallBack}
              />
            </div>
            <div className="col-lg-12 col-xl-4 ps-xl-0 glossaryTitleCol">
              <div className="h-100 titleCard faqCompleted my-md-0 card">
                <div className="faqComp updatedCard">
                  <h4>Can’t find a suitable role?</h4>
                  <p className="mb-0">
                    Got what it takes to play a leading role in the Web3
                    revolution? Passionate about educating? then reach out to us
                    at <br />
                    <a href="javascript:void(0);">careers@mavefi.com</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="allPartnerCard">
          <div className="allUpdateCardList">
            <div className="updates_card_section updatesCardSection mb-3">
              {records.length > 0 && (
                <ScrollMoreProvider
                  className="row updatesCardSectionRow"
                  id="careersList"
                  reachedBottomCall={reachedBottomCall}
                  useWindowScroll={true}
                >
                  {records.map((c) => {
                    return (
                      <CareerCard
                        key={c._id}
                        career={c}
                        apply={handleCareerAptPopup}
                      />
                    );
                  })}
                </ScrollMoreProvider>
              )}
              {records.length < recordsCount && <Loading isSmall={true} />}
            </div>
          </div>
        </div>
      </div>
      {showCareerApt && (
        <CareerAptPopup
          onClose={handleCareerAptPopup}
          careerId={showCareerAptId}
        />
      )}
    </>
  );
}

export default Careers;
