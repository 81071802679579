function FaqCard({ faq }) {
  return (
    <div className="accordion-item card">
      <h2 className="accordion-header" id={"heading" + faq._id}>
        <button
          className="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={"#collapse" + faq._id}
          aria-expanded="false"
          aria-controls={"collapse" + faq._id}
        >
          {faq.question}
        </button>
      </h2>
      <div
        id={"collapse" + faq._id}
        className="accordion-collapse collapse faq-fix-05"
        aria-labelledby={"heading" + faq._id}
        data-bs-parent="#accordionFaq"
      >
        <div className="accordion-body">
          <p>{faq.answer}</p>
        </div>
      </div>
    </div>
  );
}
export default FaqCard;
