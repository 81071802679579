import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import CourseCard from "../Courses/CourseCard";
import BlogCard from "../Blogs/BlogCard";
import VideoCard from "../Videos/VideoCard";
import Loading from "../../shared/Loading";
import BookmarkService from "../../services/BookmarkService";
import util from "../../util/util";
import { Helmet } from "react-helmet";
import NewsCard from "../News/NewsCard";

const serv = new BookmarkService();

function jqLoadSlider() {
  setTimeout(() => {
    let $ = window.jQuery;

    $(".bookMarkSlider").owlCarousel({
      loop: false,
      margin: 15,
      responsiveClass: true,
      dots: false,
      nav: true,
      responsive: {
        0: {
          items: 1,
        },
        350: {
          items: 1,
        },
        450: {
          items: 2,
        },
        575: {
          items: 2,
        },
        768: {
          items: 3,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 4,
        },
        1440: {
          items: 5,
        },
      },
    });

    $(".bookMarkSlider .owl-prev").html('<img src="/images/svg-icons/next.svg">');
    $(".bookMarkSlider .owl-next").html('<img src="/images/svg-icons/next.svg">');

    $(".blogSlider").owlCarousel({
      loop: false,
      margin: 15,
      responsiveClass: true,
      dots: false,
      nav: true,
      responsive: {
        0: {
          items: 1,
        },
        350: {
          items: 1,
        },
        450: {
          items: 2,
        },
        575: {
          items: 2,
        },
        768: {
          items: 3,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 4,
        },
        1440: {
          items: 5,
        },
      },
    });

    $(".blogSlider .owl-prev").html('<img src="/images/svg-icons/next.svg">');
    $(".blogSlider .owl-next").html('<img src="/images/svg-icons/next.svg">');
  });
}

function Bookmark() {
  const [blogs, setBlogs] = useState([]);
  const [courses, setCourses] = useState([]);
  const [videos, setVideos] = useState([]);
  const [news, setNews] = useState([]);
  const [toggleBookmark, setToggleBookmark] = useState("courses");
  const [records, setRecords] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [recordsCount, setRecordsCount] = useState(0);
  const navigate = useNavigate();

  const [search, setSearch] = useState({
    filter: { searchText: "", is_active: true },
    sort: { createdAt: "desc" },
    start: 0,
    length: 20,
  });
  let loading = false;

  useEffect(() => {
    BookMarkListApi();
  }, []);

  const BookMarkListApi = async () => {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        user_id: util.getUserLocal()._id,
        is_active: true,
      },
    };

    try {
      if (loading === false && (recordsCount == 0 || recordsCount > records.length)) {
        loading = true;
        let response = await serv.listAllWithObjects(activity);
        if (response && response.data) {
          response.data.map((data) => {
            setCourses(data.courses);
            setBlogs(data.blogs);
            setVideos(data.videos);
            setNews(data.news);
            jqLoadSlider();
          });
        }
        setRecords(recordsCount == 0 ? response.data : [...records, ...response.data]);
        setRecordsCount(response.total);
        setShowLoading(false);
      }
    } catch (err) {
      throw err;
    }
  };

  if (!util.getUserLocal()) {
    navigate("/");
  }
  if (showLoading) {
    return <Loading />;
  }
  return (
    <div className="ljSectionData w-100 clearfix " id="ljSectionData">
      <Helmet>
        <title>Bookmarks | MaveFi</title>
      </Helmet>
      <div className="bookmarkSectionData">
        <div className="glossaryTitleSec">
          <div className="row marginleft">
            <div className="col-md-12 borderFix">
              <div className="titleCard titleAboutCard  card borderNone">
                <h4 className="mb-2 margin0 bookMarkHeading">
                  <img src="/images/svg-icons/bookmark.svg" alt="book" className="img-fluid" /> &nbsp;Bookmarks
                </h4>
              </div>
              <div className="d-flex bookmarkNav">
                {/* <p className={`bookmarkToggleBtn ${toggleBookmark == "courses" && "activeBookmark"}`} onClick={() => setToggleBookmark("courses")}>Courses</p>
              //  <p className={`bookmarkToggleBtn ${toggleBookmark == "blogs" && "activeBookmark"}`} onClick={() => setToggleBookmark("blogs")}>Blog Posts</p> */}
                <li
                  className={`bookmarkNavItem letterSpacing0 padding13 ${toggleBookmark == "courses" && "activeLi"}`}
                  onClick={() => setToggleBookmark("courses")}
                  style={{ marginBottom: "0" }}
                >
                  Courses
                </li>
                <li
                  className={`bookmarkNavItem letterSpacing0 padding13 ${toggleBookmark == "news" && "activeLi"}`}
                  onClick={() => setToggleBookmark("news")}
                >
                  News
                </li>
                <li
                  className={`bookmarkNavItem letterSpacing0  padding13${toggleBookmark == "video" && "activeLi"}`}
                  onClick={() => setToggleBookmark("video")}
                >
                  Videos
                </li>
                <li
                  className={`bookmarkNavItem letterSpacing0 padding13 ${toggleBookmark == "blogs" && "activeLi"}`}
                  onClick={() => setToggleBookmark("blogs")}
                >
                  Blog Post
                </li>
              </div>
            </div>
          </div>
        </div>
        <div className="allGlossaryCard ">
          <div className="allUpdateCardList allLearnEarnCardList">
            <div className="updates_card_section updatesCardSection mb-3">
              {/* <div className="row updatesCardSectionRow" id="glossary list"> */}
              {/* {toggleBookmark == "courses" ?
                  courses.length > 0 && (
                    courses.map((c) => {
                      return <CourseCard key={c._id} course={c} bookmark={false} />;
                    })
                  ) :
                  blogs.map((b) => {
                    return <BlogCard key={b._id} blog={b} />;
                  })
                } */}
              {toggleBookmark == "courses" &&
                (courses.length > 0 ? (
                  <div className="row updatesCardSectionRow" id="glossary list">
                    {courses
                      .map((c) => {
                        return <CourseCard key={c._id} course={c} bookmark={false} />;
                      })
                      .reverse()}
                  </div>
                ) : (
                  <p className="emptyDiv">Empty! No bookmarks found.</p>
                ))}
              {toggleBookmark == "blogs" &&
                (blogs.length > 0 ? (
                  <div className="row updatesCardSectionRow" id="glossary list">
                    {blogs
                      .map((b) => {
                        return <BlogCard key={b._id} blog={b} />;
                      })
                      .reverse()}
                  </div>
                ) : (
                  <p className="emptyDiv">Empty! No bookmarks found.</p>
                ))}
              {toggleBookmark == "video" &&
                (videos.length > 0 ? (
                  <div className="row updatesCardSectionRow" id="glossary list">
                    {videos
                      .map((b) => {
                        return <VideoCard key={b._id} video={b} />;
                      })
                      .reverse()}
                  </div>
                ) : (
                  <p className="emptyDiv">Empty! No bookmarks found.</p>
                ))}
              {toggleBookmark == "news" &&
                (news.length > 0 ? (
                  <div className="row updatesCardSectionRow" id="glossary list">
                    {news
                      .map((b) => {
                        return <NewsCard key={b._id} news={b} />;
                      })
                      .reverse()}
                  </div>
                ) : (
                  <p className="emptyDiv">Empty! No bookmarks found.</p>
                ))}
              {/* </div> */}
            </div>
          </div>
        </div>
        {/* <div className="bookmarkSectionInner courses">
          <div className="bookmarkTitleOuter d-flex align-items-center justify-content-between">
            <div className="bookmarkTitle">
              <h5 className="mb-0">In Courses</h5>
            </div>
          </div>
          <div className="bookMarkCard">
            <div className="bookMarkCardList">
              <div className="bookMark_card_section mb-3">
                <div className="row">
                  {courses.length > 0 ? (
                    <div
                      className="owl-carousel bookMarkSlider commonSlider owl-loaded owl-drag"
                      id="bookMarkSlider"
                      style={{ zIndex: 2 }}
                    >
                      {courses.map((c) => {
                        return (
                          <div className="item">
                            <CourseCard
                              key={c._id}
                              course={c}
                              bookmark={false}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="searchResultEmpty">
                      <p>
                        No bookmarks found. Looks like you haven’t saved any
                        courses yet.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="bookmarkSectionInner emptySlider blogPost">
          <div className="bookmarkTitleOuter d-flex align-items-center justify-content-between">
            <div className="bookmarkTitle">
              <h5 className="mb-0">In Blog Posts</h5>
            </div>
          </div>
          <div className="bookMarkCard">
            <div className="bookMarkCardList">
              <div className="bookMark_card_section mb-3">
                <div className="row">
                  {blogs.length > 0 ? (
                    <div
                      className="owl-carousel blogSlider commonSlider owl-loaded owl-drag"
                      id="blogSlider"
                    >
                      {blogs.map((b) => {
                        return <BlogCard key={b._id} blog={b} />;
                      })}
                    </div>
                  ) : (
                    <div className="searchResultEmpty">
                      <p>
                        No bookmarks found. Looks like you haven’t saved any
                        articles yet.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {records.length < recordsCount && <Loading />}
      </div>
    </div>
  );
}

export default Bookmark;
