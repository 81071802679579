function CareerCard({ career, apply }) {
  return (
    <div className="col-sm-6 col-lg-6 col-xl-3 col-xxl-2 cardsBox pb-md-4 pb-3">
      <div className="updates_card">
        <div className="h-100 updatesBoxCareers">
          <div className="h-100 boxCareersMain d-flex flex-column justify-content-between">
            <div className="updatesBoxCareersOuter">
              <div className="updatesBoxCareersInner">
                <div className="updatesBoxHead">
                  <p>Role:</p>
                </div>
                <div className="updatesBoxCont">
                  <p>{career.role}</p>
                </div>
              </div>
              <div className="updatesBoxCareersInner">
                <div className="updatesBoxHead">
                  <p>Location:</p>
                </div>
                <div className="updatesBoxCont">
                  <p>{career.location}</p>
                </div>
              </div>
              <div className="updatesBoxCareersInner">
                <div className="updatesBoxHead">
                  <p>Availability:</p>
                </div>
                <div className="updatesBoxCont">
                  <p>{career.availability}</p>
                </div>
              </div>
            </div>
            <div className="outlineBtn updatesBoxCareersBtn d-grid text-center">
              <a
                href="javascript:void(0);"
                className="btn btnOutlineDark btn-block"
                onClick={() => apply(career._id)}
              >
                APPLY NOW
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CareerCard;
