import { useContext, useState } from "react";
import UserService from "../../services/UserService";
import GlobalContext from "../../context/GlobalContext";

const serv = new UserService();

function BetaPassword() {
  const globalCtx = useContext(GlobalContext);
  const [firstTimeView, setFirstTimeView] = globalCtx.firstTimeView;
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  async function submitPassword() {
    if (password !== "") {
      const respData = await serv.verifyBetaPassword({ value: password });
      if (respData.message == "success") {
        localStorage.setItem("firstTimeView", "no");
        setFirstTimeView("no");
      } else {
        setError("Password does not match");
      }
    }
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="w-100 clearfix betaEnterPassword" id="betaEnterPassword">
      <div className="w-100 betaEnterPasswordInner d-flex align-items-center justify-content-between">
        <div className="card betaPasswordCard">
          <div className="card-header bg-white">
            <div className="betaLogo text-center">
              <img
                src="/images/logo-beta.svg"
                alt="betalogo"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="card-body">
            <div className="middelContant">
              <p className="">Please Enter Beta Access Password</p>
              <div className={"input-group" + (error ? " border-red" : "")}>
                <span className="input-group-text bg-white betaLock border-0">
                  <img
                    src="/images/lock.svg"
                    alt="lock"
                    className="img-fluid"
                  />
                </span>
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control passInput border-0"
                  placeholder="*************"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  style={{ textAlign: "center" }}
                />
                <span className="input-group-text bg-white betaEye border-0 passwordGroupTxt">
                  {/* <img
                    src="/images/eye-hide.svg"
                    alt="eye-hide"
                    className="img-fluid"
                  /> */}
                  {!showPassword ? (
                    <img
                      src="/images/svg-icons/hide.svg"
                      className="passwordHide"
                      onClick={handleShowPassword}
                    />
                  ) : (
                    <img
                      src="/images/svg-icons/show.svg"
                      className="passwordHide"
                      onClick={handleShowPassword}
                    />
                  )}
                </span>
              </div>
              {error && <div className="formik-errors bg-error">{error}</div>}
              <div className="betaSubmit">
                <div className="modelSignBtn">
                  <div className="d-grid">
                    <button
                      type="submit"
                      className={
                        "btn btn-primary btn-block " +
                        (password ? "" : "disabled")
                      }
                      onClick={submitPassword}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer bg-white">
            <div className="footerTxt">
              <p className="mb-0">
                Message us on twitter for access{" "}
                <a href="javascript:void(0);">@joinMaveFi</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BetaPassword;
