import { useState, useContext } from "react";
import { Helmet } from "react-helmet";
import GlobalContext from "../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
const NotFound = () => {
  const navigate = useNavigate();
  const globalCtx = useContext(GlobalContext);
  const [searchText, setSearchText] = useState("");
  const [searchDtl, setSearchDtl] = globalCtx.searchDtl;
  const handleSubmit = () => {
    setSearchDtl(searchText);
    navigate("/search/" + searchText, { state: { search: searchText } });
  };
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <Helmet>
        <title>404 Not Found | MaveFi</title>
      </Helmet>
      <div className="notFound card height400">
        <div className="pageNotFound">
          <div className="notFoundContent">
            <div className="infuriatesIcon">
              <img
                src="images/svg-icons/infuriates.svg"
                alt="infuriates"
                className="img-fluid"
              />
            </div>
            <div className="infuriatesGroup">
              <div className="infuriatesHeading">
                <h2>404 NOT FOUND</h2>
              </div>
              <div className="infuriatesPara">
                <p>
                  Oops! The link you used is broken or the page has been
                  removed. Use the search function below to find the page or
                  information you’re looking for.
                </p>
              </div>
            </div>
            <div className="w-100 notFoundSearchOuter d-flex align-items-center">
              <div className="notFoundSearch">
                <div className="headerSearchBar has-clear">
                  <div className="input-group">
                    <button className="btn " style={{ zIndex: "9" }}>
                      <img
                        src="images/svg-icons/search.svg"
                        alt="logo"
                        className="img-fluid"
                      />
                    </button>
                    <input
                      onChange={(e) => setSearchText(e.target.value)}
                      type="text"
                      className="form-control border-0 searchInput"
                      placeholder="Search courses, profiles, glossary, tools suite and blog posts"
                    />
                    <span className="close form-control-clear hidden">
                      <img
                        src="images/svg-icons/close.svg"
                        alt="search"
                        className="img-fluid"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="notFoundSearchBtn">
                <button
                  onClick={handleSubmit}
                  type="submit"
                  className="btn btn-outline-info notFoundBtn submitBtn"
                >
                  <img
                    src="images/svg-icons/whiteSearchIcon.svg"
                    alt="infuriates"
                    className="img-fluid search-blue"
                  />{" "}
                  <img
                    src="images/svg-icons/search-icon-white.svg"
                    alt="infuriates"
                    className="img-fluid search-icon-white"
                  />{" "}
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NotFound;
