import moment from "moment";
import { useEffect, useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useParams, useNavigate } from "react-router-dom";
import BlogService from "../../services/BlogService";
import NewsSubscriptionService from "../../services/NewsSubscriptionService";
import GlobalContext from "../../context/GlobalContext";
import Loading from "../../shared/Loading";
import SocialShare from "../../shared/SocialShare";
import { Helmet } from "react-helmet";

const serv = new BlogService();
const subsServ = new NewsSubscriptionService();

function BlogArticle() {
  const navigate = new useNavigate();
  const globalCtx = useContext(GlobalContext);
  const { updateBookmark, checkBookmark } = globalCtx;
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const { blogId } = useParams();
  const [showLoading, setShowLoading] = useState(true);
  const [blog, setBlog] = useState(null);
  const [emailActive, setEmailActive] = useState(false);
  const [showSussessPopup, setShowSuccessPopup] = useState(false);
  const [value, setValue] = useState({
    name: "",
    email: "",
  });
  useEffect(() => {
    if (blogId) {
      getDetails();
    }
  }, [blogId]);

  async function getDetails() {
    const respData = await serv.getDetails(blogId);
    if (respData.data && respData.data.is_active) {
      await serv.updateViewCount({ _id: blogId });
      setBlog(respData.data);
      console.log(respData.data);
      setShowLoading(false);
    } else {
      navigate("/notfound");
    }
  }
  const ValidateSchema = Yup.object({
    name: Yup.string().required("Name is required field"),
    email: Yup.string().email("Please enter correct email address").required("Email is required field"),
  });

  const onSubmit = async (values, { resetform }) => {
    let obj = {
      name: values.name,
      email: values.email,
    };
    try {
      const respData = await subsServ.addEmail(obj);
      if (respData.data) {
        setShowSuccessPopup(true);
        setTimeout(() => {
          setShowSuccessPopup(false);
        }, 3000);
        values.name = "";
        values.email = "";
      }
    } catch (error) {
      console.log(error);
    }
  };
  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  if (showLoading) {
    return <Loading />;
  }
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <Helmet>
        <title>{blog.blog_title ? blog.blog_title : "Blog"} - MaveFi</title>
        <meta name="description" content={blog.metadata?.description ? blog.metadata?.description : ""} />
        <meta name="keywords" content={blog.metadata?.tags ? blog.metadata?.tags : ""} />
        <meta property="og:title" content={blog.blog_title || "Mavefi - Blog"} />
        <meta property="og:description" content={blog.metadata?.description || ""} />
        <meta property="og:image" content={blog.blog_image} />
      </Helmet>
      <div className="blogDetailSec">
        <div className="coursesDetailInner d-flex">
          <div className="detailSec card">
            <div className="backArrow">
              <Link to="/blogs">
                <img src="/images/svg-icons/backIcon.svg" alt="back-icon" className="img-fluid backIcon" />
                <img src="/images/svg-icons/backIcon-active.svg" alt="back-icon" className="img-fluid backIconActive" />
              </Link>
            </div>

            <div className="pageMainTopHead">
              <div className="pageTopHead">
                <div className="pageTopHeadInner">
                  <div className="pageTopHeading blogHeading">
                    <h2>{blog?.blog_title}</h2>
                  </div>
                  <div className="postBySec">
                    <div className="postBy">
                      <div className="mainIconSec">
                        <div className="iconSec">
                          <div className="iconOuterSec">
                            <div className="iconInnerSec">
                              <img
                                src="/images/svg-icons/courses-detail-author.svg"
                                alt="mave-logo"
                                className="img-fluid"
                              />
                            </div>
                            <div className="iconTxtSec">
                              <p className="mb-0">Authored By:</p>
                            </div>
                          </div>
                          <div className="iconRightTxt">
                            <p className="mb-0">{blog.author?.full_name}</p>
                          </div>
                        </div>
                        <div className="iconSec">
                          <div className="iconOuterSec">
                            <div className="iconInnerSec">
                              <img
                                src="/images/svg-icons/courses-detail-time.svg"
                                alt="mave-logo"
                                className="img-fluid"
                              />
                            </div>
                            <div className="iconTxtSec">
                              <p className="mb-0">Read Time:</p>
                            </div>
                          </div>
                          <div className="iconRightTxt">
                            <p className="mb-0">{blog?.read_duration} mins</p>
                          </div>
                        </div>
                        <div className="iconSec">
                          <div className="iconOuterSec">
                            <div className="iconInnerSec">
                              <img
                                src="/images/svg-icons/courses-detail-tag.svg"
                                alt="mave-logo"
                                className="img-fluid"
                              />
                            </div>
                            <div className="iconTxtSec">
                              <p className="mb-0">Tags:</p>
                            </div>
                          </div>
                          <div className="iconRightTxt">
                            <p className="mb-0">Metamask, Wallet</p>
                          </div>
                        </div>
                        <div className="iconSec">
                          <div className="iconOuterSec">
                            <div className="iconInnerSec">
                              <img src="/images/svg-icons/date.svg" alt="mave-logo" className="img-fluid" />
                            </div>
                            <div className="iconTxtSec">
                              <p className="mb-0">Date:</p>
                            </div>
                          </div>
                          <div className="iconRightTxt">
                            <p className="mb-0">{moment(blog?.createdAt).format("MMM DD, YYYY")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="shareIcon">
                      <div className="cardBtnIcon infoIconBtn">
                        <div className="headerBtnSec d-flex">
                          {isAuthentiCated && (
                            <div className="modeBtn">
                              <a
                                href="javascript:void(0)"
                                // className="cardBookmark"
                                onClick={() => {
                                  updateBookmark(blog._id, "blog");
                                }}
                              >
                                {checkBookmark(blog._id) ? (
                                  <img
                                    src="/images/svg-icons/bookmark-active.svg"
                                    alt="bookmark"
                                    className="img-fluid bookmarkActive"
                                  />
                                ) : (
                                  <img
                                    src="/images/svg-icons/bookmark-normal.svg"
                                    alt="bookmark"
                                    className="img-fluid bookmarkNormal"
                                  />
                                )}
                                {/* <img
                                src="/images/svg-icons/bookmark-hover.svg"
                                alt="bookmark"
                                className="img-fluid bookmarkHover"
                              /> */}
                              </a>
                            </div>
                          )}
                          <SocialShare
                            twitter={{
                              text: blog.blog_title,
                              url: window.location.origin + "/blogs/" + blog._id,
                            }}
                            facebook={{
                              title: blog.blog_title,
                              url: window.location.origin + "/blogs/" + blog._id,
                            }}
                            website={{
                              title: blog.blog_title,
                              url: window.location.origin + "/blogs/" + blog._id,
                            }}
                          />
                          {/* <div className="modeBtn">
                            <a
                              href="javascript:void(0)"
                              className="cardShareIcon"
                            >
                              <img
                                src="/images/svg-icons/share-rounded-normal.svg"
                                alt="share-rounded"
                                className="img-fluid shareRoundedNormal"
                              />
                              <img
                                src="/images/svg-icons/share-rounded-active.svg"
                                alt="share-rounded"
                                className="img-fluid shareRoundedActive"
                              />
                            </a>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blogContant">
                <div className="blogBanner">
                  <img
                    src={blog?.blog_image}
                    //"/images/svg-icons/blogBanner.png"
                    alt="blogBanner"
                    className="blogImgBanner img-fluid"
                  />
                </div>
                <div className="blogContantInner" dangerouslySetInnerHTML={{ __html: blog?.content }}></div>
                {/* <hr className="hrizoLine" /> */}
                {/* <div className="blogContantDisclaimer">
                  <h4>Disclaimer</h4>
                  <p>
                    This article may contais links to third-party websites or
                    other content for information purposes only (“Third-Party
                    Sites”). The Third-Party Sites are not under the control of
                    MaveFi, and MaveFi is not responsible for the content of any
                    Third-Party Site, including without limitation any link
                    contained in a Third-Party Site, or any changes or updates
                    to a Third-Party Site. MaveFi is providing these links to
                    you only as a convenience, and the inclusion of any link
                    does not imply endorsement, approval or recommendation by
                    MaveFi of the site or any association with its operators.
                    This article is intended to be used and must be used for
                    informational purposes only. It is important to do your own
                    research and analysis before making any material decisions
                    related to any of the products or services described. This
                    article is not intended as, and shall not be construed as,
                    financial advice. The views and opinions expressed in this
                    article are the author's own and do not necessarily reflect
                    those of MaveFi.
                  </p>
                </div> */}
              </div>
            </div>
          </div>
          <div>
            <div className="sticky positonDetail">
              {showSussessPopup ? (
                <div className="successMsgNewsLetter">
                  <img src="/images/svg-icons/handIcon.svg" />
                  <br />
                  <span style={{ paddingTop: "5px" }}>Thank you for subscribing to our newsletter.</span>
                </div>
              ) : (
                <div className="subscribeNewsletter d-none d-xl-block">
                  <div className="faqCompleted my-md-0 card">
                    {/*extra class =  faqCompletedCustom */}
                    <div className="needHelp">
                      <div className="needHelpHead d-flex align-items-center m-0">
                        <div className="needHelpHeading needHelpHeadingCustom">
                          <img src="/images/svg-icons/newsletter-grey.svg" />
                          <h5 className="mb-0">Subscribe to our Newsletter</h5>
                          <p className="">
                            To be the first to hear about the latest news and developments from MaveFi and the world of
                            Web3.
                          </p>
                        </div>
                      </div>
                      {/* <div className="subscribeNewsImg">
                          <img
                            src="/images/svg-icons/detail-bg.svg"
                            alt="rectangle"
                            className="w-100 img-fluid"
                          />
                        </div> */}
                      {showSussessPopup}
                      <div className="subscribeNewsInput">
                        <form onSubmit={formik.handleSubmit}>
                          <div className="newsletterCustom">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your Full Name"
                              name="name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.name}
                            />
                            {formik.touched.name && formik.errors.name ? (
                              <div className="formik-errors bg-error">{formik.errors.name}</div>
                            ) : null}
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Enter your Email Address"
                              name="email"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                            />
                            {formik.touched.email && formik.errors.email ? (
                              <div className="formik-errors bg-error">{formik.errors.email}</div>
                            ) : null}

                            <button
                              type="submit"
                              className={
                                "input-group-text " +
                                (!(formik.isValid && formik.dirty) ? " disabled" : "inputButtonActive")
                              }
                            >
                              Subscribe
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="customAddCardBlog card">
                <amp-ad
                  width="280"
                  height="315"
                  type="adsense"
                  data-ad-client="ca-pub-1429924092740823"
                  data-ad-slot="8890481255"
                  data-auto-format="rspv"
                  data-full-width=""
                >
                  <div overflow=""></div>
                </amp-ad>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogArticle;
