import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import UserService from "../../services/UserService";
import { useFormik } from "formik";
import * as Yup from "yup";
import GlobalContext from "../../context/GlobalContext";
import axios from "axios";
import Loading from "../../shared/Loading";
const serv = new UserService();
const ValidateSchema = Yup.object({
  full_name: Yup.string().required("Name is a required field"),
  email: Yup.string()
    .required("Email is a required field")
    .email()
    .test(
      "Unique Email",
      "Email already in use", // <- key, message
      function (value) {
        return new Promise((resolve, reject) => {
          axios
            .post(process.env.REACT_APP_API_BASEURL + `/user/verifyEmail`, {
              email: value,
            })
            .then((res) => {
              if (res.data.message === "Available") {
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
              if (error.response.data.content === "Not Available") {
                resolve(false);
              }
            });
        });
      }
    ),
  password: Yup.string().required("Password is a required field"),
  accept: Yup.boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted."),
});

function Signup({ onClose, onFinish, onShowLogin, handleShowSocialLogin }) {
  const globalCtx = useContext(GlobalContext);
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [signupObj, setSignupObj] = new useState({
    full_name: "",
    email: "",
    password: "",
    accept: false,
  });
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const onSubmit = async (values) => {
    setIsLoading(true);
    let obj = { ...values };
    try {
      const resp = await serv.sendOtp(obj);
      if (resp?.message) {
        localStorage.setItem("userDetails", JSON.stringify(obj));
        setIsLoading(false);
        onFinish();
      } else {
        setIsLoading(false);
        setErrorMsg(resp.error);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.message);
    }
  };
  const formik = useFormik({
    initialValues: signupObj,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  return (
    <>
      <div
        className="modal commanModel SignupPopup showBlock"
        id="Signup"
        onClick={() => {
          onClose();
        }}
      >
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center">
            <div className="modal-dialog">
              <div
                className="modal-content"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {/* <!-- Modal Header --> */}
                <div className="modal-header">
                  <h4 className="modal-title">Sign up</h4>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                      onClose();
                    }}
                  ></button>
                </div>
                {/* <!-- Modal body --> */}
                <div className="modal-body">
                  <div className="connectWalletOuter">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="modelFormInput">
                        <label for="signupfname">Full name</label>
                        <div
                          className={
                            "input-group" + (formik.touched.full_name && formik.errors.full_name ? " border-red" : "")
                          }
                        >
                          <span className="input-group-text pe-0">
                            <img src="/images/svg-icons/userGray1.svg" />
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="John Doe"
                            name="full_name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.full_name}
                          />
                        </div>
                        {formik.touched.full_name && formik.errors.full_name ? (
                          <div className="formik-errors bg-error">{formik.errors.full_name}</div>
                        ) : null}
                      </div>
                      <div className="modelFormInput">
                        <label for="signupemail">Email address</label>
                        <div
                          className={"input-group" + (formik.touched.email && formik.errors.email ? " border-red" : "")}
                        >
                          <span className="input-group-text pe-0">
                            <img src="/images/svg-icons/email-gray-icon.svg" />
                          </span>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="john@mavefi.com"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                        </div>
                        {formik.touched.email && formik.errors.email ? (
                          <div className="formik-errors bg-error">{formik.errors.email}</div>
                        ) : null}
                      </div>
                      <div className="modelFormInput">
                        <label for="signuppass">Password</label>
                        <div
                          className={
                            "input-group" + (formik.touched.password && formik.errors.password ? " border-red" : "")
                          }
                        >
                          <span className="input-group-text pe-0">
                            <img src="/images/svg-icons/password.svg" />
                          </span>
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="*************"
                            name="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                          />
                          <span className="input-group-text passwordGroupTxt ps-0">
                            {!showPassword ? (
                              <img
                                src="/images/svg-icons/hide.svg"
                                className="passwordHide"
                                onClick={handleShowPassword}
                              />
                            ) : (
                              <img
                                src="/images/svg-icons/show.svg"
                                className="passwordHide"
                                onClick={handleShowPassword}
                              />
                            )}
                          </span>
                        </div>
                        {formik.touched.password && formik.errors.password ? (
                          <div className="formik-errors bg-error">{formik.errors.password}</div>
                        ) : null}
                      </div>
                      <div className="modelFormInput">
                        <div className={"form-check" + (formik.errors.accept ? " border-red" : "")}>
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="accept"
                              checked={formik.values.accept}
                              onClick={() => formik.setFieldValue("accept", !formik.values.accept)}
                              value={formik.values.accept}
                            />{" "}
                            Accept our{" "}
                            <a href="/legal/terms-of-service" target="_blank">
                              Terms of Service
                            </a>{" "}
                            &{" "}
                            <a href="/legal/privacy-policy" target="_blank">
                              Privacy Policy
                            </a>
                          </label>
                        </div>
                        {formik.errors.accept ? (
                          <div className="formik-errors bg-error">{formik.errors.accept}</div>
                        ) : null}
                      </div>
                      {errorMsg && <div className="formik-errors bg-error text-center">{errorMsg}</div>}
                      <div className="modelSignBtn">
                        <div className="d-grid">
                          <button
                            type="submit"
                            className={
                              "btn btn-primary btn-block" +
                              (Object.keys(formik.errors).length !== 0 || !formik.touched || isLoading
                                ? " disabled"
                                : "")
                            }
                          >
                            Sign up {isLoading && <Loading isInline={true} />}
                          </button>
                          <button
                            type=""
                            className={"btn btn-primary btn-block social-login-btn"}
                            onClick={() => handleShowSocialLogin("login")}
                          >
                            Or signup with
                            <img src="/images/svg-icons/social-combo-blue.svg" className="img-fluid" />
                            {/* {isLoading && <Loading isInline={true} />} */}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {/* <!-- Modal footer --> */}
                <div className="modal-footer justify-content-center">
                  <div className="footerLink">
                    <p className="mb-0">
                      Already have an account?{" "}
                      <Link
                        to="#"
                        onClick={() => {
                          onShowLogin();
                        }}
                      >
                        Login now
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
}

export default Signup;
