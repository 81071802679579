import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import UserService from "../../services/UserService";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loading from "../../shared/Loading";
const serv = new UserService();
const ValidateSchema = Yup.object({
  currentPassword: Yup.string().required(
    "Current Password is a required field"
  ),
  newPassword: Yup.string().required("New Password is a required field"),
  verifyPassword: Yup.string().required("Confirm password is a required field"),
});

function ChangePassword({ onClose, onFinish, token }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [obj, setObj] = new useState({
    currentPassword: "",
    newPassword: "",
    verifyPassword: "",
  });

  const onSubmit = async (values) => {
    setIsLoading(true)
    let obj = { ...values };
    try {
      const resp = await serv.changePassword(obj, token);
      if (resp?.message) {
        onClose();
      }
      setIsLoading(false)
    } catch (err) { }
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };
  const formik = useFormik({
    initialValues: obj,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <>
      <div
        className="modal commanModel SignupPopup showBlock"
        id="Signin"
        onClick={() => {
          onClose();
        }}
      >
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center">
            <div className="modal-dialog">
              <div
                className="modal-content"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {/* <!-- Modal Header --> */}
                <div className="modal-header">
                  <h4 className="modal-title">Change Password</h4>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                      onClose();
                    }}
                  ></button>
                </div>
                {/* <!-- Modal body --> */}
                <div className="modal-body">
                  <div className="connectWalletOuter">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="modelFormInput">
                        <label for="signinpass">Enter Current Password</label>
                        <div
                          className={
                            "input-group" +
                            (formik.touched.currentPassword &&
                              formik.errors.currentPassword
                              ? " border-red"
                              : "")
                          }
                        >
                          <span className="input-group-text pe-0">
                            <img src="/images/svg-icons/password.svg" />
                          </span>
                          <input
                            type={showCurrentPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="*************"
                            name="currentPassword"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.currentPassword}
                          />
                          <span className="input-group-text passwordGroupTxt ps-0">
                            {!showCurrentPassword ? (
                              <img
                                src="/images/svg-icons/hide.svg"
                                className="passwordHide"
                                onClick={handleShowCurrentPassword}
                              />
                            ) : (
                              <img
                                src="/images/svg-icons/show.svg"
                                className="passwordHide"
                                onClick={handleShowCurrentPassword}
                              />
                            )}
                          </span>
                        </div>
                        {formik.touched.currentPassword &&
                          formik.errors.currentPassword ? (
                          <div className="formik-errors bg-error">
                            {formik.errors.currentPassword}
                          </div>
                        ) : null}
                      </div>
                      <div className="modelFormInput">
                        <label for="signinpass">Enter New Password</label>
                        <div
                          className={
                            "input-group" +
                            (formik.touched.newPassword &&
                              formik.errors.newPassword
                              ? " border-red"
                              : "")
                          }
                        >
                          <span className="input-group-text pe-0">
                            <img src="/images/svg-icons/password.svg" />
                          </span>
                          <input
                            type={showNewPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="*************"
                            name="newPassword"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.newPassword}
                          />
                          <span className="input-group-text passwordGroupTxt ps-0">
                            {!showNewPassword ? (
                              <img
                                src="/images/svg-icons/hide.svg"
                                className="passwordHide"
                                onClick={handleShowNewPassword}
                              />
                            ) : (
                              <img
                                src="/images/svg-icons/show.svg"
                                className="passwordHide"
                                onClick={handleShowNewPassword}
                              />
                            )}
                          </span>
                        </div>
                        {formik.touched.newPassword &&
                          formik.errors.newPassword ? (
                          <div className="formik-errors bg-error">
                            {formik.errors.newPassword}
                          </div>
                        ) : null}
                      </div>
                      <div className="modelFormInput">
                        <label for="signinpass">Confirm New Password</label>
                        <div
                          className={
                            "input-group" +
                            (formik.touched.verifyPassword &&
                              formik.errors.verifyPassword
                              ? " border-red"
                              : "")
                          }
                        >
                          <span className="input-group-text pe-0">
                            <img src="/images/svg-icons/password.svg" />
                          </span>
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="*************"
                            name="verifyPassword"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.verifyPassword}
                          />
                          <span className="input-group-text passwordGroupTxt ps-0">
                            {!showPassword ? (
                              <img
                                src="/images/svg-icons/hide.svg"
                                className="passwordHide"
                                onClick={handleShowPassword}
                              />
                            ) : (
                              <img
                                src="/images/svg-icons/show.svg"
                                className="passwordHide"
                                onClick={handleShowPassword}
                              />
                            )}
                          </span>
                        </div>
                        {formik.touched.verifyPassword &&
                          formik.errors.verifyPassword ? (
                          <div className="formik-errors bg-error">
                            {formik.errors.verifyPassword}
                          </div>
                        ) : null}
                      </div>
                      <div className="modelSignBtn">
                        <div className="d-grid">
                          <button
                            type="submit"
                            className={
                              "btn btn-primary btn-block" +
                              (Object.keys(formik.errors).length !== 0 ||
                                !formik.touched || isLoading
                                ? " disabled"
                                : "")
                            }
                          >
                            Change Password {isLoading && <Loading isInline={true} />}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {/* <!-- Modal footer --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
}

export default ChangePassword;
