import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import CourseService from "../../services/CourseService";
import UserAnswerService from "../../services/UserAnswerService";
import QuizPass from "../../popups/QuizPopup/PassedQuiz";
import QuizFail from "../../popups/QuizPopup/FailedQuiz";
import util from "../../util/util";
import Loading from "../../shared/Loading";
import { Helmet } from "react-helmet";
const serv = new CourseService();
const answerServ = new UserAnswerService();

function CourseQuiz() {
  const navigate = useNavigate();
  const globalCtx = useContext(GlobalContext);
  const [user, setUser] = globalCtx.user;
  const { courseId } = useParams();
  const [showLoading, setShowLoading] = useState(true);
  const [course, setCourse] = useState(null);
  const [userCourse, setUserCourse] = useState(null);
  const [userAnswer, setUserAnswer] = useState([]);
  const [preUserAnswer, setPreUserAnswer] = useState({});
  const [earnedRewards, setEarnedRewards] = useState({});
  const [submitPopUp, setSubmitPopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (courseId) {
      getDetails();
    }
  }, [courseId]);
  useEffect(() => {
    getAnswers();
  }, [course]);
  async function getDetails() {
    const respData = await serv.getCourse(courseId);
    console.log(respData);
    if (respData && respData.data && respData.data.is_active) {
      let diff = 0;
      if (respData?.courseReward) {
        let date = new Date();
        diff = moment(respData?.courseReward?.failed_time).add(24, "hours").diff(moment(date));
      }
      if (respData.isRewardClaimed || diff > 0) {
        navigate("/courses/course/" + courseId);
      }
      await serv.updateViewCount({ _id: courseId });
      setCourse(respData.data);
      setUserCourse(respData.userCourse);
      setShowLoading(false);
    } else {
      navigate("/notfound");
    }
  }
  const getAnswers = async () => {
    let question_ids = course.questions.reduce((pV, cV) => {
      pV.push(cV._id);
      return pV;
    }, []);
    let filter = {
      filter: {
        user_id: user._id,
        question_id: question_ids,
      },
    };
    const answerData = await answerServ.listAll(filter);
    let ques_ans_list = {};
    let userAnsArr = [];
    if (answerData.data.length > 0) {
      answerData.data.forEach((item) => {
        ques_ans_list[item.question_id] = item.user_answers[0];
        userAnsArr.push({
          user_id: item.user_id,
          course_id: item.course_id,
          question_id: item.question_id,
          user_answers: item.user_answers,
        });
      });
      setPreUserAnswer(ques_ans_list);
      setUserAnswer(userAnsArr);
    }
  };
  const handleRadio = (e) => {
    let userArr = userAnswer;
    const index = userArr.findIndex((obj) => obj.question_id == e.target.name);
    if (index == -1) {
      userArr.push({
        user_id: user._id,
        course_id: courseId,
        question_id: e.target.name,
        user_answers: [e.target.value],
      });
    } else {
      userArr[index].user_answers = [e.target.value];
    }
    let ansObj = preUserAnswer;
    ansObj[e.target.name] = e.target.value;
    setPreUserAnswer({ ...ansObj });
    setUserAnswer(userArr);
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      let data = {
        answers: userAnswer,
        courseId: courseId,
        userId: user._id,
      };
      let resp = await answerServ.addUserAnswer(data);
      setIsLoading(false);
      setEarnedRewards({ ...resp.reward });
      setSubmitPopUp(true);
    } catch (err) {
      console.error(err);
    }
  };
  const handlePopUp = async () => {
    setSubmitPopUp(false);
  };
  if (!util.getUserLocal()) {
    navigate("/courses/info/" + courseId);
  }
  if (userCourse && userCourse.status < 70) {
    navigate("/courses/course/" + courseId);
  }
  if (showLoading || !course) {
    return <Loading />;
  }
  return (
    <div>
      <div className="ljSectionData w-100 clearfix" id="ljSectionData">
        <Helmet>
          <title>Learn {course.metadata?.title ? course.metadata?.title : ""} - MaveFi</title>
          <meta name="description" content={course.metadata?.description ? course.metadata?.description : ""} />
          <meta name="keywords" content={course.metadata?.tags ? course.metadata?.tags : ""} />
        </Helmet>
        <div className="coursesDetailSec">
          <div className="coursesDetailInner d-flex">
            <div className="detailSec card">
              <div className="backArrow">
                <Link to={"/courses/course/" + courseId}>
                  <img src="/images/svg-icons/backIcon.svg" alt="back-icon" className="img-fluid backIcon" />
                  <img
                    src="/images/svg-icons/backIcon-active.svg"
                    alt="back-icon"
                    className="img-fluid backIconActive"
                  />
                </Link>
              </div>
              <div className="pageMainTopHead pageMainTopMcq" id="rewardTest">
                <div className="pageTopHead">
                  <div className="pageTopHeadInner">
                    <div className="subTitleHeading">
                      <h5>{course.course_name}</h5>
                    </div>
                    <div className="pageTopHeading">
                      <h2>REWARD TEST</h2>
                    </div>
                    <div className="pageTopPara">
                      <p>
                        Looks like you have completed a course. You can now take the below test to claim your course
                        rewards. This test provides multiple answer choices for each question, but only one of them is
                        correct. So choose wisely.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mcqQuestion">
                  {course.questions.map((ques, key) => {
                    return (
                      <div className="mcqQuesGroup">
                        <div className="questionGroup">
                          <div className="questionPoints">
                            <p className="mb-0">Q)</p>
                          </div>
                          <div className="question">
                            <p className="mb-0">{ques.question}</p>
                          </div>
                        </div>
                        <ul>
                          {ques.option.map((element) => {
                            return (
                              <li>
                                <div className="form-check">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    id="radio1"
                                    name={ques._id}
                                    value={element}
                                    checked={element == preUserAnswer[ques._id]}
                                    onClick={handleRadio}
                                  />
                                  <label className="form-check-label" for="radio1">
                                    {element}
                                  </label>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })}
                  <div className="mcqSubmitBtn mcqSubmitBtnCustom">
                    <a
                      type="button"
                      className={`btn btnSubmit d-flex align-items-center mb-2 lh-26 ${
                        course.questions.length == userAnswer.length || isLoading ? "" : "disabled"
                      }`}
                      onClick={handleSubmit}
                    >
                      Submit {isLoading && <Loading isInline={true} />}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailMenuSec">
              <div className="pageSideToggle d-block d-xl-none">
                <div className="pageSidebarToggle">
                  <img src="/images/svg-icons/sideArrow.svg" alt="back-icon" className="img-fluid" />
                </div>
              </div>
              <div className="detailMenuOuterSec sticky card" id="sidebar">
                <div className="detailMenuInnerSec">
                  <div className="detailMenuHeading">
                    <h4>CONTENT TABLE</h4>
                  </div>
                  <div className="detailMenuLink detailSideLink">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a className="nav-link clickHere" href={"/courses/course/" + course._id + "/#intro"}>
                          Intro
                        </a>
                      </li>
                      {course.lessons.map((l, idx) => (
                        <li className="nav-item">
                          <a
                            className="nav-link clickHere"
                            href={"/courses/course/" + course._id + "/#lesson" + (idx + 1)}
                            // onClick={(e) => scrollToLesson(e)}
                          >
                            Lesson {idx + 1}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="rewardTest detailSideLink">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a className="nav-link active" href="#rewardTest">
                          Reward Test
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {submitPopUp && earnedRewards.reward_percent >= 60 && (
        <QuizPass
          name={course.course_name}
          reward={earnedRewards.rewards}
          onClose={() => {
            handlePopUp();
            navigate("/courses");
          }}
        />
      )}
      {submitPopUp && earnedRewards.reward_percent < 60 && (
        <QuizFail
          onClose={() => {
            handlePopUp();
            navigate("/courses");
          }}
          failedTime={earnedRewards.failed_time}
        />
      )}
    </div>
  );
}

export default CourseQuiz;
