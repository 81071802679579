import { useEffect, useState } from "react";
import FaqCard from "./FaqCard";
import FaqService from "../../services/FaqService";
import ScrollMoreProvider from "../../shared/Scroll/ScrollMoreProvider";
import TitleCard2 from "../../shared/TitleCard2";
import PageSearch from "../../shared/PageSearch";
import Loading from "../../shared/Loading";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import FaqsSearch from "./FaqSearch";
import PageHeader from "../../shared/PageHeader";

const serv = new FaqService();

function Faq() {
  const [records, setRecords] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [recordsCount, setRecordsCount] = useState(0);
  const [search, setSearch] = useState({
    filter: { searchText: "", is_active: true },
    start: 0,
    length: 20,
  });
  let loading = false;

  useEffect(() => {
    getList();
  }, [search]);

  async function getList() {
    if (loading === false && (recordsCount == 0 || recordsCount > records.length)) {
      loading = true;
      let respData = await serv.listAll(search);
      setRecords(recordsCount == 0 ? respData.data : [...records, ...respData.data]);
      setRecordsCount(respData.total);
      setShowLoading(false);
    }
  }

  function reachedBottomCall() {
    let searchTemp = { ...search };
    searchTemp.start = search.start + search.length;
    setSearch(searchTemp);
  }

  function searchCallBack(value, filter, sortBy) {
    let searchTemp = { ...search };
    searchTemp.start = 0;
    searchTemp.filter = filter;
    searchTemp.filter.searchText = value;
    setRecordsCount(0);
    setSearch(searchTemp);
  }

  if (showLoading) {
    return <Loading />;
  }
  const handleRichText = () => {
    return (
      <div className="h-100 titleCard faqCompleted my-md-0 card">
        <div className="faqComp">
          <h4>Can not find the answers to your questions?</h4>
          <p className="mb-0">
            <div className="needHelp">
              <ul className="needHelpList">
                <li className="helpList d-flex align-items-center">
                  <div className="needHelpIcon">
                    <img src="/images/svg-icons/headphone.svg" alt="needHelpIcon" className="img-fluid" />
                  </div>
                  <div className="needHelpHeading">
                    <p className="mb-0">
                      Reach out to us via <Link to="/support">Support</Link>
                    </p>
                  </div>
                </li>
                <li className="helpList d-flex align-items-center">
                  <div className="needHelpIcon">
                    <img src="/images/svg-icons/email-sign.svg" alt="needHelpIcon" className="img-fluid" />
                  </div>
                  <div className="needHelpHeading">
                    <p className="mb-0">
                      Email us at <a href="mailto:support@mavefi.com">support@mavefi.com</a>
                    </p>
                  </div>
                </li>
                <li className="helpList d-flex align-items-center">
                  <div className="needHelpIcon">
                    <img src="/images/svg-icons/twitter-squared.svg" alt="needHelpIcon" className="img-fluid" />
                  </div>
                  <div className="needHelpHeading">
                    <p className="mb-0">
                      OR Tweet us{" "}
                      <a href="https://twitter.com/joinmavefi" target="_blank">
                        @MaveFiSupport
                      </a>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <Helmet>
        <title>FAQ's | MaveFi</title>
      </Helmet>
      {/* <PageHeader
        type="faq"
        searchCallBack={searchCallBack}
        search={search}
        // handlePopUp={handleGlossaryPopup}
        titleCard={{
          image: "/images/svg-icons/faq.svg",
          title: "FAQ’s",
          content:
            "You’re bound to have a lot of questions related to MaveFi, and all the aspects of our platform. This is where you’ll find the answers to those questions.",
          type: "glossary",
        }}
        searchType={{ type: "faq", placeholder: "Search faqs" }}
        loginTitle="Please login/sign up to contribute a word to our library."
        rightActionCard={{
          heading: "Submit a Word/Slang",
          desc: "There are new words/slangs coined everyday in the web3 space & you can help us index them by submitting your entries below. ",
          buttonName: "Contribute",
          richText: handleRichText(),
        }}
      /> */}
      <div className="faqTitleSec">
        <div className="row glossaryTitleSecRow">
          <div className="col-lg-12 col-xl-8 glossaryTitleCol faqTitleColCustom">
            <TitleCard2
              image="/images/svg-icons/faq.svg"
              title="FAQ’s"
              type="glossary"
              content="You’re bound to have a lot of questions related to MaveFi, and all the aspects of our platform. This is where you’ll find the answers to those questions."
            />
            <FaqsSearch placeholder="Search faqs" searchCallBack={searchCallBack} />
          </div>
          <div className="col-lg-12 col-xl-4 ps-xl-0 glossaryTitleCol">
            <div className="h-100 titleCard faqCompleted my-md-0 card">
              <div className="faqComp">
                <h4>Can not find the answers to your questions?</h4>
                <p className="mb-0">
                  <div className="needHelp">
                    <ul className="needHelpList">
                      <li className="helpList d-flex align-items-center">
                        <div className="needHelpIcon">
                          <img src="/images/svg-icons/headphone.svg" alt="needHelpIcon" className="img-fluid" />
                        </div>
                        <div className="needHelpHeading">
                          <p className="mb-0">
                            Reach out to us via <Link to="/support">Support</Link>
                          </p>
                        </div>
                      </li>
                      <li className="helpList d-flex align-items-center">
                        <div className="needHelpIcon">
                          <img src="/images/svg-icons/email-sign.svg" alt="needHelpIcon" className="img-fluid" />
                        </div>
                        <div className="needHelpHeading">
                          <p className="mb-0">
                            Email us at <a href="mailto:support@mavefi.com">support@mavefi.com</a>
                          </p>
                        </div>
                      </li>
                      <li className="helpList d-flex align-items-center">
                        <div className="needHelpIcon">
                          <img src="/images/svg-icons/twitter-squared.svg" alt="needHelpIcon" className="img-fluid" />
                        </div>
                        <div className="needHelpHeading">
                          <p className="mb-0">
                            OR Tweet us{" "}
                            <a href="https://twitter.com/joinmavefi" target="_blank">
                              @MaveFiSupport
                            </a>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="faqQues">
        <div className="row glossaryTitleSecRow">
          <div className="col-lg-12 col-xl-8 glossaryTitleCol">
            {records.length > 0 && (
              <ScrollMoreProvider
                className="accordion faqAccordion"
                id="accordionFaq"
                reachedBottomCall={reachedBottomCall}
                useWindowScroll={true}
              >
                {records.map((f) => {
                  return <FaqCard key={f._id} faq={f} />;
                })}
              </ScrollMoreProvider>
            )}
            {records.length < recordsCount && <Loading isSmall={true} />}
          </div>
          {/* <div className="col-lg-12 col-xl-4 ps-xl-0 glossaryTitleCol">
            <div className="faqCompleted my-md-0 card">
              <div className="needHelp">
                <div className="needHelpHead d-flex align-items-center">
                  <div className="needHelpIcon">
                    <img
                      src="/images/svg-icons/sos.png"
                      alt="needHelpIcon"
                      className="img-fluid"
                    />
                  </div>
                  <div className="needHelpHeading">
                    <h5 className="mb-0">Need Help?</h5>
                  </div>
                </div>
                <ul className="needHelpList">
                  <li className="helpList d-flex align-items-center">
                    <div className="needHelpIcon">
                      <img
                        src="/images/svg-icons/email-sign.svg"
                        alt="needHelpIcon"
                        className="img-fluid"
                      />
                    </div>
                    <div className="needHelpHeading">
                      <p className="mb-0">
                        Email us at{" "}
                        <a href="javascript:void(0);">support@mavefi.com</a>
                      </p>
                    </div>
                  </li>
                  <li className="helpList d-flex align-items-center">
                    <div className="needHelpIcon">
                      <img
                        src="/images/svg-icons/twitter-squared.svg"
                        alt="needHelpIcon"
                        className="img-fluid"
                      />
                    </div>
                    <div className="needHelpHeading">
                      <p className="mb-0">
                        OR Tweet us{" "}
                        <a
                          href="https://twitter.com/joinmavefi"
                          target="_blank"
                        >
                          @MaveFiSupport
                        </a>
                      </p>
                    </div>
                  </li>
                  <li className="helpList d-flex align-items-center">
                    <div className="needHelpIcon">
                      <img
                        src="/images/svg-icons/discord-new.svg"
                        alt="needHelpIcon"
                        className="img-fluid"
                      />
                    </div>
                    <div className="needHelpHeading">
                      <p className="mb-0">
                        OR Open a ticket in our{" "}
                        <a
                          href="https://discord.com/invite/mavefi"
                          target="_blank"
                        >
                          Discord
                        </a>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Faq;
