import React from "react";
import { useContext } from "react";
import GlobalContext from "../context/GlobalContext";
import BlogsSearch from "../pages/Blogs/BlogSearch";
import CoursesSearch from "../pages/Courses/CoursesSearch";
import LoginSignupCard from "./LoginSignupCard";
import TitleCard2 from "./TitleCard2";
import PageSearch from "./PageSearch";
import RightActionCard from "./RightActionCard";
import FaqsSearch from "../pages/Faq/FaqSearch";
import PageSearchNew from "./PageSearchNew";

function PageHeader({
  searchCallBack,
  search,
  titleCard,
  searchType,
  loginTitle,
  rightActionCard,
  sortArray,
  allowedFilter,
  filterListObj,
  filterObj,
  newsLetter,
  topW100,
  height98,
}) {
  const globalCtx = useContext(GlobalContext);
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const [user, setUser] = globalCtx.user;
  // const componentNames = {
  //   blog: BlogsSearch,
  //   course: CoursesSearch,
  //   glossary: PageSearch,
  //   faq: FaqsSearch,
  //   video: VideoSearch,
  // };

  // const SearchComponent = componentNames[searchType.type];
  return (
    <div className="glossaryTitleSec">
      <div className={"row " + (!topW100 ? " glossaryTitleSecRow" : " ")}>
        <div className={"col-lg-12" + (!topW100 ? "col-xl-8 glossaryTitleCol" : " ")}>
          <TitleCard2 {...titleCard} height98={height98} />
          <div className={search.sortLabel ? "filterSec d-flex align-items-center glosaary-search-custom" : ""}>
            {/* <SearchComponent
              sortLabel={search.sortLabel}
              sortHandler={search.sortHandler}
              placeholder={searchType.placeholder}
              searchCallBack={searchCallBack}
              searchText={search.filter.searchText}
              searchFilter={search.filter}
            /> */}
            <PageSearchNew
              filterListObj={filterListObj}
              filterObj={filterObj}
              allowedFilter={allowedFilter}
              sortArray={sortArray}
              sortLabel={search.sortLabel}
              sortHandler={search.sortHandler}
              placeholder={searchType.placeholder}
              searchCallBack={searchCallBack}
              searchText={search.filter.searchText}
              searchFilter={search.filter}
            />
          </div>
        </div>
        {!newsLetter && (
          <div className="col-lg-12 col-xl-4 ps-xl-0 glossaryTitleCol">
            {isAuthentiCated ? (
              <RightActionCard {...rightActionCard} />
            ) : searchType.type == "blog" ? (
              <RightActionCard {...rightActionCard} />
            ) : (
              <LoginSignupCard title={loginTitle} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default PageHeader;
